import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FuseConfigService } from '@fuse/services/config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalConstants } from 'app/common/global-constants';
import { InterceptorSkipHeader } from 'app/pixcore/shared/auth.interceptor';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TokenService } from 'app/pixcore/shared/token.service';
import { AuthStateService } from 'app/pixcore/shared/auth-state.service';
import { LoginLinkedinService } from 'app/public/login-linkedin/login-linkedin.service';


@Component({
  selector: "login-linkedin",
  templateUrl: "./login-linkedin.component.html",
  styleUrls: ["./login-linkedin.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class LoginLinkedinComponent implements OnInit {
  linkedInToken = "";
  urlLocal = GlobalConstants.apiURL;
  URL = GlobalConstants.URL;
  loading = false;
  userLogIn = [];

  constructor(
    private route: ActivatedRoute, 
    private _fuseConfigService: FuseConfigService, 
    private httpClient: HttpClient,
    private _snackBar: MatSnackBar,
    private token: TokenService,
    private authState: AuthStateService,
    private router: Router,
    private loginLinkedinService: LoginLinkedinService
    
    
    ) {

    this._fuseConfigService.config = {
      layout: {
          navbar: {
              hidden: true
          },
          toolbar: {
              hidden: true
          },
          footer: {
              hidden: true
          },
          sidepanel: {
              hidden: true
          }
      }
  };

  }
  ngOnInit() {

    this.loading = true;

    this.linkedInToken = this.route.snapshot.queryParams["code"];

    const headers = new HttpHeaders().set(InterceptorSkipHeader, '');

    const formData = new FormData();
    formData.append("code", this.linkedInToken);
    formData.append("redirect_uri", `${this.URL}/login-linkedin`);
    formData.append("user_type", "1");
    formData.append("provider", "linkedin");
    formData.append("provider_id", this.linkedInToken);


    this.httpClient.post(`${this.urlLocal}auth_user_social_media_linkedin`, formData, {headers: headers}).subscribe(
      (result: any) => {
          if (!result.errors) {

              localStorage.removeItem('admin_mode');
              this.openSnackBar("Ingreso satisfactorio", 'snack-success');
              this.responseHandler(result.sessionData);
              this.authState.removeUserSession();
              this.authState.setAuthState(true);
              this.router.navigate(['/home']);
              this.loading = false;
              this.userLogIn = result.user;
              this.loginLinkedinService.updateLinkedinUser(this.userLogIn);
          
            } else {

              this.loading = false;
              this.openSnackBar(result.message, 'snack-error');
              console.log(result.message);
          }

      });
    
  }

  // Handle response
  responseHandler(data){
    this.token.handleData(data.access_token);
  }

  /**
   * Mostrar la respuesta al usuario
   * @param mensaje
   */
   private openSnackBar(mensaje: string, clase: string): void {
    this._snackBar.open(mensaje, 'x', {
     duration: 4000,
     horizontalPosition: 'end',
     verticalPosition: 'top',
     panelClass: clase,
   });
  }
}
