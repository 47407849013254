export const locale = {
    lang: 'es',
    data: {
        'WELCOME': {
            'TITLE': '¡Hola! Bienvenido a ___ :)',
            'SUBTITLE': '___'
        },
        'REGISTER_FORM': {
            'TITLE': 'CREA TU CUENTA',
            'NAME': 'Nombre',
            'EMAIL': 'Correo',
            'PASSWORD': 'Contraseña',
            'PASSWORD_CONFIRM': 'Contraseña (Confirmar)',
            'ACCEPT': 'Leí y acepto',
            'TERMS': 'términos y condiciones',
            'BUTTON_ACCEPT': 'CREAR CUENTA',
            'BUTTON_ACCEPT_FB': 'Registrarme con Facebook',
            'BUTTON_ACCEPT_GOOGLE': 'Registrarme con Google',
            'BUTTON_ACCEPT_LINKEDIN': 'Registrarme con Linkedin',
            'ALREADY_ACCOUNT': '¿Ya tienes una cuenta?',
            'LOGIN': 'Ingresa',            
            'USER_TYPE': 'Te registrarás como...',
        }
    }
};
