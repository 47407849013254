import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Vacante } from 'app/public/lista-vacantes/model/vacante.model';
import { GlobalConstants } from 'app/common/global-constants';
import { AuthStateService } from 'app/pixcore/shared/auth-state.service';
import { InterceptorSkipHeader } from 'app/pixcore/shared/auth.interceptor';


@Injectable()
export class MinisitioVacantesService implements Resolve<any>
{


    urlLocal = GlobalConstants.apiURL;
    urlProd = '';

    userId: number;
    pageIndexFiltro = 1;
    binbsEjecutados = false;

    onVacantesPublicasChanged: BehaviorSubject<any>;
    onSearchTextVacantesPublicasChanged: Subject<any>;
    onSearchTextVacantesPublicasEnter: Subject<any>;
    onFilterVacantesPublicasChanged: Subject<any>;
    onListadoChangedVacantesPublicas: BehaviorSubject<any>;


    vacantes: Vacante[];
    vacantesLenght: number;
    vacantesFiltradas: Vacante[];
    
    searchText = '';
    filterBy = 'all';
    dataFiltro: any;


    estadosVacante: any;
    
    tiposContrato: any[];
    sectoresEconomicos: any[];
    tiposJornada: any[];
    salarios: any[];
    areasVacante: any[];
    nivelesEducativos: any[];
    experiencias: any[];
    generos: any[];
    ciudades: any[];
    localidades: any[];
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private authState: AuthStateService,
        private _activateRoute: ActivatedRoute,
    ) {

        
        
        // Set the defaults
        this.onVacantesPublicasChanged = new BehaviorSubject([]);
        this.onSearchTextVacantesPublicasChanged = new Subject();
        this.onFilterVacantesPublicasChanged = new Subject();
        this.onSearchTextVacantesPublicasEnter = new Subject();
        this.onListadoChangedVacantesPublicas = new BehaviorSubject(false);

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        

        return new Promise((resolve, reject) => {

            if (route.params.empresa) {
                Promise.all([
                    this.getVacantes(route.params.empresa),
                    this.getAllSelects()
                       ]).then(
                        ([files]) => {

                            if (!this.binbsEjecutados) {

                            
                                this.onFilterVacantesPublicasChanged.subscribe(filter => {
                                    this.filterBy = filter;

                                    if (this.filterBy === 'filtroApi') {
                                        this.vacantes = this.vacantesFiltradas;
                                        this.onVacantesPublicasChanged.next(this.vacantes);
                                    }

                                    if (this.filterBy === 'all' || this.filterBy === '' || this.vacantes.length === 0) {
                                        this.getVacantes(route.params.empresa);
                                    }

                                });

                                this.binbsEjecutados = true;
                            }

                            resolve('');
                        },
                        reject
                    );
                }
        });
    }

    /**
     * Get contacts
     *
     * @returns {Promise<any>}
     */
    getVacantes(empresa: any, page?: any): Promise<any> {
        this.pageIndexFiltro = page || 1;
        
        return new Promise((resolve) => {

                const headers = new HttpHeaders().set(InterceptorSkipHeader, '');

                this._httpClient.get(`${this.urlLocal}vacantes_empresa/${empresa}?page=${page}`, {headers: headers})
                .subscribe((response: any) => {
                    
                    this.vacantes = response.data;
                    this.vacantesLenght = response.total;
                    this.onVacantesPublicasChanged.next(this.vacantes);
                            

                    resolve(this.vacantes);

                }, (err) => {
                    this.vacantes = [];
                    this.onVacantesPublicasChanged.next(this.vacantes);
                    resolve(err);

                });
            
            
        }
        );
    }


    /**
     * Get todos los selects
     *
     * @returns {Promise<any>}
     */
    getAllSelects(): Promise<any> {
        return new Promise((resolve) => {
            const headers = new HttpHeaders().set(InterceptorSkipHeader, '');

            this._httpClient.get(`${this.urlLocal}selects_candidato_vacantes_filtro_publico`, {headers: headers})
                .subscribe((response: any) => {

                    this.tiposContrato = response.tiposContrato;
                    this.sectoresEconomicos = response.sectoresEconomicos;
                    this.tiposJornada = response.tiposJornada;
                    this.salarios = response.salarios;
                    this.areasVacante = response.areasVacante;
                    this.nivelesEducativos = response.nivelesEducativos;
                    this.experiencias = response.experiencias;
                    this.generos = response.generos;
                    this.ciudades = response.ciudades;
                    this.localidades = response.localidades;
                    resolve(response);
                }, (err) => { 
                    this.tiposContrato = [];
                    this.sectoresEconomicos = [];
                    this.tiposJornada = [];
                    this.salarios = [];
                    this.areasVacante = [];
                    this.nivelesEducativos = [];
                    this.experiencias = [];
                    this.generos = [];
                    this.ciudades = [];
                    this.localidades = [];
                    resolve(err);
                } );
        });
    }




    // pendiente ruta para filtrar
    
    filtrar(data, page?: any): Promise<any> {
        this.pageIndexFiltro = page || 1;
        this.dataFiltro = data;
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
            this._httpClient.post(`${this.urlLocal}vacantes_filtro_publico?page=${page}`, data, {headers: headers})
                .subscribe((response: any) => {
                    this.vacantesFiltradas = response.data;
                    this.vacantesLenght = response.total;
                    this.onFilterVacantesPublicasChanged.next('filtroApi');
                    
                    resolve(response);
                }, reject);
        });
    }
}
