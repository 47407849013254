<div class="navbar-header" [ngClass]="isCandidato ? 'navbar-candidato-primary' : fuseConfig.layout.navbar.primaryBackground ">

    <div class="logo" (click)="verDashboard()">
        
        <img class="logo-icon" src="{{ logoPath }}"  alt="logo">
      
    </div>

    <!-- <button mat-icon-button class="toggle-sidebar-folded"
            (click)="toggleSidebarFolded()" fxHide.lt-lg>
        <mat-icon>menu</mat-icon>
    </button> -->

    <button mat-icon-button class="toggle-sidebar-opened"
            (click)="toggleSidebarOpened()" fxHide.gt-md>
        <mat-icon class="back-button">arrow_back</mat-icon>
    </button>

</div>

<div class="navbar-content" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}"
     [ngClass]="isCandidato ? 'navbar-candidato-primary' : fuseConfig.layout.navbar.primaryBackground">
    <fuse-navigation  layout="vertical"></fuse-navigation>
    
</div>