import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'
@Pipe({name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform
{
    /**
     * Transform
     *
     * @param value
     * @param {string[]} args
     * @returns {any}
     */
    constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    // console.log(this.sanitized.bypassSecurityTrustHtml(value))
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}