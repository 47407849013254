import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,  } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthStateService } from '../shared/auth-state.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ModalTipoPaqueteComponent } from '../modals/modal-tipo-paquete/modal-tipo-paquete.component';
import { GlobalConstants } from 'app/common/global-constants';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class PaqueteTypeGuard implements CanActivate  {

  tipoPlanEmpresa: number;
  urlLocal = GlobalConstants.apiURL;

  constructor(private _authService: AuthStateService, private router: Router, public _matDialog: MatDialog, private _httpClient: HttpClient){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    return this.checkTipoPaquete(next);
  
  }


  checkTipoPaquete(route: ActivatedRouteSnapshot): boolean {
   
    if (!this._authService.getUserSession().candidato) {
      
        this.getPackagesEmpresa(this._authService.getUserSession().empresa.id).then(
          (data: any) => {

            
            if (data.length > 0) {
              return true;
            }else {

            this.modalTipoPlan(1);
            this.router.navigate(['/home']);
            return false;
            }
          });

          return true;

    }else {
      return true;
    }

  }

  modalTipoPlan(tipoPlanEmpresa): void {
      const modal = this._matDialog.open(ModalTipoPaqueteComponent, { 
        data: tipoPlanEmpresa
    });
  }

  getPackagesEmpresa(empresaId): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(`${this.urlLocal}consultar_paquete/${empresaId}`)
              .subscribe((response: any) => {
                  resolve(response);
              }, reject);
      });
  }
}
