import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseMatchMediaService } from '@fuse/services/match-media.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { AuthStateService } from 'app/pixcore/shared/auth-state.service';

import { DomSanitizer} from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { environment } from "environments/environment";

@Component({
    selector   : 'fuse-shortcuts',
    templateUrl: './shortcuts.component.html',
    styleUrls  : ['./shortcuts.component.scss']
})
export class FuseShortcutsComponent implements OnInit, AfterViewInit, OnDestroy
{
    shortcutItems: any[];
    navigationItems: any[];
    filteredNavigationItems: any[];
    searching: boolean;
    mobileShortcutsPanelActive: boolean;
    isCandidato: boolean;
    roleId: number;

    @Input()
    navigation: any;


    @ViewChild('searchInput')
    searchInputField;

    @ViewChild('shortcuts')
    shortcutsEl: ElementRef;

    // Private
    private _unsubscribeAll: Subject<any>;

    isPreLaunch = environment.preLaunch;

    /**
     * Constructor
     *
     * @param {CookieService} _cookieService
     * @param {FuseMatchMediaService} _fuseMatchMediaService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {MediaObserver} _mediaObserver
     * @param {Renderer2} _renderer
     */
    constructor(
        private _cookieService: CookieService,
        private _fuseMatchMediaService: FuseMatchMediaService,
        private _fuseNavigationService: FuseNavigationService,
        private _mediaObserver: MediaObserver,
        private _renderer: Renderer2,
        private authState: AuthStateService,
        private _iconRegistry: MatIconRegistry, 
        private _sanitizer: DomSanitizer
    )
    {
        // Set the defaults
        this.shortcutItems = [];
        this.searching = false;
        this.mobileShortcutsPanelActive = false;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        _iconRegistry.addSvgIcon('ocupate_account_box', _sanitizer.bypassSecurityTrustResourceUrl('../assets/images/dashboard/ocupate_empresas_perfil.svg'));
        _iconRegistry.addSvgIcon('ocupate_account_list', _sanitizer.bypassSecurityTrustResourceUrl('../assets/images/dashboard/ocupate_empresas_mis_vacantes.svg'));
        _iconRegistry.addSvgIcon('ocupate_account_ocupacional', _sanitizer.bypassSecurityTrustResourceUrl('../assets/images/dashboard/ocupacional.svg'));
        _iconRegistry.addSvgIcon('ocupate_account_aplicaciones', _sanitizer.bypassSecurityTrustResourceUrl('../assets/images/dashboard/aplicaciones.svg'));
        _iconRegistry.addSvgIcon('ocupate_account_competencias', _sanitizer.bypassSecurityTrustResourceUrl('../assets/images/dashboard/competencias.svg'));
        _iconRegistry.addSvgIcon('ocupate_account_buscar', _sanitizer.bypassSecurityTrustResourceUrl('../assets/images/dashboard/buscar.svg'));

    }
    

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

        this.authState.onDataChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((value) => {

            if (value === 1) { this.isCandidato = true; }else { this.isCandidato = false; }


            if ( this._cookieService.check('FUSE2.shortcuts') )
        {
            this.shortcutItems = JSON.parse(this._cookieService.get('FUSE2.shortcuts'));
        }
        else
        {
            if (this.isCandidato) {

                if (!this.isPreLaunch){
                    this.shortcutItems = [
                        {
                            title: 'Mi perfil ocupacional',
                            type : 'item',
                            iconSvg : 'ocupate_account_competencias',
                            url  : '/candidato/profile'
                        },
                        {
                            title: 'Mis aplicaciones',
                            type : 'item',
                            iconSvg : 'ocupate_account_aplicaciones',
                            url  : '/vacante/candidato-aplicaciones/lista'
                        },
                        {
                            title: 'Evaluación de competencias',
                            type : 'item',
                            iconSvg : 'ocupate_account_ocupacional',
                            url  : '/candidato/evaluacioncompetencias'
                        },
                        {
                            title: 'Buscar vacante',
                            type : 'item',
                            iconSvg : 'ocupate_account_buscar',
                            url  : '/vacante/candidato-vacantes/lista'
                        },
                    ];
                } else {
                    this.shortcutItems = [
                        {
                            title: 'Mi perfil ocupacional',
                            type : 'item',
                            iconSvg : 'ocupate_account_competencias',
                            url  : '/candidato/profile'
                        },
                        {
                            title: 'Evaluación de competencias',
                            type : 'item',
                            iconSvg : 'ocupate_account_ocupacional',
                            url  : '/candidato/evaluacioncompetencias'
                        },
                    ];
                }

            }else {

                if (this.authState.getUserSession()) {
                    this.roleId = this.authState.getUserSession().role_id;

                    if (this.roleId === 4) {

                        this.shortcutItems = [
                            {
                                title: 'Mi perfil empresarial',
                                type : 'item',
                                icon : 'account_box',
                                url  : '/empresa/profile'
                            },
        
                        ];

                    }else {
                        this.shortcutItems = [
                            {
                                title: 'Mi perfil empresarial',
                                type : 'item',
                                iconSvg : 'ocupate_account_box',
                                url  : '/empresa/profile'
                            },
                            {
                                title: 'Mis vacantes',
                                type : 'item',
                                iconSvg : 'ocupate_account_list',
                                url  : '/vacante/empresa-vacantes/lista'
                            }
                        ];
                    }
                }
            
            }

        }

        });
        
        // Get the navigation items and flatten them
        this.filteredNavigationItems = this.navigationItems = this._fuseNavigationService.getFlatNavigation(this.navigation);

        

    }

    ngAfterViewInit(): void
    {
        // Subscribe to media changes
        this._fuseMatchMediaService.onMediaChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                if ( this._mediaObserver.isActive('gt-sm') )
                {
                    this.hideMobileShortcutsPanel();
                }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Search
     *
     * @param event
     */
    search(event): void
    {
        const value = event.target.value.toLowerCase();

        if ( value === '' )
        {
            this.searching = false;
            this.filteredNavigationItems = this.navigationItems;

            return;
        }

        this.searching = true;

        this.filteredNavigationItems = this.navigationItems.filter((navigationItem) => {
            return navigationItem.title.toLowerCase().includes(value);
        });
    }

    /**
     * Toggle shortcut
     *
     * @param event
     * @param itemToToggle
     */
    toggleShortcut(event, itemToToggle): void
    {
        event.stopPropagation();

        for ( let i = 0; i < this.shortcutItems.length; i++ )
        {
            if ( this.shortcutItems[i].url === itemToToggle.url )
            {
                this.shortcutItems.splice(i, 1);

                // Save to the cookies
                this._cookieService.set('FUSE2.shortcuts', JSON.stringify(this.shortcutItems));

                return;
            }
        }

        this.shortcutItems.push(itemToToggle);

        // Save to the cookies
        this._cookieService.set('FUSE2.shortcuts', JSON.stringify(this.shortcutItems));
    }

    /**
     * Is in shortcuts?
     *
     * @param navigationItem
     * @returns {any}
     */
    isInShortcuts(navigationItem): any
    {
        return this.shortcutItems.find(item => {
            return item.url === navigationItem.url;
        });
    }

    /**
     * On menu open
     */
    onMenuOpen(): void
    {
        setTimeout(() => {
            this.searchInputField.nativeElement.focus();
        });
    }

    /**
     * Show mobile shortcuts
     */
    showMobileShortcutsPanel(): void
    {
        this.mobileShortcutsPanelActive = true;
        this._renderer.addClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    }

    /**
     * Hide mobile shortcuts
     */
    hideMobileShortcutsPanel(): void
    {
        this.mobileShortcutsPanelActive = false;
        this._renderer.removeClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    }
}
