<div id="register" fxLayout="column">

    <div id="background-empresa" [@fadeInOut]=currentStateEmpresa></div>
    <div id="background-candidato" [@fadeInOut]=currentStateCandidato></div>

    <div id="register-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="register-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div fxLayout="column" fxLayoutAlign="center start" style="position: absolute;">
                <a mat-icon-button href="{{URL}}" target="_blank">
                    <mat-icon class="secondary-text">home</mat-icon>
                </a>
            </div>

            <div class="logo">
                <a href="{{URL}}" target="_blank">
                    <img src="{{ logoPath }}" alt="logo" style="height:100px; width:auto;">
                </a>
            </div>

            <div class="title">{{'REGISTER_FORM.TITLE' | translate}}</div>

            <form name="registerForm" [formGroup]="registerForm" (submit)="OnSubmit()" novalidate>

                <mat-form-field *ngIf="currentStateEmpresa" appearance="outline">
                    <mat-label>Tipo Empresa</mat-label>
                    <mat-select formControlName="tipo_persona">
                        <mat-option *ngFor="let tipo of tipos" [value]="tipo.id">{{tipo.value}}</mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text">business</mat-icon>
                    <mat-error >El tipo de Empresa es requerido</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label *ngIf="currentStateCandidato">Número de documento</mat-label>
                    <mat-label *ngIf="currentStateEmpresa">NIT</mat-label>
                    <input matInput formControlName="numero_identificacion" required />
                    <mat-icon matSuffix class="secondary-text">search</mat-icon>
                    <mat-error
                        *ngIf="registerForm.get('numero_identificacion').hasError('required') && currentStateCandidato">
                        El Número de documento es requerido!</mat-error>
                    <mat-error
                        *ngIf="registerForm.get('numero_identificacion').hasError('required') && currentStateEmpresa">El
                        Nit es requerido!</mat-error>
                    <mat-error
                        *ngIf="registerForm.get('numero_identificacion').hasError('minlength') && !registerForm.get('numero_identificacion').hasError('pattern')">
                        Mínimo 5 caracteres</mat-error>
                    <mat-error
                        *ngIf="registerForm.get('numero_identificacion').hasError('maxlength') && !registerForm.get('numero_identificacion').hasError('pattern')">
                        Máximo 100 caracteres</mat-error>
                    <mat-error
                        *ngIf="registerForm.get('numero_identificacion').hasError('pattern') && currentStateCandidato">
                        Solo números</mat-error>
                    <mat-error
                        *ngIf="registerForm.get('numero_identificacion').hasError('pattern') && currentStateEmpresa">
                        Ejemplo: 2546456-6</mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="currentStateEmpresa" appearance="outline">
                  
                    <mat-label *ngIf="currentStateEmpresa">Teléfono de contacto</mat-label>
                    <input matInput formControlName="telefono" required />
                    <mat-icon matSuffix class="secondary-text">phone</mat-icon>
                    <mat-error
                        *ngIf="registerForm.get('telefono').hasError('required') && currentStateCandidato">
                        El Télefono de contacto es requerido!</mat-error>
                    <mat-error
                        *ngIf="registerForm.get('telefono').hasError('required') && currentStateEmpresa">El
                        teléfono de contacto es requerido!</mat-error>
                    <mat-error
                        *ngIf="registerForm.get('telefono').hasError('minlength') && !registerForm.get('telefono').hasError('pattern')">
                        Mínimo 10 caracteres</mat-error>
                    <mat-error
                        *ngIf="registerForm.get('telefono').hasError('maxlength') && !registerForm.get('telefono').hasError('pattern')">
                        Máximo 11 caracteres</mat-error>
                    <mat-error
                        *ngIf="registerForm.get('telefono').hasError('pattern') && currentStateCandidato">
                        Solo números</mat-error>
                    <mat-error
                        *ngIf="registerForm.get('telefono').hasError('pattern') && currentStateEmpresa">
                        Ejemplo: 3000000000</mat-error>
                </mat-form-field>

                <ng-container *ngIf="currentStateEmpresa">
                    <div fxLayout="row" fxLayoutAlign="start center" class="mb-8 accent alert"
                        *ngIf="!isEmailComporativo">
                        <mat-icon class="mr-4">warning</mat-icon>
                        <span>Por favor, ingresa un correo corporativo, o de otro modo, tu Perfil no será público hasta
                            ser validado</span>
                    </div>
                    <span class="text">Regístrate con tu correo corporativo</span>
                </ng-container>


                <mat-form-field appearance="outline">
                    <mat-label>{{'REGISTER_FORM.EMAIL' | translate}}</mat-label>
                    <input (change)="inputEmail($event)" matInput formControlName="email" type="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>

                    <mat-error *ngIf="registerForm.get('email').hasError('required')">
                        Correo electrónico requerido
                    </mat-error>
                    <mat-error *ngIf="registerForm.get('email').hasError('email')">
                        Correo electrónico invalido
                    </mat-error>
                    <mat-error
                        *ngIf="registerForm.get('email').hasError('minlength') && !registerForm.get('email').hasError('email')">
                        Mínimo 5 caracteres
                    </mat-error>
                    <mat-error
                        *ngIf="registerForm.get('email').hasError('maxlength') && !registerForm.get('email').hasError('email')">
                        Máximo 100 caracteres
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'REGISTER_FORM.PASSWORD' | translate}}</mat-label>
                    <input matInput [type]="hide1 ? 'password' : 'text'" formControlName="password">
                    <span class="secondary-text" mat-icon-button class="show-password" matSuffix
                        (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                        <mat-icon class="secondary-text cursor-pointer">{{hide1 ? 'visibility_off' : 'visibility'}}
                        </mat-icon>
                    </span>

                    <mat-error *ngIf="registerForm.get('password').hasError('required')">La contraseña es requerida
                    </mat-error>
                    <mat-error *ngIf="registerForm.get('password').hasError('minlength')">Mínimo 5 caracteres
                    </mat-error>
                    <mat-error *ngIf="registerForm.get('password').hasError('maxlength')">Máximo 100 caracteres
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'REGISTER_FORM.PASSWORD_CONFIRM' | translate}}</mat-label>
                    <input matInput [type]="hide2 ? 'password' : 'text'" formControlName="password_confirmation">
                    <span class="secondary-text" mat-icon-button class="show-password" matSuffix
                        (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                        <mat-icon class="secondary-text cursor-pointer">{{hide2 ? 'visibility_off' : 'visibility'}}
                        </mat-icon>
                    </span>
                    <mat-error *ngIf="registerForm.get('password_confirmation').hasError('required')">
                        La confirmación de contraseña es requerida
                    </mat-error>
                    <mat-error *ngIf="!registerForm.get('password_confirmation').hasError('required') &&
                                       registerForm.get('password_confirmation').hasError('passwordsNotMatching')">
                        Las contraseñas deben coincidir
                    </mat-error>
                </mat-form-field>

                <mat-label *ngIf="isOnlyRegister">{{'REGISTER_FORM.USER_TYPE' | translate}}</mat-label>
                <mat-radio-group *ngIf="isOnlyRegister" fxLayout="column" fxLayoutAlign="start start"
                    formControlName="user_type" required>
                    <mat-radio-button class="mb-6" value="1" (change)="OnCandidatoSelected($event)"> Candidato
                    </mat-radio-button>
                    <mat-radio-button class="mb-6" value="2" (change)="OnEmpresaSelected($event)"> Empresa
                    </mat-radio-button>
                </mat-radio-group>


                <div class="terms">
                    <mat-checkbox name="terms" aria-label="I read and accept" formControlName="accept_terms" required>
                        <span>{{'REGISTER_FORM.ACCEPT' | translate}}</span>
                    </mat-checkbox>
                    <a class="link" target="_blank" [routerLink]="'/terminos'">{{'REGISTER_FORM.TERMS' | translate}}</a>
                    y las
                    <a class="link" target="_blank" [routerLink]="'/politicas'">Políticas de privacidad</a>
                </div>

                <div style="text-align:center;">

                    <re-captcha (resolved)="resolved($event)" formControlName="recaptcha"
                        siteKey="6LfRB18aAAAAAICC6Va4sV6BwtJBdcDxYCgn5nrH">
                    </re-captcha>

                    <button *ngIf="currentStateCandidato" mat-raised-button color="accent" class="submit-button" [class.spinner]="loading"
                        aria-label="CREATE AN ACCOUNT" [disabled]="registerForm.invalid || loading">
                        {{'REGISTER_FORM.BUTTON_ACCEPT' | translate}}
                    </button>

                    <button *ngIf="!currentStateCandidato" mat-raised-button color="accent" class="submit-button" [class.spinner]="loading"
                        aria-label="CREATE AN ACCOUNT" [disabled]="registerForm.invalid || loading">
                        {{'REGISTER_FORM.BUTTON_ACCEPT_BUSINESS' | translate}}
                    </button>

                </div>

            </form>

            <span style="font-weight: 600;">o</span>

            <ng-container *ngIf="currentStateCandidato">

                <button mat-raised-button class="facebook" (click)="signInWithFB()">
                    <div style="margin-left: 0 10px; display: inline;">
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M21.7732 12.9477C21.7732 8.05224 17.829 4.08386 12.9643 4.08386C8.09747 4.08496 4.15332 8.05224 4.15332 12.9488C4.15332 17.372 7.37515 21.0387 11.5858 21.7037V15.51H9.35053V12.9488H11.588V10.9944C11.588 8.77346 12.9038 7.54683 14.9155 7.54683C15.8801 7.54683 16.8876 7.71971 16.8876 7.71971V9.89989H15.7766C14.6832 9.89989 14.3418 10.5837 14.3418 11.2851V12.9477H16.7841L16.3943 15.5089H14.3407V21.7026C18.5513 21.0375 21.7732 17.3709 21.7732 12.9477Z"
                                fill="white" />
                        </svg>
                    </div>
                    {{'REGISTER_FORM.BUTTON_ACCEPT_FB' | translate}}
                </button>

                <button mat-raised-button class="google" (click)="signInWithGoogle()">
                    <div style="margin-left: 0 10px; display: inline; ">
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="24" height="24" transform="translate(0.962891 0.893799)" fill="white" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M21.5479 13.097C21.5479 12.4629 21.491 11.8532 21.3853 11.2678H12.9629V14.727H17.7757C17.5684 15.8449 16.9383 16.792 15.9912 17.4261V19.6699H18.8814C20.5724 18.1131 21.5479 15.8205 21.5479 13.097Z"
                                fill="#4285F4" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M12.9632 21.8364C15.3778 21.8364 17.4021 21.0356 18.8817 19.6698L15.9916 17.426C15.1908 17.9626 14.1665 18.2796 12.9632 18.2796C10.6341 18.2796 8.6626 16.7065 7.95937 14.5928H4.97168V16.9098C6.44317 19.8324 9.46744 21.8364 12.9632 21.8364Z"
                                fill="#34A853" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M7.95938 14.593C7.78053 14.0565 7.6789 13.4833 7.6789 12.8939C7.6789 12.3045 7.78053 11.7314 7.95938 11.1948V8.87781H4.97169C4.36602 10.0851 4.02051 11.4509 4.02051 12.8939C4.02051 14.3369 4.36602 15.7028 4.97169 16.91L7.95938 14.593Z"
                                fill="#FBBC05" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M12.9632 7.50782C14.2762 7.50782 15.455 7.95903 16.3818 8.84517L18.9468 6.28023C17.398 4.83719 15.3737 3.95105 12.9632 3.95105C9.46744 3.95105 6.44317 5.95504 4.97168 8.87769L7.95937 11.1947C8.6626 9.08094 10.6341 7.50782 12.9632 7.50782Z"
                                fill="#EA4335" />
                        </svg>
                    </div>
                    {{'REGISTER_FORM.BUTTON_ACCEPT_GOOGLE' | translate}}
                </button>

                <button mat-raised-button class="google" (click)="signInWithLinkedIn()">
                    <div style="margin-left: 0 10px; display: inline; ">
                        <img src="assets/images/social/linkedin.svg" class="img" alt="linkedin">
                    </div>
                    {{'REGISTER_FORM.BUTTON_ACCEPT_LINKEDIN' | translate}}
                </button>

            </ng-container>

            <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">{{'REGISTER_FORM.ALREADY_ACCOUNT' | translate}}</span>
                <a class="link" [routerLink]="'/login'">{{'REGISTER_FORM.LOGIN' | translate}}</a>
            </div>

        </div>

    </div>

</div>