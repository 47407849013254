import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject, BehaviorSubject, merge, Subscription } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { FuseUtils } from '@fuse/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalConstants } from 'app/common/global-constants';

import { fuseAnimations } from '@fuse/animations';
import { ModalCompartirComponent } from 'app/modals/modal-compartir/modal-compartir.component';

import { MinisitioVacantesService } from '../minisitio-vacantes.service';
import { MinisitioVacantesComponent } from '../minisitio-vacantes.component';
import * as moment from 'moment';

@Component({
    selector: 'vacantes-lista',
    templateUrl: './lista-view.component.html',
    styleUrls: ['./lista-view.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ListaViewComponent implements OnInit, OnDestroy {

    vacantes: any;
    vacantesLenght: number;
    filterBy: string;
    dataFiltro: any;
    loading = false;
    loadingAll = false;

    apiFacebook = GlobalConstants.facebook_api_id;

    @Output() scrollToTop = new EventEmitter<any>();

    dataSource: FilesDataSource | null;

    mensaje: string;
    hoy: Date;
    fecha: any;
    displayedColumns = ['vacante'];

    dialogRef: any;

    btnAct: any;
    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    // @ViewChild(MatSort, { static: true })
    // sort: MatSort;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ListaVacantesService} _minisitioVacantesService
     */
    constructor(
        private _minisitioVacantesService: MinisitioVacantesService,
        public _matDialog: MatDialog,
        private _router: Router,
        private _matSnackBar: MatSnackBar,
        private _minisitioVacantesComponent: MinisitioVacantesComponent


    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.dataSource = new FilesDataSource(this._minisitioVacantesService, this.paginator);

        this._minisitioVacantesService.onVacantesPublicasChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(vacantes => {

                this.scrollToTop.emit();
                this.vacantes = vacantes;
                this.vacantesLenght = this._minisitioVacantesService.vacantesLenght;
                this.filterBy = this._minisitioVacantesService.filterBy;
                const index = this._minisitioVacantesService.pageIndexFiltro - 1;

                if (index === 0 && this.paginator.pageIndex !== 0) {
                    this.paginator.pageIndex = index;
                    this.paginator.page.next({
                        pageIndex: this.paginator.pageIndex,
                        pageSize: this.paginator.pageSize,
                        length: this.vacantesLenght
                    });
                }
                this.loadingAll = false;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    desFiltrar(): void {
        this.loadingAll = true;
        this._minisitioVacantesService.onListadoChangedVacantesPublicas.next(true);
    }

    pageChanged(event): void {

        this.loading = true;
        this.filterBy = this._minisitioVacantesService.filterBy;
        if (this.filterBy === 'all') {
            this._minisitioVacantesService.getVacantes(event.pageIndex + 1).then(() => {

                this.loading = false;
            });
        } else {
            this.dataFiltro = this._minisitioVacantesService.dataFiltro;

            this._minisitioVacantesService.filtrar(this.dataFiltro, event.pageIndex + 1).then(() => {

                this.loading = false;
            });
        }
    }

    perfilEmpresa(empresaId: number): void {

        window.open(`https://app.okempleos.com/empresa/profile/${empresaId}`);
        // window.open(`http://localhost:4200/vacante/candidato-vacantes/lista/${id}/${empresaId}`);
    }

    detalleVacante(id: any, empresaId: number): void {
        const url = `public/detalle-vacante/${id}`;
        this._router.navigateByUrl(url);

        // window.open(`https://app.okempleos.com/vacante/candidato-vacantes/lista/${id}/${empresaId}`);
        // window.open(`http://localhost:4200/vacante/candidato-vacantes/lista/${id}/${empresaId}`);
    }


    openModal(tipo: 'whatsapp' | 'email', vacanteSlug): void {

        let informacion = {
            tipo: tipo
        }

        const modal = this._matDialog.open(ModalCompartirComponent, {
            data: informacion
        });

        modal.afterClosed().subscribe((result) => {

            if (!result) { return };

            if (tipo === 'whatsapp') {

                this.openWhatsApp(result.input, vacanteSlug)

            } else {

                this.openMailTo(result.input, vacanteSlug);
            }

        });

    }

    openWhatsApp(telefono, vacanteSlug) {

        var codigoPais = "57";
        var mensaje = `¡Hola! Te invito a ver esta nueva oferta de empleo en OkEmpleos.com. https://co.okempleos.com/public/detalle-vacante/${vacanteSlug}`;


        var url = "https://api.whatsapp.com/send?phone=" + codigoPais + telefono +
            "&text=" + mensaje.replace(" ", "%20");

        window.open(url, '_blank');
    }

    openMailTo(email, vacanteSlug) {

        var mailText = `mailto:${email}?subject=¡Hola! Te invito a ver esta nueva oferta de empleo en OkEmpleos.com&body=https://co.okempleos.com/public/detalle-vacante/${vacanteSlug}`;

        window.location.href = mailText.replace(" ", "%20");

    }

    compartirFacebook(empresa: string, cargo: string, salario: string, vacanteSlug: string): void {
        let mensaje = "Te comparto esta oportunidad para el cargo: " + cargo + " de la empresa: " + empresa +
            " con un salario de " + salario;

        let post = "http://www.facebook.com/dialog/feed?" +
            "app_id=" + this.apiFacebook + "&" +
            "link=https://co.okempleos.com/public/detalle-vacante/" + vacanteSlug + "&"
        "picture=https://admin.okempleos.com/emails/images/banner-bg.jpg&" +
            "name=Ok%20Empleos&" +
            "caption=Ok%20Empleos&" +
            "description= " + mensaje.replace(" ", "%20") + "&" +
            "quote=OkEmpleos" + mensaje.replace(" ", "%20") + "&" +
            "redirect_uri=https://co.okempleos.com/public/detalle-vacante/" + vacanteSlug;

        let post2 = "http://www.facebook.com/dialog/share?" +
            "app_id=888361738657710&" +
            "href=https://co.okempleos.com/public/detalle-vacante/" + vacanteSlug + "&"
        "display=popup&" +
            "quote=OkEmpleos" + mensaje.replace(" ", "%20") + "&" +
            "redirect_uri=https://co.okempleos.com/public/detalle-vacante/" + vacanteSlug;

        // let soloEnlace = 'http://www.facebook.com/sharer.php?u=https://co.okempleos.com/public/detalle-vacante/1086-administrador-cadena-de-restaurantes'

        window.open(post);
    }

    compartirTwitter(empresa: string, cargo: string, salario: string, vacanteSlug: string): void {
        let mensaje = "Te comparto esta oportunidad para el cargo: " + cargo + " de la empresa: " + empresa +
            " con un salario de " + salario + " " + `https://co.okempleos.com/public/detalle-vacante/${vacanteSlug}`;

        let post = "https://twitter.com/intent/tweet?via=okempleos&text=" + mensaje.replace(" ", "%20");
        window.open(post);
    }

    copyText(vacanteSlug): void {

        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = 'https://co.okempleos.com/public/detalle-vacante/' + vacanteSlug;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

        this.openSnackBar('Vacante copiada en el portapapeles', 'snack-success')


    }

    private openSnackBar(mensaje: string, clase: string): void {
        this._matSnackBar.open(mensaje, 'x', {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'top',
            panelClass: clase,
        });
    }

    getColor(){
        console.log( this._minisitioVacantesComponent.minisitio);
        return this._minisitioVacantesComponent.minisitio.propiedades[0].font_color;;
    }

}

export class FilesDataSource extends DataSource<any>
{
    private _filteredDataChange = new BehaviorSubject('');
    private _filterChange = new BehaviorSubject('');
    /**
     * Constructor
     *
     * @param {MinisitioVacantesService} _minisitioVacantesService
     */
    constructor(
        private _minisitioVacantesService: MinisitioVacantesService,
        private _matPaginator: MatPaginator,
    ) {
        super();
        this.filteredData = this._minisitioVacantesService.vacantes;
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {

        const displayDataChanges = [
            this._minisitioVacantesService.onVacantesPublicasChanged,
            this._matPaginator.page
        ];

        return merge(...displayDataChanges)
            .pipe(
                map(() => {
                    let data = this._minisitioVacantesService.vacantes.slice();
                    const texto = this._minisitioVacantesService.searchText;

                    this.filteredData = [...data];

                    data.forEach(vacante => {
                        vacante.diffCreacion = this.contadorCreacion(vacante.created_at);
                    });

                    // Grab the page's slice of data.

                    // const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
                    return this.filterDataCustom(data, texto); // data.splice(startIndex, this._matPaginator.pageSize);

                }
                ));

    }

    // Filtered data
    get filteredData(): any {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any) {
        this._filteredDataChange.next(value);
    }

    // Filter
    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    contadorCreacion(fecha_creacion): string {

        let resultado = '';
        let tiempo = moment().diff(moment(fecha_creacion), 'years');
        let tipo = 'año'
        if (tiempo <= 0) {
            tiempo = moment().diff(moment(fecha_creacion), 'months');
            tipo = 'mes'
            if (tiempo <= 0) {
                tiempo = moment().diff(moment(fecha_creacion), 'days');
                tipo = 'dia'

                if (tiempo <= 0) {
                    tiempo = moment().diff(moment(fecha_creacion), 'hours');
                    tipo = 'hora'

                    if (tiempo <= 0) {
                        tiempo = moment().diff(moment(fecha_creacion), 'minutes');
                        tipo = 'minuto'
                    }
                }
            }

        }

        if (tipo === 'año') {

            tiempo === 1 ? resultado = `Hace ${tiempo} año` : resultado = `Hace ${tiempo} años`

            return resultado
        }

        if (tipo === 'mes') {

            tiempo === 1 ? resultado = `Hace ${tiempo} mes` : resultado = `Hace ${tiempo} meses`

            return resultado
        }

        if (tipo === 'dia') {

            tiempo === 1 ? resultado = `Hace ${tiempo} dia` : resultado = `Hace ${tiempo} dias`

            return resultado
        }

        if (tipo === 'hora') {

            tiempo === 1 ? resultado = `Hace ${tiempo} hora` : resultado = `Hace ${tiempo} horas`

            return resultado
        }

        if (tipo === 'minuto') {

            tiempo === 1 ? resultado = `Hace ${tiempo} minuto` : resultado = `Hace ${tiempo} minutos`

            return resultado
        }


    }

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any {
        if (!this.filter) {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    filterDataCustom(data, text): any {

        if (text && text !== '') {
            return FuseUtils.filterArrayByString(data, text);
        }
        return data;

    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */



    /**
     * Disconnect
     */
    disconnect(): void {
    }
}
