import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject, BehaviorSubject, merge } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FuseUtils } from '@fuse/utils';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { ListaEmpresasService } from 'app/public/lista-empresas/lista-empresas.service';
import { Empresa } from '../model/empresa.model';




@Component({
    selector: 'lista-view',
    templateUrl: './lista-view.component.html',
    styleUrls: ['./lista-view.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ListaViewComponent implements OnInit, OnDestroy {
    @ViewChild('clickMenuTrigger') clickMenuTrigger: MatMenuTrigger;
    @ViewChild('dialogContent')
    dialogContent: TemplateRef<any>;

    @Output() scrollToTop = new EventEmitter<any>();

    pageIndexFiltro: number;

    loading = false;
    loadingAll = false;
    empresas: Empresa[];
    empresasLenght: number;
    filterBy: string;
    dataFiltro: any;
    dataSource: FilesDataSource | null;
    mensaje: string;
    hoy: Date;
    fecha: any;
    displayedColumns = ['avatar', 'nombre', 'sector_economico_value', 'tipo_empresa_value', 'tamano', 'fundacion', 'ciudad_value', 'contacto', 'buttons'];
    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    
    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;





    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ListaEmpresasService} _listaEmpresasService
     */
    constructor(
        private _listaEmpresasService: ListaEmpresasService,
        public _matDialog: MatDialog,
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.filterBy = this._listaEmpresasService.filterBy || 'all';
        this.dataSource = new FilesDataSource(this._listaEmpresasService, this.paginator, this.sort);
        this._listaEmpresasService.onEmpresasPublicasChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(empresas => {
                this.scrollToTop.emit();
                this.empresas = empresas;
                this.empresasLenght = this._listaEmpresasService.empresasLenght;
                this.filterBy = this._listaEmpresasService.filterBy;
                const index = this._listaEmpresasService.pageIndexFiltro - 1;
                
               
                if (index === 0 && this.paginator.pageIndex !== 0) {
                    this.paginator.pageIndex = index;
                    this.paginator.page.next({
                    pageIndex: this.paginator.pageIndex,
                    pageSize: this.paginator.pageSize,
                    length: this.empresasLenght
                    });
                }
               
                this.loadingAll = false;
                
            
            });

    }

    desFiltrar(): void {
        this.loadingAll = true;
        this._listaEmpresasService.onListadoChangedEmpresasPublicas.next(true); 
    }

    verPerfilEmpresa(empresa): any {
        window.open(`https://app.okempleos.com/empresa/profile/${empresa.id}`);
        // window.open(`http://localhost:4200/empresa/profile/${empresa.id}`);
        
    }

    verVacantes(empresa): any {
        window.open(`https://app.okempleos.com/vacante/candidato-vacantes/lista/${empresa.id}`);
        // window.open(`http://localhost:4200/vacante/candidato-vacantes/lista/${empresa.id}`);
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    pageChanged(event): void {
        
        this.loading = true;
        this.filterBy = this._listaEmpresasService.filterBy;
        if (this.filterBy === 'all') {
            this._listaEmpresasService.getEmpresas(event.pageIndex + 1).then(() => {
                
                this.loading = false;
            });
        }else {
           
            this.dataFiltro = this._listaEmpresasService.dataFiltro;
           
            this._listaEmpresasService.filtrar(this.dataFiltro, event.pageIndex + 1).then(() => {
                
                this.loading = false;
        });
        }

     

        
    }
}

export class FilesDataSource extends DataSource<any>
{
    private _filteredDataChange = new BehaviorSubject('');
    private _filterChange = new BehaviorSubject('');
  
    /**
     * Constructor
     *
     * @param {ListaEmpresasService} _listaEmpresasService
     */
    constructor(
        private _listaEmpresasService: ListaEmpresasService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort,
       
    ) {
        super();
        this.filteredData = this._listaEmpresasService.empresasPublicas;
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
       
        const displayDataChanges = [
            this._listaEmpresasService.onEmpresasPublicasChanged,
            this._matPaginator.page,
            this._matSort.sortChange,
    
        ];

        return merge(...displayDataChanges)
            .pipe(
                map(() => {
                    let data = this._listaEmpresasService.empresasPublicas.slice();
                    const texto = this._listaEmpresasService.searchText;
                   
                    this.filteredData = [...data];
                    data = this.sortData(data);
                    // Grab the page's slice of data.
                    
                   
                    // return data; // .splice(startIndex, this._matPaginator.pageSize);
                    return this.filterDataCustom(data, texto); // .splice(startIndex, this._matPaginator.pageSize);


                }
                ));

    }

    // Filtered data
    get filteredData(): any {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any) {
        this._filteredDataChange.next(value);
    }

    // Filter
    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any {
        if (!this.filter) {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    filterDataCustom(data, text): any {

        if (text && text !== '') {
            return FuseUtils.filterArrayByString(data, text);
        }
        return data;
        
    }


    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data): any[] {
        if (!this._matSort.active || this._matSort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            let propertyA: number | string = '';
            let propertyB: number | string = '';

            switch (this._matSort.active) {
                case 'nombre':
                    [propertyA, propertyB] = [a.nombre, b.nombre];
                    break;
                case 'sector_economico_value':
                    [propertyA, propertyB] = [a.sector_economico_value, b.sector_economico_value];
                    break;
                case 'tipo_empresa_value':
                    [propertyA, propertyB] = [a.tipo_empresa_value, b.tipo_empresa_value];
                    break;
                case 'tamano':
                    [propertyA, propertyB] = [a.tamano, b.tamano];
                    break;
                case 'fundacion':
                    [propertyA, propertyB] = [a.fundacion, b.fundacion];
                    break;
                case 'ciudad_value':
                    [propertyA, propertyB] = [a.ciudad_value, b.ciudad_value];
                    break;
                case 'contacto':
                    [propertyA, propertyB] = [a.direccion, b.direccion];
                    break;

            }

            const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (valueA < valueB ? -1 : 1) * (this._matSort.direction === 'asc' ? 1 : -1);
        });
    }


    /**
     * Disconnect
     */
    disconnect(): void {
    }
}
