import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';



@Component({
  selector: 'modal-tipo-plan',
  templateUrl: './modal-tipo-plan.component.html',
  styleUrls: ['./modal-tipo-plan.component.scss']
})
export class ModalTipoPlanComponent implements OnInit {

  tipoPlanEmpresa: any;
  nombrePlan= ''

  constructor(
    public dialogRef: MatDialogRef<ModalTipoPlanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number,
    public _router: Router
    ) {
      this.tipoPlanEmpresa = data;
    }

  ngOnInit(): void {
   

    switch(this.tipoPlanEmpresa) { 
      case 1: { 
         this.nombrePlan = 'DEMO';
        break; 
      } 
      case 2: { 
        this.nombrePlan = 'BÁSICO';
        break; 
      } 
      case 3: { 
        this.nombrePlan = 'ENTERPRISE';
        
      } 
      default: { 
        this.nombrePlan = 'Sin nombre';
        break; 
      } 
   } 

  }

  onCerrarClick(isPlanes): void {

    if (isPlanes) {

      this._router.navigate(['/empresa/planes']);
     
    }

    this.dialogRef.close();
    
  }

}
