export const locale = {
    lang: 'en',
    data: {
        'WELCOME': {
            'TITLE': 'Hello! Welcome to Recorvet :)',
            'SUBTITLE': 'Here everyone love the animals'
        },
        'REGISTER_FORM': {
            'TITLE': 'Create an account',
            'NAME': 'Name',
            'EMAIL': 'Email',
            'PASSWORD': 'Password',
            'PASSWORD_CONFIRM': 'Password (Confirm)',
            'ACCEPT': 'I read and accept',
            'TERMS': 'terms and conditions',
            'BUTTON_ACCEPT': 'CREATE AN ACCOUNT',
            'BUTTON_ACCEPT_BUSINESS': 'CREATE AN BUSINESS',
            'BUTTON_ACCEPT_FB': 'Register with Facebook',
            'BUTTON_ACCEPT_GOOGLE': 'Register with Google',
            'BUTTON_ACCEPT_LINKEDIN': 'Register with Linkedin',
            'ALREADY_ACCOUNT': 'Already have an account?',
            'LOGIN': 'Login',
            'USER_TYPE': 'You use the platform like...',
        }
    }
};
