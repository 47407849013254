import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { ListaVacantesService } from './lista-vacantes.service';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { Router } from '@angular/router';
import { VerticalLayout1Component } from 'app/layout/vertical/layout-1/layout-1.component';
import { SEOService } from 'app/seo-service.service';

@Component({
    selector: 'lista-vacantes',
    templateUrl: './lista-vacantes.component.html',
    styleUrls: ['./lista-vacantes.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ListaVacantesComponent implements OnInit, OnDestroy {

    @ViewChild('listScrollVacantes', { read: FusePerfectScrollbarDirective, static: true })
    listScroll: FusePerfectScrollbarDirective;

    dialogRef: any;
    searchInput: FormControl;

    // Private
    private _unsubscribeAll: Subject<any>;
    vacantesLenght: number;
    filterBy: string;
    /**
     * Constructor
     *
     * @param {CandidatoVacantesService} _listaVacantesService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _listaVacantesService: ListaVacantesService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseConfigService: FuseConfigService,
        private dateAdapter: DateAdapter<any>,
        private route: ActivatedRoute,
        private _router: Router,
        private _verticalLayout1Component: VerticalLayout1Component,
        private seoService: SEOService
        ) {
        // Set the defaults
        this.searchInput = new FormControl('');

        // Set the private defaults
        this._unsubscribeAll = new Subject();


        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        /* SEoService */
        this.seoService.setPageTitle('Listado de vacantes | Ok empleos');
        this.seoService.setDescription('Consigue el trabajo de mereces, nuestro listado de vacantes es verificado, 100% activo y evaluado para ofrecerte siempre lo mejor.');
        this.seoService.createLinkForCanonicalURL();
        /* SEoService */

        this.dateAdapter.setLocale('es');

        this.searchInput.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(200),
                distinctUntilChanged()
            )
            .subscribe(searchText => {
                this._listaVacantesService.onSearchTextVacantesPublicasChanged.next(searchText);
            });

        this._listaVacantesService.onFilterVacantesPublicasChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(filter => {

                if (filter === 'all' || filter === '') {
                    this.searchInput.setValue(null);
                }
            });

        this.route.queryParams.subscribe((x) => {

            if (x.vacanteId) {
                this.detalleVacante(x.vacanteId)
            }


            if (x.palabra || x.localizacion) {

                let data: { ciudad_id?: number[]; texto_busqueda?: string } = { ciudad_id: [], texto_busqueda: null };
                if (x.palabra) {

                    if (x.palabra !== '' && x.palabra !== 'null') {
                        this.searchInput.setValue(x.palabra);
                        data.texto_busqueda = x.palabra
                    }
                }

                if (x.localizacion) {

                    if (x.localizacion !== 'null') {
                        const ciudadId = parseInt(x.localizacion, 10);
                        data.ciudad_id = [ciudadId]
                    }
                }



                if (data.ciudad_id.length === 0 && data.texto_busqueda === null) {
                    return;
                } else {

                    this._listaVacantesService.filtrar(data);
                }
            
            }     

            if (x.area && x.area !== 'null'){
                let data: { opciones? : number[], fecha_inicial? :null, fecha_final? :null, tipo_contrato? : number[], sector_economico? : number[], tipo_jornada? : number[], salario? : number[], area_vacante? : number[], nivelEducacion? : number[], experiencia? : number[], genero? : number[], disponibilidadViaje? : number[], disponibilidadCambioResidencia? : number[], ciudad_id? : number[], localidad_id? : number[], texto_busqueda? :null} = {opciones:[],fecha_inicial:null,fecha_final:null,tipo_contrato:[],sector_economico:[],tipo_jornada:[],salario:[],area_vacante:[],nivelEducacion:[],experiencia:[],genero:[],disponibilidadViaje:[],disponibilidadCambioResidencia:[],ciudad_id:[],localidad_id:[],texto_busqueda:null};
                data.area_vacante = [x.area];
                this._listaVacantesService.filtrar(data);
            }

        });

    }

    detalleVacante(id: number): void {
        const url = `public/detalle-vacante/${id}`;
        this._router.navigateByUrl(url);

        // window.open(`https://app.okempleos.com/vacante/candidato-vacantes/lista/${id}/${empresaId}`);
        // window.open(`http://localhost:4200/vacante/candidato-vacantes/lista/${id}/${empresaId}`);

    }

    scrollToTop(): void {
        this._verticalLayout1Component.scrollToTop()
        this.listScroll.scrollToTop();
    }

    onEnterSearch(): void {

        this._listaVacantesService.onSearchTextVacantesPublicasEnter.next(true);

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Reset the search
        this._listaVacantesService.onSearchTextVacantesPublicasChanged.next('');

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    // /**
    //  * New contact
    //  */
    // newContact(): void
    // {
    //     this.dialogRef = this._matDialog.open(ContactsContactFormDialogComponent, {
    //         panelClass: 'contact-form-dialog',
    //         data      : {
    //             action: 'new'
    //         }
    //     });

    //     this.dialogRef.afterClosed()
    //         .subscribe((response: FormGroup) => {
    //             if ( !response )
    //             {
    //                 return;
    //             }

    //             this._empresaAplicacionesService.updateContact(response.getRawValue());
    //         });
    // }

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void {
        this._verticalLayout1Component.scrollToTop()
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }
}
