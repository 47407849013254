import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalConstants } from 'app/common/global-constants';
import { FuseSharedModule } from '@fuse/shared.module';
import { Router } from '@angular/router';

@Component({
  selector: 'app-school-first',
  templateUrl: './school-first.component.html',
  styleUrls: ['./school-first.component.scss']
})
export class SchoolFirstComponent implements OnInit {

  public likeVideo: any[];
  public videoSelec = {
    video_id: '',
    like: 0,
    dislike: 0,
  };
  likeBase: number;
  private player;
  private ytEvent;
  videoId: string;
  urlLocal = GlobalConstants.apiURL;

  constructor(
    public dialogRef: MatDialogRef<SchoolFirstComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
  ) { 
    this.videoId = this.data;
    this.videoSelec.video_id = this.videoId;
  }

  ngOnInit(): void {
  }

  onStateChange(event): void {
    this.ytEvent = event.data;
  }

  likeClick(){
    this.router.navigate(['login']);
    this.dialogRef.close();
  }

  savePlayer(player): void {
    this.player = player;
  }

  onCerrarClick(): void {
    this.dialogRef.close();
  } 

}
