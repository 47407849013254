<div id="login" fxLayout="column">

    <div id="background-empresa" [@fadeInOut]=currentStateEmpresa></div>
    <div id="background-candidato" [@fadeInOut]=currentStateCandidato></div>

    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">



        <div id="login-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div fxLayout="column" fxLayoutAlign="center start" style="position: absolute;">
                <a mat-icon-button href="{{URL}}" target="_Self">
                    <mat-icon class="secondary-text">home</mat-icon>
                </a>
            </div>

            <div class="logo">
                <a href="{{URL}}" target="_blank">
                    <img src="{{ logoPath }}" alt="logo" height="100" width="auto" style="height: 100px; width: auto;">
                </a>
            </div>

            <div class="title">{{'LOGIN_FORM.TITLE' | translate}}</div>

            <form name="loginForm" [formGroup]="loginForm" (submit)="OnSubmit()" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>{{'LOGIN_FORM.EMAIL' | translate}}</mat-label>
                    <input matInput formControlName="email" type="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="loginForm.get('email').hasError('required')">
                        {{'LOGIN_FORM_ERROR.EMAIL_REQUIRED' | translate}}
                    </mat-error>
                    <mat-error *ngIf="!loginForm.get('email').hasError('required') &&
                                loginForm.get('email').hasError('email')">
                        {{'LOGIN_FORM_ERROR.EMAIL_INVALID' | translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'LOGIN_FORM.PASSWORD' | translate}}</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
                    <span class="secondary-text" mat-icon-button class="show-password" matSuffix (click)="hide = !hide"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon class="secondary-text cursor-pointer">{{hide ? 'visibility_off' : 'visibility'}}
                        </mat-icon>
                    </span>
                    <mat-error>
                        {{'LOGIN_FORM_ERROR.PASSWORD_REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>


                <button mat-raised-button color="accent" class="submit-button" aria-label="LOG IN"
                    [disabled]="loginForm.invalid || loadingLogin">

                    <div *ngIf="loadingLogin" fxLayout="row" fxLayoutAlign="center center" class="py-8">
                        <mat-spinner strokeWidth="4" color="primary" diameter="30"> </mat-spinner>
                    </div>

                    <span *ngIf="!loadingLogin">
                        {{'LOGIN_FORM.BUTTON_LOGIN' | translate}}
                    </span>
                </button>

            </form>

            <!--<div class="separator">
                <span class="text">{{'LOGIN_FORM.OR' | translate}}</span>
            </div>-->

            <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
                <!--<mat-checkbox class="remember-me" aria-label="Remember Me">
                        {{'LOGIN_FORM.REMEMBER_ME' | translate}}
                    </mat-checkbox>-->

                <a class="forgot-password" [routerLink]="'/forgot-password'">
                    {{'LOGIN_FORM.FORGOT_PASSWORD' | translate}}
                </a>
            </div>

            <button class="facebook" (click)="signInWithFB()">
                <img src="../../../assets/images/logos/face_log.svg" alt="">
                <!-- {{'LOGIN_FORM.LOG_FACEBOOK' | translate}} -->
            </button>

            <button class="google" (click)="signInWithGoogle()">
                <img src="../../../assets/images/logos/google_log.svg" alt="">
                <!-- {{'LOGIN_FORM.LOG_GOOGLE' | translate}} -->
            </button>

            <button class="google" (click)="signInWithLinkedIn()">
                <img src="../../../assets/images/logos/linekdin_log.svg" alt="">
                <!-- {{'LOGIN_FORM.LOG_LINKEDIN' | translate}} -->
            </button>

            <div class="separator">
            </div>

            <div class="terms">
                <a class="link" target="_blank" [routerLink]="'/politicas'">Ver políticas de privacidad</a>
            </div>

            <!--<button mat-raised-button class="google">
                Log in with Google
            </button>

            <button mat-raised-button class="facebook">
                Log in with Facebook
            </button>

            <button mat-raised-button class="linkedin">
                {{'LOGIN_FORM.LOG_LINKEDIN' | translate}}
            </button>-->

            <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">{{'LOGIN_FORM.DONT_ACCOUNT' | translate}}</span>
                <div fxLayout="flex" fxLayoutAlign="center">
                    <span class="text">{{'LOGIN_FORM.CREATE_ACCOUNT' | translate}}:</span>
                    <a class="link mr-4" [routerLink]="'/registro'"
                        [queryParams]="{registro: 'candidato'}">{{'LOGIN_FORM.REGISTER_CANDIDATE' | translate}}</a> /
                    <a class="link ml-4" [routerLink]="'/registro'"
                        [queryParams]="{registro: 'empresa'}">{{'LOGIN_FORM.REGISTER_COMPANY' | translate}}</a>
                </div>

            </div>

        </div>

    </div>

</div>