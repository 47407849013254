export const cityList = [
    {
        value: '21',
        name: 'ANTIOQUIA'
    },
    {
        value: '26',
        name: 'ARMENIA'
    },
    {
        value: '28',
        name: 'BELLO'
    },
    {
        value: '51',
        name: 'COPACABANA'
    },
    {
        value: '63',
        name: 'GIRARDOTA'
    },
    {
        value: '67',
        name: 'GUARNE'
    },
    {
        value: '71',
        name: 'ITAGUI'
    },
    {
        value: '76',
        name: 'LA ESTRELLA'
    },
    {
        value: '82',
        name: 'MEDELLIN'
    },
    {
        value: '96',
        name: 'RIONEGRO'
    },
    {
        value: '98',
        name: 'SABANETA'
    },
    {
        value: '103',
        name: 'SAN JERONIMO'
    },
    {
        value: '137',
        name: 'ARAUCA'
    },
    {
        value: '145',
        name: 'BARRANQUILLA'
    },
    {
        value: '151',
        name: 'MALAMBO'
    },
    {
        value: '176',
        name: 'CARTAGENA DE INDIAS'
    },
    {
        value: '184',
        name: 'MAGANGUE'
    },
    {
        value: '222',
        name: 'BOYACÁ'
    },
    {
        value: '306',
        name: 'SOGAMOSO'
    },
    {
        value: '321',
        name: 'TUNJA'
    },
    {
        value: '331',
        name: 'AGUADAS'
    },
    {
        value: '339',
        name: 'MANIZALES'
    },
    {
        value: '364',
        name: 'FLORENCIA'
    },
    {
        value: '418',
        name: 'POPAYÁN'
    },
    {
        value: '434',
        name: 'AGUACHICA'
    },
    {
        value: '450',
        name: 'PELAYA'
    },
    {
        value: '456',
        name: 'SAN MARTÍN'
    },
    {
        value: '458',
        name: 'VALLEDUPAR'
    },
    {
        value: '502',
        name: 'MONTERÍA'
    },
    {
        value: '524',
        name: 'BOGOTÁ DC'
    },
    {
        value: '528',
        name: 'CAJICÁ'
    },
    {
        value: '533',
        name: 'CHIA'
    },
    {
        value: '538',
        name: 'COTA'
    },
    {
        value: '543',
        name: 'FACATATIVA'
    },
    {
        value: '546',
        name: 'FUNZA'
    },
    {
        value: '548',
        name: 'FUSAGASUGA'
    },
    {
        value: '550',
        name: 'GACHANCIPÁ'
    },
    {
        value: '553',
        name: 'GIRARDOT'
    },
    {
        value: '572',
        name: 'MADRID'
    },
    {
        value: '575',
        name: 'MOSQUERA'
    },
    {
        value: '601',
        name: 'SIBATÉ'
    },
    {
        value: '604',
        name: 'SOACHA'
    },
    {
        value: '605',
        name: 'SOPO'
    },
    {
        value: '606',
        name: 'SUBACHOQUE'
    },
    {
        value: '614',
        name: 'TENJO'
    },
    {
        value: '618',
        name: 'TOCANCIPÁ'
    },
    {
        value: '629',
        name: 'VILLETA'
    },
    {
        value: '633',
        name: 'ZIPAQUIRÁ'
    },
    {
        value: '647',
        name: 'ACEVEDO'
    },
    {
        value: '665',
        name: 'NEIVA'
    },
    {
        value: '671',
        name: 'PITALITO'
    },
    {
        value: '709',
        name: 'FUNDACION'
    },
    {
        value: '724',
        name: 'SANTA MARTA'
    },
    {
        value: '756',
        name: 'VILLAVICENCIO'
    },
    {
        value: '799',
        name: 'PASTO'
    },
    {
        value: '825',
        name: 'BUCARASICA'
    },
    {
        value: '831',
        name: 'CÚCUTA'
    },
    {
        value: '855',
        name: 'SARDINATA'
    },
    {
        value: '875',
        name: 'ARMENIA'
    },
    {
        value: '896',
        name: 'PEREIRA'
    },
    {
        value: '907',
        name: 'BARBOSA'
    },
    {
        value: '909',
        name: 'BARRANCABERMEJA'
    },
    {
        value: '912',
        name: 'BUCARAMANGA'
    },
    {
        value: '939',
        name: 'GIRÓN'
    },
    {
        value: '969',
        name: 'RIONEGRO'
    },
    {
        value: '1012',
        name: 'SINCELEJO'
    },
    {
        value: '1017',
        name: 'ALVARADO'
    },
    {
        value: '1037',
        name: 'IBAGUÉ'
    },
    {
        value: '1041',
        name: 'MARIQUITA'
    },
    {
        value: '1042',
        name: 'MELGAR'
    },
    {
        value: '1071',
        name: 'CALI'
    },
    {
        value: '1087',
        name: 'PALMIRA'
    },
    {
        value: '1088',
        name: 'PRADERA'
    },
    {
        value: '1102',
        name: 'ZARZAL'
    },
    {
        value: '1111',
        name: 'PUERTO CARREÑO'
    },
    {
        value: '21',
        name: 'ANTIOQUIA'
    },
    {
        value: '26',
        name: 'ARMENIA'
    },
    {
        value: '28',
        name: 'BELLO'
    },
    {
        value: '51',
        name: 'COPACABANA'
    },
    {
        value: '63',
        name: 'GIRARDOTA'
    },
    {
        value: '67',
        name: 'GUARNE'
    },
    {
        value: '71',
        name: 'ITAGUI'
    },
    {
        value: '76',
        name: 'LA ESTRELLA'
    },
    {
        value: '82',
        name: 'MEDELLIN'
    },
    {
        value: '96',
        name: 'RIONEGRO'
    },
    {
        value: '98',
        name: 'SABANETA'
    },
    {
        value: '103',
        name: 'SAN JERONIMO'
    },
    {
        value: '137',
        name: 'ARAUCA'
    },
    {
        value: '145',
        name: 'BARRANQUILLA'
    },
    {
        value: '151',
        name: 'MALAMBO'
    },
    {
        value: '176',
        name: 'CARTAGENA DE INDIAS'
    },
    {
        value: '184',
        name: 'MAGANGUE'
    },
    {
        value: '222',
        name: 'BOYACÁ'
    },
    {
        value: '306',
        name: 'SOGAMOSO'
    },
    {
        value: '321',
        name: 'TUNJA'
    },
    {
        value: '331',
        name: 'AGUADAS'
    },
    {
        value: '339',
        name: 'MANIZALES'
    },
    {
        value: '364',
        name: 'FLORENCIA'
    },
    {
        value: '418',
        name: 'POPAYÁN'
    },
    {
        value: '434',
        name: 'AGUACHICA'
    },
    {
        value: '450',
        name: 'PELAYA'
    },
    {
        value: '456',
        name: 'SAN MARTÍN'
    },
    {
        value: '458',
        name: 'VALLEDUPAR'
    },
    {
        value: '502',
        name: 'MONTERÍA'
    },
    {
        value: '524',
        name: 'BOGOTÁ DC'
    },
    {
        value: '528',
        name: 'CAJICÁ'
    },
    {
        value: '533',
        name: 'CHIA'
    },
    {
        value: '538',
        name: 'COTA'
    },
    {
        value: '543',
        name: 'FACATATIVA'
    },
    {
        value: '546',
        name: 'FUNZA'
    },
    {
        value: '548',
        name: 'FUSAGASUGA'
    },
    {
        value: '550',
        name: 'GACHANCIPÁ'
    },
    {
        value: '553',
        name: 'GIRARDOT'
    },
    {
        value: '572',
        name: 'MADRID'
    },
    {
        value: '575',
        name: 'MOSQUERA'
    },
    {
        value: '601',
        name: 'SIBATÉ'
    },
    {
        value: '604',
        name: 'SOACHA'
    },
    {
        value: '605',
        name: 'SOPO'
    },
    {
        value: '606',
        name: 'SUBACHOQUE'
    },
    {
        value: '614',
        name: 'TENJO'
    },
    {
        value: '618',
        name: 'TOCANCIPÁ'
    },
    {
        value: '629',
        name: 'VILLETA'
    },
    {
        value: '633',
        name: 'ZIPAQUIRÁ'
    },
    {
        value: '647',
        name: 'ACEVEDO'
    },
    {
        value: '665',
        name: 'NEIVA'
    },
    {
        value: '671',
        name: 'PITALITO'
    },
    {
        value: '709',
        name: 'FUNDACION'
    },
    {
        value: '724',
        name: 'SANTA MARTA'
    },
    {
        value: '756',
        name: 'VILLAVICENCIO'
    },
    {
        value: '799',
        name: 'PASTO'
    },
    {
        value: '825',
        name: 'BUCARASICA'
    },
    {
        value: '831',
        name: 'CÚCUTA'
    },
    {
        value: '855',
        name: 'SARDINATA'
    },
    {
        value: '875',
        name: 'ARMENIA'
    },
    {
        value: '896',
        name: 'PEREIRA'
    },
    {
        value: '907',
        name: 'BARBOSA'
    },
    {
        value: '909',
        name: 'BARRANCABERMEJA'
    },
    {
        value: '912',
        name: 'BUCARAMANGA'
    },
    {
        value: '939',
        name: 'GIRÓN'
    },
    {
        value: '969',
        name: 'RIONEGRO'
    },
    {
        value: '1012',
        name: 'SINCELEJO'
    },
    {
        value: '1017',
        name: 'ALVARADO'
    },
    {
        value: '1037',
        name: 'IBAGUÉ'
    },
    {
        value: '1041',
        name: 'MARIQUITA'
    },
    {
        value: '1042',
        name: 'MELGAR'
    },
    {
        value: '1071',
        name: 'CALI'
    },
    {
        value: '1087',
        name: 'PALMIRA'
    },
    {
        value: '1088',
        name: 'PRADERA'
    },
    {
        value: '1102',
        name: 'ZARZAL'
    },
    {
        value: '1111',
        name: 'PUERTO CARREÑO'
    },
];