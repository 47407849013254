<div class="cont_footer" #contFoo>
    <section fxLayout="column" fxLayoutAlign="center center" class="section section--follow">
        <h1 class="title follow_title">Síguenos en nuestras redes sociales</h1>
        <div fxLayout="row wrap" fxLayoutAlign="center center" class="follow_items">
            <a *ngFor="let url of followLog" href="{{url.url}}" target="_blank">
                <img class="follow_icon" src="{{url.img}}" alt="">
            </a>
        </div>
    </section>
    <section fxLayout="row wap" fxLayoutAlign="space-around start" class="section section__services">
        <div fxLayout="column" fxFlex="100" fxFlex.gt-xs="25" fxLayoutAlign.xs="start center" class="follow_card">
            <h1 class="title">Candidatos</h1>
            <p [routerLink]="'/registro'" [queryParams]="{registro: 'candidato'}">Registrarse</p>
            <a href="https://okempleos.com/vacantes/?palabra=&localizacion=null">Buscar Vacantes</a>
            <a href="https://okempleos.com/calculadora-salarial/">Calculadora Salarial</a>
            <a href="https://www.youtube.com/channel/UCE56i6YHPlYBrpATAilz3XQ" target="_blank">Escuela de
                empleabilidad</a>
            <a href="https://okempleos.com/eventos/">Eventos laborales</a>
            <p [routerLink]="'/login'">Tutoriales</p>
        </div>
        <div fxLayout="column" fxFlex="100" fxFlex.gt-xs="25" fxLayoutAlign.xs="start center" class="follow_card">
            <h1 class="title">Empresas</h1>
            <p [routerLink]="'/registro'" [queryParams]="{registro: 'empresa'}">Registrarse</p>
            <a href="/planes">Planes</a>
            <a href="https://api.whatsapp.com/send?phone=573108668776&text=!Hola%C2%A1" target="_blank">Solicitar
                asesoría</a>
            <a href="https://api.whatsapp.com/send?phone=573144041975&text=%C2%A1Hola%20buenas%20tardes!%20quisiera%20m%C3%A1s%20informaci%C3%B3n%20%F0%9F%98%83"
                target="_blank">Outsourcing de selección</a>
            <a href="https://api.whatsapp.com/send?phone=573144041975&text=%C2%A1Hola%20buenas%20tardes!%20quisiera%20m%C3%A1s%20informaci%C3%B3n%20%F0%9F%98%83"
                target="_blank">Contácto empresarial</a>
        </div>
        <div fxLayout="column" fxFlex="100" fxFlex.gt-xs="25" fxLayoutAlign.xs="start center" class="follow_card">
            <h1 class="title">Enlaces de interes</h1>
            <a href="https://news.okempleos.com/">Blog</a>
            <a href="https://okempleos.com/politicas-de-privacidad/">Políticas de privacidad</a>
            <a href="https://app.okempleos.com/terminos">Terminos y condiciones</a>
            <a href="https://www.gesth.co/#/home" target="_blank">Servicios de selección</a>
        </div>
    </section>

    <footer fxLayout="row wrap" fxLayoutAlign="center center">
        <div class="col-md-4" style="text-align-last: center;">
            <a mat-button href="https://okempleos.com/wp-content/uploads/2022/05/RESOLUCiON-0094-2.pdf" target="_blank" class="text-small text-truncate">
                <img src="/assets/images/home/SPE.png" style="width: 5%;"  alt="Servicio Público de Empleo">
                <img src="/assets/images/home/MinTrabajo.png" style="width: 10%; margin: 10px; vertical-align: middle;" alt="Ministerio del Trabajo">
                Vinculado a la red de prestadores del Servicio Público de Empleo.
                Autorizado por la 
                Unidad Administrativa Especial del Servicio Público de Empleo según 
                Resolución No. 0094 del 8 de Marzo del 2021.
            </a>
        </div>
    </footer>
</div>