import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalConstants } from 'app/common/global-constants';
import { HttpClient } from '@angular/common/http';
import {ViewEncapsulation} from '@angular/core';
import { formatDate } from "@angular/common";
import { AuthStateService } from 'app/pixcore/shared/auth-state.service';


declare function OpenWompiModalPackage(amount: number, reference: string, currency: string, empresa_id: number, paquete_id: number, paquete_name: string, paquete_valor: string, paquete_valor_i: number, back_url: string, front: string, mode: string ): any;
declare function SendRequestAjaxPostPackage(reference: string, empresa_id:number, paquete_id:number, paquete_name: string, paquete_valor: string, paquete_valor_i: number, back_url: string, front: string  ): any;

export  interface Paquete {
  id: number;
  nombre: string;
  valor: string;
  valor_i: number;
}

export interface DatosPaquete {
  paquetesSeguridad: Paquete[];
}


@Component({
  selector: 'modal-tipo-paquete',
  templateUrl: './modal-tipo-paquete.component.html',
  styleUrls: ['./modal-tipo-paquete.component.scss',
],
encapsulation: ViewEncapsulation.None 
})
export class ModalTipoPaqueteComponent implements OnInit {

  tipoPlanEmpresa: any;
  nombrePlan= ''
  urlLocal = GlobalConstants.apiURL;
  urlBase = GlobalConstants.URL;
  mode = GlobalConstants.mode;
  packages : Paquete[];
  favoritePackage: string;
  dateNow: any;

  packageSelected: Paquete;
  selected= false;

  constructor(
    public dialogRef: MatDialogRef<ModalTipoPaqueteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number,
    public _router: Router,
    private _httpClient: HttpClient,
    private _authService: AuthStateService
    ) {
      this.tipoPlanEmpresa = data;
    }

  ngOnInit(): void {


    this.getSelectsPackages();
  }

  onCerrarClick(isPlanes): void {

    if (isPlanes) {

      this._router.navigate(['/empresa/planes']);
     
    }

    this.dialogRef.close();
    
  }

  getSelectsPackages(): Promise<DatosPaquete>
  {
      return new Promise((resolve, reject) => {
          this._httpClient.get(`${this.urlLocal}selects_paquetes_empresa`)
              .subscribe((response: DatosPaquete) => {
                // console.log(response);
                this.packages = response.paquetesSeguridad;
                  resolve(response);
              }, reject);
      });
  }

  changeComboo(event) {
    this.packageSelected = event;
    this.selected = true;
    // console.log(event);
  }

  startPayment(valor): void{
    // console.log(valor);
    let select = this.packages.find(x => x.valor == valor);
    // console.log(select);
    OpenWompiModalPackage(valor, this.GenerateTransactionReference(select.id, select.nombre), 'COP', this._authService.getUserSession().empresa.id, select.id, select.nombre, select.valor, select.valor_i, this.urlLocal, this.urlBase, this.mode);
  }

  chargePackage(valor): void{
    // console.log(valor);
    let select = this.packages.find(x => x.valor == valor);
    // console.log(select);
    SendRequestAjaxPostPackage(this.GenerateTransactionReference(select.id, select.nombre),this._authService.getUserSession().empresa.id, select.id, select.nombre, select.valor, select.valor_i, this.urlLocal, this.urlBase);
  }

  GenerateTransactionReference(paquete_id, paquete_nombre): string{
    this.dateNow = new Date();
    let reference = "";
    let sufix = "OK";
    let plan = "";
    let periodo_format = "";
    let empresa_id = this._authService.getUserSession().empresa.id;
    let empresa_id_format =  this.pad(empresa_id, 4, "0"); // "0005"
    const format = 'yyyyMMddhhmmss';
    const locale = 'es-CO';
    const formattedDate = formatDate(this.dateNow, format, locale);

    plan = paquete_nombre.replace(/\s/g, "_");

    reference = sufix + "_" + paquete_id + "_" + plan + "_" + empresa_id_format + "_" + formattedDate;
    return reference;
}

pad(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

}
