import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { GlobalConstants } from 'app/common/global-constants';
import { Empresa } from './model/empresa.model';
import { InterceptorSkipHeader } from 'app/pixcore/shared/auth.interceptor';


@Injectable()
export class ListaEmpresasService implements Resolve<any>
{


    urlLocal = GlobalConstants.apiURL;
    urlProd = '';
    routeParams: any;
    binbsEjecutados = false;
    pageIndexFiltro = 1;
    onEmpresasPublicasChanged: BehaviorSubject<any>;

    onSearchTextEmpresasPublicasChanged: Subject<any>;
    onSearchTextEmpresasPublicasEnter: Subject<any>;
    onFilterEmpresasPublicasChanged: Subject<any>;
    onListadoChangedEmpresasPublicas: BehaviorSubject<any>;

    empresasPublicas: Empresa[];
    empresasLenght: number;
    empresasPublicasFiltradas: any[];
    
    user: any;
    
    searchText = '';
    filterBy = 'all';
    dataFiltro: any;

    sectoresEconomicos: any[];
    tiposEmpresas: any[];
    ciudades: any[];
    localidades: any[];
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    ) {

        this.onEmpresasPublicasChanged = new BehaviorSubject([]);
        this.onSearchTextEmpresasPublicasChanged = new Subject();
        this.onFilterEmpresasPublicasChanged = new Subject();
        this.onSearchTextEmpresasPublicasEnter = new Subject();
        this.onListadoChangedEmpresasPublicas = new BehaviorSubject(false);

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
       
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getSelectsAll(),
                this.getEmpresas()
            ]).then(
                ([files]) => {

                    if (!this.binbsEjecutados) {

    

                        this.onFilterEmpresasPublicasChanged.subscribe(filter => {
                            this.filterBy = filter;
            
                            // this.getEmpresas();
                            if (this.filterBy === 'filtroApi') {
                            
                                this.empresasPublicas = this.empresasPublicasFiltradas;
                                this.onEmpresasPublicasChanged.next(this.empresasPublicas);

                            }

                            if (this.filterBy === 'all' || this.filterBy === '') {
                                this.getEmpresas();
                            }
        
        
                            

                        });

                        this.binbsEjecutados = true;
                    }

                    resolve('');
                },
                reject
            );
        });
    }

    /**
     * Get contacts
     *
     * @returns {Promise<any>}
     */
    getEmpresas(page?: any): Promise<any> {
        this.pageIndexFiltro = page || 1;
        
        return new Promise((resolve) => {

            const headers = new HttpHeaders().set(InterceptorSkipHeader, '');

            this._httpClient.get(`${this.urlLocal}empresas_publico?page=${page}`, {headers: headers})
                .subscribe((response: any) => {
                  
                    this.empresasPublicas = response.data;
                    this.empresasLenght = response.total;
                
                    this.onEmpresasPublicasChanged.next(this.empresasPublicas);
                    resolve(this.empresasPublicas);

                }, (err) => {
                    this.empresasPublicas = [];
                    this.onEmpresasPublicasChanged.next(this.empresasPublicas);
                    resolve(err);

                });
        }
        );
    }


    getSelectsAll(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            
            const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
            this._httpClient.get(`${this.urlLocal}selects_empresa_filtro_publico`, {headers: headers})
                .subscribe((response: any) => {
                    this.sectoresEconomicos = response.sectoresEconomicos;
                    this.tiposEmpresas = response.tiposEmpresas;
                    this.ciudades = response.ciudades;
                    this.localidades = response.localidades;
                    resolve(response);
                }, reject);
        });
    }

    filtrar(data, page?: any): Promise<any> {
        this.pageIndexFiltro = page || 1;
        this.dataFiltro = data;
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
            this._httpClient.post(`${this.urlLocal}empresas_filtro_publico?page=${page}`, data, {headers: headers})
                .subscribe((response: any) => {
                    this.empresasPublicasFiltradas = response.data;
                    this.empresasLenght = response.total;
                    this.onFilterEmpresasPublicasChanged.next('filtroApi');
                    resolve(response);
                }, reject);
        });
    }



}
