
<div mat-dialog-content fxLayout="column" fxLayoutAlign="center center">

    <div class="logo">
        <img src="assets/images/logos/logo.svg" alt="logo">
    </div>

    <div fxLayout="row" fxLayoutAlign="center center">
        <span class="texto h2">Esta funcionalidad solo esta disponible para planes superiores</span>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center" class="py-24">
        <span class="texto h3">Si deseas usarla puedes adquirir uno de nuestros planes</span>
    </div>


    <div fxLayout="row" fxLayoutAlign="center center">
        <button class="mr-4" mat-raised-button color="accent" (click)="onCerrarClick(true)">Ver planes</button>
        <button class="ml-4" mat-raised-button color="primary" (click)="onCerrarClick(false)">Cerrar</button>
      </div>
    
</div>

