<div id="contacts" class="page-layout simple left-sidebar inner-sidebar inner-scroll">

    <div fxHide.gt-sm class="boton-flotante-container">
        <button mat-raised-button color="accent" class="boton-flotante" 
        (click)="toggleSidebar('contacts-main-sidebar')">
        <mat-icon class="mr-8">filter_list</mat-icon>
        <span>Filtros</span>
    </button>
    </div>

    <!-- HEADER -->
    <div class="header accent p-16 p-sm-24" fxLayout="column" fxLayoutAlign="start"
         fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">

        <!-- APP TITLE -->
        <div fxLayout="row" fxLayoutAlign="start center">

            <div fxHide fxShow.gt-xs class="logo" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon mr-16"
                          [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">list
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Vacantes disponibles
                </span>
            </div>

        </div>
        <!-- / APP TITLE -->

        <!-- SEARCH -->
        <div class="ml-sm-32" fxLayout="row" fxLayoutAlign="center center" fxFlex>

            <div class="search-wrapper">

                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <button mat-icon-button (click)="onEnterSearch()">
                        <mat-icon >search</mat-icon>
                    </button>
                    <input [formControl]="searchInput" (keyup.enter)="onEnterSearch()" placeholder="Buscador vacantes">
                </div>

            </div>

        </div>
        <!-- / SEARCH -->


    </div>
    <!-- / HEADER -->

    <!-- SELECTED BAR -->
    <!-- / SELECTED BAR -->

    <!-- CONTENT -->
    <div class="content">

        <!-- SIDEBAR -->
        <fuse-sidebar class="sidebar" name="contacts-main-sidebar" position="left" lockedOpen="gt-sm">

            <!-- SIDEBAR CONTENT -->
            <div class="content" fusePerfectScrollbar>
                <lista-vacantes-side-bar [@animate]="{value:'*'}"></lista-vacantes-side-bar>
            </div>
            <!-- / SIDEBAR CONTENT -->

        </fuse-sidebar>
        <!-- / SIDEBAR -->

        <!-- CENTER -->
        <div class="center p-24 pb-56" fusePerfectScrollbar #listScrollVacantes>

            <!-- CONTENT -->
            <div class="content">
                <vacantes-lista (scrollToTop)="scrollToTop()" class="lista"></vacantes-lista>
            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CENTER -->

    </div>
    <!-- / CONTENT-->

</div>

<!-- ADD CONTACT BUTTON -->
<!-- <button mat-fab class="accent" id="add-contact-button" (click)="newContact()" aria-label="add contact"
        [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}">
    <mat-icon>person_add</mat-icon>
</button> -->
<!-- / ADD CONTACT BUTTON -->
