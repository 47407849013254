export class GlobalTexts {

    //------------------------------
    // Consejos de Archivos
    //------------------------------

    public static consejo_video_presentacion: string = 'Sube un video con una duración de 1 minuto máximo, en donde le cuentes a las empresas sobre ti, tu experiencia laboral y académica';
    public static consejo_hoja_de_vida: string = 'Sube tu hoja de vida actualizada para detallar tu Perfil';
    public static consejo_portafolio: string = 'Sube tu portafolio de servicios, para mostrar tus habilidades, conocimientos y experiencia en proyectos propios u otras empresas.';

    public static consejo_video_presentacion_empresa: string = 'Sube un video con una duración de 1 minuto máximo, en donde le cuentes a los Candidatos sobre tu empresa';
    public static consejo_rut_empresa: string = 'Sube el RUT de la empresa para ser validado por el equipo de Ok Empleos';
    public static consejo_portafolio_empresa: string = 'Sube el portafolio de servicios o productos de tu empresa.';
    public static consejo_camara_comercio_empresa: string = 'Sube el documento de Cámara y Comercio para ser validado por el equipo de Ok Empleos';
    public static representante: string = 'Sube la cédula del representante legal para ser validado por el equipo de Ok Empleos';

    //------------------------------
    // Dialogos
    //------------------------------

    public static dialogo_login: string = '¡Felicitaciones! Estás más cerca de conseguir el trabajo de tus sueños.';
    public static dialogo_completar_perfil: string = 'Recuerda que al tener más completo todo tu perfil, los reclutadores podrán analizarte y más posibilidades tendrás de conseguir empleo.';
    public static dialogo_evaluacion_competencias: string = 'La evaluación de competencias, además de mostrarte tu Top 3 de competencias, permite a los reclutadores conocer más sobre ti y tu perfil para aplicar a ciertas vacantes.';

    
    public static getNamePlan(plan_id: number) {

        let plan = '';

        if (plan_id === 1) {
            plan = 'Plan Demo';
        }
            
        else if (plan_id === 2) {
            plan = 'Plan Básico';
        }

        else if (plan_id=== 3) {
            plan = 'Plan Enterprise';
        }

        else if (plan_id=== 4) {
            plan = 'Plan 1';
        }

        else if (plan_id=== 5) {
            plan = 'Plan 2';
        }

        else if (plan_id=== 6) {
            plan = 'Plan 4';
        }

        return plan;
        
    }
    

}