
<div mat-dialog-content fxLayout="column" >

    <div class="logo">
        <img src="assets/images/logos/logo.svg" alt="logo">
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="mb-8">
        <span *ngIf="tipo === 'whatsapp'" class="texto h2">Ingresa el número para compartir por WhatsApp</span>
        <span *ngIf="tipo !== 'whatsapp'" class="texto h2">Ingresa el correo para compartir la vacante</span>
    </div>

    <form fxLayout="column" fxLayoutAlign="start"  [formGroup]="formInput">
        
        <div fxLayout="row">
            <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
                <input matInput [formControlName]="'input'" required>
                <mat-error *ngIf="formInput.get('input').hasError('required')">El campo es requerido!</mat-error>
                <mat-error *ngIf="formInput.get('input').hasError('minlength')">Mínimo 5 caracteres</mat-error>
                <mat-error *ngIf="formInput.get('input').hasError('maxlength')">Máximo 100 caracteres</mat-error>
                <mat-error *ngIf="formInput.get('input').hasError('email')">Email incorrecto</mat-error>
                <mat-error *ngIf="formInput.get('input').hasError('pattern')">Solo escribe numeros</mat-error>
            </mat-form-field>
        </div>

        <div class="mt-4" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end center">   
        
            <button 
                    color="default"
                    type="button"
                    mat-raised-button
                    (click)="dialogRef.close(false)"
                    aria-label="CANCEL">
                    Cancelar
            </button>

            <button
                    color="primary"
                    mat-raised-button
                    (click)="dialogRef.close(formInput.value)"
                    [disabled]="formInput.invalid"
                    aria-label="SAVE">
                    Enviar
            </button>

        </div>
    </form>
    
</div>

