import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
  @ViewChild('contFoo') public conte: ElementRef;

  constructor(
    private _router: Router,
  ) {

  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (this.conte !== undefined) {
      this.conte.nativeElement.focus();
    }

    // const cont = this.conte.nativeElement;


    // if (this._router.url === '/inicio') {
    //   cont.style.backgroundColor = '#EEEDF2';
    // }

    // if (this._router.url === '/okupa') {
    //   cont.style.backgroundColor = '#fff';
    // }
  }

goToSupport(){
        
    let url = "https://api.whatsapp.com/send?phone=31440419758&text=Hola%20OkSupport%2C%20necesito%20ayuda%20o%20soporte%20t%C3%A9cnico";

    window.open(url, '_blank');
}

goToSales(){
        
  let url = "https://api.whatsapp.com/send?phone=573134433778&text=Hola%20OK%20EMPLEOS%2C%20%2Asoy%20una%20empresa%2A%20interesada%20en%20sus%20servicios.%20Quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20%5BColoque%20aqu%C3%AD%20el%20servicio%20sobre%20el%20que%20desea%20obtener%20informaci%C3%B3n%5D";

  window.open(url, '_blank');
}

}
