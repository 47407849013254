import { Component, Input } from '@angular/core';
import { Process } from './process.model';

@Component({
  selector: 'app-mini-chat',
  templateUrl: './mini-chat.component.html',
  styleUrls: ['./mini-chat.component.scss']
})
export class MiniChatComponent {
  @Input() process: Process;
  isChatOpen = false;

  toggleChat() {
    this.isChatOpen = !this.isChatOpen;
  }
}