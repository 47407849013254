import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { ModalErrorServidorComponent } from '../../modals/modal-error-servidor/modal-error-servidor.component';
import { MatDialog } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { TokenService } from 'app/pixcore/shared/token.service';
import { AuthStateService } from 'app/pixcore/shared/auth-state.service';
import { forEach } from 'lodash';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(
        public _dialog: MatDialog,
        public _snackBar: MatSnackBar,
        private _router: Router,
        private _token: TokenService,
        private _authState: AuthStateService
        )
    {         
    }

    
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
        .pipe(
            catchError((error: HttpErrorResponse) => {
            let errorMsg = '';
            if (error.error instanceof ErrorEvent) {
                console.log('this is client side error');
                errorMsg = `Error: ${error.error.message}`;
            }
            else {

                errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
                console.log(errorMsg);
                
                switch (error.status) { 
                    case 400: { 
                        console.log('codigo error:', 400);
                        break; 
                    }
                    case 401: { 

                        // this.openSnackBar('Lo sentimos, debes iniciar sesión', 'snack-error');
                        console.log('codigo error:', 401);
                        localStorage.removeItem('admin_mode');
                        this._authState.setAuthState(false);
                        this._token.removeToken();
                        this._router.navigate(['inicio']);
                     
                        break; 
                    }
                    case 500: {
                        // snack-bar
                        const mensaje500 = `Ooopss! Ha ocurrido un error... Por favor envía este mensaje al equipo de soporte: `;
                        this.openSnackBar( mensaje500 + this.createErrorVista(error.error), 'snack-error', true);
                        console.log('codigo error:', 500);
                        setTimeout(() => {
                            this._router.navigate(['/home']);
                        }, 2000);
                        break; 
                    }
                    case 0: {
                        const mensaje0 = `Ooopss! Ha ocurrido un error... Por favor envía este mensaje al equipo de soporte: `;
                        this.openSnackBar( mensaje0 + 'Código 0, sin conexión al servidor', 'snack-error', true);
                        console.log('codigo error:', 0);
                        setTimeout(() => {
                            this._router.navigate(['/home']);
                        }, 2000);
                        
                        break;
                    }
                    default: { 
                        console.log('codigo error:', error.status);
                        break; 
                    } 
                } 

            }
            
            return throwError(errorMsg);
            })
        );
    }

  openModal(): void {
    const modal = this._dialog.open(ModalErrorServidorComponent);
  }

  createErrorVista(objeto): string {
    let result = ``;
    
    for (const i in objeto) {
        if (i !== 'trace') {
            result += `${i}: ${objeto[i]} `;
        }
    }
    return result;
  }

    /**
     * Mostrar la respuesta al usuario
     * @param mensaje
     */
    private openSnackBar(mensaje: string, clase: string, is500?: boolean): void {
        
        if (is500) {

            this._snackBar.open(mensaje, 'x', {
                horizontalPosition: 'end',
                verticalPosition: 'top',
                panelClass: clase,
              });

        }else {
            this._snackBar.open(mensaje, 'x', {
                duration: 4000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
                panelClass: clase,
              });
        }
        
        
      }
}
