export const environment = {
    production: true,
    hmr       : false,
    URL : 'https://app.okempleos.com',
    apiURL: 'https://admin.okempleos.com/api/',
    oauthURL: 'https://admin.okempleos.com/oauth/',
    iaURL: 'http://admin.okempleos.com:8080/',
    mode: 'production',
    banners: true,
    slidetime: 5,
    preLaunch: false,
    country_code: 'co',
    logo_horizontal: false,
    country: 'Colombia',
    firebase: {
        apiKey: "AIzaSyAz09CGJamjxoj1x1-i3Q0m4MzGfL28JEo",
        authDomain: "okempleosapp.firebaseapp.com",
        databaseURL: 'okempleosapp-default-rtdb.firebaseio.com',
        projectId: "okempleosapp",
        storageBucket: "okempleosapp.appspot.com",
        messagingSenderId: "734633604976",
        appId: "1:734633604976:web:527824229b64e9d41441b5",
        measurementId: "G-7LWEWFYNE6",
        vapidKey: "BBBwgUWVnzf7KQxRrpnvRsNELd6C7VeaPhdy_vDeS0jU7eYfzwbTXvnlC_zTelOcIAJbXoBPYXuk-8dnDTZmyK0"
      },
      MQTTCONFIG: {
        url: 'mqtt.okempleos.com',
        hostname: 'mqtt.okempleos.com',
        protocol: 'ws',
        port: 8083,
        username: 'admin',
        password: 'emqUL&6%g0Zsr3bahjrx_test',
        path: '/mqtt',
        topic_query: 'topic/mqttx',
        topic_update_state: 'topic/mqttx',
        clientID: 'mqttx_597046f4'
      }
};
