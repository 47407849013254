import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormBuilder} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'modal-compartir',
  templateUrl: './modal-compartir.component.html',
  styleUrls: ['./modal-compartir.component.scss']
})
export class ModalCompartirComponent implements OnInit {
  
  tipo = ''
  formInput: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ModalCompartirComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    ) {
      this.tipo = data.tipo
    }

  ngOnInit(): void {
   
    this.formInput = this.createForm();

    if (this.tipo === 'whatsapp') {
      this.formInput.get('input').setValidators([Validators.pattern('[0-9]+$'), Validators.required, Validators.minLength(10), Validators.maxLength(100)]);
      this.formInput.get('input').updateValueAndValidity();
    } else {
      this.formInput.get('input').setValidators([Validators.email, Validators.required, Validators.minLength(10), Validators.maxLength(100)]);
      this.formInput.get('input').updateValueAndValidity();
    }

  }

  createForm(): FormGroup
    {
        return this._formBuilder.group({
          input: [
            {
              value: null,
              disabled: false,
            }
          ],
    
        });
    }

}
