export class CandidatoBd {

    primer_apellido: string;
    primer_nombre: string;
    numero_documento: string;
    nivel_educativo_id: number;
    tipo_documento_id: number;
    perfil: string;
    correo: string;
    telefono: string;
    fecha_nacimiento: Date;

    genero_id: number;
    ciudad_id: number;
    barrio: string;


    /**
     * Constructor
     *
     * @param candidatoBd
     */
    constructor(candidatoBd)
    {
        {
            this.primer_apellido = candidatoBd.primer_apellido || null;
            this.primer_nombre = candidatoBd.primer_nombre || null;
            this.numero_documento = candidatoBd.numero_documento || null;
            this.nivel_educativo_id = candidatoBd.nivel_educativo_id || null;
            this.tipo_documento_id = candidatoBd.tipo_documento_id || null;
            this.perfil = candidatoBd.perfil || null;
            this.correo = candidatoBd.correo || null;
            this.telefono = candidatoBd.telefono || null;

            this.genero_id = candidatoBd.genero_id || null;
            this.barrio = candidatoBd.barrio || null;
            this.ciudad_id = candidatoBd.ciudad_id || null;            
        }
    }
}
