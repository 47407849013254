
<div mat-dialog-content fxLayout="column" fxLayoutAlign="center center">

    <div class="logo">
        <img src="assets/images/logos/logo.svg" alt="logo">
    </div>

    <div fxLayout="row" fxLayoutAlign="center center">
        <span class="texto h2">{{ dialogo_login }} </span>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="pt-24">
        <button mat-raised-button color="accent" mat-dialog-close>Inicia sesión</button>
      </div>
    
</div>

