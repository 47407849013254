import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LogoService {
  constructor() {}

  getLogoPath(): string {
    if (environment.country_code !== null && environment.logo_horizontal == false ) {
      return `assets/images/logos/logo_${environment.country_code}.svg`;
    } else if (environment.country_code !== null && environment.logo_horizontal == true ) {
      return `assets/images/logos/ok_logo_horizontal.svg`;
    } else {
      // Default logo if the country code doesn't match any of the above
      return 'assets/images/logos/logo.svg';
    }
  }
}
