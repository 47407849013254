import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginatorIntl } from '../../main/utils/custom-paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgSelectModule } from '@ng-select/ng-select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';

import { FuseConfirmDialogModule, FuseSidebarModule } from '@fuse/components';


import { ListaEmpresasComponent } from 'app/public/lista-empresas/lista-empresas.component';
import { ListaEmpresasService } from 'app/public/lista-empresas/lista-empresas.service';
import { ListaViewComponent } from 'app/public/lista-empresas/lista-view/lista-view.component';
import { SideBarListaEmpresasComponent } from 'app/public/lista-empresas/side-bar/side-bar.component';


export const MY_FORMATS = {
    parse: {
      dateInput: 'DD/MM/YYYY',
    },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMM YYYY',
    },
  };

const routes: Routes = [
    {
        path     : 'lista-empresas',
        component: ListaEmpresasComponent,
        resolve  : {
            vacantes: ListaEmpresasService
        }
    }
];

@NgModule({
    declarations: [
    
        ListaEmpresasComponent,
        ListaViewComponent,
        SideBarListaEmpresasComponent,


    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        MatMenuModule,
        MatRadioModule,
        FuseSidebarModule,
        FuseConfirmDialogModule,
        NgSelectModule,
        FuseSharedModule,
        FuseWidgetModule,
        MatProgressSpinnerModule
       
    ],
    providers   : [
        ListaEmpresasService,
        { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl}
    ]
})
export class ListaEmpresasModule
{
}
