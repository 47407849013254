<div fxLayout="column" class="cont_general">
    <app-navbar></app-navbar>
    <header fxLayout="column" fxLayoutAlign="start center" style="margin-top: 3%;">
        <div *ngIf="env.banners" #secondDiv class="reveal">
            <div class="slides">
                <section data-background="assets/images/banners/header_background.webp">
                    <div class="switch" style="padding-top: 2%;">
                        <div>
                            <h1 style="color: #FFFFFF; font-family: 'Montserrat'; font-style: normal; font-weight: 700; text-transform: none;">¡Descubre el talento </h1>
                            <h1 style="color: #F83109; font-family: 'Montserrat'; font-style: normal; font-weight: 700; text-transform: none;">que te mereces!</h1>
                            <p style="color: #FFFFFF; font-family: 'Montserrat'; font-style: normal; font-weight: 400; margin-top: 32px;">Conecta con el mejor talento humano para <br>tu compañía desde inicio a fin. ¡Confía en nosotros</p>
                            <p class="btn_align" style="padding-top: 1%;">
                                <button class="button-custom btn-vacant" mat-raised-button color="accent"  fxLayoutAlign="center center" onclick="window.open('/login','_self')"  style="font-weight: 700; font-size: 25px; line-height: 39px; width: fit-content; border-radius: 6px !important">Publicar vacantes</button>
                                <button class="button-custom btn-vacant-y" mat-raised-button color="" fxLayoutAlign="center center" onclick="window.open('/registro?registro=empresa','_self')"  style="font-weight: 700; font-size: 25px; line-height: 39px; width: fit-content; border-radius: 6px !important;">Registrar empresa</button>
                            </p>
                            <p style="color: #FFFFFF; font-family: 'Montserrat'; font-style: normal; font-weight: 400; line-height: 15px;">¿No tienes una cuenta? <a href="/registro?registro=candidato" style="color: #FFFFFF; text-decoration-line: underline;">Regístrate aquí</a></p>
                        </div>
                    </div>
                </section>
                <section *ngFor="let banner of banners" class="slide-background-content" style="background-image: url('{{banner.details[0].fondo_src}}');">
                    <div class="switch">
                        <div [innerHtml]="banner.details[0].titulo  | safeHtml"></div>
                    </div>                  
                </section>
           </div>
        </div>
    </header>
    <div *ngIf="showButton" id="popup" class="container-twa">
        <div class="header">
          <h1>¡Instala nuestra aplicación web gratis!</h1>
        </div>
        <div class="content">
          <p>Es rápida y ocupa menos espacio de almacenamiento.</p>
        </div>
        <div class="actions">
          <button class="install-button" (click)="installPlayStore()">Instalar</button>
          <button class="not-now-button" (click)="showButton = false">No ahora</button>
        </div>
      </div>

    <div fxLayout="column" fxLayoutAlign="start center" style="margin-top: 3%;">
        <h1 class="tittle-h1-morad">Las mejores empresas esperan por ti</h1>
        <div fxLayout="column" fxHide fxShow.gt-sm style="place-content: center flex-start; align-items: flex-start; width: 55%; align-items: flex-start;">
            <p><strong>Nombre de la compañía o cargo</strong></p>
        </div>
        <div #searchDiv class="header_carousel" >
            <div fxLayout="row wrap" fxFlex="100" fxFlex.gt-md="80" fxLayoutAlign="center center" class="search" style="z-index: 999;" [ngStyle]="env.banners && {'margin-top' : '-1%'}">  
                <input id="searchi" fxFlex="100" fxFlex.gt-xs="68" fxFlex.gt-md="68" fxFlex.gt-lg="68" class="item_sarch input_cargo" [(ngModel)]="palabra" id="cargox" required="" placeholder="Buscar vacantes">
                <button class="button-custom btn-vacant" mat-raised-button color="accent" (click)="searchVacante(palabra)" fxLayoutAlign="center center">Buscar Vacantes </button>
            </div>
        </div>
        <div fxFlex="100" class="aliances">
            <!-- slidert movile -->
            <ng-image-slider class="slider-school--mobile"
                [imagePopup]="false" [animationSpeed]="5" [infinite]="true" [images]="imgCollection"
                [autoSlide]="1" [lazyLoading]="1" [slideImage]="1" [imageSize]="{width:'100%', height:'auto',space: 12}">
            </ng-image-slider>

            <!-- slidert Desktop -->
            <ng-image-slider class="slider-school--desktop"
                [imagePopup]="false" [animationSpeed]="5" [infinite]="true" [images]="imgCollection"
                [autoSlide]="1"  [lazyLoading]="1" [slideImage]="1" [imageSize]="{width:'auto', height:'60px',space: 12}">
            </ng-image-slider>
        </div>
        <h2>Descubre todo lo que puedes hacer con <strong>OK empleos</strong></h2>
        <img src="/assets/images/home/down_arrow.svg" alt="down">
    </div>


    <main class="main_cont">


        <section class="section section__find">
            <div fxLayout="row wrap" class="cont_find">
                <div fxLayout="row" fxFlex="85" fxFlex.gt-sm="50" fxLayoutAlign="center center"
                    class="find_section find_section-right">
                    <img src="assets/images/home/encuentra_img.png" alt="home" width="657" height="346" style="width: 90%; height:auto;">
                </div>
                <div fxFlex="100" fxFlex.gt-sm="45" class="find_section find_section-left" style="align-content: center;">
                    <h1 class="title title--visible">Ecuentra a tu candidato</h1>
                    <p class="parr--visible">Te conectamos con candidatos cada vez más preparados para tus posiciones operativas, bilingues, administrativas y directivas.</p>
                    <p class="visible_subtext">¡Publica tus vacantes, Ilimitadamente!</p>
                    <button class="button-custom btn--visible" mat-raised-button color="accent" (click)="goToURL('registro?registro=empresa')">Publica tus vacantes</button>
                </div>
            </div>
        </section>

        <section class="section section__visible">
            
            <h1 class="title title--visible">Prepárate con Ok <br> para el trabajo</h1>
            <!-- <h2 class="title--h2">Taller personalizado</h2> -->
            <p class="parr--visible">Practicando tus entrevistas laborales en línea gratis en español o ingles con <strong>RIO</strong>, nuestra Inteligencia Artificial; incrementa tus posibilidades de encontrar el empleo que mereces.</p>
            <p class="visible_list">
                Entrena para tus entrevistas de trabajo en línea <img src="assets/images/logos/arrow_down_orange.svg" alt="down">
                <br><hr>Toma un Taller personalizado <img src="assets/images/logos/arrow_down_orange.svg" alt="down"></p>
            <!-- <p class="visible_subtext">Solicita tu asesoría ahora.</p> -->
            <button class="button-custom btn--visible" mat-raised-button color="accent" (click)="goToURL('registro?registro=candidato')">¡Regístrate ahora!</button>
            <img class="visible_img" fxFlex="80" fxFlex.gt-xs="45" fxFlex.gt-sm="70"
            src="assets/images/home/preparate_img.png" alt="visible" width="511" height="477" style="width: 70%; height:auto;">
        </section>

        <section class="section section__ferias">
            <div fxLayout="row wrap" class="cont_find">
                <div fxLayout="row" fxFlex="85" fxFlex.gt-sm="50" fxLayoutAlign="center center"
                    class="find_section find_section-right">
                    <img src="assets/images/home/ferias_img.png" alt="home" width="657" height="346" style="width: 90%; height:auto;">
                </div>
                <div fxFlex="100" fxFlex.gt-sm="45" class="find_section find_section-left" style="align-content: center;">
                    <h1 class="title title--visible">Ferias laborales</h1>
                    <p class="parr--visible">Ferias de empleo organizamos junto a entidades públicas e instituciones educativas diferentes feria de empleo para llegar a miles de personas, fomentando así, el crecimiento económico del país.</p>
                    <!-- <p class="visible_subtext">¡Publica tus vacantes, Ilimitadamente!</p> -->
                    <button class="button-custom btn--visible" mat-raised-button color="accent" (click)="goToURL('login')">¡Comienza ahora!</button>
                </div>
            </div>
        </section>

        <section class="section section__academia">
            
            <h1 class="title title--visible">Academia Ok empleos</h1>
            <h2 class="title--h2">Tu futuro empieza aquí.</h2>
            <p class="parr--visible">Entrenamiento práctico con expertos para conseguir el empleo que mereces. Capacita para conseguir trabajo y despega tu vida profesional.</p>
            <!-- <p class="visible_subtext">Solicita tu asesoría ahora.</p> -->
            <button class="button-custom btn--visible" mat-raised-button color="accent" (click)="goToURLExternal('https://academia.okempleos.com')">¡Comienza ahora!</button>
            <img class="visible_img" fxFlex="80" fxFlex.gt-xs="45" fxFlex.gt-sm="70"
            src="assets/images/home/academia_img.png" alt="visible" width="511" height="477" style="width: 70%; height:auto;">
        </section>
        
        <section class="section section__head">
            <div fxLayout="row wrap" class="cont_find">
                <div fxLayout="row" fxFlex="85" fxFlex.gt-sm="50" fxLayoutAlign="center center"
                    class="find_section find_section-right">
                    <img src="assets/images/home/head_hunter_img.png" alt="home" width="657" height="346" style="width: 90%; height:auto;">
                </div>
                <div fxFlex="100" fxFlex.gt-sm="45" class="find_section find_section-left" style="align-content: center;">
                    <h1 class="title title--visible">Head Hunter</h1>
                    <p class="parr--visible">Encontramos de manera rápida y personalizada a tus candidatos <strong style="color:#FC3109;">en toda Latinoamérica</strong>. No importa dónde te encuentres, nuestros expertos en selección encontrarán para ti al candidato ideal.</p>
                    <!-- <p class="visible_subtext">¡Publica tus vacantes, Ilimitadamente!</p> -->
                    <button class="button-custom btn--visible" mat-raised-button color="accent" (click)="goToURLExternal('https://gesth.co')">¡Empieza ahora!</button>
                </div>
            </div>
        </section>

        <section fxLayout="column" id="school" class="section section__school">
            <h1 class="title" style="margin-bottom: 0px; text-align: center;">¡No pierdas más oportunidades de empleo!</h1>
            <h1 class="title" style="color:#F83109; font-weight: 400; margin-top: 0px; text-align: center;">Capacítate en empleabilidad y conviértete en el <br>candidato Ideal.</h1>
            <div fxFlex="100">
                <!-- slidert movile -->
                <ng-image-slider class="slider-school--mobile" #nav (imageClick)="imageClick($event)"
                    [imagePopup]="false" [animationSpeed]="5" [infinite]="true" [images]="videosCandidatoSlider"
                    [autoSlide]="1" [lazyLoading]="1" [slideImage]="1" [imageSize]="{width:'100%', height:'auto',space: 12}">
                </ng-image-slider>

                <!-- slidert Desktop -->
                <ng-image-slider class="slider-school--desktop" #nav (imageClick)="imageClick($event)"
                    [imagePopup]="false" [animationSpeed]="5" [infinite]="true" [images]="videosCandidatoSlider"
                    [autoSlide]="1"  [lazyLoading]="1" [slideImage]="1" [imageSize]="{width:'371px', height:'auto',space: 12}">
                </ng-image-slider>
            </div>
        </section>
    </main>
    <app-footer></app-footer>
    <app-footer-float></app-footer-float>
</div>