import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as spanish } from './i18n/es';
import { locale as english } from './i18n/en';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Params, Router, ActivatedRoute } from '@angular/router';
import { GlobalConstants } from 'app/common/global-constants';
import { AuthService } from 'app/pixcore/shared/auth.service';
import { TokenService } from 'app/pixcore/shared/token.service';
import { AuthStateService } from 'app/pixcore/shared/auth-state.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { InterceptorSkipHeader } from 'app/pixcore/shared/auth.interceptor';

@Component({
    selector     : 'login-admin',
    templateUrl  : './login-admin.component.html',
    styleUrls    : ['./login-admin.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class LoginAdminComponent implements OnInit
{
    loginForm: FormGroup;
    hide = true;
    loadingLogin = false;
    urlLocal = GlobalConstants.apiURL;
    clientId = GlobalConstants.clientId;
    clientKey = GlobalConstants.clientKey;
    currentStateEmpresa = "1";


    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private http: HttpClient,
        private router: Router,
        public authService: AuthService,
        private token: TokenService,
        private authState: AuthStateService,
        private _snackBar: MatSnackBar,
        private _route: ActivatedRoute
     
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        this._fuseTranslationLoaderService.loadTranslations(spanish, english);

        if (this.authState.getUserSession()) {
            this.router.navigate(['/home']);
        } 
            
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.loginForm = this._formBuilder.group({
            email   : ['', [Validators.required, Validators.email]],
            email_user   : ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
    }

    OnSubmit(): void
    {
        this.loadingLogin = true;
        const formData = this.loginForm.getRawValue();
        const data = {
            email_usuario: formData.email_user,
            email: formData.email,
            password: formData.password,        
            grant_type: 'password',
            client_id: this.clientId,
            client_secret: this.clientKey,
            scope: '*'
        }

        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');

        this.http.post(`${this.urlLocal}login`, data, {headers:headers}).subscribe(
            (result: any) => {
                this.openSnackBar("Ingreso satisfactorio", 'snack-success');
                this.responseHandler(result);
                localStorage.setItem('admin_mode', 'admin');
                this.authState.removeUserSession();
                this.authState.setAuthState(true);
                this.router.navigate(['/home']);
                this.loadingLogin = false;
            },
            error => {
                if (error.status === 0) {
                    this.openSnackBar('Sin conexión al servidor', 'snack-error');
                }else {
                    this.openSnackBar('Los datos ingresados son incorrectos.', 'snack-error');
                }
                
                this.loadingLogin = false;
            }
            
            
        )
    
    }


    
    

    // Handle response
    responseHandler(data){
        this.token.handleData(data.access_token);
    }

    /**
     * Mostrar la respuesta al usuario
     * @param mensaje
     */
    private openSnackBar(mensaje: string, clase: string): void {
        this._snackBar.open(mensaje, 'x', {
         duration: 4000,
         horizontalPosition: 'end',
         verticalPosition: 'top',
         panelClass: clase,
       });
      }
}
