import { Component, OnDestroy, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as spanish } from './i18n/es';
import { locale as english } from './i18n/en';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Params, Router, ActivatedRoute } from '@angular/router';
import { GlobalConstants } from 'app/common/global-constants';
import { AuthService } from 'app/pixcore/shared/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

import { AuthStateService } from 'app/pixcore/shared/auth-state.service';
import { InterceptorSkipHeader } from 'app/pixcore/shared/auth.interceptor';

import { TokenService } from 'app/pixcore/shared/token.service';

import { AfterViewInit } from '@angular/core';
declare var anime: any;

import { ViewChild, ElementRef } from '@angular/core';
import { LogoService } from 'app/logo.service';

@Component({
    selector: 'code',
    templateUrl: './code.component.html',
    styleUrls: ['./code.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class CodeComponent implements OnInit, OnDestroy, AfterViewInit {


    codeForm: FormGroup;
    hide1 = true;
    hide2 = true;
    loading = false;

    // Private
    private _unsubscribeAll: Subject<any>;

    urlLocal = GlobalConstants.apiURL;
    
    @ViewChild('dataContainer') dataContainer: ElementRef;
    @ViewChild('sideContainer') sideContainer: ElementRef;
    @ViewChild('footerContainer') footerContainer: ElementRef;


    isOnlycode = true;
    isEmailComporativo = true;
    currentStateCandidato = true;
    currentStateEmpresa = false;
    currentEmail='';
    sky= [];
    isEnabled = true;
    state = {
        num: 60,
        vw: 330,
        vh: 600
    };
    logoPath: String;
   

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private httpClient: HttpClient,
        private router: Router,
        public authService: AuthService,
        private authState: AuthStateService,
        private _snackBar: MatSnackBar,
        public _dialog: MatDialog,
        private _route: ActivatedRoute,
        private token: TokenService,
        private logoService: LogoService,
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this._fuseTranslationLoaderService.loadTranslations(spanish, english);

        if (this.authState.getUserSession()) {
            this.router.navigate(['/home']);
        }
        this.logoPath = this.logoService.getLogoPath();
            
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {


        this.codeForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email, Validators.minLength(5), Validators.maxLength(100)]],
            user_type: ['', Validators.required],
            accept_terms: [false, Validators.required],
            password: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(100)]],
            password_confirmation: ['', [Validators.required, confirmPasswordValidator]],
            recaptcha: [null],
            numero_identificacion: [null],
            codigo_verificacion: [null]
        });


        this._route.queryParams
        .subscribe(params => {

            this.codeForm.controls['codigo_verificacion'].setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('[0-9]')]);
            this.codeForm.get('codigo_verificacion').updateValueAndValidity();
        
            if (params.email) {
               this.currentEmail = params.email;
            }
        });

        if (!window.location.href.includes('localhost')){
            this.codeForm.controls['recaptcha'].setValidators([Validators.required]);
            
        }

    }


    resolved(captchaResponse: string): void {
        // console.log(`Resolved response token: ${captchaResponse}`);
       
      }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    OnSubmit(): void {

        this.isEnabled = false;

        this.loading = true;

        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');

        const formData = this.codeForm.getRawValue();

        if (formData.user_type !== '1') {
            formData.correo_valido = this.isEmailComporativo;
        }
        formData.email = this.currentEmail;
     
        this.httpClient.post(`${this.urlLocal}code_user`, formData, {headers: headers}).subscribe(
            (result: any) => {
                if (!result.errors) {

                    localStorage.removeItem('admin_mode');
                    this.openSnackBar("Ingreso satisfactorio", 'snack-success');
                    this.responseHandler(result.sessionData);
                    this.authState.removeUserSession();
                    this.authState.setAuthState(true);
                    this.router.navigate(['/home']);
                    this.loading = false;
                    this.isEnabled = true;

                } else if (result.errors.email) {
                    this.loading = false;
                    this.isEnabled = true;
                    this.openSnackBar(result.errors.email[0], 'snack-error');
                    
                } else if (result.errors.numero_identificacion) {
                    this.isEnabled = true;
                    this.loading = false;
                    this.openSnackBar(result.errors.numero_identificacion[0], 'snack-error');
                } else if (result.errors.codigo_verificacion) {
                    this.isEnabled = true;
                    this.loading = false;
                    this.openSnackBar(result.errors.codigo_verificacion[0], 'snack-error');
                }else {
                    this.isEnabled = true;
                    this.loading = false;
                    this.openSnackBar(result.message, 'snack-error');
                }

            });
    }

    resendEmail(e): void {

        this.loading = true;
        
        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');

        const formData = this.codeForm.getRawValue();

        formData.email = this.currentEmail;
     
        this.httpClient.post(`${this.urlLocal}resend_code`, formData, {headers: headers}).subscribe(
            (result: any) => {
                if (!result.errors) {
              
                    this.openSnackBar("Correo enviado Satisfactoriamente", 'snack-success');


                } else if (result.errors.email) {
                    this.loading = false;
                     
                    this.openSnackBar(result.errors.email[0], 'snack-error');
                }else {
                    this.loading = false;
                    this.openSnackBar(result.message, 'snack-error');
                }

            });

    }

    updateInput(e): void {

        if (e.target.value.length === 6) {
            this.OnSubmit();
        }
    }

    private sleep(ms): Promise<any> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    // Handle response
    responseHandler(data){
        this.token.handleData(data.access_token);
    }

    /**
     * Mostrar la respuesta al usuario
     * @param mensaje
     */
    private openSnackBar(mensaje: string, clase: string): void {
        this._snackBar.open(mensaje, 'x', {
         duration: 4000,
         horizontalPosition: 'end',
         verticalPosition: 'top',
         panelClass: clase,
       });
      }

    OnCandidatoSelected(e): void {
        
        
        this.currentStateEmpresa = false;
        this.currentStateCandidato = true;
        this.codeForm.controls['numero_identificacion'].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(100), Validators.pattern('[0-9]+$')]);
        this.codeForm.get('numero_identificacion').updateValueAndValidity();
    }

    OnEmpresaSelected(e): void {
    
        
        this.currentStateEmpresa = true;
        this.currentStateCandidato = false;
        this.codeForm.controls['numero_identificacion'].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(100), Validators.pattern('[0-9]+\-[0-9]{1}$')]);
        this.codeForm.get('numero_identificacion').updateValueAndValidity();  
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    starryNight(): void {
        anime({
            targets: ["#sky .star"],
            opacity: [
              {
                duration: 700,
                value: "0"
              },
              {
                duration: 700,
                value: "1"
              }
            ],
            easing: "linear",
            loop: true,
            delay: (el, i) => 50 * i
          });
    }

    starryNightMobile(): void {
        anime({
            targets: ["#sky-mobile .star"],
            opacity: [
              {
                duration: 700,
                value: "0"
              },
              {
                duration: 700,
                value: "1"
              }
            ],
            easing: "linear",
            loop: true,
            delay: (el, i) => 50 * i
          });
    }

    shootingStar(): void {
        anime({
            targets: ["#shootingstars .wish"],
            easing: "linear",
            loop: true,
            delay: (el, i) => 1000 * i,
            opacity: [
              {
                duration: 700,
                value: "1"
              }
            ],
            width: [
              {
                value: "150px"
              },
              {
                value: "0px"
              }
            ],
            translateX: 350
          });
    }

    shootingStarMobile(): void {
        anime({
            targets: ["#shootingstars-mobile .wish-mobile"],
            easing: "linear",
            loop: true,
            delay: (el, i) => 1000 * i,
            opacity: [
              {
                duration: 700,
                value: "1"
              }
            ],
            width: [
              {
                value: "150px"
              },
              {
                value: "0px"
              }
            ],
            translateX: 350
          });
    }

    randomRadius(): number {
        return Math.random() * 0.7 + 0.6;
    }

    getRandomX(): any {
        return Math.floor(Math.random() * Math.floor(this.sideContainer.nativeElement.offsetWidth)).toString();
    }

    getRandomY(): any {
        return Math.floor(Math.random() * Math.floor(this.sideContainer.nativeElement.offsetHeight)).toString();
    }

    getRandomXMobile(): any {
        return Math.floor(Math.random() * Math.floor(this.footerContainer.nativeElement.offsetWidth)).toString();
    }

    getRandomYMobile(): any {
        return Math.floor(Math.random() * Math.floor(this.footerContainer.nativeElement.offsetHeight)).toString();
    }

    ngAfterViewInit(): void {

        const { num } = this.state;

        {[...Array(num)].map((x, y) => (
            this.dataContainer.nativeElement.insertAdjacentHTML('beforeend', '<svg id="sky"><circle cx='+this.getRandomX()+' cy='+this.getRandomY()+' r='+this.randomRadius()+' stroke="none" strokeWidth="0" fill="white" key='+y+' class="star"/></svg>')
        ))}

        {[...Array(30)].map((x, y) => (
            this.dataContainer.nativeElement.insertAdjacentHTML('beforeend', "<div id='shootingstars'><div key="+y+" class='wish' style='left: "+this.getRandomY()+"px; top: "+this.getRandomX()+"px;'/></div>")
        ))}
   
        
        this.starryNight();
        this.shootingStar();

        
        {[...Array(30)].map((x, y) => (
            this.footerContainer.nativeElement.insertAdjacentHTML('beforeend', '<svg id="sky-mobile"><circle cx='+this.getRandomXMobile()+' cy='+this.getRandomYMobile()+' r='+this.randomRadius()+' stroke="none" strokeWidth="0" fill="white" key='+y+' class="star"/></svg>')
        ))}

        {[...Array(15)].map((x, y) => (
            this.footerContainer.nativeElement.insertAdjacentHTML('beforeend', "<div id='shootingstars-mobile'><div key="+y+" class='wish-mobile' style='left: "+this.getRandomYMobile()+"px; top: "+this.getRandomXMobile()+"px;'/></div>")
        ))}

        this.starryNightMobile();
        this.shootingStarMobile();


         
    }
     
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('password');
    const password_confirmation = control.parent.get('password_confirmation');

    if (!password || !password_confirmation) {
        return null;
    }

    if (password_confirmation.value === '') {
        return null;
    }

    if (password.value === password_confirmation.value) {
        return null;
    }

    return { passwordsNotMatching: true };
};
