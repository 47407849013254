export const locale = {
    lang: 'en',
    data: {
        'WELCOME': {
            'TITLE': 'Hello! Welcome to Recorvet :)',
            'SUBTITLE': 'Here everyone love the animals'
        },
        'CODE_FORM': {
            'TITLE': 'Check your email',
            'SUBTITLE1': 'We send you an email with a verification code to ',
            'SUBTITLE2': 'Write it below to confirm your email address.',
            'RESEND': 'Request another code',
            'INPUT_CODE': 'Enter your 6-digit code',
            'WRONG_CODE': 'The entered code is incorrect...',
          
        }
    }
};
