<div class="cont_btn_foot_circle" fxLayout="column" fxLayoutAlign="center center">
    <div *ngIf="circle === true" class="cont_circ_foo">
        <div class="circle_item-cont" fxLayoutAlign="center center">
            <div class="cont_img--circle" fxLayout="column" [routerLink]="'/lista-vacantes'"
                fxLayoutAlign="center center">
                <img class="circle_log" src="../../../../assets/images/logos/movil_search.png" alt="">
                <p>Buscar <br />Empleo</p>
            </div>
        </div>
        <div class="circle_item-cont" fxLayoutAlign="space-between center">
            <div class="cont_img--circle" fxLayout="column" [routerLink]="'/registro'"
                [queryParams]="{registro: 'empresa'}" fxLayoutAlign="center center">
                <img class="circle_log" src="../../../../assets/images/logos/movil_page.png" alt="">
                <p>Registrar <br />Empresa</p>
            </div>
            <div class="cont_img--circle" fxLayout="column" [routerLink]="'/login'" fxLayoutAlign="center center">
                <img class="circle_log" src="../../../../assets/images/logos/movil_table.png" alt="">
                <p>Publicar <br />Vacante</p>
            </div>
        </div>
        <div class="circle_item-cont" fxLayoutAlign="space-evenly center">
            <div class="cont_img--circle" fxLayout="column" [routerLink]="'/registro'"
                [queryParams]="{registro: 'candidato'}" fxLayoutAlign="center center">
                <img class="circle_log" src="../../../../assets/images/logos/movil_register.png" alt="">
                <p>Registrar <br />HV</p>
            </div>
            <div class="cont_img--circle" fxLayout="column" [routerLink]="'/login'" fxLayoutAlign="center center">
                <img class="circle_log" src="../../../../assets/images/logos/movil_user.png" alt="">
                <p>Perfil</p>
            </div>
        </div>
    </div>
</div>

<div class="cont_btn_foot_whats" fxLayout="column" fxLayoutAlign="center end">
    <div *ngIf="whatsapp === true" class="cont_chat">
        <span class="close" (click)="openWhats()">X</span>
        <div class="chat_header" fxLayout="column" fxLayoutAlign="center center">
            <h1>Estamos para ayudarte</h1>
            <p>Selecciona una asesora</p>
        </div>
        <a fxLayout="row" fxLayoutAlign="space-between center"
            href="https://api.whatsapp.com/send?phone=573108668776&text=Hola Ok Empleos 👋 soy candidato/a y quiero más información de las vacantes."
            target="_blank">
            <img src="../../../../assets/images/logos/whatsapp_chat.svg" alt="">
            <div class="chat_text" fxLayout="column" fxLayoutAlign="start center">
                <h1>Soy Candidato</h1>
                <p>Asistencia candidatos</p>
            </div>
        </a>
        <a fxLayout="row" fxLayoutAlign="space-between center"
            href="https://api.whatsapp.com/send?phone=573144041975&text=Hola Ok Empleos 👋 soy una empresa en búsqueda del talento humano, quiero una asesoría en reclutamiento. 😀"
            target="_blank">
            <img src="../../../../assets/images/logos/whatsapp_chat.svg" alt="">
            <div class="chat_text" fxLayout="column" fxLayoutAlign="start center">
                <h1>Soy Empresa</h1>
                <p>Asistencia Empresas</p>
            </div>
        </a>
    </div>
    <img class="log_whatsapp" (click)="openWhats()" src="../../../../assets/images/logos/whatsapp_chat.svg" alt="">
</div>

<div fxLayout="row" fxFlex="100" class="circle_footer" fxLayoutAlign="center center">

    <div fxLayout="row" fxFlex="80" class="circle_cont" fxLayoutAlign="space-between center">
        <div class="item_circle" [routerLink]="'/first'" fxLayout="column" fxLayoutAlign="center center">
            <img class="icon_menu" src="../../../../assets/images/logos/home_orange.png" alt="">
            <p>Home</p>
        </div>
        <div class="item_circle" fxLayout="column" fxLayoutAlign="center center">
            <div *ngIf="circle !== true" class="item_circle--selec" fxLayout="column" fxLayoutAlign="center center"
                (click)="openCircle()">
                <img class="icon_inter" src="../../../../assets/images/logos/add.png" alt="">
                <p>Más</p>
            </div>
            <div *ngIf="circle === true" class="item_circle--selec" fxLayout="column" fxLayoutAlign="center center"
                (click)="openCircle()">
                <img class="icon_inter" src="../../../../assets/images/logos/closeX.png" alt="">
                <p>Menos</p>
            </div>
        </div>
        <div class="item_circle item_what" (click)="openWhats()" fxLayout="column" fxLayoutAlign="center center">
            <img class="icon_menu" src="../../../../assets/images/logos/whatsapp_orange.png" alt="">
            <p>WhatsApp</p>
        </div>
    </div>
</div>