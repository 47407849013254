import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'createdAtDiff'
})
export class CreatedAtPipe implements PipeTransform {

  transform(value: string, args?: any): string {

    let resultado = '';
    let tiempo = moment().diff(moment(value), 'years');
    let tipo = 'año'
        if (tiempo <= 0) {
            tiempo = moment().diff(moment(value), 'months');
            tipo = 'mes'
            if (tiempo <= 0) {
                tiempo = moment().diff(moment(value), 'days');
                tipo = 'dia'

                if (tiempo <= 0) {
                    tiempo = moment().diff(moment(value), 'hours');
                    tipo = 'hora'

                    if (tiempo <= 0) {
                        tiempo = moment().diff(moment(value), 'minutes');
                        tipo = 'minuto'
                    }
                }
            }
            
        }
    
    if (tipo === 'año') {

        tiempo === 1 ? resultado = `Hace ${tiempo} año`: resultado = `Hace ${tiempo} años`;

        return resultado;
    }

    if (tipo === 'mes') {

        tiempo === 1 ? resultado = `Hace ${tiempo} mes`: resultado = `Hace ${tiempo} meses`;

        return resultado;
    }

    if (tipo === 'dia') {

        tiempo === 1 ? resultado = `Hace ${tiempo} dia`: resultado = `Hace ${tiempo} dias`;

        return resultado;
    }

    if (tipo === 'hora') {

        tiempo === 1 ? resultado = `Hace ${tiempo} hora`: resultado = `Hace ${tiempo} horas`;

        return resultado;
    }

    if (tipo === 'minuto') {

        tiempo === 1 ? resultado = `Hace ${tiempo} minuto`: resultado = `Hace ${tiempo} minutos`

        return resultado;
    }

    return value;
  }

}