import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { GlobalTexts } from 'app/common/global-texts';

@Component({
  selector: 'modal-registro-exitoso',
  templateUrl: './registro-exitoso.component.html',
  styleUrls: ['./registro-exitoso.component.scss']
})
export class ModalRegistroComponent implements OnInit {

  dialogo_login = GlobalTexts.dialogo_login;
  
  constructor(
    public dialogRef: MatDialogRef<ModalRegistroComponent>,
    @Inject(MAT_DIALOG_DATA) public name: string
    ) {

    }

  ngOnInit(): void {
   

  }

}
