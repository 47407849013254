import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginLinkedinService {
    private linkedinUser = new BehaviorSubject(null);
    currentLinkedinUser = this.linkedinUser.asObservable();

   

    constructor(){

  }

  updateLinkedinUser(user: any) {
    this.linkedinUser.next(user)
    }

}
