import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalConstants } from 'app/common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class TerminosService {

  faqs: any;
  onFaqsChanged: BehaviorSubject<any>;

  /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
      private _httpClient: HttpClient,
  )
  {

  }

  /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getFaqs(),
            ]).then(
                () => {
                    resolve('');
                },
                reject
            );
        });
    }

    /**
     * Get tiposDocumento
     *
     * @returns {Promise<any>}
     */
    getFaqs(): Promise<any>
    {
      console.log("sadasdasdasd");
        return new Promise((resolve, reject) => {
            //this._httpClient.get(`${GlobalConstants.apiURL}config/registros/100/true`)
            this._httpClient.get('api/faq')
                .subscribe((response: any) => {
                    this.faqs = response;
                    resolve(response);
                }, reject);
        });
    }
}
