import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalConstants } from 'app/common/global-constants';
import { AuthStateService } from 'app/pixcore/shared/auth-state.service';
import { InterceptorSkipHeader } from 'app/pixcore/shared/auth.interceptor';

@Injectable()
export class PlanesService implements Resolve<any>
{

    candidatoId: number;
    routeParams: any;
    isLogged = false;
    isCandidato = false;
    urlLocal = GlobalConstants.apiURL;
    urlProd = '';
    planes : any;
    // onVacanteDetalleChanged: BehaviorSubject<Vacante | any>;
    // Datos desde la API
    
    // vacante: Vacante;
   
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _authState: AuthStateService,
        private _router: Router,
        private _activaRoute: ActivatedRoute,
    )
    {
        // Set the defaults
        // this.onVacanteDetalleChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;
    
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getPlanes(),
            ]).then(
                () => {
                    resolve('');
                },
                reject
            );
        });
    }


    /**
     * Get Vacante
     *
     * @returns {Promise<any>}
     */
    getPlanes(): Promise<any>
    {
        return new Promise((resolve) => {
            this._httpClient.get(`${this.urlLocal}planes`).subscribe((response: any) => {
         
                 if (response) {
                 
                         this.planes = response;
                         resolve('');
                 
                 }
             });
        });
    }

}
