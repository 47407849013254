import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs';
import { TokenService } from '../shared/token.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalConstants } from 'app/common/global-constants'

//@TEMPORAL
import { navigation } from 'app/navigation/navigation';
import { navigation_empresa } from 'app/navigation/navigation_empresa';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { DeviceDetectorService } from 'ngx-device-detector';


// User interface
export class UserSession {
  name: String;
  email: String;
  user_type: Number;
}

@Injectable({
  providedIn: 'root'
})

export class AuthStateService {

  private userState = new BehaviorSubject<boolean>(this.token.isLoggedIn());
  private userData = new BehaviorSubject<number>(null);

  userAuthState = this.userState.asObservable();
  userAuthData = this.userData.asObservable();

  
  UserProfile: UserSession;

  urlLocal = GlobalConstants.apiURL;
  user: any;

  user_type: any;

  constructor(
    public token: TokenService,
    public router: Router,
    private http: HttpClient,
    private _fuseNavigationService: FuseNavigationService,
    private _deviceDetectorService: DeviceDetectorService
  ) {
  
   }


   get onLoginChanged(): Observable<any>
    {
        return this.userState.asObservable();
    }

    get onDataChanged(): Observable<any>
    {
        return this.userData.asObservable();
    }


  isLoggedIn(): boolean
  {
    if (this.getUserSession())
      return true 
    else
      return false;
  }

  getUserId(): number
  {
    if (this.getUserSession() && this.getUserSession().id)
      return this.getUserSession().id;
    else
      return null;
  }

  getCandidatoId(): number
  {
    if (this.getUserSession() && this.getUserSession().candidato)
      return this.getUserSession().candidato.id;
    else
      return null;
  }

  getEmpresaId(): number
  {
    if (this.getUserSession() && this.getUserSession().empresa)
      return this.getUserSession().empresa.id;
    else
      return null;
  }

  getToken(): String
  {
    return this.token.getToken();
  }

  isCandidato(): boolean {
    return this.getUserSession().user_type === 1;
  }

  isEmpresa(): boolean {
    return this.getUserSession().user_type === 2;
}

  setAuthState(value: boolean) {

    this.userState.next(value);
    if(!value)
      this.removeUserSession();
  }

  navigation: any;

  validateSession(redirect = null)
  {   
       
      if (!this.token.getToken())
      {
        this.removeUserSession();
        // if(this._deviceDetectorService.isMobile()){
        //   this.router.navigate(['/login']);  
        // } else {
          this.router.navigate(['/']);  
        // }     
      }
      else
      {      
        if (this.getUserSession() == null)
        {
          const headers = new HttpHeaders({
            'Authorization': `Bearer ${this.token.getToken()}`});
  
          this.http.get(`${this.urlLocal}user_data`, {headers: headers}).subscribe(
            (result: any) => {
             
              
              localStorage.setItem('user_session', JSON.stringify(result));

              if(this.getUserSession() && this.getUserSession().user_type)
              {
                this.user_type = this.getUserSession().user_type;
                this.userData.next(this.user_type);

                if(redirect != null){
                  this.router.navigate([redirect]);
                }
               
              }
        
            },
            error => {
              this.router.navigate(['/login']);
            }
          );
          
        }else {
          this.setAuthState(true);
          this.userData.next(this.getUserSession().user_type);
        }   
      
      }
      
  }

  handleData(user){
    this.user = user;
    localStorage.setItem('user_session', user);
  }

  updatePlanes(data){
    let user = JSON.parse(localStorage.getItem('user_session'));
    user.empresa.planes= data;
    localStorage.setItem('user_session', JSON.stringify(user));
  }

  getUserSession(){
    if(localStorage.getItem('user_session'))
    {
      return JSON.parse(localStorage.getItem('user_session'))
    }
    else
    {
      return null;
    }
  }

  // Remove token
  removeUserSession(){
    localStorage.removeItem('user_session');
  }
  
}