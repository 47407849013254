import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthStateService } from '../shared/auth-state.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RoleTypeGuard implements CanActivate, CanActivateChild  {

  user_type: any;

  constructor(private _authService: AuthStateService, private router: Router ){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    return this.checkUserLogin(next);
  
  }


  checkUserLogin(route: ActivatedRouteSnapshot): boolean {
   
      const userRole = this._authService.getUserSession().role_id;

      if (!route.data.rolesBlock.includes(userRole)) {
        return true;
      }else {
        this.router.navigate(['/home']);
        return false;
      }

  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }
  
}
