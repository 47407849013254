import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthStateService } from '../shared/auth-state.service';
import { Router } from '@angular/router';
import { GlobalConstants } from 'app/common/global-constants';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class PlanVisualizacionCandidatosGuard implements CanActivate, CanActivateChild  {

  urlLocal = GlobalConstants.apiURL;
  cantidadVistas: number;
  planEmpresa: number;
  empresaId: number;


  constructor(private _authService: AuthStateService, private router: Router, private _httpClient: HttpClient){

    
    
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
 
      return this.checkLimitPlan(next);

  }


  async checkLimitPlan(route: ActivatedRouteSnapshot): Promise<boolean> {

     

      if (!this._authService.getUserSession().empresa) {

        return true;

      }else {

        this.empresaId = this._authService.getUserSession().empresa.id;
        this.planEmpresa = this._authService.getUserSession().empresa.plan_id;  

        await this.getCantidadVistas(this.empresaId);


        switch (this.planEmpresa) {

            case 1: { 
              if (this.cantidadVistas >= 50) {
                  this.router.navigate(['/home']);
                  return false;
              }else {

                  return true;
              }
            } 
            case 2: { 
              if (this.cantidadVistas >= 100) {
                this.router.navigate(['/home']);
                return false;
            }else {
                return true;
            }
            }
            case 3: { 
              if (this.cantidadVistas >= 200) {
                this.router.navigate(['/home']);
                return false;
            }else {
                return true;
            }
        }   
      }

    }

      
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }

  getCantidadVistas(empresaId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${this.urlLocal}candidato_visitas_empresa/${empresaId}`)
            .subscribe((response: any) => {
                this.cantidadVistas = response;
                resolve(response);
            }, reject);
    });
  }

}
