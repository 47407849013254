export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'HOME': 'Home',
            'EMPRESA': 'Business',
            'APPLICATIONS': 'Applications',
            'DASHBOARDS'  : 'Dashboards',
            'CALENDAR'    : 'Calendar',
            'ECOMMERCE'   : 'E-Commerce',
            'ACADEMY'     : 'Academy',
            'MAIL'        : {
                'TITLE': 'Mail',
                'BADGE': '25'
            },
            'MAIL_NGRX'        : {
                'TITLE': 'Mail Ngrx',
                'BADGE': '13'
            },
            'CHAT'        : 'Chat',
            'FILE_MANAGER': 'File Manager',
            'CONTACTS'    : 'Contacts',
            'TODO'        : 'To-Do',
            'SCRUMBOARD'  : 'Scrumboard',
            'MY_INFORMATION': 'My information',
            'MY_DATA': 'My data',
            'PERSONAL_DATA': 'Personal data',
            'ACADEMIC_DATA': 'Academic information',
            'EXPERIENCE': 'Experience',
            'SKILLS': 'Skill',
            'AWARDS': 'Awards and honors',
            'LANGUAGES': 'Idiomas',
            'MY_APPLICATIONS': 'My applications',
            'VIDEO_PRESENTATION': 'Video presentation',
            'LABORAL_OFERTS': 'Oferts',
            'COMPENTENCE_TESTS': 'Competence Tests',
            'AUTH': 'Autentication',
            'LOGIN': 'Login',
            'REGISTER': 'Register',
            'FORGOT_PASSWORD': 'Forgot password',
            'RESET_PASSWORD': 'Reset password',
        },
    }
};
