import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Vacante } from './model/vacante.model';
import { GlobalConstants } from 'app/common/global-constants';
import { AuthStateService } from 'app/pixcore/shared/auth-state.service';
import { InterceptorSkipHeader } from 'app/pixcore/shared/auth.interceptor';

@Injectable()
export class DetalleVacanteService implements Resolve<any>
{

    candidatoId: number;
    routeParams: any;
    isLogged = false;
    isCandidato = false;
    urlLocal = GlobalConstants.apiURL;
    urlProd = '';
    onVacanteDetalleChanged: BehaviorSubject<Vacante | any>;
    // Datos desde la API
    
    vacante: Vacante;
   
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _authState: AuthStateService,
        private _router: Router
    )
    {
        // Set the defaults
        this.onVacanteDetalleChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.isLogged = this._authState.isLoggedIn();
        if (this.isLogged) {
            this.isCandidato = this._authState.isCandidato();
        }
        this.routeParams = route.params;
    
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getVacante(),
            ]).then(
                () => {
                    resolve('');
                },
                reject
            );
        });
    }


    /**
     * Get Vacante
     *
     * @returns {Promise<any>}
     */
    getVacante(): Promise<any>
    {
        return new Promise((resolve) => {
            const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
            this._httpClient.get(`${this.urlLocal}vacante_unica_publica/${this.routeParams.id_vacante}`, {headers: headers})
                .subscribe((response: any) => {
                

                    if(response.length <= 0) {

                        const url = `/public/lista-vacantes`;
                        this._router.navigateByUrl(url);
                        return;
                    }

                    if (response[0].id) {
                        this.vacante = response[0];
                        
                        if (this.isLogged && this.isCandidato) {
                          
                            window.open(`https://app.okempleos.com/vacante/candidato-vacantes/lista/${this.vacante.id}/${this.vacante.empresa_id}`);
                            // window.open(`http://localhost:4200/vacante/candidato-vacantes/lista/${this.vacante.id}/${this.vacante.empresa_id}`);
                            const url = `/public/lista-vacantes`;
                            this._router.navigateByUrl(url);
                        }else {
                            this.onVacanteDetalleChanged.next(this.vacante);
                            resolve(this.vacante);
                        }

                    }

                });
            
        });
    }

}
