import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'modal-post-candidato',
  templateUrl: './modal-post-candidato.component.html',
  styleUrls: ['./modal-post-candidato.component.scss']
})
export class ModalPostCandidatoComponent implements OnInit {
 
  constructor(
    public dialogRef: MatDialogRef<ModalPostCandidatoComponent>,
    public _router: Router
  ) {}

  ngOnInit(): void {

  }

  onCerrarClick(): void {
    this.dialogRef.close();
  }

  goToVacantes(): void {
    
    this.dialogRef.close(true);
    
    
  }

}
