import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import {MatDialogModule} from '@angular/material/dialog';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';

import { FuseSharedModule } from '@fuse/shared.module';
import {MatSelectModule} from '@angular/material/select';

import { RegisterComponent } from 'app/public/register/register.component';

import { TranslateModule } from '@ngx-translate/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';

import { ModalRegistroComponent } from './modal/registro-exitoso.component';

const routes = [
    {
        path     : 'registro',
        component: RegisterComponent
    }
];

@NgModule({
    declarations: [
        RegisterComponent,
        ModalRegistroComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatDialogModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        MatSelectModule,
        
        FuseSharedModule,
        TranslateModule,

        MatRadioModule,
        MatSnackBarModule,
    ]
})
export class RegisterModule
{
}
