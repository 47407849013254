import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';

import { FuseSharedModule } from '@fuse/shared.module';
import { PlanesPublicoComponent } from '../planes/planes-publico.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';

import { PlanesService } from './planes.service';



const routes = [
    {
        path     : 'planes',
        component: PlanesPublicoComponent,
        resolve : {
            planes : PlanesService
        }
    }
];

@NgModule({
    declarations: [
        PlanesPublicoComponent,
        NavbarComponent,
        FooterComponent,
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatDividerModule,

        FuseSharedModule,
        MatRadioModule,
        MatIconModule
    ],
    providers : [
        PlanesService
    ]
})
export class PlanesPublicoModule
{
}
