import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Vacante } from 'app/public/lista-vacantes/model/vacante.model';
import { Filtro } from 'app/public/lista-vacantes/model/filtro.model';
import * as moment from 'moment';

import { ListaVacantesService } from 'app/public/lista-vacantes/lista-vacantes.service';


@Component({
    selector: 'lista-vacantes-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit, OnDestroy {


    loadingFiltro = false;
 

    formFiltroBasico: FormGroup;
    formFiltroAvanzado: FormGroup;
    maxDate: Date;
    fechaInicial: Date;
    fechaFinal: Date;
    filterBy = 'all';
    vacantes: Vacante[];
    changed = false;
    selected1: any;
    selected2: any;

    tipoContratoFiltro: number[] = [];
    tipoJornadaFiltro: number[] = [];
    salarioFiltro: number[] = [];
    experienciaFiltro: number[] = [];
    generoFiltro: number[] = [];
    disponibilidadViajeFiltro: number[] = [];
    disponibilidadCambioResidenciaFiltro: number[] = [];



    filtro: Filtro;

    tiposContrato: any[];
    sectoresEconomicos: any[];
    tiposJornada: any[];
    salarios: any[];
    areasVacante: any[];
    nivelesEducativos: any[];
    experiencias: any[];
    generos: any[];
    ciudades: any[];
    localidades: any[];

    searchText = ''


    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {CandidatoVacantesService} _listaVacantesService
     */
    constructor(
        private _listaVacantesService: ListaVacantesService,
        private _formBuilder: FormBuilder
    ) {
        this.maxDate = new Date();
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

       
        this.formFiltroBasico = this.createFormFiltroBasico();
        this.formFiltroAvanzado = this.createFormFiltroAvanzado();

        this.vacantes = this._listaVacantesService.vacantes;

        this.tiposContrato = this._listaVacantesService.tiposContrato;
        this.tiposJornada = this._listaVacantesService.tiposJornada;
        this.salarios = this._listaVacantesService.salarios;
        this.experiencias = this._listaVacantesService.experiencias;
        this.generos = this._listaVacantesService.generos;
        this.sectoresEconomicos = this._listaVacantesService.sectoresEconomicos;
        this.areasVacante = this._listaVacantesService.areasVacante;


        this.nivelesEducativos = this._listaVacantesService.nivelesEducativos;
        this.ciudades = this._listaVacantesService.ciudades;
        this.localidades = this._listaVacantesService.localidades;


       
        this.tiposContrato.forEach((x) => {
            x.checked = false;
        });
        this.tiposJornada.forEach((x) => {
            x.checked = false;
        });
        this.salarios.forEach((x) => {
            x.checked = false;
        });
        this.experiencias.forEach((x) => {
            x.checked = false;
        });
        this.generos.forEach((x) => {
            x.checked = false;
        });

        
        this._listaVacantesService.onSearchTextVacantesPublicasChanged
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(searchText => {
            this.searchText = searchText;
                this.formFiltroBasico.get('texto_busqueda').setValue(this.searchText);
        });

        this._listaVacantesService.onSearchTextVacantesPublicasEnter
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(value => {
            
                if (this.searchText === null || this.searchText === '' || this.searchText.length >= 3) {
                    this.filtrar();
                }

                
            
        });


        this._listaVacantesService.onFilterVacantesPublicasChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(filter => {
                this.filterBy = filter
            })
        
        this._listaVacantesService.onListadoChangedVacantesPublicas
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(changed => {
            this.changed = changed;

            if (this.changed) {
                this.filtroAll();
            }
        });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this._listaVacantesService.onFilterVacantesPublicasChanged.next('all');
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    createFormFiltroBasico(): FormGroup
    {
        return this._formBuilder.group({            
            texto_busqueda: [{
                value: null,
                disabled: false
            }, [Validators.minLength(3), Validators.maxLength(100)]],
            ciudad_id: [{
                value: null,
                disabled: false
            }],
            nivelEducacion: [{
                value: null,
                disabled: false
            }],
        });
    }

    createFormFiltroAvanzado(): FormGroup
    {
        return this._formBuilder.group({
            sectorEcomonico: [{
                value: null,
                disabled: false
            }],
            areaVacante: [{
                value: null,
                disabled: false
            }],            
            localidad_id: [{
                value: null,
                disabled: false
            }],
            
        });
    }


    filtroAll(): void {

        if (this.filterBy === 'all') {
               
            return;
        }
            
        this.loadingFiltro = true
        this.filterBy = 'all';
        this.selected1 = undefined;
        this.selected2 = undefined;
        this.fechaInicial = null;
        this.fechaFinal = null;


        this.tiposContrato.forEach((x) => {
            x.checked = false;
        });
        this.tiposJornada.forEach((x) => {
            x.checked = false;
        });
        this.salarios.forEach((x) => {
            x.checked = false;
        });

        this.experiencias.forEach((x) => {
            x.checked = false;
        });

        this.generos.forEach((x) => {
            x.checked = false;
        });

       
    // filtro basico
        this.tipoContratoFiltro = [];
        this.tipoJornadaFiltro = [];
        this.salarioFiltro = [];
        this.salarioFiltro = [];
        this.createFormFiltroBasico();
        this.formFiltroBasico.reset();
    // filtro avanzado
        this.experienciaFiltro = [];
        this.generoFiltro = [];
        this.disponibilidadViajeFiltro = [];
        this.disponibilidadCambioResidenciaFiltro = [];
        this.createFormFiltroAvanzado();
        this.formFiltroAvanzado.reset();
        
       
        this._listaVacantesService.onFilterVacantesPublicasChanged.next(this.filterBy);

        setTimeout(() => {
            this.loadingFiltro = false
        }, 1500);
           
        
    }

    filtrar(): void {

        
        if (this.fechaInicial !== null || 
            this.tipoContratoFiltro.length > 0 ||
            this.tipoJornadaFiltro.length > 0 ||
            this.salarioFiltro.length > 0 ||
            this.formFiltroAvanzado.get('sectorEcomonico').value !== null || 
            this.formFiltroAvanzado.get('areaVacante').value !== null ||
            this.experienciaFiltro.length > 0 ||
            this.generoFiltro.length > 0 || 
            this.disponibilidadViajeFiltro.length > 0 || 
            this.disponibilidadCambioResidenciaFiltro.length > 0 ||
            this.formFiltroBasico.get('ciudad_id').value !== null || 
            this.formFiltroAvanzado.get('localidad_id').value !== null ||
            this.formFiltroBasico.get('nivelEducacion').value !== null ||
            (this.formFiltroBasico.get('texto_busqueda').value !== null &&
            this.formFiltroBasico.get('texto_busqueda').value !== '')
            ) {

            this.loadingFiltro = true;
            this.filterBy = 'filtroApi';

            let filtros;
            filtros = {
                
                opciones: null,
                tipo_contrato: this.tipoContratoFiltro || null,
                tipo_jornada: this.tipoJornadaFiltro || null,
                salario: this.salarioFiltro || null,
                sector_economico: [this.formFiltroAvanzado.get('sectorEcomonico').value] || null,
                area_vacante: [this.formFiltroAvanzado.get('areaVacante').value] || null,
                experiencia: this.experienciaFiltro || null,
                genero: this.generoFiltro || null,
                disponibilidadViaje: this.disponibilidadViajeFiltro || null,
                disponibilidadCambioResidencia: this.disponibilidadCambioResidenciaFiltro || null,
                nivelEducacion: [this.formFiltroBasico.get('nivelEducacion').value] || null,
                ciudad_id: [this.formFiltroBasico.get('ciudad_id').value] || null,
                localidad_id: [this.formFiltroAvanzado.get('localidad_id').value] || null,


                fecha_inicial: moment(this.fechaInicial, ['YYYY-MM-DD', 'DD-MM-YYYY']).format('YYYY-MM-DD'),
                fecha_final: moment(this.fechaFinal, ['YYYY-MM-DD', 'DD-MM-YYYY']).format('YYYY-MM-DD'),

                texto_busqueda: this.formFiltroBasico.get('texto_busqueda').value || null

            };


            if (this.fechaInicial === null) {

                filtros = {
                
                    opciones: null,
                    tipo_contrato: this.tipoContratoFiltro || null,
                    tipo_jornada: this.tipoJornadaFiltro || null,
                    salario: this.salarioFiltro || null,
                    sector_economico: [this.formFiltroAvanzado.get('sectorEcomonico').value] || null,
                    area_vacante: [this.formFiltroAvanzado.get('areaVacante').value] || null,
                    experiencia: this.experienciaFiltro || null,
                    genero: this.generoFiltro || null,
                    disponibilidadViaje: this.disponibilidadViajeFiltro || null,
                    disponibilidadCambioResidencia: this.disponibilidadCambioResidenciaFiltro || null,
                    nivelEducacion: [this.formFiltroBasico.get('nivelEducacion').value] || null,
                    ciudad_id: [this.formFiltroBasico.get('ciudad_id').value] || null,
                    localidad_id: [this.formFiltroAvanzado.get('localidad_id').value] || null,

    
                    fecha_inicial: null,
                    fecha_final:  null,

                    texto_busqueda: this.formFiltroBasico.get('texto_busqueda').value || null
    
                };
            }

            if (this.formFiltroAvanzado.get('sectorEcomonico').value === null) {
                filtros.sector_economico = [];
            }

            if (this.formFiltroAvanzado.get('areaVacante').value === null) {
                filtros.area_vacante = [];
            }

            if (this.formFiltroBasico.get('ciudad_id').value === null) {
                filtros.ciudad_id = [];
            }
            
            if (this.formFiltroBasico.get('ciudad_id').value !== 524) {
                filtros.localidad_id = [];
            }

            if (this.formFiltroAvanzado.get('localidad_id').value === null) {
                filtros.localidad_id = [];
            }

            if (this.formFiltroBasico.get('nivelEducacion').value === null) {
                filtros.nivelEducacion = [];
            }

            this.filtro = new Filtro(filtros);
           
            this._listaVacantesService.filtrar(this.filtro).then(() => {
                this.loadingFiltro = false;
        
            });
        }else {

            if (this.filterBy !== 'all') {
               
                this.filtroAll();
            } 
            
        }
    }

    arreglosFiltroToggle(id: number, tipo: string): void{
        
        if (tipo === 'disponibilidadViaje') {
            this.disponibilidadViajeFiltro = [];
            this.disponibilidadViajeFiltro.push(id);
        }else if (tipo === 'disponibilidadCambioResidencia') {
            this.disponibilidadCambioResidenciaFiltro = [];
            this.disponibilidadCambioResidenciaFiltro.push(id);
        }
    }

    arreglosFiltro(event, itemId: number, tipo: string): void {


        if (event.checked) {

            if (tipo === 'tipoContrato') {
                this.tipoContratoFiltro.push(itemId);
            }else if (tipo === 'tipoJornada') {
                this.tipoJornadaFiltro.push(itemId);
            }else if (tipo === 'salario') {
                this.salarioFiltro.push(itemId);
            }else if (tipo === 'experiencia') {
                this.experienciaFiltro.push(itemId);
            }else if (tipo === 'genero') {
                this.generoFiltro.push(itemId);
            }
        } else {

            if (tipo === 'tipoContrato') {
                const index = this.tipoContratoFiltro.indexOf(itemId);
                this.tipoContratoFiltro.splice(index, 1);
            }else if (tipo === 'tipoJornada') {
                const index = this.tipoJornadaFiltro.indexOf(itemId);
                this.tipoJornadaFiltro.splice(index, 1);
            }else if (tipo === 'salario') {
                const index = this.salarioFiltro.indexOf(itemId);
                this.salarioFiltro.splice(index, 1);
            }else if (tipo === 'experiencia') {
                const index = this.experienciaFiltro.indexOf(itemId);
                this.experienciaFiltro.splice(index, 1);
            }else if (tipo === 'genero') {
                const index = this.generoFiltro.indexOf(itemId);
                this.generoFiltro.splice(index, 1);
            }
            
        }

    }


}
