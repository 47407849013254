import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
// import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateModule, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

// import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
// import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';
import { PublicModule } from './public/public.module';
import { SecureComponent } from './secure/secure.component';
import { AuthGuard } from './pixcore/guards';
import { RoleTypeGuard } from './pixcore/guards';
import { ModalFormCandidatoComponent } from './modals/modal-form-candidato/modal-form-candidato.component';
import { ModalFormEmpresaComponent } from './modals/modal-form-empresa/modal-form-empresa.component';
import { AuthInterceptor } from './pixcore/shared/auth.interceptor';
import { HttpErrorInterceptor } from './pixcore/shared/error.interceptor';

import { JoyrideModule } from 'ngx-joyride';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';

//Social login providers
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';

import { MatTreeModule } from '@angular/material/tree';

import { MatDividerModule } from '@angular/material/divider';

import { DateAgoPipe } from './pipes/date-ago.pipe';

import { ProgressComponent } from './main/vacante/nueva-vacante/progress/progress.component'
import { VideoRecordingService } from './video-recording.service';
import { DndDirective } from './dnd.directive';
import { ModalCompletaPerfilComponent } from './modals/modal-completa-perfil/modal-completa-perfil.component';
import { ModalReportarVacanteComponent } from './modals/modal-reportar-vacante/modal-reportar-vacante.component';
import { ModalVisualizeComponent } from './modals/modal-visualize/modal-visualize.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { NgxMatNativeDateModule, NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';

import { OkselectModule } from './main/apps/okselect/okselect.module';

import { IMqttServiceOptions, MqttModule, MQTT_SERVICE_OPTIONS } from 'ngx-mqtt';
import { MiniChatComponent } from './mini-chat/mini-chat.component';
import { ChatManagerComponent } from './mini-chat/chat-manager.component';
// import { initializeApp } from "firebase/app";
// initializeApp(environment.firebase);


registerLocaleData(localeEs);

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
  },
};

const fbLoginOptions = {
  fields: 'id,name,email,picture,first_name,last_name',
  scope: 'email',
  return_scopes: true,
  enable_profile_selector: true,
  version: "v2.11"
};

const appRoutes: Routes = [
  {
    path: 'secure',
    component: SecureComponent
  },
  {
    path: 'public',
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
  },
  {
    path: 'candidato',
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/candidato/candidato.module').then(m => m.CandidatoModule)
  },
  {

    path: 'empresa',
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/empresa/empresa.module').then(m => m.EmpresaModule)
  },
  {

    path: 'usuario',
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/usuario/usuario.module').then(m => m.UsuarioModule)
  },
  {

    path: 'vacante',
    canActivate: [AuthGuard, RoleTypeGuard],
    data: { rolesBlock: [4] },
    loadChildren: () => import('./main/vacante/vacante.module').then(m => m.VacanteModule)
  },
  {

    path: 'tutoriales',
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/tutoriales/tutoriales.module').then(m => m.TutorialesModule)
  },
  {
    path: 'apps',
    loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule)
  },
  {
    path: 'pixcore',
    loadChildren: () => import('./pixcore/pixcore.module').then(m => m.PixcoreModule)
  },
  // {
  //   path: 'okselect',
  //   loadChildren: () => import('./main/apps/okselect/okselect.module').then(m => m.OkselectModule)
  // },
  // {
  //   path: 'pages',
  //   canActivate: [AuthGuard, RoleTypeGuard],
  //   data: { rolesBlock: [2, 3] },
  //   loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  // },
  // {
  //     path        : 'ui',
  //     loadChildren: () => import('./main/ui/ui.module').then(m => m.UIModule)
  // },
  // {
  //     path        : 'documentation',
  //     loadChildren: () => import('./main/documentation/documentation.module').then(m => m.DocumentationModule)
  // },
  {
    path: 'home',
    redirectTo: 'apps/dashboards/analytics'
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

const config: IMqttServiceOptions  = {
  hostname: 'mqtt.okempleos.com',
  port: 8084,
  path: '/mqtt',
  clean: true, // Retain session
  connectTimeout: 4000, // Timeout period
  reconnectPeriod: 4000, // Reconnect period
  // Authentication information
  clientId: 'mqttx_597046f4',
  username: 'test',
  password: '12345',
  protocol: 'wss'
 };
@NgModule({
  declarations: [
    AppComponent,
    SecureComponent,
    ModalFormCandidatoComponent,
    ModalFormEmpresaComponent,
    DateAgoPipe,
    ProgressComponent,
    DndDirective,
    ModalCompletaPerfilComponent,
    ModalReportarVacanteComponent,
    ModalVisualizeComponent,
    MiniChatComponent,
    ChatManagerComponent
  ],
  imports: [
    PublicModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes,{
      useHash: false,
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled'
  }),

    TranslateModule.forRoot(),
    // InMemoryWebApiModule.forRoot(FakeDbService, {
    //   delay: 0,
    //   passThruUnknownUrl: true
    // }),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatDialogModule,
    MatTooltipModule,
    NgSelectModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatStepperModule,
    MatSnackBarModule,
    MatTreeModule,
    MatDividerModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule,
    // AppStoreModule,

    JoyrideModule.forRoot(),
    SocialLoginModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }), 
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MqttModule.forRoot(config),
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],

    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },

    { provide: LOCALE_ID, useValue: 'es-CO' },

    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('583393450390-u20i6bekinpaip6hoblij7ib7kgf2fld.apps.googleusercontent.com')
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('637021080948027', fbLoginOptions)
          }
        ]
      } as SocialAuthServiceConfig,
    },
    VideoRecordingService

  ]
})
export class AppModule {
}
