
<div class="sidebar-content side-bar-3">

    <div class="card">
        <!-- SIDEBAR CONTENT -->
        <div class="content py-16" fusePerfectScrollbar>
       

            <div class="nav material2">

                <mat-accordion class="example-headers-align" multi>
                    <mat-expansion-panel [expanded]="true">
                        <!--<mat-expansion-panel-header>
                            <mat-panel-title>
                            Filtros básicos
                            </mat-panel-title>
                        </mat-expansion-panel-header>-->
                  
                        <form fxLayout="column" fxLayoutAlign="start start" [formGroup]="formFiltroBasico">

                            <div fxLayout="column" fxLayoutAlign="start start" class="max-width-100">

                                <mat-form-field appearance="outline" class="max-width-100">
                                    <mat-label><mat-icon>search</mat-icon> Búsqueda</mat-label>
                                    <textarea matInput placeholder="Busca aquí."
                                    name="texto_busqueda"
                                    formControlName="texto_busqueda"
                                    rows="2">
                                    </textarea>
                                    <mat-error *ngIf="formFiltroBasico.get('texto_busqueda').hasError('minlength')">Mínimo 3 caracteres</mat-error>
                                    <mat-error *ngIf="formFiltroBasico.get('texto_busqueda').hasError('maxlength')">Máximo 100 caracteres</mat-error>
                                </mat-form-field>
        
                            </div>
               
                            <div fxLayout="column" fxLayoutAlign="start start" class="max-width-100">
                    
                                <ng-select appearance="outline"
                                    [items]="ciudades"
                                    bindValue="id"
                                    bindLabel="nombre"
                                    placeholder="Ciudad"
                                    formControlName="ciudad_id">
                                </ng-select>
        
                            </div>

                            <div fxLayout="column" fxLayoutAlign="start start" class="max-width-100">
                                
                                <ng-select appearance="outline"
                                    [items]="nivelesEducativos"
                                    bindValue="id"
                                    bindLabel="nombre"
                                    placeholder="Nivel educativo"
                                    formControlName="nivelEducacion">
                                </ng-select>
        
                            </div>                            
        
                        </form>

                        <div class="nav-item" fxLayout="column" fxLayoutAlign="start start">
                            <mat-checkbox *ngFor="let item of generos" [(ngModel)]="item.checked" (change)="arreglosFiltro($event, item.id, 'genero')" class="example-margin" >{{item.nombre}}</mat-checkbox>
                        </div>

                        <div class="separador"></div>   

                        <div class="nav-item" fxLayout="column" fxLayoutAlign="start start">
                            <mat-checkbox *ngFor="let item of salarios" [(ngModel)]="item.checked" (change)="arreglosFiltro($event, item.id, 'salario')" class="example-margin" >{{item.nombre}}</mat-checkbox>
                        </div>


                  
                    </mat-expansion-panel>
                   
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                        Filtros avanzados
                        </mat-panel-title>
                      </mat-expansion-panel-header>

                      <div class="nav material3">

                        <div class="range-control" fxLayout="row" fxLayoutAlign="space-between center">
                            <mat-form-field class="input-number">
                            <input matInput [value]="fechaInicial | date:'dd/MM/yyyy'"  disabled>
                            </mat-form-field>
        
                            <span>a</span>
        
                            <mat-form-field class="input-number">
                            <input matInput [value]="fechaFinal | date:'dd/MM/yyyy'"  disabled>
                            </mat-form-field>
                        </div>
                        <mat-form-field appearance="outline" class="date-range">
                            <mat-label>Rango de fecha</mat-label>
                            <mat-date-range-input [rangePicker]="picker" [max]="maxDate">
                                <input matStartDate [hidden]  [(ngModel)]="fechaInicial">
                                <input matEndDate [hidden]  [(ngModel)]="fechaFinal">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker">
                                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>
        
                    </div>

                    <div class="separador"></div>

                    <span class="label-filter">Disponibilidad de viajar</span>

                    <div class="nav-item" fxLayout="column" fxLayoutAlign="center center" >
                        <mat-button-toggle-group [(ngModel)]="selected1">
                            <mat-button-toggle (click)="arreglosFiltroToggle(1, 'disponibilidadViaje')" [value]="1">Si</mat-button-toggle>
                            <mat-button-toggle (click)="arreglosFiltroToggle(2, 'disponibilidadViaje')" [value]="2">No</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>

                    <div class="separador"></div>

                    <span class="label-filter">Disponibilidad de cambio de residencia</span>

                    <div class="nav-item" fxLayout="column" fxLayoutAlign="center center">
                        <mat-button-toggle-group [(ngModel)]="selected2">
                            <mat-button-toggle (click)="arreglosFiltroToggle(1, 'disponibilidadCambioResidencia')" [value]="1">Si</mat-button-toggle>
                            <mat-button-toggle (click)="arreglosFiltroToggle(2, 'disponibilidadCambioResidencia')" [value]="2">No</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>

                    <div class="separador"></div>

                    <div class="nav-item" fxLayout="column" fxLayoutAlign="start start">
                        <mat-checkbox *ngFor="let item of tiposContrato" [(ngModel)]="item.checked" (change)="arreglosFiltro($event, item.id, 'tipoContrato')" class="example-margin" >
                            <span class="text-truncated">{{item.nombre}}</span>
                        </mat-checkbox>
                    </div>

                    <div class="separador"></div>   

                    <div class="nav-item" fxLayout="column" fxLayoutAlign="start start">
                        <mat-checkbox *ngFor="let item of experiencias" [(ngModel)]="item.checked" (change)="arreglosFiltro($event, item.id, 'experiencia')" class="example-margin" >{{item.nombre}}</mat-checkbox>
                    </div>

                    <div class="separador"></div>   

                    <div class="nav-item" fxLayout="column" fxLayoutAlign="start start">
                        <mat-checkbox *ngFor="let item of tiposJornada" [(ngModel)]="item.checked" (change)="arreglosFiltro($event, item.id, 'tipoJornada')" class="example-margin" >{{item.nombre}}</mat-checkbox>
                    </div>

                    <div class="separador"></div>

                    <form fxLayout="column" fxLayoutAlign="start start" [formGroup]="formFiltroAvanzado">
            
                        <div fxLayout="column" fxLayoutAlign="start start" class="max-width-100">
                            
                            <ng-select appearance="outline"
                                [items]="sectoresEconomicos"
                                bindValue="id"
                                bindLabel="nombre"
                                placeholder="Sector económico"
                                formControlName="sectorEcomonico">
                            </ng-select>
    
                        </div>

                        <div fxLayout="column" fxLayoutAlign="start start" class="max-width-100">
                        
                            <ng-select appearance="outline"
                                [items]="areasVacante"
                                bindValue="id"
                                bindLabel="nombre"
                                placeholder="Aréa vacante"
                                formControlName="areaVacante">
                            </ng-select>
    
                        </div>

                        <div fxLayout="column" fxLayoutAlign="start start" class="max-width-100">        
                            
        
                            <div *ngIf="formFiltroBasico.get('ciudad_id').value === 524" fxLayout="column" fxLayoutAlign="start start" class="max-width-100">
                                
                                <ng-select appearance="outline"
                                    [items]="localidades"
                                    bindValue="id"
                                    bindLabel="nombre"
                                    placeholder="Localidad"
                                    formControlName="localidad_id">
                                </ng-select>
        
                            </div>
        
    
                        </div>
                        
    
                    </form>
                        
                  
                      

                    </mat-expansion-panel>
                  </mat-accordion>





                <div aria-label="frequently contacted" class="px-16 pt-16" >

                    <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="1 0 auto">
                        <button *ngIf="!loadingFiltro" mat-raised-button class="mat-accent" (click)="filtroAll()">
                            <mat-icon >list</mat-icon>
                            <span>Todos</span>
                        </button>

        

                        <button *ngIf="!loadingFiltro" mat-raised-button class="mat-accent" [disabled]="formFiltroBasico.invalid" (click)="filtrar()">
                            <mat-icon>search</mat-icon>
                            <span>Filtrar</span>
                        </button>


                        <div *ngIf="loadingFiltro" fxLayout="row" fxLayoutAlign="center center" class="py-8 m-auto">
                            <mat-spinner strokeWidth="4" color="primary" diameter="30"> </mat-spinner>
                        </div>
                    </div>
                    
                </div>


               
        
            </div>

        </div>

    </div>

</div>
