import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'modal-error-servidor',
  templateUrl: './modal-error-servidor.component.html',
  styleUrls: ['./modal-error-servidor.component.scss']
})
export class ModalErrorServidorComponent implements OnInit {
  
  constructor(
    public dialogRef: MatDialogRef<ModalErrorServidorComponent>,
    ) {

    }

  ngOnInit(): void {
   

  }

}
