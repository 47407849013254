import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { GlobalConstants } from 'app/common/global-constants';
import { PlanesService } from './planes.service';
import {DomSanitizer} from "@angular/platform-browser";
import { SEOService } from 'app/seo-service.service';



@Component({
    selector     : 'planes-publico',
    templateUrl  : './planes-publico.component.html',
    styleUrls    : ['./planes-publico.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PlanesPublicoComponent implements OnInit
{
    limiteVacantesPlan1 = GlobalConstants.limiteVacantesPlan1;
    limiteVacantesPlan2 = GlobalConstants.limiteVacantesPlan2;
    show_plan = 0;
    planes : any;
    planesFiltered : any;
    planActual: any[] | null = null;
    indiceActual = 0;

    /**
     * Constructor
     */
    constructor(private _fuseConfigService: FuseConfigService, private _planesService: PlanesService, private domSanitizer: DomSanitizer, private seoService: SEOService)
    {
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };      

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        /* SEoService */
        this.seoService.setPageTitle('Ver planes | Ok empleos');
        this.seoService.createLinkForCanonicalURL();
        this.seoService.setDescription('Conoce nuestros planes y paquetes.');
        /* SEoService */
        this.planes = this._planesService.planes;
        this.planActual = this.planes[this.indiceActual];
    }

    registro(): void {
        window.open('/registro?registro=empresa');
        // window.open('http://localhost:4200/registro?registro=empresa');
    }

    showPlan(number, el: HTMLElement){
        this.show_plan = number;
        this.planesFiltered = this.planes.filter(x =>  x.plan_type == this.show_plan);
        this.indiceActual = 0;
        this.planActual = this.planesFiltered[this.indiceActual];
        el.scrollIntoView();
    }

    mostrarSiguientePlan() {

        this.indiceActual++;
        if (this.indiceActual < this.planesFiltered.length) {
          this.planActual = this.planesFiltered[this.indiceActual];
        } else {
          this.planActual = this.planesFiltered[this.planesFiltered.length-1];
        }
    }
    
    mostrarAnteriorPlan() {

        this.indiceActual--;
        if (this.indiceActual > 0) {
          this.planActual = this.planesFiltered[this.indiceActual];
        } else {
          this.planActual = this.planesFiltered[0];
        }
    }

    getHtml(html){
        return this.domSanitizer.bypassSecurityTrustHtml(html);
    }
    
    goToPlanTutorial(){
        window.open('https://okempleos.com/adquirir_plan_ok_empleos.pdf');
    }

}
