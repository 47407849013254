export const locale = {
    lang: 'en',
    data: {
        'WELCOME': {
            'TITLE': 'Hello! Welcome to___ :)',
            'SUBTITLE': 'Here '
        },
        'LOGIN_FORM': {
            'TITLE': 'LOGIN TO YOUR ACCOUNT',
            'EMAIL': 'Email',
            'PASSWORD': 'Password',
            'REMEMBER_ME': 'Remember Me',
            'FORGOT_PASSWORD': 'Forgot Password?',
            'OR': 'Or',
            'LOG_GOOGLE': 'Log in with Google',
            'LOG_FACEBOOK': 'Log in with Facebook',
            'LOG_LINKEDIN': 'Log in with LinkedIn',
            'DONT_ACCOUNT': 'Don\'t have an account?',
            'REGISTER_CANDIDATE': 'Candidate',
            'REGISTER_COMPANY': 'Company',
            'CREATE_ACCOUNT': 'Create an account',
            'TERMS': 'terms and conditions',
            'BUTTON_LOGIN': 'LOGIN'
        },
        'LOGIN_FORM_ERROR': {
            'EMAIL_REQUIRED': 'Email is required',
            'EMAIL_INVALID': 'Please enter a valid email address',
            'PASSWORD_REQUIRED': 'Password is required',
        }
    }
};
