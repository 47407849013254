import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
  @ViewChild('contFoo') public conte: ElementRef;
  followLog = [];
  private urlLogs = '../../../../assets/images/logos/';

  constructor(
    private _router: Router,
  ) {

    this.followLog = [
      {
          img: this.urlLogs + '/log_tiktok.png',
          url: 'https://www.tiktok.com/@en.modo.ok?'
      },
      {
          img: this.urlLogs + '/log_instagram.png',
          url: 'https://www.instagram.com/ok.empleos/?hl=es'
      },
      {
          img: this.urlLogs + '/log_linkedin.png',
          url: 'https://www.linkedin.com/company/ok-empleos/'
      },
      {
          img: this.urlLogs + '/log_face.png',
          url: 'https://www.facebook.com/OkEmpleosColombia'
      },
      {
          img: this.urlLogs + '/log_youtube.png',
          url: 'https://www.youtube.com/channel/UCE56i6YHPlYBrpATAilz3XQ'
      },
      {
          img: this.urlLogs + '/log_twiter.png',
          url: 'https://twitter.com/ok_empleos'
      },
  ]
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (this.conte !== undefined) {
      this.conte.nativeElement.focus();
    }

    const cont = this.conte.nativeElement;
    // console.log('contttt',cont);
    // console.log('rutassss', this._router.url);

    if (this._router.url === '/inicio') {
      cont.style.backgroundColor = '#EEEDF2';
    }

    if (this._router.url === '/okupa') {
      cont.style.backgroundColor = '#fff';
    }
  }

}
