<div id="reset-password" fxLayout="column">

    <div id="reset-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="reset-password-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="assets/images/logos/logo.svg" alt="logo">
            </div>

            <div class="title">{{'RESET_FORM.TITLE' | translate}}</div>

            <form name="resetPasswordForm" [formGroup]="resetPasswordForm" (submit)="OnSubmit()"  novalidate>
                <mat-form-field appearance="outline" style="display: none;">
                    <input matInput formControlName="token" >
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{'RESET_FORM.EMAIL' | translate}}</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="resetPasswordForm.get('email').hasError('required')">
                        {{'RESET_FORM_ERROR.EMAIL_REQUIRED' | translate}}
                    </mat-error>
                    <mat-error *ngIf="resetPasswordForm.get('email').hasError('email')">
                        {{'RESET_FORM_ERROR.EMAIL_INVALID' | translate}}
                    </mat-error>
                    <mat-error *ngIf="resetPasswordForm.get('email').hasError('minlength')">
                        Mínimo 5 caracteres
                    </mat-error>
                    <mat-error *ngIf="resetPasswordForm.get('email').hasError('maxlength')">
                        Máximo 100 caracteres
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'RESET_FORM.PASSWORD' | translate}}</mat-label>
                    <input matInput type="password" formControlName="password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="resetPasswordForm.get('password').hasError('required')">{{'RESET_FORM_ERROR.PASSWORD_REQUIRED' | translate}}</mat-error>
                    <mat-error *ngIf="resetPasswordForm.get('password').hasError('minlength')">Mínimo 5 caracteres</mat-error>
                    <mat-error *ngIf="resetPasswordForm.get('password').hasError('maxlength')">Máximo 100 caracteres</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{'RESET_FORM.PASSWORD_CONFIRMATION' | translate}}</mat-label>
                    <input matInput type="password" formControlName="password_confirmation">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="resetPasswordForm.get('password_confirmation').hasError('required')">                        
                        {{'RESET_FORM_ERROR.PASSWORD_REQUIRED' | translate}}
                    </mat-error>
                    <mat-error *ngIf="!resetPasswordForm.get('password_confirmation').hasError('required') &&
                                       resetPasswordForm.get('password_confirmation').hasError('passwordsNotMatching')">                        
                        {{'RESET_FORM_ERROR.PASSWORD_CONFIRMATION_MATCH' | translate}}
                    </mat-error>
                </mat-form-field>

                <button mat-raised-button class="submit-button" [class.spinner]="loading" color="accent"
                        aria-label="RESET MY PASSWORD" [disabled]="resetPasswordForm.invalid || loading">
                    {{'RESET_FORM.BUTTON_ACCEPT' | translate}}
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/login'">{{'RESET_FORM.GO_TO_LOGIN' | translate}}</a>
            </div>

        </div>

    </div>

</div>
