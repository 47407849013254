import { Renderer2, Component, OnDestroy, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { Vacante } from 'app/public/detalle-vacante/model/vacante.model';
import { FuseConfigService } from '@fuse/services/config.service';
import { DetalleVacanteService } from 'app/public/detalle-vacante/detalle-vacante.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { Meta } from '@angular/platform-browser';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Location } from '@angular/common'

@Component({
    selector     : 'detalle-vacante',
    templateUrl  : './detalle-vacante.component.html',
    styleUrls    : ['./detalle-vacante.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class DetalleVacanteComponent implements OnInit, OnDestroy
{
    vacante: Vacante;
    lat: number;
    lng: number;
    zoom: number;
    mapTypeId: string;

    isForbidden = false;
    estadosAceptados = [1, 2, 3];

    scroll: boolean;

    

    // Private
    private _unsubscribeAll: Subject<any>;

    jsond: SafeHtml;

    /**
     * Constructor
     *
     * @param {DetalleService} _detalleService
     * @param {MatSnackBar} _matSnackBar
     */
    constructor(
        private _detalleVacanteService: DetalleVacanteService,
        private _router: Router,
        private _matSnackBar: MatSnackBar,
        private _fuseConfigService: FuseConfigService,
        private meta: Meta, 
        private sanitizer: DomSanitizer,
        private _renderer2: Renderer2,
        @Inject(DOCUMENT) private _document: Document,
        private location: Location       
    )
    {
        
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        
        this._detalleVacanteService.onVacanteDetalleChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(vacante => {
                this.vacante = vacante;
            });

        // Subscribe to update product on changes
    
        this.verificarEstadoVacante(this.vacante);
        this.setPositionInit();
        this.mapTypeId = 'roadmap';
        this.scroll = false;
        this.zoom = 5;

        let url_tag = this._router.url.split('/').pop();

        this.meta.removeTag("property='og:title'");
        this.meta.updateTag({property: 'og:title', content: this.vacante.cargo + ' en ' + this.vacante.empresa.nombre +" - "+ this.vacante.ciudad_value});
        this.meta.removeTag("property='og:description'");
        this.meta.updateTag({property: 'description', content: this.vacante.descripcion});
        this.meta.removeTag("property='og:url'");
        this.meta.updateTag({property: 'og:url', content: 'https://co.okempleos.com/public/detalle-vacante/'+`${url_tag}`});
        this.meta.removeTag("name='twitter:title'");
        this.meta.updateTag({property: 'twitter:title', content: this.vacante.cargo + ' en ' + this.vacante.empresa.nombre +" - "+ this.vacante.ciudad_value});
        this.meta.removeTag("name='twitter:description'");
        this.meta.updateTag({property: 'twitter:description', content: this.vacante.descripcion});
        this.meta.removeTag("name='twitter:image'");
        this.meta.updateTag({property: 'twitter:image', content: 'https://app.okempleos.com/assets/images/banners/redes.jpg'});
        this.meta.removeTag("property='og:image'");
        this.meta.updateTag({property: 'og:image', content: 'https://app.okempleos.com/assets/images/banners/redes.jpg'});
        
        let salary = 0.00;

        if(this.vacante.salario_id === 2300){
            salary = 500000.00;
        } else if(this.vacante.salario_id === 2301){
            salary = 1000000.00;
        } else if(this.vacante.salario_id === 2301){
            salary = 1500000.00;
        } else if(this.vacante.salario_id === 2301){
            salary = 2500000.00;
        } else if(this.vacante.salario_id === 2301){
            salary = 3500000.00;
        } else if(this.vacante.salario_id === 2301){
            salary = 5500000.00;
        } else if(this.vacante.salario_id === 2301){
            salary = 7500000.00;
        } else {
            salary = 0.00;
        }

        const json =  {
            '@context' : 'https://schema.org/',
            '@type' : 'JobPosting',
            'title' : this.vacante.cargo,
            'description' : this.vacante.descripcion,
            'identifier': {
              '@type': 'PropertyValue',
              'name': this.vacante.cargo,
              'value': this.vacante.id
            },
            'datePosted' : this.vacante.fecha_inicio,
            'validThrough' : this.vacante.fecha_limite,
            'employmentType' : 'CONTRACTOR',
            'hiringOrganization' : {
              '@type' : 'Organization',
              'name' : 'Ok Empleos',
              'sameAs' : 'https://okempleos.com/',
              'logo' : 'https://okempleos.com/wp-content/uploads/2021/08/Asset-1Holi-22.png'
            },
            'jobLocation': {
            '@type': 'Place',
              'address': {
              '@type': 'PostalAddress',
              'streetAddress': this.vacante.direccion,
              'addressLocality': this.vacante.ciudad_value+', '+this.vacante.pais_value,
              'addressCountry': 'CO'
              }
            },
            'baseSalary': {
              '@type': 'MonetaryAmount',
              'currency': 'COP',
              'value': {
                '@type': 'QuantitativeValue',
                'value': salary,
                'unitText': 'MONTHLY'
              }
            }
        };


        // this.jsond = this.getSafeHTML(json);

        console.log(json);
        let script = this._renderer2.createElement('script');
        script.type = `application/ld+json`;
        script.text = `${JSON.stringify(json)}`;
        
        this._renderer2.appendChild(this._document.head, script);

    }

    aplicarVacante(): void {
        localStorage.setItem('vacanteId', this.vacante.id.toString());
        window.open(`https://app.okempleos.com/login`);
        // window.open(`http://localhost:4200/login`);
        const url = `/public/lista-vacantes`;
        this._router.navigateByUrl(url);
    }

    verListadoVacantes(): void {
   
        // const url = `/public/lista-vacantes`;
        // this._router.navigateByUrl(url); 
        // this._router.navigate([".."]);
        this.location.back();
 
    }

    verificarEstadoVacante(vacante: Vacante): void {
        if (!this.estadosAceptados.includes(vacante.estado_id)) {
            this.isForbidden = true;
            this.openSnackBar(`La vacante tiene el estado: "${vacante.historial_estados[0].estado}"`, 'snack-error');
            this._router.navigateByUrl('/public/lista-vacantes');
        }else if (moment(vacante.fecha_limite) < moment()) {
            this.isForbidden = true;
            this.openSnackBar(`La vacante se vencio el: "${moment(vacante.fecha_limite, ['YYYY-MM-DD, DD-MM-YYYY']).format('DD-MM-YYYY')}"`, 'snack-error');
            this._router.navigateByUrl('/public/lista-vacantes');
        }else {
            this.isForbidden = false;
        }
    }

    getSafeHTML(value: {}) {
    // getSafeHTML(jsonLD: {[key: string]: any}): SafeHtml {
        // const json = jsonLD ? JSON.stringify(jsonLD, null, 2).replace(/<\/script>/g, '<\\/script>') : ''; // escape / to prevent script tag in JSON
        // const html = `<script type="application/ld+json">${json}</script>`;
        // return this.sanitizer.bypassSecurityTrustHtml(html);
        // If value convert to JSON and escape / to prevent script tag in JSON
            const json = value
            ? JSON.stringify(value, null, 2).replace(/\//g, '\\/')
            : '';
        const html = `${json}`;
        return this.sanitizer.bypassSecurityTrustHtml(html);
      }

    
    /**
     * Mostrar la respuesta al usuario
     * @param mensaje
     */
    private openSnackBar(mensaje: string, clase: string): void {
        this._matSnackBar.open(mensaje, 'x', {
         duration: 4000,
         horizontalPosition: 'end',
         verticalPosition: 'top',
         panelClass: clase,
       });
      }

    

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    setPositionInit(): void {
        this.lat = this.vacante?.latitud || null;
        this.lng = this.vacante?.longitud || null;
    }
   
}
