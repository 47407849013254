
<div mat-dialog-content fxLayout="column" >

    <div class="logo">
        <img src="assets/images/logos/logo.svg" alt="logo">
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="mb-8">
        <span class="texto h2">Hurra! Tu perfil está casi listo. Busca y aplica a las vacantes según tu perfil.</span>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="pt-24">
        <button class="mr-4" mat-raised-button  color="accent" (click)="goToVacantes()">Buscar Vacantes</button>
        <button class="ml-4" mat-raised-button (click)="onCerrarClick()">Cerrar</button>
    </div>

</div>

