<div id="candidato-vacantes-lista" fxLayout="column" fxFlex>
    <mat-table #table [dataSource]="dataSource" class="candidato-vacantes-table" [@animateStagger]="{value:'50'}">

        <!-- unico -->
        <ng-container matColumnDef="vacante">
            <mat-header-cell *matHeaderCellDef fxHide></mat-header-cell>
            <mat-cell class="cell-container" *matCellDef="let vacante">
                <div [ngClass]="{
                        'vacante-container-urgente': vacante.estado_id === 2,
                        'vacante-container-destacada': vacante.estado_id === 3,
                        'vacante-container-otros': vacante.estado_id !== 2 && vacante.estado_id !== 3}" class="p-20"
                    fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between start">

                    <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="68" fxLayoutAlign="start start"
                        class="mb-8 mb-sm-0">
                        <div class="title font-weight-900">{{vacante.cargo}}</div>
                        <!-- <div fxLayout="row" *ngIf="vacante.empresa.tipo_perfil !== 2"  [ngClass]="{'secondary-text': vacante.estado_id !==3}" class="empresa-nombre cursor-pointer"><span (click)="perfilEmpresa(vacante.empresa_id)">{{vacante.empresa.nombre}}</span></div> -->
                        <div fxLayout="row" [ngClass]="{'secondary-text': vacante.estado_id !==3}"
                            class="empresa-nombre">{{vacante.empresa.nombre}}</div>
                        <div fxHide fxShow.gt-xs class="descripcion-container pb-8 pt-4 w-full">
                            <div class="text-truncated descripcion" [innerHTML]="vacante.descripcion">
                            </div>
                        </div>
                        <div fxHide fxShow.gt-xs fxLayout="row" fxLayoutAlign="start center" class="data">
                            <div class="timer mr-8">{{vacante.diffCreacion}}</div>
                            <div *ngIf="vacante.estado_id === 2 || vacante.estado_id === 3"
                                [ngClass]="{'tag-urgente': vacante.estado_id === 2, 'tag-destacada': vacante.estado_id === 3}"
                                class="py-4 px-8 font-weight-900">{{vacante.historial_estados[0].estado}}</div>
                        </div>
                    </div>

                    <div fxHide fxShow.gt-sm class="separador mx-8 my-auto"></div>

                    <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="28" class="my-auto">
                        <div class="salario mb-4" fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon class="mr-4">monetization_on</mat-icon>
                            <span>{{vacante.salario_value}}</span>
                        </div>
                        <div class="ciudad" fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon class="mr-4">location_on</mat-icon>
                            <span>{{vacante.ciudad_value}}</span>
                            <span *ngIf="vacante.localidad_value" class="ml-4">{{vacante.localidad_value}}</span>
                        </div>
                        <button class="ver-vacante my-8" mat-raised-button color="accent"
                            (click)="detalleVacante(vacante.slug, vacante.empresa_id)">
                            Ver oferta completa
                        </button>


                        <button [matMenuTriggerFor]="moreMenu" [disabled]="aplicando" class="my-8" mat-raised-button
                            [color]="vacante.estado_id === 3 ? 'default' : 'primary'">
                            <mat-icon class="mr-4">share</mat-icon>
                            <span>Compartir</span>
                        </button>

                        <mat-menu #moreMenu="matMenu">


                            <button mat-menu-item aria-label="remove"
                                (click)="compartirFacebook(vacante.empresa.nombre, vacante.cargo, vacante.salario_value, vacante.slug)">
                                <i class="fab fa-facebook mr-12"></i>
                                <span>Facebook</span>
                            </button>

                            <button mat-menu-item aria-label="remove"
                                (click)="compartirTwitter(vacante.empresa.nombre, vacante.cargo, vacante.salario_value, acante.slug)">
                                <i class="fab fa-twitter mr-12"></i>
                                <span>Twitter</span>
                            </button>

                            <button mat-menu-item aria-label="remove" (click)="openModal('whatsapp', vacante.slug)">
                                <i class="fab fa-whatsapp mr-12"></i>
                                <span>WhatsApp</span>
                            </button>

                            <button mat-menu-item aria-label="remove" (click)="openModal('email', vacante.slug)">
                                <mat-icon>email</mat-icon>
                                <span>Correo</span>
                            </button>

                            <button mat-menu-item aria-label="remove" (click)="copyText(vacante.slug)">
                                <mat-icon>file_copy</mat-icon>
                                <span>Copiar</span>
                            </button>


                        </mat-menu>

                    </div>

                    <div fxHide.gt-xs fxLayout="row" fxLayoutAlign="start center" class="data">
                        <div class="timer mr-8">{{vacante.diffCreacion}}</div>
                        <div *ngIf="vacante.estado_id === 2 || vacante.estado_id === 3"
                            [ngClass]="{'tag-urgente': vacante.estado_id === 2, 'tag-destacada': vacante.estado_id === 3}"
                            class="py-4 px-8 font-weight-900">{{vacante.historial_estados[0].estado}}</div>
                    </div>
                </div>

            </mat-cell>
        </ng-container>

        <!-- Avatar Column -->
        <!-- <ng-container matColumnDef="ver">
            <mat-header-cell *matHeaderCellDef fxHide></mat-header-cell>
            <mat-cell *matCellDef="let vacante" fxHide fxShow.gt-xs>
                <button mat-button aria-label="remove" (click)="detalleVacante(vacante.id, vacante.empresa_id)">
                    <mat-icon>visibility</mat-icon>
                </button>
            </mat-cell>
        </ng-container> -->

        <!-- empresa Column -->
        <!-- <ng-container matColumnDef="empresa">
            <mat-header-cell *matHeaderCellDef fxHide>Empresa</mat-header-cell>
            <mat-cell *matCellDef="let vacante">
                <a (click)="detalleVacante(vacante.id, vacante.empresa_id)" >{{vacante.empresa.nombre}}</a>
            </mat-cell>
        </ng-container> -->


        <!-- cargo Column -->
        <!-- <ng-container matColumnDef="cargo">
            <mat-header-cell *matHeaderCellDef fxHide>Cargo</mat-header-cell>
            <mat-cell *matCellDef="let vacante">
                <p >{{vacante.cargo}}</p>
            </mat-cell>
        </ng-container> -->

        <!-- Salario Column -->
        <!-- <ng-container matColumnDef="salario_value">
            <mat-header-cell *matHeaderCellDef fxHide>Salario</mat-header-cell>
            <mat-cell *matCellDef="let vacante" fxHide fxShow.gt-xs >
                <p class="price">
                    {{vacante.salario_value}}
                </p>   
            </mat-cell>
        </ng-container> -->

        <!-- fecha inicio Column -->
        <!-- <ng-container matColumnDef="fecha_inicio">
            <mat-header-cell *matHeaderCellDef fxHide>Fecha inicio</mat-header-cell>
            <mat-cell *matCellDef="let vacante" fxHide fxShow.gt-xs>
                <p class="category ">
                    {{vacante.fecha_inicio | date:'dd-MM-yyyy'}}
                </p>
            </mat-cell>
        </ng-container> -->

        <!-- fecha limite Column -->
        <!-- <ng-container matColumnDef="fecha_limite">
            <mat-header-cell *matHeaderCellDef fxHide >Fecha límite</mat-header-cell>
            <mat-cell *matCellDef="let vacante" fxHide fxShow.gt-xs >
                <p class="category">
                    {{vacante.fecha_limite | date:'dd-MM-yyyy'}}
                </p>
            </mat-cell>
        </ng-container> -->

        <!-- numero_vacantes Column -->
        <!-- <ng-container matColumnDef="numero_vacantes">
            <mat-header-cell *matHeaderCellDef fxHide >Cantidad</mat-header-cell>
            <mat-cell *matCellDef="let vacante" fxHide fxShow.gt-xs > -->
        <!-- 
                <span class="quantity-indicator text-truncate"
                      [ngClass]="{'red-500':product.quantity <= 5, 'amber-500':product.quantity > 5 && product.quantity <= 25,'green-600':product.quantity > 25}">
                </span>
                -->
        <!-- <span>
                    {{vacante.numero_vacantes}}
                </span>

            </mat-cell>
        </ng-container> -->

        <!-- Status Column -->
        <!-- <ng-container matColumnDef="estado">
            <mat-header-cell *matHeaderCellDef fxHide>Estado</mat-header-cell>
            <mat-cell *matCellDef="let vacante">
                <p class="status text-truncate h6 p-4" [ngClass]="vacante.historial_estados[0].color">
                    {{vacante.historial_estados[0].estado}}
                </p>
            </mat-cell>
        </ng-container> -->




        <!-- Buttons Column -->
        <!-- <ng-container matColumnDef="buttons">
            <mat-header-cell  *matHeaderCellDef fxHide>
            </mat-header-cell>
            <mat-cell *matCellDef="let vacante">
                <div fxFlex="row" fxLayoutAlign="end center">

                
                    <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More"
                            (click)="$event.stopPropagation();">
                        <mat-icon class="secondary-text">more_vert</mat-icon>
                    </button>

                    <mat-menu #moreMenu="matMenu">
                        <button mat-menu-item aria-label="remove" (click)="detalleVacante(vacante.id, vacante.empresa_id)">
                            <mat-icon>visibility</mat-icon>
                            <span>Ver vacante</span>
                        </button>
                        <button mat-menu-item aria-label="remove" (click)="compartirFacebook(vacante.empresa.nombre, vacante.cargo, vacante.salario_value)">
                            <mat-icon>thumb_up_alt</mat-icon>
                            <span>Compartir en Facebook</span>
                        </button>
                        <button mat-menu-item aria-label="remove" (click)="compartirTwitter(vacante.empresa.nombre, vacante.cargo, vacante.salario_value)">
                            <mat-icon>comment</mat-icon>
                            <span>Compartir en Twitter</span>
                        </button>

                    </mat-menu>
                </div>

            </mat-cell>
        </ng-container> -->

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let vacante; columns: displayedColumns;" class="contact"
            [@animate]="{value:'*',params:{y:'100%'}}">
        </mat-row>
    </mat-table>
    <mat-paginator #paginator [length]="vacantesLenght" [hidePageSize]="true" [showFirstLastButtons]="true"
        (page)="pageChanged($event)" [pageSizeOptions]="[50]" [disabled]="loading">
    </mat-paginator>
    <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center center" class="py-8">
        <mat-spinner strokeWidth="4" color="primary" diameter="30"> </mat-spinner>
    </div>
</div>

<div *ngIf="filterBy !== 'all' && vacantesLenght <= 0" class="p-4">

    <div class="mb-4 text-center" fxLayout="row" fxLayoutAlign="center center">
        No hemos encontramos resultados para tu busqueda, por favor especifica tus criterios de busqueda...
    </div>

    <div class="logo">
        <img src="assets/images/logos/logo.svg" alt="logo">
    </div>

    <div fxLayout="row" fxLayoutAlign="center center">
        <button [disabled]="loadingAll" #filter class="filt" (click)="desFiltrar()" color="accent" mat-raised-button>
            <span *ngIf="!loadingAll">Mostrar todo</span>
            <div *ngIf="loadingAll" fxLayout="row" fxLayoutAlign="center center" class="py-8">
                <mat-spinner strokeWidth="4" color="primary" diameter="30"> </mat-spinner>
            </div>
        </button>
    </div>

</div>