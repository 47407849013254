<div fxLayout="column" fxLayoutAlign="center center" class="cont_modal">
    <span class="close" (click)="onCerrarClick()">X</span>
    <h1>Potencializa tu HV y perfil para las vacantes</h1>
    <h2>Conviértete en un candidato idóneo al que las empresas quieran contratar</h2>
    <div fxLayout="row grap" fxFlex="100" fxLayoutAlign="center center" class="type_plan">
        <button fxFlex="90" fxFlex.gt-xs="30" fxFlex.gt-sm="20" class="btn btn-{{opcion === 1 ? 'yes' : 'no' }}"
            (click)="selectOption(1)">1 Persona</button>
        <button fxFlex="90" fxFlex.gt-xs="30" fxFlex.gt-sm="20" class="btn btn-{{opcion === 2 ? 'yes' : 'no' }}"
            (click)="selectOption(2)">2 Personas</button>
        <button fxFlex="90" fxFlex.gt-xs="30" fxFlex.gt-sm="20" class="btn btn-{{opcion === 3 ? 'yes' : 'no' }}"
            (click)="selectOption(3)">Personaliza tu plan</button>
    </div>

    <div fxLayout="row grap" fxFlex="100" fxLayoutAlign="center center" class="detail_plan">
        <div fxLayout="row grap" class="section_left" fxFlex="100" fxLayoutAlign="center center" fxFlex.gt-sm="50">
            <img fxFlex="60" class="img_section img_group" *ngIf="opcion === 1" src="{{option.url}}" alt="">
            <img fxFlex="60" class="img_section img_group" *ngIf="opcion === 2" src="{{option.url}}" alt="">
            <img fxFlex="60" class="img_section img_group" *ngIf="opcion === 3" src="{{option.url}}" alt="">
            <img fxFlex="60" class="img_section"
                src="https://okempleos.com/wp-content/uploads/2021/10/Metodos-de-pago.jpg" alt="">
        </div>

        <div fxLayout="column" class="section_right" fxFlex="100" fxFlex.gt-sm="50">
            <h1 class="title_orange">¿Qué incluye?</h1>
            <div fxLayout="row" fxLayoutAlign="left center" class="item_right">
                <p>Entrenamiento con psicólogos especialistas en selección, donde te ayudaremos a:</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="left center" class="item_right">
                <span style="width: 4vh; height: 4vh; padding: 1%; margin-right: 2%;">
                    <svg width="24px" height="24px" viewBox="-2.4 -2.4 28.80 28.80" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"><rect x="-2.4" y="-2.4" width="28.80" height="28.80" rx="14.4" fill="#F83109" strokewidth="0"/></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                    <g id="SVGRepo_iconCarrier"> <g id="User / User_Check"> <path id="Vector" d="M15 19C15 16.7909 12.3137 15 9 15C5.68629 15 3 16.7909 3 19M21 10L17 14L15 12M9 12C6.79086 12 5 10.2091 5 8C5 5.79086 6.79086 4 9 4C11.2091 4 13 5.79086 13 8C13 10.2091 11.2091 12 9 12Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </g> </g>
                    </svg></span>
                <p>Entrevista.</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="left center" class="item_right">
                <img src="../../../assets/images/logos/check_range.png" alt="logo">
                <p>Corrección de hoja de vida.</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="left center" class="item_right">
                <img src="../../../assets/images/logos/edit_orange.png" alt="logo">
                <p>Configuracióin de perfil en Jobsite y aplicación a vacantes.</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="left center" class="item_right">
                <span style="width: 4vh; height: 4vh; padding: 1%; margin-right: 2%;">
                    <svg width="24px" height="24px" viewBox="-2.4 -2.4 28.80 28.80" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#F83109"><g id="SVGRepo_bgCarrier" stroke-width="0">
                        <rect x="-2.4" y="-2.4" width="28.80" height="28.80" rx="14.4" fill="#F83109" strokewidth="0"/></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                        <g id="SVGRepo_iconCarrier"> <g id="User / User_Voice"> <path id="Vector" d="M15 19C15 16.7909 12.3137 15 9 15C5.68629 15 3 16.7909 3 19M16.8281 5.17188C17.1996 5.54331 17.4942 5.98427 17.6952 6.46957C17.8962 6.95487 17.9999 7.47533 17.9999 8.00062C17.9999 8.52591 17.8963 9.04497 17.6953 9.53027C17.4943 10.0156 17.1996 10.457 16.8281 10.8285M19 3C19.6566 3.65661 20.1775 4.43612 20.5328 5.29402C20.8882 6.15192 21.0718 7.07127 21.0718 7.99985C21.0718 8.92844 20.8886 9.84815 20.5332 10.7061C20.1778 11.564 19.6566 12.3435 19 13.0001M9 12C6.79086 12 5 10.2091 5 8C5 5.79086 6.79086 4 9 4C11.2091 4 13 5.79086 13 8C13 10.2091 11.2091 12 9 12Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </g> </g>
                        </svg>
                </span>
                <p>Retroalimentación e inducción a herramientas complementarias de formación.</p>
            </div>
            <hr>
            <div fxLayout="row grap" fxFlex="100" class="cont_detail" fxLayoutAlign="center center">
                <div fxLayout="column" fxFlex="45" fxFlex.gt-xs="30" class="cont_cel" fxLayoutAlign="center center">
                    <th>Duración</th>
                    <div fxLayout="row" fxLayoutAlign="start center" class="text_cel">
                        <mat-icon>insert_photo</mat-icon>
                        <p> {{option.hora}}</p>
                    </div>
                </div>

                <div fxLayout="column" fxFlex="45" fxFlex.gt-xs="30" class="cont_cel" fxLayoutAlign="center center">
                    <th>Personas</th>
                    <div fxLayout="row" fxLayoutAlign="start center" class="text_cel">
                        <mat-icon>insert_photo</mat-icon>
                        <p> {{option.personas}}</p>
                    </div>
                </div>

                <div fxLayout="column" fxFlex="45" fxFlex.gt-xs="30" class="cont_cel" fxLayoutAlign="center center">
                    <th>Modalidad</th>
                    <div fxLayout="row" fxLayoutAlign="start center" class="text_cel">
                        <p>Presencial (Bogotá D.C) / Virtual</p>
                    </div>
                </div>
            </div>
            <hr>
            <div fxLayout="column" fxFlex="100" class="cont_price" fxLayoutAlign="center center">
                <h2 class="h2_price">Precio</h2>
                <p class="parr_price">$ {{option.precio}}</p>
                <button *ngIf="opcion === 1" class="btn btn-pago" (click)="pagarTaller(1)">Comprar ahora</button>
                <button *ngIf="opcion === 2" class="btn btn-pago" (click)="pagarTaller(2)">Comprar ahora</button>
                <a href="https://api.whatsapp.com/send?phone=573108668776&text=¡Hola! Me gustaría personalizar un plan para asesoría con un psicologo experto. Vengo de Ok Empleos :)"
                    target="_blank" *ngIf="opcion === 3" fxLayout="row" fxLayoutAlign="center center"
                    class="btn btn-whatsapp">
                    <img class="log_whats" src="./assets/images/logos/whatsapp.png" alt="">
                    WHATSAPP
                </a>
            </div>
        </div>
    </div>
    <p class="text_footer">¡Descubre más tips GRATIS que te ayudarán a conseguir trabajo!</p>
    <a href="https://news.okempleos.com/" class="btn btn-footer" fxLayoutAlign="center center">Haz clic aquí</a>
</div>