import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

import { JoyrideService } from 'ngx-joyride';

import { HttpClient } from '@angular/common/http';
import { AuthStateService } from 'app/pixcore/shared/auth-state.service';
import { GlobalConstants } from 'app/common/global-constants';
@Component({
    selector       : 'fuse-navigation',
    templateUrl    : './navigation.component.html',
    styleUrls      : ['./navigation.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit, OnDestroy
{
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    openOnboarding = false;
    // Private
    private _unsubscribeAll: Subject<any>;

    userSession: any;
    
    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private readonly joyrideService: JoyrideService,
        private _httpClient: HttpClient,
        private authState: AuthStateService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.userSession = this.authState.getUserSession();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();

        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
         .subscribe(() => {

             // Mark for check
             this._changeDetectorRef.markForCheck();
         });

         //Tipo de usuario
        this.authState.onDataChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((value) => {
            

            if(value) {

                this.userSession = this.authState.getUserSession();
            
                if(value === 1 && this.userSession){

                    if(this.userSession.candidato) // ya tiene finalizó de registrar los datos
                    {
                        if (this.userSession.candidato.tutorial.home == 0) {
                            setTimeout(() => {
                                this.startTour();
                            }, 1000);
                        }
                       
                    }
                }

                if(value === 2 && this.userSession) {
                    
                    if(this.userSession.empresa) // ya tiene finalizó de registrar los datos
                    {
                        if (this.userSession.empresa.tutorial?.home == 0) {
                            setTimeout(() => {
                                this.startTour();
                            }, 1000);
                        }
                        
                    }
                }
            }
        })

        
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    startTour() {
        var navigation_tutorial= new Array(); 

        // Mapeamos el menú de navegacion a los Tutoriales
        this.navigation.forEach(item => {            
            if(item.tutorial)
                navigation_tutorial.push(item);  
            if(item.children)
            {
                item.children.forEach(subitem => {
                    if(subitem.tutorial)
                        navigation_tutorial.push(subitem);
                    if(subitem.children)
                    {
                        subitem.children.forEach(lastsubitem => {
                            if(lastsubitem.tutorial)
                                navigation_tutorial.push(lastsubitem);
                        });
                    }
                });
            }                         
            
        });

        var steps_tutorial= new Array();
        navigation_tutorial.forEach(item => 
        {
            steps_tutorial.push("tutorial_item_" + item.id);
        });
        
        const options = {
            steps: steps_tutorial,
            /*steps: [
                'tutorial_item_home',
                'tutorial_item_perfil',
                'tutorial_item_perfil_publico'
            ],*/
            // startWith: 'step3@app',
            // waitingTime: 3000,
            stepDefaultPosition: 'top',
            themeColor: '#091c28',
            showPrevButton: true,
            logsEnabled: false,
            customTexts: {
                next: ' > ',
                prev: ' < ',
                done: '¡Finalizar!'
              }
        };
        this.joyrideService.startTour(options).subscribe(
            step => {
                console.log('Next:', step);
            },
            e => {
                console.log('Error', e);
            },
            () => {
                //this.stepDone();
                //@TODO: Llamar servicio web para guardar el estado
                this.saveTutorial("home");
                console.log('Tour finished');
            }
        );
    }

    urlLocal = GlobalConstants.apiURL;

    saveTutorial(tipo_tutorial:string): Promise<any>
    {
        let tutorialId = 0;
        let url = "";

        if(this.userSession.user_type == 1) //Candidato
        {
            tutorialId = this.userSession.candidato.tutorial.id;
            this.userSession.candidato.tutorial.home = 1;
            url = `${this.urlLocal}candidato_tutorial/${tutorialId}`;
        }        
        
        if(this.userSession && this.userSession.user_type == 2) //Empresa
        {
            tutorialId = this.userSession.empresa.tutorial.id;
            this.userSession.empresa.tutorial.home = 1;
            url = `${this.urlLocal}empresa_tutorial/${tutorialId}`;
        }

        let data = {
            home : 1
        };

        return new Promise((resolve, reject) => {
            this._httpClient.put(url, data)
                .subscribe((response: any) => {
                    this.authState.removeUserSession();
                    this.authState.validateSession();
                    resolve(response);                    
                }, reject);
        });
        
    }
}
