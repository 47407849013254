<div class="cont_school" fxLayout="column" fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutAlign="center center">
        <div class="video-container" fxLayoutAlign="center center">
            <youtube-player width="100%" height="100%" [videoId]="videoId" (ready)="savePlayer($event)"
                (change)="onStateChange($event)">
            </youtube-player>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center" class="pt-24 butons">
            <div class="item" fxLayout="row" fxLayoutAlign="center center"  (click)="likeClick()">
                <mat-icon class="item_like">thumb_up</mat-icon>
                <p class="item_text">Me gusta</p>
            </div>
            <div class="item" fxLayou0="row" fxLayoutAlign="center center" (click)="likeClick()">
                <mat-icon class="item_like">thumb_down_alt</mat-icon>
                <p class="item_text">No me gusta</p>
            </div>
            <button class="item" mat-raised-button color="primary" (click)="onCerrarClick()">Cerrar</button>
        </div>
    </div>
</div>