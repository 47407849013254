
<div mat-dialog-content fxLayout="column" fxLayoutAlign="center center">

    <div class="logo">
        <img src="assets/images/logos/logo.svg" alt="logo">
    </div>

    <div fxLayout="row" fxLayoutAlign="center center">
        <span class="texto h2">Ooopss! Ha ocurrido un error...
        </span>
    </div>
    
</div>

