import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalConstants } from 'app/common/global-constants';
import { formatDate } from "@angular/common";

declare function OpenWompiModalWorkshop(amount: number, reference: string, currency: string, front: string, mode: string): any;
@Component({
  selector: 'app-modal-visualize',
  templateUrl: './modal-visualize.component.html',
  styleUrls: ['./modal-visualize.component.scss']
})
export class ModalVisualizeComponent implements OnInit {
  option: {
    url: string,
    precio: string,
    personas: string,
    hora: string,
  }

  urlLocal = GlobalConstants.apiURL;
  urlBase = GlobalConstants.URL;
  mode = GlobalConstants.mode;

  opcion = 1;

  constructor(
    public dialogRef: MatDialogRef<ModalVisualizeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.selectOption(1);
  }

  ngOnInit(): void {
  }

  selectOption(value) {

    switch (value) {
      case 1:
        this.option = {
          url: 'https://okempleos.com/wp-content/uploads/2021/10/Asset-8Ilu.svg',
          precio: '120.000 COP',
          personas: '1',
          hora: '1 Hora'
        }
        this.opcion = 1;
        break;

      case 2:
        this.option = {
          url: 'https://okempleos.com/wp-content/uploads/2021/10/Asset-6Ilu.svg',
          precio: '199.900 COP',
          personas: '2',
          hora: '2 Horas'
        }
        this.opcion = 2;
        break;

      case 3:
        this.option = {
          url: 'https://okempleos.com/wp-content/uploads/2021/10/Asset-7Ilu.svg',
          precio: 'Personalizado',
          personas: 'Personalizado',
          hora: 'Personalizado'
        }
        this.opcion = 3;
        break;
    }
  }

  GenerateTransactionReference(paquete_id, paquete_nombre): string {
    let dateNow = new Date();
    let reference = "";
    let sufix = "OK";
    let plan = "";
    let periodo_format = "";
    const format = 'yyyyMMddhhmmss';
    const locale = 'es-CO';
    const formattedDate = formatDate(dateNow, format, locale);

    plan = paquete_nombre.replace(/\s/g, "_");

    reference = sufix + "_" + paquete_id + "_" + plan + "_" + formattedDate;
    return reference;
  }

  pagarTaller(value) {
    let valor: number;
    let id: string;
    let plan: string;
    if (value === 1) {
      valor = 120000;
      id = '1';
      plan = "taller de una persona";
    } else {
      valor = 199900;
      id = '1';
      plan = "taller de dos persona";
    }
    OpenWompiModalWorkshop(valor, this.GenerateTransactionReference(id, plan), 'COP', this.urlBase, this.mode);
  }

  onCerrarClick(): void {
    this.dialogRef.close();
  } 
}