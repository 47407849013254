import { Component, OnDestroy, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as spanish } from './i18n/es';
import { locale as english } from './i18n/en';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Params, Router, ActivatedRoute } from '@angular/router';
import { GlobalConstants } from 'app/common/global-constants';
import { AuthService } from 'app/pixcore/shared/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ModalRegistroComponent } from './modal/registro-exitoso.component';
import { AuthStateService } from 'app/pixcore/shared/auth-state.service';
import { InterceptorSkipHeader } from 'app/pixcore/shared/auth.interceptor';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { TokenService } from 'app/pixcore/shared/token.service';
import { SEOService } from 'app/seo-service.service';
import { LogoService } from 'app/logo.service';

@Component({
    selector: 'register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class RegisterComponent implements OnInit, OnDestroy {

    registerForm: FormGroup;
    hide1 = true;
    hide2 = true;
    loading = false;

    // Private
    private _unsubscribeAll: Subject<any>;

    urlLocal = GlobalConstants.apiURL;
    URL = GlobalConstants.URL;
    tipos: any[];
    isOnlyRegister = true;
    isEmailComporativo = true;
    currentStateCandidato = true;
    currentStateEmpresa = false;

    user: SocialUser;
    loggedIn: boolean;

    linkedInCredentials = {
        clientId: "77wet208pi7uo6",
        redirectUrl: `${this.URL}/login-linkedin`,
        scope: "r_liteprofile%20r_emailaddress" // To read basic user profile data and email
    };

    logoPath: String;


    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private httpClient: HttpClient,
        private router: Router,
        public authService: AuthService,
        private authState: AuthStateService,
        private _snackBar: MatSnackBar,
        public _dialog: MatDialog,
        private _route: ActivatedRoute,
        private _socialAuthService: SocialAuthService,
        private token: TokenService,
        private seoService: SEOService,
        private logoService: LogoService,
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this.tipos = [
            { id: 1, value: "Persona Jurídica" },
            { id: 2, value: "Persona Natural" },
        ]

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this._fuseTranslationLoaderService.loadTranslations(spanish, english);

        if (this.authState.getUserSession()) {
            this.router.navigate(['/home']);
        }
        this.logoPath = this.logoService.getLogoPath();

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        /* SEoService */
        
        this.seoService.createLinkForCanonicalURL();
        this.seoService.setDescription('Registrate, completa tu perfil y consigue el empleo que mereces o el talento que buscas.');
        /* SEoService */

        this.registerForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email, Validators.minLength(5), Validators.maxLength(100)]],
            user_type: ['', Validators.required],
            accept_terms: [false, Validators.required],
            password: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(100)]],
            password_confirmation: ['', [Validators.required, confirmPasswordValidator]],
            recaptcha: [null],
            numero_identificacion: [null],
            telefono:[null],
            tipo_persona: [null],
        });

        this._socialAuthService.authState.subscribe((user) => {
            this.user = user;
            this.loggedIn = (user != null);
            if (this.loggedIn) {

                this.auth_register(this.user.email, this.user.name, this.user.firstName, this.user.lastName, this.user.photoUrl, this.user.id, this.user.provider, this.user.id);
            }
        });

        // Update the validity of the 'password_confirmation' field
        // when the 'password' field changes
        this.registerForm.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.registerForm.get('password_confirmation').updateValueAndValidity();
            });

        this._route.queryParams
            .subscribe(params => {

                if (params.registro) {
                    this.isOnlyRegister = false;
                    if (params.registro === 'candidato') {
                        this.registerForm.controls['numero_identificacion'].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(100), Validators.pattern('[0-9]+$')]);
                        this.registerForm.get('numero_identificacion').updateValueAndValidity();
                        this.registerForm.get('user_type').setValue('1');
                        this.currentStateEmpresa = false;
                        this.currentStateCandidato = true;
                        this.seoService.setPageTitle('Registro Candidato | Ok empleos');
                    } else if (params.registro === 'empresa') {
                        this.registerForm.controls['numero_identificacion'].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(100), Validators.pattern('[0-9]+\-[0-9]{1}$')]);
                        this.registerForm.get('numero_identificacion').updateValueAndValidity();
                        this.registerForm.get('user_type').setValue('2');
                        this.registerForm.controls['tipo_persona'].setValidators([Validators.required]);
                        this.registerForm.get('tipo_persona').updateValueAndValidity();
                        this.registerForm.controls['telefono'].setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(11), Validators.pattern('[0-9]+$')]);
                        this.currentStateEmpresa = true;
                        this.currentStateCandidato = false;
                        this.seoService.setPageTitle('Registro Empresa | Ok empleos');
                    } else {
                        this.registerForm.controls['numero_identificacion'].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(100), Validators.pattern('[0-9]+$')]);
                        this.registerForm.get('numero_identificacion').updateValueAndValidity();
                        this.registerForm.get('user_type').setValue('1');
                        this.currentStateEmpresa = false;
                        this.currentStateCandidato = true;
                        this.seoService.setPageTitle('Registro Candidato | Ok empleos');
                    }
                } else {
                    this.isOnlyRegister = true;
                    this.registerForm.controls['numero_identificacion'].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(100), Validators.pattern('[0-9]+$')]);
                    this.registerForm.get('numero_identificacion').updateValueAndValidity();
                    this.registerForm.get('user_type').setValue('1');
                    this.currentStateEmpresa = false;
                    this.currentStateCandidato = true;
                    this.seoService.setPageTitle('Registro Candidato | Ok empleos');
                }
            });

        if (!window.location.href.includes('localhost')) {
            this.registerForm.controls['recaptcha'].setValidators([Validators.required]);

        }


    }
    auth_register(email: string, name: string, first_name: string, last_name: string, photoUrl: string, password: string, provider: string, providerId: string, telefono?: string ) {

        this.loading = true;

        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');

        const formData = this.registerForm.getRawValue();

        formData.user_type = 1;
        formData.email = email;
        formData.name = name;
        formData.first_name = first_name;
        formData.last_name = last_name;
        formData.photo_url = photoUrl;
        formData.password = password;
        formData.provider = provider;
        formData.provider_id = providerId;
        formData.phone = telefono;


        this.httpClient.post(`${this.urlLocal}auth_user_social_media`, formData, { headers: headers }).subscribe(
            (result: any) => {
                if (!result.errors) {

                    localStorage.removeItem('admin_mode');
                    this.openSnackBar("Ingreso satisfactorio", 'snack-success');
                    this.responseHandler(result.sessionData);
                    this.authState.removeUserSession();
                    this.authState.setAuthState(true);
                    this.router.navigate(['/home']);
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.openSnackBar(result.message, 'snack-error');
                }

            });
    }

    inputEmail(e): void {


        if (
            e.target.value.includes('gmail') ||
            e.target.value.includes('hotmail') ||
            e.target.value.includes('yahoo') ||
            e.target.value.includes('outlook')
        ) {
            this.isEmailComporativo = false;
        } else {
            this.isEmailComporativo = true;
        }


    }

    resolved(captchaResponse: string): void {
        // console.log(`Resolved response token: ${captchaResponse}`);

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    abrirModal(usuario): void {
        const modal = this._dialog.open(ModalRegistroComponent, {
            data: usuario
        });

        modal.afterClosed().subscribe(() => {

            this.router.navigate(['/login']);
        });
    }

    OnSubmit(): void {

        this.loading = true;

        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');

        const formData = this.registerForm.getRawValue();
        formData.phone = this.registerForm.get('telefono').value;

        if (formData.user_type !== '1') {
            formData.correo_valido = this.isEmailComporativo;
        }

        this.httpClient.post(`${this.urlLocal}register_user`, formData, { headers: headers }).subscribe(
            (result: any) => {
                if (!result.errors) {
                    this.loading = false;
                    if (formData.user_type === '1') {

                        //this.abrirModal(formData.name);
                        this.router.navigate(['/verificacion'], { queryParams: { email: formData.email } });
                    } else {
                        let mensaje = 'Registro satisfactorio';
                        if (!this.isEmailComporativo) {
                            mensaje = 'Registro satisfactorio, sin embargo tu inscripción entrará a validación';
                        }

                        this.openSnackBar(`${mensaje}`, 'snack-success');
                        this.sleep(1000).then(() => {
                            this.router.navigate(['/login'], { queryParams: { email: formData.email } });
                        });

                    }
                } else if (result.errors.email) {
                    this.loading = false;

                    this.openSnackBar(result.errors.email[0], 'snack-error');

                } else if (result.errors.numero_identificacion) {
                    this.loading = false;
                    this.openSnackBar(result.errors.numero_identificacion[0], 'snack-error');
                } else {
                    this.loading = false;
                    this.openSnackBar(result.message, 'snack-error');
                }

            });
    }

    private sleep(ms): Promise<any> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    /**
     * Mostrar la respuesta al usuario
     * @param mensaje
     */
    private openSnackBar(mensaje: string, clase: string): void {
        this._snackBar.open(mensaje, 'x', {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'top',
            panelClass: clase,
        });
    }

    OnCandidatoSelected(e): void {


        this.currentStateEmpresa = false;
        this.currentStateCandidato = true;
        this.registerForm.controls['numero_identificacion'].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(100), Validators.pattern('[0-9]+$')]);
        this.registerForm.get('numero_identificacion').updateValueAndValidity();
    }

    OnEmpresaSelected(e): void {


        this.currentStateEmpresa = true;
        this.currentStateCandidato = false;
        this.registerForm.controls['numero_identificacion'].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(100), Validators.pattern('[0-9]+\-[0-9]{1}$')]);
        this.registerForm.get('numero_identificacion').updateValueAndValidity();
    }


    signInWithGoogle(): void {
        this._socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
    }

    signInWithFB(): void {
        const fbLoginOptions = {
            scope: 'email',
            return_scopes: true,
            enable_profile_selector: true
        };
        this._socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID, fbLoginOptions);
    }

    signInWithLinkedIn(): void {
        window.location.href = `https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=${this.linkedInCredentials.clientId
            }&redirect_uri=${this.linkedInCredentials.redirectUrl}&scope=${this.linkedInCredentials.scope}`;
    }

    signOut(): void {
        this._socialAuthService.signOut();
    }

    // Handle response
    responseHandler(data) {
        this.token.handleData(data.access_token);
    }

}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('password');
    const password_confirmation = control.parent.get('password_confirmation');

    if (!password || !password_confirmation) {
        return null;
    }

    if (password_confirmation.value === '') {
        return null;
    }

    if (password.value === password_confirmation.value) {
        return null;
    }

    return { passwordsNotMatching: true };
};