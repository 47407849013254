import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from 'app/common/global-constants';
import { DateAdapter } from '@angular/material/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import { AuthStateService } from 'app/pixcore/shared/auth-state.service';
import { Router } from '@angular/router';
import { CandidatoBd } from './model/candidato-bd';
import { FuseMatchMediaService } from '@fuse/services/match-media.service';
import { TokenService } from 'app/pixcore/shared/token.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SocialAuthService } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { LoginLinkedinService } from 'app/public/login-linkedin/login-linkedin.service';

export interface DialogData {
  texto: string;
}

@Component({
  selector: 'modal-form-candidato',
  templateUrl: './modal-form-candidato.component.html',
  styleUrls: ['./modal-form-candidato.component.scss']
})
export class ModalFormCandidatoComponent implements OnInit, OnDestroy {

    urlLocal = GlobalConstants.apiURL;
    personalDataForm: FormGroup;
    contactForm: FormGroup;
    informationForm: FormGroup;
    profileForm: FormGroup;
    educacionForm: FormGroup;
    experienciaForm: FormGroup;
    candidato = new CandidatoBd({});

    minDateFinEducacion = new Date();
    minDateInicio = new Date();
    minDateFinTextEducacion = ''
    minDateInicioText = '';

    hasExperiencia = false;
    hideLabelStep = false;

    minDateFinExperiencia = new Date();
    minDateInicioExperiencia = new Date();
    minDateFinTextExperiencia = ''
    minDateInicioTextExperiencia = '';

    // formd de info personal
    tiposDocumento: any[];
    generos: any[];
    orientaciones: any[];
    minDate: Date;
    maxDate: Date;
    maxDateInicio = new Date();

    // formd de contacto
    ciudades: any[];
    localidades: any[];

    private _unsubscribeAll: Subject<any>;
    // formd de informacion
    gruposSanguineos: any[];
    rh: any[];
    nivelEducativo: any[];
    estadoCivil: any[];
    salarios: any [];
    listaEmpresas: any [];
    experiencia: any[];
    estadosEstudios: any[];
    gruposEtnicos: any[];
    condicionDiscapacidad: any[];
    tipoPoblacion: any[];
    condicionSaludMental: any[];

    loading = false;

    user: SocialUser;
    loggedIn: boolean;

    DocReadOnly = true;

    isOpen = true;

    empresaText = '';

    constructor(
    public dialogRef: MatDialogRef<ModalFormCandidatoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private _httpClient: HttpClient,
    private dateAdapter: DateAdapter<any>,
    private _snackBar: MatSnackBar,
    private authState: AuthStateService,
    private _fuseMatchMediaService: FuseMatchMediaService,
    private router: Router,
    private token: TokenService,
    private _socialAuthService: SocialAuthService,
    private loginLinkedinService: LoginLinkedinService
    ) {

      const currentYear = new Date().getFullYear();
      const currentMont = new Date().getMonth();
      const currentDay = new Date().getDate();
      this.maxDate = new Date(currentYear - 15, currentMont, currentDay);
      this.minDate = new Date(currentYear - 90, currentMont, currentDay);
    //   this.getTiposDocumento();
    //   this.getGeneros();
    //   this.getCiudades();
    //   this.getLocalidades();
    //   this.getGruposSanguineos();
    //   this.getRh();
    //   this.getNivelEducativo();
    //   this.getEstadoCivil();
    //   this.getSalarios();
    //   this.getExperiencia();
      this.getAllSelects();
      this.getCandidato();
      this._unsubscribeAll = new Subject();

    }

    ngOnInit(): void {


    this.dateAdapter.setLocale('es');
    this.personalDataForm = this.createPersonalDataForm();
    this.contactForm = this.createContactFom();
    this.informationForm = this.createInformationFom();
    this.profileForm = this.createProfileForm();
    this.educacionForm = this.createEducationForm();
    this.experienciaForm = this.createExperienceForm();

    if (this.candidato.numero_documento == null) {
        this.DocReadOnly = false;
    }
    
    this.loginLinkedinService.currentLinkedinUser.subscribe( usr => {

        this.personalDataForm.controls['primer_nombre'].setValue(usr.first_name);
        this.personalDataForm.controls['primer_apellido'].setValue(usr.last_name);
        this.contactForm.controls['correo'].setValue(usr.email);

    });
     
    this._socialAuthService.authState.subscribe((user) => {
        this.user = user;
        this.loggedIn = (user != null);

        if (this.loggedIn) {
        
            this.personalDataForm.controls['primer_nombre'].setValue(this.user.firstName);
            this.personalDataForm.controls['primer_apellido'].setValue(this.user.lastName);
            this.contactForm.controls['correo'].setValue(this.user.email);

            if (this.candidato.numero_documento == null) {
                this.DocReadOnly = false;
            }
        }
      });


    
    this._fuseMatchMediaService.onMediaChange
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
            switch(res) { 
                case 'xs': { 
                   this.hideLabelStep = false;
                    break; 
                }
                case 'sm': { 
                    this.hideLabelStep = false;
                    break; 
                } 
                default: { 
                    this.hideLabelStep = true;
                    break; 
                } 
            }        
        });


    // this.informationForm.get('poblacion_focalizada').valueChanges
    //         .subscribe((value) => {

    //             if (value == 1) 
    //             {
    //                 this.informationForm.get('tipo_poblacion_id').setValidators([Validators.required])
    //                 this.informationForm.get('tipo_poblacion_id').updateValueAndValidity();
    //             }else {
    //                 this.informationForm.get('tipo_poblacion_id').setValidators([])
    //                 this.informationForm.get('tipo_poblacion_id').updateValueAndValidity();
    //             }

    //         });
  }

    /**
     * Get todos los selects de este servicio
     *
     * @returns {Promise<any>}
     */
    getAllSelects(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${this.urlLocal}selects_generales`)
                .subscribe((response: any) => {
                    this.tiposDocumento = response.tiposDocumento;
                    this.generos = response.generos;
                    this.orientaciones = response.orientacion;
                    this.ciudades = response.ciudades;
                    this.localidades = response.localidades;
                    this.gruposSanguineos = response.gruposSanguineos;
                    this.rh = response.rh;
                    this.nivelEducativo = response.nivelesEducativos;
                    this.estadoCivil = response.estadosCiviles;
                    this.salarios = response.salarios;
                    this.experiencia = response.experiencias;
                    this.estadosEstudios = response.estadosEstudios;
                    this.listaEmpresas = response.listaEmpresas;
                    this.gruposEtnicos = response.gruposEtnicos;
                    this.condicionDiscapacidad = response.condicionDiscapacidad;
                    this.tipoPoblacion = response.tipoPoblacion;
                    this.condicionSaludMental = response.condicionSaludMental;
                    resolve(response);
                }, reject);
        });
    }

    getCandidato(): Promise<any> {
        return new Promise((resolve, reject) => {
              this._httpClient.get(`${this.urlLocal}primer_login/${this.data.user_id}`)
                .subscribe((response: any) => {
                    if (response.length <= 0) {
                        this.candidato = new CandidatoBd({});

                    }else {
                        this.candidato = new CandidatoBd(response);
                        this.personalDataForm.controls['primer_nombre'].setValue(this.candidato.primer_nombre);
                        this.personalDataForm.controls['primer_apellido'].setValue(this.candidato.primer_apellido? this.candidato.primer_apellido: null);
                        this.personalDataForm.controls['tipo_documento_id'].setValue(this.candidato.tipo_documento_id);
                        this.personalDataForm.controls['numero_documento'].setValue(this.candidato.numero_documento);
                        this.personalDataForm.controls['fecha_nacimiento'].setValue(moment(response.fecha_nacimiento).format('YYYY-MM-DD'));
                        this.minDateInicio = response.fecha_nacimiento;
                        this.minDateInicioText = moment(response.fecha_nacimiento, ['YYYY-MM-DD', 'DD-MM-YYYY']).format('DD-MM-YYYY');

                        this.personalDataForm.controls['genero_id'].setValue(this.candidato.genero_id); 

                        this.contactForm.controls['telefono'].setValue(this.candidato.telefono);                        
                        this.contactForm.controls['correo'].setValue(this.candidato.correo);                        
                        this.contactForm.controls['ciudad_id'].setValue(this.candidato.ciudad_id);  
                        this.contactForm.controls['barrio'].setValue(this.candidato.barrio); 

                        this.informationForm.controls['nivel_educativo_id'].setValue(this.candidato.nivel_educativo_id);

                        this.profileForm.controls['perfil'].setValue(this.candidato.perfil);

                        
                        this.personalDataForm.markAllAsTouched();
                        this.informationForm.markAllAsTouched();
                        this.contactForm.markAllAsTouched();
                        this.profileForm.markAllAsTouched();

                    }
                
                    resolve(response);
                }, reject);
        });
    }


  private openSnackBar(mensaje: string, clase: string): void {
      this._snackBar.open(mensaje, 'x', {
       duration: 4000,
       horizontalPosition: 'end',
       verticalPosition: 'top',
       panelClass: clase,
     });
    }

    createPersonalDataForm(): FormGroup {

        return this._formBuilder.group({

        user_id  : [
            {
                value   : this.data.user_id,
                disabled: false
            }
        ],
        primer_apellido : [
            {
                value   : null,
                disabled: false
            }, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]
        ],
        primer_nombre : [{
                value   : null,
                disabled: false
            }, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]
        ],

        tipo_documento_id : [{
                value   : null,
                disabled: false
            }, Validators.required
        ],
        numero_documento : [{
                value   : this.authState.getUserSession().numero_identificacion,
                disabled: false
            },  [Validators.required, Validators.minLength(5), Validators.maxLength(100), Validators.pattern('[0-9]+$')]
        ],
        fecha_nacimiento : [{
                value   : null,
                disabled: false
            }, Validators.required
        ],
        genero_id : [{
                value   : null,
                disabled: false
            }, Validators.required
        ],
        orientacion_id : [{
                value   : null,
                disabled: false
            }, Validators.required
        ],
        orientacion_detail: [{
                value   : null,
                disabled: false
            }, 
        ],


    });
    }

    createContactFom(): FormGroup {

        return this._formBuilder.group({

        telefono : [
            {
                value   : null,
                disabled: false
            }, [Validators.required, Validators.minLength(5), Validators.maxLength(20), Validators.pattern('[0-9]*')]
            ],
            correo : [{
                    value   : this.authState.getUserSession().email,
                    disabled: false
                }, [Validators.required, Validators.minLength(5), Validators.maxLength(100)]
            ],
            pais_id : [{
                    value   : 47,
                    disabled: false
                }
            ],
            ciudad_id : [{
                    value   : null,
                    disabled: false
                }, Validators.required
            ],
            localidad_id : [{
                value   : null,
                disabled: false
            }
            ],
            direccion : [{
                    value   : null,
                    disabled: false
                }, [Validators.required, Validators.minLength(5), Validators.maxLength(100)]
            ],
            barrio : [{
                    value   : null,
                    disabled: false
                }, [Validators.required, Validators.minLength(4), Validators.maxLength(100)]
            ],


    });
    }

    createInformationFom(): FormGroup {

        return this._formBuilder.group({

            grupo_sanguineo_id : [
            {
                value   : null,
                disabled: false
            }, Validators.required
            ],
            rh_id : [{
                    value   : null,
                    disabled: false
                }, Validators.required
            ],
            nivel_educativo_id : [{
                    value   : null,
                    disabled: false
                }, Validators.required
            ],
            estado_civil_id : [{
                    value   : null,
                    disabled: false
                }, Validators.required
            ],
            disponibilidad_viaje : [{
                value   : null,
                disabled: false
                }, Validators.required
                ],
            disponibilidad_cambio_vivienda : [{
                value   : null,
                disabled: false
                }, Validators.required
            ],
            poblacion_focalizada : [{
                value   : false,
                disabled: false
            }],
            grupo_etnico_id : [{
                value   : null,
                disabled: false
            }],
            discapacidad_id : [{
                value   : null,
                disabled: false
            }],
            tipo_poblacion_id : [{
                value   : null,
                disabled: false
            }],
            salud_mental_id : [{
                value   : null,
                disabled: false
            }],
            salario_id : [{
                    value   : null,
                    disabled: false
                }, Validators.required
            ],
            experiencia_id : [{
                    value   : null,
                    disabled: false
                }, Validators.required
            ]


    });
    }

    createProfileForm(): FormGroup {

        return this._formBuilder.group({

        perfil : [
            {
                value   : null,
                disabled: false
            }, [Validators.required, Validators.minLength(10), Validators.maxLength(2000)]
        ],

    });
    }

    createEducationForm(): FormGroup
    {
        return this._formBuilder.group({
            id: [{
                value: null,
                disabled: false,
            }],
            titulo_obtenido: [{
                value: null,
                disabled: false,
            }, [Validators.required, Validators.minLength(5), Validators.maxLength(100)]],
            fecha_inicio: [{
                value: null,
                disabled: false,
            }, Validators.required],
            fecha_fin  : [{
                value: null,
                disabled: false,
            }],
            nivel_academico_id: [{
                value: null,
                disabled: false,
            }, Validators.required],
            estado_estudios_id: [{
                value: null,
                disabled: false,
            }, Validators.required],
           
        });
    }


    createExperienceForm(): FormGroup
    {
        return this._formBuilder.group({
            id: [{
                value: null,
                disabled: false,
            }],
            trabaja_actualmente: [{
                value: null,
                disabled: false,
            }, [Validators.required]],
            cargo: [{
                value: null,
                disabled: false,
            }, [Validators.required, Validators.minLength(5), Validators.maxLength(100)]],
            empresa: [{
                value: null,
                disabled: false,
            }, [Validators.required]],
            empresa_telefono: [{
                value: null,
                disabled: false,
            }, [Validators.required, Validators.minLength(5), Validators.maxLength(20), Validators.pattern('[0-9]*')]],
            fecha_inicio: [{
                value: null,
                disabled: false,
            }, Validators.required],
            fecha_fin  : [{
                value: null,
                disabled: false,
            }],
        });
    }

    onCerrarClick(logout?: boolean): void {
        this.dialogRef.close(logout);
    
    }


    hasExperienciaChange(event): void{

        this.hasExperiencia = event.value;

        if (event.value) {
            this.createExperienceForm();
            this.experienciaForm.markAsUntouched();
        }else {

            for (const key in this.experienciaForm.controls) {
                this.experienciaForm.get(key).clearValidators();
                this.experienciaForm.get(key).updateValueAndValidity();
            }

        }

    }


    onSubmit(personalDataForm, contactForm, informationForm, profileForm, educacionForm, experienciaForm ): void {
      this.loading = true;
      
      personalDataForm.fecha_nacimiento = moment(personalDataForm.fecha_nacimiento, ['YYYY-MM-DD', 'DD-MM-YYYY']).format('YYYY-MM-DD');

        if (contactForm.ciudad_id !== 524) {
            contactForm.localidad_id = null;
        }
    
        if (educacionForm.estado_estudios_id !== 1550) {
            educacionForm.fecha_fin = null;
        } else {
            educacionForm.fecha_inicio = educacionForm.fecha_fin;
        }

        if (experienciaForm.trabaja_actualmente === 1)
        {
            experienciaForm.fecha_fin = null;
        }

        educacionForm.fecha_inicio = moment(educacionForm.fecha_inicio, ['YYYY-MM-DD', 'DD-MM-YYYY']).format('YYYY-MM-DD');
        experienciaForm.fecha_inicio = moment(experienciaForm.fecha_inicio, ['YYYY-MM-DD', 'DD-MM-YYYY']).format('YYYY-MM-DD');

        if (educacionForm.fecha_fin) {
            educacionForm.fecha_fin = moment(educacionForm.fecha_fin, ['YYYY-MM-DD', 'DD-MM-YYYY']).format('YYYY-MM-DD');
        }

        if (experienciaForm.fecha_fin) {
            experienciaForm.fecha_fin = moment(experienciaForm.fecha_fin, ['YYYY-MM-DD', 'DD-MM-YYYY']).format('YYYY-MM-DD');
        }

    
      const formData = Object.assign(personalDataForm, contactForm, informationForm, profileForm);

      formData.educacion = educacionForm;
      formData.experiencia = experienciaForm;

      if (!this.hasExperiencia) {
        formData.experiencia = null
      } else {
        formData.experiencia = experienciaForm;

        if (typeof formData.experiencia.empresa !== 'number'){
            formData.experiencia.empresa = formData.experiencia.empresa
        }else {
            formData.experiencia.empresa_id = formData.experiencia.empresa
            formData.experiencia.empresa = null
        }
      }
    
      this.postCandidato(formData).then((response) => {
    
        if (!response.errors){
          this.openSnackBar(response.message, 'snack-success');

          
          setTimeout(() => {
            this.onCerrarClick(true);
          }, 1500);          

        }else {
            if (response.errors.correo) {

                if (response.errors.correo[0] === 'validation.unique') {
                    this.openSnackBar('El correo ya existe, prueba con otro', 'snack-error');
                }else {
                    this.openSnackBar(response.message, 'snack-error');
                }
                

            }else if (response.errors.numero_documento){
                if (response.errors.numero_documento[0] === 'validation.unique') {
                    this.openSnackBar('El documento ya existe, prueba con otro', 'snack-error');
                }else {
                    this.openSnackBar(response.message, 'snack-error');
                }
            }else {
                this.openSnackBar(response.message, 'snack-error');
            }
        }
        this.loading = false;

      });

      


      // this._informacionPersonalService.updateCandidato(form).then((res) => {

      //     if (!res.errors) {
      //         this.authState.removeUserSession();
      //         this.authState.validateSession();
      
      //     }
      //     this.loadingForm = false;
      // });
    }


    postCandidato(data): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${this.urlLocal}candidato`, data)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
        
    }

    changeSelect(event) {
      
        if (event.value === 1550){
            this.educacionForm.controls['fecha_fin'].setValidators([Validators.required]);
            this.educacionForm.get('fecha_fin').setValue(moment());
            this.educacionForm.controls['fecha_inicio'].clearValidators();
        }else {
            this.educacionForm.controls['fecha_fin'].clearValidators();
        }

        this.educacionForm.get('fecha_fin').updateValueAndValidity();
        this.educacionForm.get('fecha_inicio').updateValueAndValidity();
    }

    changeRadio(evento) {  
        
     
        if (evento.value === 0) {

          this.experienciaForm.controls['fecha_fin'].setValidators([Validators.required]);
   
        } else {
            this.experienciaForm.controls['fecha_fin'].clearValidators();
        }
        
        this.experienciaForm.get('fecha_fin').updateValueAndValidity();
     
    }

    cambioFechaInicialExperiencia(event: MatDatepickerInputEvent<Date>): void {
        this.minDateFinExperiencia = new Date(event.value);
        this.minDateFinTextExperiencia = moment(this.minDateFinExperiencia, ['YYYY-MM-DD', 'DD-MM-YYYY']).format('DD-MM-YYYY');
     }

    changeSelectEmpresa(event) {
        if (event != null) {
            this.isOpen = false;
        }
    }
   
    cambioFechaInicialEducacion(event: MatDatepickerInputEvent<Date>): void {
       this.minDateFinEducacion = new Date(event.value);
       this.minDateFinTextEducacion = moment(this.minDateFinEducacion, ['YYYY-MM-DD', 'DD-MM-YYYY']).format('DD-MM-YYYY');
    }

    cambioFechaNacimiento(event: MatDatepickerInputEvent<Date>): void {

        this.minDateInicio = new Date(event.value);
        this.minDateInicioText = moment(this.minDateInicio, ['YYYY-MM-DD', 'DD-MM-YYYY']).format('DD-MM-YYYY');

    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    /**
     * Logout
     *
     */
     logout(): void
     {

         if (this.loggedIn){
            this._socialAuthService.signOut().then(() => {
                this.onCerrarClick(false);
                this.authState.setAuthState(false);
                this.token.removeToken();
                this.router.navigate(['login']); 
            });
         } else {
            this.onCerrarClick(false);
            this.authState.setAuthState(false);
            this.token.removeToken();
            this.router.navigate(['login']);  
         }
          
     }
    keyupSelectEmpresa(event) {
        console.log(event);
        this.empresaText = event.target.value;

    }
    blurSelectEmpresa(event) {
        console.log(event);
    }


    focusOutSelectEmpresa(event, empresa) {
        
        if(empresa == null) {
            this.experienciaForm.controls['empresa'].setValue(this.empresaText);
        }
    }

}
