<mat-toolbar class="p-0 mat-elevation-z1">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar"
                (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/logo.svg"  alt="logo">
                </div>
            </div>

            <div class="px-8 px-md-16">
                <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
            </div>

        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

            <div fxHide fxShow.gt-sm fxLayout="row" *ngIf="isAdminMode" class="accent admin-view px-12 mx-8">
                MODO ADMIN
            </div>

            <button fxHide.gt-sm *ngIf="isAdminMode" class="mx-4" [matMenuTriggerFor]="adminMenu" mat-icon-button>
                <mat-icon>supervised_user_circle</mat-icon>
            </button>

            <mat-menu #adminMenu="matMenu" [overlapTrigger]="false">

                <div class="h3 px-12 py-8 accent">
                    MODO ADMIN
                </div>

            </mat-menu>

            <div *ngIf="isAdminMode" class="toolbar-separator"></div>

            <div fxHide.gt-sm fxFlex fxLayout="row" fxLayoutAlign="left center">
                <button *ngIf="!isCandidato" mat-flat-button color="primary" aria-label="view"
                    [routerLink]="'/vacante/nueva-vacante'" style="font-weight: 100;">
                    <mat-icon class="mr-4">add_circle_outline</mat-icon>Nueva vacante
                </button>
            </div>

            <div *ngIf="userData && userData.empresa" fxLayout="row" fxLayoutAlign="left center">
                <a mat-button [routerLink]="'/empresa/profile'">
                    <img *ngIf="userData.empresa.url_logo" class="avatar mr-0 mr-sm-16"
                        [src]="urlLocalStorage + userData.empresa.url_logo">
                    <img *ngIf="!userData.empresa.url_logo" class="avatar mr-0 mr-sm-16"
                        src="assets/images/logos/profile_default.svg">
                    <span class="username-empresa mt-12 mr-24 text-sm" fxHide
                        fxShow.gt-xs>{{userData.empresa.nombre}}</span>
                </a>
                <div fxHide fxShow.gt-xs class="toolbar-separator"></div>
                <button fxHide fxShow.gt-xs mat-flat-button color="accent"
                    [routerLink]="'/empresa/planes'">{{planEmpresa}}</button>
            </div>

            <div *ngIf="isChecklist && userData.email_verified_at === null" class="toolbar-separator"></div>

            <ng-container *ngIf="isChecklist">

                <button mat-button [matMenuTriggerFor]="checklistMenu" class="checklist-button">

                    <div class="" fxLayout="row" fxLayoutAlign="center center">

                        <div fxHide.xs class="mr-4">
                            <span>Perfil: </span><span
                                class="text-accent">{{ porcentaje / 100  | percent: '2.0-0'}}</span>
                        </div>

                        <div fxHide.gt-xs class="mr-4" fxLayout="column" fxLayoutAlign="center center">
                            <div>Perfil:</div>
                            <div class="text-accent">{{ porcentaje / 100  | percent: '2.0-0'}}</div>
                        </div>

                        <mat-progress-bar fxHide.xs color="accent" class="mx-4" value="{{porcentaje}}">
                        </mat-progress-bar>

                        <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                    </div>


                </button>


                <mat-menu #checklistMenu="matMenu" [overlapTrigger]="false">

                    <div class="px-12">
                        <mat-progress-bar fxHide.gt-xs color="accent" value="{{porcentaje}}">
                        </mat-progress-bar>
                    </div>


                    <h3 *ngIf="porcentaje < 100" class="px-16 text-bold">Te falta por completar</h3>
                    <h3 *ngIf="porcentaje >= 100" class="px-16 text-bold">Perfil completo</h3>

                    <ng-container *ngFor="let item of itemsChecklist">

                        <button *ngIf="!item.checked" mat-menu-item (click)="goToLinkItemChecklist(item.link)">
                            <span><img class="achievement achievement-locked mr-0 mr-sm-16"
                                    [src]="'assets/images/logros/' + item.icon">{{item.name}}</span>
                        </button>

                    </ng-container>

                </mat-menu>

            </ng-container>

            <div *ngIf="userData && userData.candidato"  class="toolbar-separator"></div>

            <button  *ngIf="userData && userData.candidato" mat-button [matMenuTriggerFor]="userMenuNotifications" class="user-notifications">
                <mat-icon *ngIf="userData.candidato.send_notifications == true">notifications_active</mat-icon>
                <mat-icon *ngIf="userData.candidato.send_notifications == false">notifications_off</mat-icon>
                <label fxHide fxShow.gt-sm>Notificaciones</label>
              </button>
              <mat-menu #userMenuNotifications="matMenu">
                <button mat-menu-item (click)="toggleNotifications(true)">
                  <mat-icon>notifications_active</mat-icon>
                  Notificaciones Activadas
                </button>
                <button mat-menu-item (click)="toggleNotifications(false)">
                  <mat-icon>notifications_off</mat-icon>
                  Notificaciones Desactivadas
                </button>
              </mat-menu>

            <div class="toolbar-separator"></div>

            <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">

                <div *ngIf="userData" fxLayout="row" fxLayoutAlign="center center">
                    <img *ngIf="userData.profile_photo_path" class="avatar mr-0 mr-sm-16"
                        [src]="urlLocalStorage + userData.profile_photo_path">
                    <img *ngIf="!userData.profile_photo_path" class="avatar mr-0 mr-sm-16"
                        src="assets/images/avatars/profile.jpg">

                    <span class="username mr-12" fxHide fxShow.gt-sm>{{userData.first_name}}
                        {{userData.last_name}}</span>
                    <button *ngIf="!isCandidato" fxHide fxShow.gt-xs mat-flat-button color="primary">{{role}}</button>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <div mat-menu-item *ngIf="!isCandidato" fxHide.gt-xs>
                    <span class="mr-16">Rol:</span>
                    <span>{{role}}</span>
                </div>

                <button mat-menu-item (click)="verPerfil()">
                    <mat-icon>account_circle</mat-icon>
                    <span>Perfil de usuario</span>
                </button>

                <button mat-menu-item class="" *ngIf="isCandidato" (click)="verHojaDeVida()">
                    <mat-icon>description</mat-icon>
                    <span>Hoja de vida</span>
                </button>

                <button mat-menu-item class="" *ngIf="loggedIn" (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Salir</span>
                </button>

            </mat-menu>



            <!--<fuse-search-bar (input)="search($event)"></fuse-search-bar>-->



            <!--<button mat-button fxHide fxShow.gt-xs
                    class="language-button"
                    [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'"  alt="icon">
                    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                </div>
            </button>-->

            <!-- <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'"  alt="icon">
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>

                <button mat-menu-item [routerLink]="'/components/multi-language'">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <span>Learn more</span>
                    </div>
                </button>

            </mat-menu> -->

            <!--<button mat-icon-button fxHide.gt-md
                    class="chat-panel-toggle-button"
                    (click)="toggleSidebarOpen('chatPanel')"
                    aria-label="Toggle chat panel">
                <mat-icon class="secondary-text">chat</mat-icon>
            </button>-->

            <!-- <div class="toolbar-separator ml-8 mr-8" fxHide.gt-md></div> -->

            <!--<button mat-icon-button
                    class="quick-panel-toggle-button"
                    (click)="toggleSidebarOpen('quickPanel')"
                    aria-label="Toggle quick panel">
                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            </button>-->

            <!--<div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>-->

            <!-- Help Joyride
            <div class="toolbar-separator ml-8 mr-8" fxHide.gt-md></div>

            <button fxHide fxShow.gt-xs [routerLink]="'/usuario'" mat-icon-button color="primary">
                <mat-icon class="ml-8 mr-8">help_outline</mat-icon>
                <span></span>
            </button>
            -->

        </div>

    </div>

</mat-toolbar>