import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgImageSliderModule } from 'ng-image-slider';
import { FuseSharedModule } from '@fuse/shared.module';

import { FirstComponent } from 'app/public/first/first.component';

import { TranslateModule } from '@ngx-translate/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { SalaryCalculatorComponent } from './salary-calculator/salary-calculator.component';
import { FooterFloatComponent } from './footer-float/footer-float.component';
import { ModalCompletTallerComponent } from './modal-complet-taller/modal-complet-taller.component';
import { WhatsappChatComponent } from './whatsapp-chat/whatsapp-chat.component';
import { SchoolFirstComponent } from './school-first/school-first.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';

const routes = [
    {
        path: '',
        component: FirstComponent
    },
];

@NgModule({
    declarations: [
        FirstComponent,        
        SalaryCalculatorComponent,
        FooterFloatComponent,
        ModalCompletTallerComponent,
        WhatsappChatComponent,
        SchoolFirstComponent,
        FooterComponent,
        NavbarComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        NgImageSliderModule,
        MatDialogModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        FlexLayoutModule,
        FuseSharedModule,
        TranslateModule,
        MatRadioModule,
        MatSnackBarModule,
        NgxYoutubePlayerModule.forRoot(),
        MatToolbarModule,
        MatSidenavModule,
        MatDividerModule, 
    ]
})
export class FirstModule {
}
