<div joyrideStep="tutorial_item_{{item.id}}" [stepContent]="content_tutorial_item" title="{{item.title}}">
<ng-container *ngIf="!item.hidden">

    <!-- item.url -->
    <a class="nav-link"  [matTooltipPosition]="'right'"  [matTooltipClass]="{'tooltip-big': true}"  [ngClass]="item.classes" [ngClass]="item.isItemTitle? 'nav-link-custom-sub-item' : 'nav-link-custom'" 

    *ngIf="item.url && !item.externalUrl && !item.function"
       [routerLink]="[item.url]" [routerLinkActive]="['active', activeButton]"
       [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
       [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl -->
    <a class="nav-link" [matTooltipPosition]="'right'" matTooltipClass="tooltip-big" [ngClass]="item.classes" [ngClass]="item.isItemTitle ? 'nav-link-custom-sub-item' : 'nav-link-custom'" *ngIf="item.url && item.externalUrl && !item.function"
       [href]="item.url" [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.function -->
    <span class="nav-link" [matTooltipPosition]="'right'" matTooltipClass="tooltip-big" [ngClass]="item.classes" [ngClass]="item.isItemTitle ? 'nav-link-custom-sub-item' : 'nav-link-custom'" *ngIf="!item.url && item.function"
          (click)="item.function()">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </span>

    <!-- item.url && item.function -->
    <a class="nav-link" [matTooltipPosition]="'right'" matTooltipClass="tooltip-big" [ngClass]="item.classes" [ngClass]="item.isItemTitle ? 'nav-link-custom-sub-item' : 'nav-link-custom'" *ngIf="item.url && !item.externalUrl && item.function"
       (click)="item.function()"
       [routerLink]="[item.url]" [routerLinkActive]="['active', activeButton]"
       [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
       [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl && item.function -->
    <a class="nav-link" [matTooltipPosition]="'right'" matTooltipClass="tooltip-big" [ngClass]="item.classes" [ngClass]="item.isItemTitle ? 'nav-link-custom-sub-item' : 'nav-link-custom'" *ngIf="item.url && item.externalUrl && item.function"
       (click)="item.function()"
       [href]="item.url" [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <ng-template #itemContent>
        <mat-icon class="nav-link-icon nav-link-icon-custom" *ngIf="item.icon">{{item.icon}}</mat-icon>

        <div *ngIf="item.svgIcon" [ngClass]="item.isItemTitle ? 'svg-container-title' : 'svg-container'">
            <img class="nav-link-icon nav-link-icon-custom-svg"  [src]="item.svgIcon" alt="icon">
            <span class="nav-link-badge nav-link-badge-custom" *ngIf="item.badge" [translate]="item.badge.translate"
                [ngStyle]="{'background-color': item.badge.bg? item.badge.bg: 'accent','color': item.badge.fg}">
                {{item.badge.title}}
            </span>
        </div>
        

        <span *ngIf="item.isItemTitle || item.isItemBigTitle" class="nav-link-title" [ngClass]="item.isItemBigTitle ? 'nav-link-title-big-custom' : 'nav-link-title-custom'" [translate]="item.translate">{{item.title}}</span>

        
    </ng-template>


</ng-container>
</div>
<!-- Template Tutorial -->
<ng-template #content_tutorial_item>
    <div *ngIf="item.svgIcon" [ngClass]="">
        <img class="svg-color-orange"  [src]="item.svgIcon" alt="icon">
    </div>
    <p>{{item.tutorial}}</p>

</ng-template>
<!-- Template -->