import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Candidato } from './model/candidato.model';
import { CandidatoContacto } from './model/candidato-contacto.model';
import { CandidatoInfo } from './model/candidato-info.model';
import { CandidatoPerfil } from './model/candidato-perfil.model';
import { CandidatoRedes } from './model/candidato-redes.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import { All } from './model/all.model';
import { GlobalConstants } from 'app/common/global-constants';
import { AuthStateService } from 'app/pixcore/shared/auth-state.service';

@Injectable()
export class InformacionPersonalService implements Resolve<any>
{
    urlLocal = GlobalConstants.apiURL;
    urlProd = '';
   
    candidatoId: number;
    tiposDocumento: any[];
    generos: any[];
    orientacion: any[];
    orientacion_details: any;
    paises: any[];
    ciudades: any[];
    localidades: any[];
    gruposSanguineos: any[];
    rh: any[];
    nivelEducativo: any[];
    estadoCivil: any[];
    salarios: any [];
    experiencia: any[];
    categoriasLicencia: any[];
    checklist: any;


    candidatoAll: All[];
    candidatoDatos: Candidato[];
    candidatoContacto: CandidatoContacto[];
    candidatoInfo: CandidatoInfo[];
    candidatoRedes: CandidatoRedes[];
    candidatoPerfil: CandidatoPerfil[];

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _snackBar: MatSnackBar,
        private authState: AuthStateService,
    )
    {

    }

    /** Usables */
    /**
     * Mostrar la respuesta al usuario
     * @param mensaje
     */
    private openSnackBar(mensaje: string, clase: string): void {
        this._snackBar.open(mensaje, 'x', {
         duration: 4000,
         horizontalPosition: 'end',
         verticalPosition: 'top',
         panelClass: clase,
       });
      }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.candidatoId = this.authState.getCandidatoId();

        return new Promise((resolve, reject) => {

            Promise.all([
                
                // this.getCiudades(),
                // this.getLocalidades(),
                // this.getPaises(),
                // this.getTiposDocumento(),
                // this.getGeneros(),
                // this.getGruposSanguineos(),
                // this.getRh(),
                // this.getNivelEducativo(),
                // this.getEstadoCivil(),
                // this.getSalarios(),
                // this.getExperiencia(),
                this.getAllSelects(),
                this.getAllCandidatoInfoPersonal(this.candidatoId),
                // this.getAll(),
                // this.getProgresoCandidato(),
                // this.getCandidato(),
                // this.getCandidatoContacto(),
                // this.getCandidatoInfo(),
                // this.getCandidatoRedes(),
                // this.getCandidatoPerfil(),
            ]).then(
                () => {
                    resolve('');
                },
                reject
            );
        });
    }


    getAllCandidatoInfoPersonal(candidato_id: number): Promise<any>
    {
        if (candidato_id!=null){
            return new Promise((resolve) => {
                
                this._httpClient.get(`${this.urlLocal}candidato_personal_agrupada/${candidato_id}`)
                    .subscribe((response: any) => {
                        this.checklist = response.checklist;
                        this.candidatoAll = response.candidatoAll;
                        this.candidatoDatos = response.candidatoDatos;
                        this.candidatoContacto = response.candidatoContacto;
                        this.candidatoInfo = response.candidatoInfo;
                        this.candidatoRedes = response.candidatoRedes;
                        this.candidatoPerfil = response.candidatoPerfil;
                        resolve(response);
                    }, (err) => {
                        this.checklist = [];
                        this.candidatoAll = [];
                        this.candidatoDatos = [];
                        this.candidatoContacto = [];
                        this.candidatoInfo = [];
                        this.candidatoRedes = [];
                        this.candidatoPerfil = [];
                        
                        resolve(err);
                    });
            });
        }
        
    }


    /**
     * Get todos los selects de este servicio
     *
     * @returns {Promise<any>}
     */
    getAllSelects(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${this.urlLocal}selects_generales`)
                .subscribe((response: any) => {
                    this.tiposDocumento = response.tiposDocumento;
                    this.generos = response.generos;
                    this.orientacion = response.orientacion;
                    this.orientacion_details = response.orientacion_details;
                    this.ciudades = response.ciudades;
                    this.localidades = response.localidades;
                    this.gruposSanguineos = response.gruposSanguineos;
                    this.rh = response.rh;
                    this.nivelEducativo = response.nivelesEducativos;
                    this.estadoCivil = response.estadosCiviles;
                    this.salarios = response.salarios;
                    this.experiencia = response.experiencias;
                    this.categoriasLicencia = response.categoriasLicencia;
                    resolve(response);
                }, reject);
        });
    }

    getAllSelectsPublic(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${this.urlLocal}selects_generales_public`)
                .subscribe((response: any) => {
                    this.tiposDocumento = response.tiposDocumento;
                    this.generos = response.generos;
                    this.orientacion = response.orientacion;
                    this.orientacion_details = response.orientacion_details;
                    this.ciudades = response.ciudades;
                    this.localidades = response.localidades;
                    this.gruposSanguineos = response.gruposSanguineos;
                    this.rh = response.rh;
                    this.nivelEducativo = response.nivelesEducativos;
                    this.estadoCivil = response.estadosCiviles;
                    this.salarios = response.salarios;
                    this.experiencia = response.experiencias;
                    this.categoriasLicencia = response.categoriasLicencia;
                    resolve(response);
                }, reject);
        });
    }


    /**
     * Get tiposDocumento
     *
     * @returns {Promise<any>}
     */
    getTiposDocumento(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${this.urlLocal}config/registros/100/true`)
                .subscribe((response: any) => {
                    this.tiposDocumento = response;
                    resolve(response);
                }, reject);
        });
    }

    
    /**
     * Get paises
     *
     * @returns {Promise<any>}
     */
    getPaises(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${this.urlLocal}division/paises`)
                .subscribe((response: any) => {
                    this.paises = response;
                    resolve(response);
                }, reject);
        });
        
    }

    /**
     * Get ciudades
     *
     * @returns {Promise<any>}
     */
    getCiudades(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${this.urlLocal}division/ciudades_pais/47`)
                .subscribe((response: any) => {
                    this.ciudades = response;
                    resolve(response);
                }, reject);
        });
        
    }
    /**
     * Get localidades
     *
     * @returns {Promise<any>}
     */
    getLocalidades(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${this.urlLocal}division/localidades/524`)
                .subscribe((response: any) => {
                    this.localidades = response;
                    resolve(response);
                }, reject);
        });
        
    }

    /**
     * Get generos
     *
     * @returns {Promise<any>}
     */
    getGeneros(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${this.urlLocal}config/registros/110`)
                .subscribe((response: any) => {
                    this.generos = response;
                    resolve(response);
                }, reject);
        });
        
    }

    /**
     * Get grupos sanguineos
     *
     * @returns {Promise<any>}
     */
    getGruposSanguineos(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${this.urlLocal}config/registros/120`)
                .subscribe((response: any) => {
                    this.gruposSanguineos = response;
                    resolve(response);
                }, reject);
        });
        
    }

    /**
     * Get RH
     *
     * @returns {Promise<any>}
     */
    getRh(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${this.urlLocal}config/registros/130/true`)
                .subscribe((response: any) => {
                    this.rh = response;
                    resolve(response);
                }, reject);
        });
        
    }

    /**
     * Get Niveles educativos
     *
     * @returns {Promise<any>}
     */
    getNivelEducativo(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${this.urlLocal}config/registros/150`)
                .subscribe((response: any) => {
                    this.nivelEducativo = response;
                    resolve(response);
                }, reject);
        });
        
    }

    
    /**
     * Get Niveles educativos
     *
     * @returns {Promise<any>}
     */
    getEstadoCivil(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${this.urlLocal}config/registros/115`)
                .subscribe((response: any) => {
                    this.estadoCivil = response;
                    resolve(response);
                }, reject);
        });
        
    }

    /**
     * Get Salarios
     *
     * @returns {Promise<any>}
     */
    getSalarios(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${this.urlLocal}config/registros/230`)
                .subscribe((response: any) => {
                    this.salarios = response;
                    resolve(response);
                }, reject);
        });
        
    }

    /**
     * Get Niveles educativos
     *
     * @returns {Promise<any>}
     */
    getExperiencia(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${this.urlLocal}config/registros/140`)
                .subscribe((response: any) => {
                    this.experiencia = response;
                    resolve(response);
                }, reject);
        });
        
    }

    

    // Datos consumidos desde la API

    /**
     * Get Datos del candidato
     *
     * @returns {Promise<any>}
     */
    getCandidato(): Promise<any>
    {
        return new Promise((resolve) => {
            this._httpClient.get(`${this.urlLocal}candidato_datos/${this.candidatoId}`)
                .subscribe((response: any) => {
                    this.candidatoDatos = response;
                    resolve(response);
                }, (err) => {
                    this.candidatoDatos = [
                        {
                            primer_apellido: '',
                            segundo_apellido: '',
                            primer_nombre: '',
                            segundo_nombre: '',
                            numero_documento: '',
                            tipo_documento_id: null,
                            genero_id: null,
                            fecha_nacimiento: '',
                        }
                    ];
                    resolve(this.candidatoDatos);
                });
        });
        
    }

    /**
     * Get Datos de contacto del candidato
     *
     * @returns {Promise<any>}
     */
    getCandidatoContacto(): Promise<any>
    {
        return new Promise((resolve) => {
            this._httpClient.get(`${this.urlLocal}candidato_contactos/${this.candidatoId}`)
                .subscribe((response: any) => {
                    this.candidatoContacto = response;
                    resolve(response);
                }, (err) => {
                    this.candidatoContacto = [
                    ];
                    resolve(this.candidatoContacto);
                });
        });
        
    }

    /**
     * Get Datos de info del candidato
     *
     * @returns {Promise<any>}
     */
    getCandidatoInfo(): Promise<any>
    {
        return new Promise((resolve) => {
            this._httpClient.get(`${this.urlLocal}candidato_informacion/${this.candidatoId}`)
                .subscribe((response: any) => {
                    this.candidatoInfo = response;
                    resolve(response);
                });
        });
        
    }
    /**
     * Get Redes del candidato
     *
     * @returns {Promise<any>}
     */
    getCandidatoRedes(): Promise<any>
    {
        return new Promise((resolve) => {
            this._httpClient.get(`${this.urlLocal}candidato_redes/${this.candidatoId}`)
                .subscribe((response: any) => {
                    this.candidatoRedes = response;
                    resolve(response);
                }, (err) => {
                    this.candidatoRedes = [
                        {
                            facebook: '',
                            twitter: '',
                            linkedIn: '',
                            instagram: '',
                        }
                    ];
                    resolve(this.candidatoRedes);
                });
        });
        
    }
    /**
     * Get perfil del candidato
     *
     * @returns {Promise<any>}
     */
    getCandidatoPerfil(): Promise<any>
    {
        return new Promise((resolve) => {
            this._httpClient.get(`${this.urlLocal}candidato_perfil/${this.candidatoId}`)
                .subscribe((response: any) => {
                    this.candidatoPerfil = response;
                    resolve(response);
                }, (err) => {
                    this.candidatoPerfil = [
                        {
                            perfil: '',
                        }
                    ];
                    resolve(this.candidatoPerfil);
                });
        });
        
    }


    // Actualizacion de la informacion del candidato metodos PUT

    /**
     * Update datos personales del candidato
     * @param data
     * @returns {Promise<any>}
     */
    updateCandidato(data): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.put(`${this.urlLocal}candidato_datos`, data)
                .subscribe((response: any) => {                    
                    resolve(response);
                    if (!response.errors){       
                        this.getCandidato();                 
                        this.openSnackBar(response.message, 'snack-success');                        
                    } else {

                        if (response.errors.numero_documento) {

                            if (response.errors.numero_documento[0] === 'validation.unique') {
                                this.openSnackBar('El documento ya existe, prueba con otro', 'snack-error');
                            }else {
                                this.openSnackBar(response.message, 'snack-error');
                            }
                            
        
                        }else {
                            this.openSnackBar(response.message, 'snack-error');
                        }

                       
                    }
                    
                }, reject);
        });
        
    }

    /**
     * Update datos de contacto del candidato
     * @param data
     * @returns {Promise<any>}
     */
    updateCandidatoContacto(data): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.put(`${this.urlLocal}candidato_contactos`, data)
                .subscribe((response: any) => {
                    resolve(response);
                    if (!response.errors){
                        this.getCandidatoContacto();
                        this.openSnackBar(response.message, 'snack-success');                        
                    } else {
                        if (response.errors.correo) {

                            if (response.errors.correo[0] === 'validation.unique') {
                                this.openSnackBar('El correo ya existe, prueba con otro', 'snack-error');
                            }else {
                                this.openSnackBar(response.message, 'snack-error');
                            }
                            
        
                        }else {
                            this.openSnackBar(response.message, 'snack-error');
                        }
                       
                    }
                    
                   
                    resolve(response);
                }, reject);
        });
        
    }

    /**
     * Update redes sociales del candidato
     * @param data
     * @returns {Promise<any>}
     */
    updateCandidatoRedes(data): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.put(`${this.urlLocal}candidato_redes`, data)
                .subscribe((response: any) => {
                    resolve(response);
                    if (!response.errors){
                        this.getCandidatoRedes();
                        this.openSnackBar(response.message, 'snack-success');                        
                    } else {
                        this.openSnackBar(response.message, 'snack-error');
                    }
                    
                    resolve(response);
                }, reject);
        });
        
    }

    /**
     * Update info extra del candidato
     * @param data
     * @returns {Promise<any>}
     */
    updateCandidatoInfo(data): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.put(`${this.urlLocal}candidato_informacion`, data)
                .subscribe((response: any) => {
                    resolve(response);
                    if (!response.errors){
                        this.getCandidatoInfo();
                        this.openSnackBar(response.message, 'snack-success');                        
                    } else {
                        this.openSnackBar(response.message, 'snack-error');
                    }
                
                    resolve(response);
                }, reject);
        });
        
    }

    /**
     * Update perfil del candidato
     * @param Data
     * @returns {Promise<any>}
     */
    updateCandidatoPerfil(data): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.put(`${this.urlLocal}candidato_perfil`, data)
                .subscribe((response: any) => {
                    resolve(response);
                    if (!response.errors){
                        this.getCandidatoPerfil();
                        this.openSnackBar(response.message, 'snack-success');                        
                    } else {
                        this.openSnackBar(response.message, 'snack-error');
                    }
                    
                    resolve(response);
                }, reject);
        });
        
    }

    

    /**
     * Get Datos de info del candidato
     *
     * @returns {Promise<any>}
     */
    getAll(): Promise<any>
    {
        return new Promise((resolve) => {
            this._httpClient.get(`${this.urlLocal}candidato_all/${this.candidatoId}`)
                .subscribe((response: any) => {
                    this.candidatoAll = response;
                    resolve(response);
                }, (err) => {
                    this.candidatoAll = [
                        
                    ];
                    resolve(this.candidatoAll);
                });
        });
        
    }

    getProgresoCandidato(): Promise<any>
    {
        if (this.candidatoId !== null){
            return new Promise((resolve) => {
                this._httpClient.get(`${this.urlLocal}candidato_progress/${this.candidatoId}`)
                    .subscribe((response: any) => {
                        this.checklist = response;
                        resolve(response);
                    }, (err) => {
                        this.checklist = [];
                        resolve(this.checklist);
                    });
            });
        }
        
    }

    /**
     * 
     * @param data
     * @returns {Promise<any>}
     */
    subirVideo(data): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${this.urlLocal}candidato_video`, data)
                .subscribe((response: any) => {                    
                    resolve(response);
                    if (!response.errors){    
                        this.getCandidato();                    
                        this.openSnackBar(response.message, 'snack-success');                        
                    } else {
                        this.openSnackBar(response.message, 'snack-error');
                    }
                    
                }, reject);
        });
        
    }

    /**
     * 
     * @param data
     * @returns {Promise<any>}
     */
    subirHojaDeVida(data): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${this.urlLocal}candidato_hojadevida`, data)
                .subscribe((response: any) => {                    
                    resolve(response);
                    if (!response.errors){        
                        this.getCandidato();                
                        this.openSnackBar(response.message, 'snack-success');                        
                    } else {
                        this.openSnackBar(response.message, 'snack-error');
                    }
                    
                }, reject);
        });
        
    }

    subirPortafolio(data): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${this.urlLocal}candidato_portafolio`, data)
                .subscribe((response: any) => {
                    resolve(response);
                    if (!response.errors){      
                        this.getCandidato();                  
                        this.openSnackBar(response.message, 'snack-success');                       
                    } else {
                        this.openSnackBar(response.message, 'snack-error');
                    }
                    
                }, reject);
        });
        
    }

    updateTutorial(data): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.put(`${this.urlLocal}completo_tutorial_perfil/${this.candidatoId}`, data)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
        
    }

    subirAvatar(data): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${this.urlLocal}candidato_avatar`, data)
                .subscribe((response: any) => {
                    resolve(response);
                    if (!response.errors){
                        this.openSnackBar(response.message, 'snack-success');                        
                    } else {
                        this.openSnackBar(response.message, 'snack-error');
                    }
                    
                }, reject);
        });
        
    }

    subirPortada(data): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${this.urlLocal}candidato_portada`, data)
                .subscribe((response: any) => {
                    resolve(response);
                    if (!response.errors){
                        this.openSnackBar(response.message, 'snack-success');                        
                    } else {
                        this.openSnackBar(response.message, 'snack-error');
                    }
                    
                }, reject);
        });
        
    }


    

}
