export class Filtro {

    sector?: number[];
    empresa?: number[];
    ciudad?: number[];
    localidad_id?: number[];
    texto_busqueda?: string;

    constructor(filtro?) {
        this.sector = filtro.sector;
        this.empresa = filtro.empresa ;
        this.ciudad = filtro.ciudad;
        this.localidad_id = filtro.localidad_id || null;
        this.texto_busqueda = filtro.texto_busqueda || null

    }
}
