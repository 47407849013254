import { Component, OnInit, Inject, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthStateService } from 'app/pixcore/shared/auth-state.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { RegistroCandidatosComponent } from '../registro-candidatos.component';


@Component({
  selector: 'app-modal-vacants',
  templateUrl: './modal-vacants.component.html',
  styleUrls: ['./modal-vacants.component.scss']

})
export class ModalVacantsComponent implements OnInit, OnChanges {

  vacantes = []
  idNewVacant = 0;
  filterBy: string;
  aplicacionesTotales: number;
  aplicaciontesAplicadas: number
  aplicacionesPreseleccionados: number;
  aplicacionesFinalistas: number;
  aplicacionesContratados: number;
  aplicacionesDescartados: number;
  view = 1;
  searchTerm:string="";

  constructor(
    public dialogRef: MatDialogRef<ModalVacantsComponent>,
    private authState: AuthStateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _matSnackBar: MatSnackBar,
    private router: Router,
    private _registroCandidato: RegistroCandidatosComponent,
  ) {
  }
  
  ngOnChanges(): void {
    
  }
  ngOnInit(): void {
   
    this.vacantes = this.data.filter( x => { if (x.estado_id === 1 || x.estado_id === 2 || x.estado_id === 3) {return x; }} );
  }



  setNewVacant(oldvacant, newVacant) {
    this.actualizarEstado(this.data.aplicacion, 15);
    const data = {
      aplicacion: oldvacant,
      vacante: newVacant
    }
    
  }

  seleccionar(id){
    this._registroCandidato.setVacanteId(id);
    // this._registroCandidato.vacante_id = id;
    this.dialogRef.close(id);
  }

  onCerrarClick(): void {
    this.dialogRef.close();
  }

  actualizarEstado(aplicacion, estado_id): void {
    const data = [];
    data.push({ 'id': aplicacion.id, 'estado_id': estado_id, 'nota': null });
   
    this.onCerrarClick();
  }

  refresh() {
    window.location.reload();
  }

  actualizar_estados_aplicaciones(aplicaciones: any[]): void {
    this.aplicacionesTotales = aplicaciones.length;
    this.aplicaciontesAplicadas = aplicaciones.filter(aplicacion => aplicacion.estado_id === 8).length;
    this.aplicacionesPreseleccionados = aplicaciones.filter(aplicacion => aplicacion.estado_id === 10).length;
    this.aplicacionesFinalistas = aplicaciones.filter(aplicacion => aplicacion.estado_id === 11).length;
    this.aplicacionesContratados = aplicaciones.filter(aplicacion => aplicacion.estado_id === 14).length;
    this.aplicacionesDescartados = aplicaciones.filter(aplicacion => aplicacion.estado_id === 13).length;
  }

  private openSnackBar(mensaje: string, clase: string): void {
    this._matSnackBar.open(mensaje, 'x', {
      duration: 4000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: clase,
    });
  }

}
