<div fxLayout="column" fxLayoutAlign="center center" class="cont">
    <h1>Carga masiva de candidatos</h1>

    <div>
        <a href="assets/massive-loads/plantilla_masiva_candidatos.csv" download>Descargar plantilla base para carga masiva</a>
      </div>
      <br>
      <div *ngIf="!finished">
        <label for="fileInput">Selecciona un archivo CSV:</label>
        <input type="file" id="fileInput" (change)="onFileSelected($event.target.files)">
      </div>
      <div *ngIf="!sending && !finished">
        <button mat-raised-button [disabled]="!file_selected  ||  file_selected.length == 0" color="accent" class="submit-button" (click)="sendData()">Enviar</button>
      </div>
      <div *ngIf="sending">
        <br>
        <br>
        <mat-progress-bar value="{{ complete }}"></mat-progress-bar>
        <label>Enviando por favor espere y no cierre la ventana ...</label>
      </div>
      <div *ngIf="!sending && finished">
        <br>
        <br>
        <label>Envío finalizado, enviado(s) <strong>{{total}} registro(s)</strong>  con <strong>{{errors}}</strong> error(es)... </label>
        <a (click)="downloadFile(file_selected[0].name)">Descarga archivo de resultados</a>
      </div>
  
</div>
