import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDividerModule } from '@angular/material/divider';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgImageSliderModule } from 'ng-image-slider';
import { FuseSharedModule } from '@fuse/shared.module';

import { OkupaComponent } from './okupa.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { FooterFloatComponent } from './footer-float/footer-float.component';

const routes = [
    {
        path: 'okupa',
        component: OkupaComponent
    },
];

@NgModule({
    declarations: [
        OkupaComponent,    
        NavbarComponent,
        FooterComponent ,
        FooterFloatComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        NgImageSliderModule,
        MatDialogModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        FlexLayoutModule,
        FuseSharedModule,
        TranslateModule,
        MatRadioModule,
        MatSnackBarModule,
        NgxYoutubePlayerModule.forRoot(),
        MatToolbarModule,
        MatSidenavModule,
        // BrowserAnimationsModule,
        MatDividerModule,    
            
    ]
})
export class OkupaModule  {
}
