import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthStateService } from '../shared/auth-state.service';
import { Router } from '@angular/router';
import { GlobalConstants } from 'app/common/global-constants';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ModalLimiteVacantesComponent } from 'app/pixcore/modals/modal-limite-vacantes/modal-limite-vacantes.component';
import { resolve } from 'dns';

@Injectable({
  providedIn: 'root'
})
export class PlanCreacionVacantesGuard implements CanActivate, CanActivateChild  {

  limiteVacantesPlan1 = GlobalConstants.limiteVacantesPlan1;
  limiteVacantesPlan2 = GlobalConstants.limiteVacantesPlan2;
  limiteVacantesPlan4 = GlobalConstants.limiteVacantesPlan4;
  limiteVacantesPlan5 = GlobalConstants.limiteVacantesPlan5;
  limiteVacantesPlan6 = GlobalConstants.limiteVacantesPlan6;

  plan_empresa: any;
  urlLocal = GlobalConstants.apiURL;
  cantidadVacantes: number;
  planEmpresa: number;
  empresaId: number


  constructor(private _authService: AuthStateService, private router: Router, private _httpClient: HttpClient, public _matDialog: MatDialog ){

    
    
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
 
      return this.checkLimitPlan(next);

  }


  async checkLimitPlan(route: ActivatedRouteSnapshot): Promise<boolean> {
      this.empresaId = this._authService.getUserSession().empresa.id;
      this.planEmpresa = this._authService.getUserSession().empresa.plan_id;

      return true;

    //   await this.getCantidadVacantes(this.empresaId);


    //   switch (this.planEmpresa) {

    //       case 1: { 
    //          if (this.cantidadVacantes >= this.limiteVacantesPlan1) {
    //             this.modalLimiteVacantes();
    //             return false;
    //          }else {

    //             return true;
    //          }
    //       } 
    //       case 2: { 
    //         if (this.cantidadVacantes >= this.limiteVacantesPlan2) {
    //           this.modalLimiteVacantes();
    //           return false;
    //        }else {
    //           return true;
    //        }
    //       }
    //       case 3: { 

    //         return true;
    //       }
    //       case 4: {
    //         if (this.cantidadVacantes >= this.limiteVacantesPlan4) {
    //           this.modalLimiteVacantes();
    //           return false;
    //        }else {
    //           return true;
    //        }
    //       }   
    //       case 5: {
    //         if (this.cantidadVacantes >= this.limiteVacantesPlan5) {
    //           this.modalLimiteVacantes();
    //           return false;
    //        }else {
    //           return true;
    //        }
    //       }   
    //       case 6: {
    //         if (this.cantidadVacantes >= this.limiteVacantesPlan6) {
    //           this.modalLimiteVacantes();
    //           return false;
    //        }else {
    //           return true;
    //        }
    //       }   
    // }

    return true;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }

  getCantidadVacantes(empresaId: number): Promise<any> {
    return new Promise((resolve, reject) => {
        this._httpClient.get(`${this.urlLocal}conteo_vacantes/${empresaId}`)
            .subscribe((response: any) => {
                this.cantidadVacantes = response;
                resolve(response);
            }, reject);
    });
  }

  modalLimiteVacantes(): void {
    const modal = this._matDialog.open(ModalLimiteVacantesComponent);
}
}
