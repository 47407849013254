import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthStateService } from '../shared/auth-state.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ModalPlanVencidoComponent } from '../modals/modal-plan-vencido/modal-plan-vencido.component';

@Injectable({
  providedIn: 'root'
})
export class PlanFechaVigenciaGuard implements CanActivate, CanActivateChild  {

  
  fechaVencimientoPlan: string;
  planEmpresa: number;


  constructor(private _authService: AuthStateService, private router: Router, private _httpClient: HttpClient, public _matDialog: MatDialog ){

    
    
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
 
      return this.checkLimitPlan(next);

  }


  checkLimitPlan(route: ActivatedRouteSnapshot): boolean {
    
      this.fechaVencimientoPlan = this._authService.getUserSession().empresa.plan_vencimiento;
      this.planEmpresa = this._authService.getUserSession().empresa.plan_id

      const tiempoRestante = moment(new Date).diff(moment(this.fechaVencimientoPlan))

      const metadatosVerificadorPlan = {
        fechaVencimiento: this.fechaVencimientoPlan,
        planEmpresa: this.planEmpresa
      }

      if (tiempoRestante >= 0) {

        this.modalPlanVencido(metadatosVerificadorPlan);
        
        return false;
      } 
      return true;
      
    
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }



  modalPlanVencido(metadatosVerificadorPlan): void {
    const modal = this._matDialog.open(ModalPlanVencidoComponent, { 
      data: metadatosVerificadorPlan
  });
}
}
