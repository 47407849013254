<div id="empresas-lista" fxLayout="column" fxFlex>
  
    <mat-table #table [dataSource]="dataSource"
                matSort
                class="empresas-table"
                
            [@animateStagger]="{value:'50'}">
    

        <!-- Avatar Column -->
        <ng-container matColumnDef="avatar">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let empresa">

                <div *ngIf="empresa.tipo_perfil ===2">
                    <img *ngIf="empresa.url_logo" class="avatar" [src]="empresa.url_logo" alt="logo">
                    <img *ngIf="!empresa.url_logo" class="avatar" src="assets/images/logos/profile_default.svg" alt="profile">
                </div>

                <div *ngIf="empresa.tipo_perfil === 1" class="cursor-pointer">
                    <img *ngIf="empresa.url_logo" class="avatar" [src]="empresa.url_logo" (click)="verPerfilEmpresa(empresa)" alt="logo">
                    <img *ngIf="!empresa.url_logo" class="avatar" (click)="verPerfilEmpresa(empresa)" src="assets/images/logos/profile_default.svg" alt="profile">
                </div>

                

            </mat-cell>
        </ng-container>

        <!-- nombre Column -->
        <ng-container matColumnDef="nombre">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</mat-header-cell>
            <mat-cell *matCellDef="let empresa" >
                <p>{{empresa.nombre}}</p>
            </mat-cell>
        </ng-container>

         <!-- sector_economico_value Column -->
         <ng-container matColumnDef="sector_economico_value">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Sector económico</mat-header-cell>
            <mat-cell *matCellDef="let empresa" fxHide fxShow.gt-xs >
                <p class="text-truncate">{{empresa.sector_economico_value}}</p>
            </mat-cell>
        </ng-container>

        <!-- tipo_empresa_value Column -->
        <ng-container matColumnDef="tipo_empresa_value">
            <mat-header-cell *matHeaderCellDef fxHide mat-sort-header fxShow.gt-xs>Tipo</mat-header-cell>
            <mat-cell *matCellDef="let empresa" fxHide fxShow.gt-xs>
                <p >
                    {{empresa.tipo_empresa_value}}
                </p>
            </mat-cell>
        </ng-container>

        <!-- edad Column -->
        <ng-container matColumnDef="tamano">
            <mat-header-cell *matHeaderCellDef fxHide mat-sort-header>Tamaño</mat-header-cell>
            <mat-cell *matCellDef="let empresa" fxHide >
                <p >
                    {{empresa.tamano}}
                </p>
            </mat-cell>
        </ng-container>

        <!-- fundacion Column -->
        <ng-container matColumnDef="fundacion">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide>Fecha fundación</mat-header-cell>
            <mat-cell *matCellDef="let empresa" fxHide>
                <p class="category text-truncate">
                    {{empresa.fundacion | date:'dd-MM-yyyy'}}
                </p>
            </mat-cell>
        </ng-container>
    
      

        <!-- ciudad_value Column -->
        <ng-container matColumnDef="ciudad_value">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Ciudad</mat-header-cell>
            <mat-cell *matCellDef="let empresa" fxHide fxShow.gt-xs>
                <p  class="category text-truncate">
                    {{empresa.ciudad_value}}
                </p>
            </mat-cell>
        </ng-container>

        <!-- contacto Column -->
        <ng-container matColumnDef="contacto">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide>Contacto</mat-header-cell>
            <mat-cell *matCellDef="let empresa" fxHide>
                <div>
                    <span *ngIf="empresa.telefono" class="text-truncate" >{{empresa.telefono}}</span>
                    <span  class="text-truncate">{{empresa.direccion}}</span>
                </div>
            </mat-cell>
        </ng-container>
        
        <!-- Buttons Column -->
        <ng-container matColumnDef="buttons">
            <mat-header-cell *matHeaderCellDef>
            </mat-header-cell>
            <mat-cell *matCellDef="let empresa">
                <div fxFlex="row" fxLayoutAlign="end center">

                    
                    <button 
                    mat-icon-button #clickMenuTrigger="matMenuTrigger" 
                    [matMenuTriggerFor]="moreMenu" 
                    (click)="$event.stopPropagation();" aria-label="More">
                        <mat-icon class="secondary-text">more_vert</mat-icon>

                    </button>

                    <mat-menu #moreMenu="matMenu">
                        <button  *ngIf="empresa.tipo_perfil === 1" mat-menu-item aria-label="remove" (click)="verPerfilEmpresa(empresa)">
                            <mat-icon>work</mat-icon>
                            <span>Perfil</span>
                        </button>
                        <button mat-menu-item aria-label="remove" (click)="verVacantes(empresa)">
                            <mat-icon>list</mat-icon>
                            <span>Vacantes</span>
                        </button>


                    </mat-menu>
                </div>

            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let empresa; columns: displayedColumns;"
                class="contact"
                [@animate]="{value:'*',params:{y:'100%'}}"
                >

                <!-- (click)="clickMenuTrigger.openMenu()" -->
        </mat-row>
    </mat-table>

    <mat-paginator #paginator
    [length]="empresasLenght"
    [pageSize]="100"
    [hidePageSize]="true"
    [showFirstLastButtons]="true"
    (page)="pageChanged($event)"
    [pageSizeOptions]="[100]"
    [disabled]="loading"
   >
    </mat-paginator>
    <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center center" class="py-8">
        <mat-spinner strokeWidth="4" color="primary" diameter="30"> </mat-spinner>
    </div>
</div>

<div *ngIf="filterBy !== 'all' && empresasLenght <= 0" class="p-4">
    <div class="mb-4 text-center" fxLayout="row" fxLayoutAlign="center center">
        No hemos encontramos resultados para tu busqueda...
    </div>

    <div class="logo">
       
        <img src="assets/images/logos/logo.svg" alt="logo" >
       
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <button [disabled]="loadingAll" (click)="desFiltrar()" color="accent" mat-raised-button>
            <span *ngIf="!loadingAll" >Mostrar todo</span>
            <div *ngIf="loadingAll" fxLayout="row" fxLayoutAlign="center center" class="py-8">
                <mat-spinner strokeWidth="4" color="primary" diameter="30"> </mat-spinner>
            </div>
        </button>
    </div>
    
</div>
