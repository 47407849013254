<ng-container *ngIf="visible">

    <mat-progress-bar color="accent" [bufferValue]="bufferValue" [mode]="mode" [value]="value"></mat-progress-bar>
    

</ng-container>
<div *ngIf="visible" class="overlay">

    <div class="center">

        <!-- Splash screen -->
        <div class="logo">
            <img width="256" src="assets/images/logos/logo.gif" alt="logo">
        </div>
        <!-- Splash screen  -->

        <!-- Material Design Spinner -->
        <!-- <div class="spinner-wrapper">
            <div class="spinner">
                <div class="inner">
                    <div class="gap"></div>
                    <div class="left">
                        <div class="half-circle"></div>
                    </div>
                    <div class="right">
                        <div class="half-circle"></div>
                    </div>
                </div>
            </div>
        </div>-->
        <!-- / Material Design Spinner -->

    </div>

</div>

