import { Injectable } from '@angular/core';
import { Process } from './process.model';

@Injectable({
  providedIn: 'root'
})
export class ProcessService {
  private processes: Process[] = [
    { idx: 0, id: 221, title: 'Proceso #221', authorizedUsers: ['user1', 'user2'] },
    { idx: 1, id: 222, title: 'Proceso #222', authorizedUsers: ['user1', 'user3'] },
    // Más procesos aquí
  ];

  getProcessesForUser(username: string): Process[] {
    return this.processes.filter(process => process.authorizedUsers.includes(username.toString()));
  }
}