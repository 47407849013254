import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { FuseSharedModule } from '@fuse/shared.module';

import { AgmCoreModule } from '@agm/core';

import { DetalleVacanteComponent } from 'app/public/detalle-vacante/detalle-vacante.component';
import { DetalleVacanteService } from 'app/public/detalle-vacante/detalle-vacante.service';

import { GlobalConstants } from 'app/common/global-constants';

const routes: Routes = [
    {
        path     : 'detalle-vacante/:id_vacante',
        component: DetalleVacanteComponent,
        resolve  : {
            vacantes: DetalleVacanteService
        }
    }
];

@NgModule({
    declarations: [
        DetalleVacanteComponent,
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatRippleModule,
        MatTableModule,
        MatTabsModule,
        MatMenuModule,
        MatRadioModule,
        MatProgressBarModule,
        FuseSharedModule,
        MatSnackBarModule,
       
        AgmCoreModule.forRoot({
            apiKey: GlobalConstants.apiKeyGoogleMaps
        }),
    ],
    providers   : [
        DetalleVacanteService,
    ]
})
export class DetalleVacanteModule
{
}
