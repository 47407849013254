<div id="code" fxLayout="column" >
    <div class="logo">
        <a href="https://okempleos.com" target="_blank">
            <img src="{{ logoPath }}" alt="logo" style="margin-left: 18%;">
        </a>
    </div>

   

    <div id="code-form-wrapper" fxLayout="column" fxLayoutAlign="top center">

        <div id="container">
            
            <div class="left-side" #sideContainer>
                <div id="App" #dataContainer>
                </div>
            </div>
            <div class="logo-mobile" >
                <a href="https://okempleos.com" target="_blank" >
                    <img src="{{ logoPath }}" alt="logo">
                </a>
            </div>
            <div class="separator"></div>
            <div class="rigth-side" fxLayout="column">
                <!-- <div id="code-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"> -->

                        <div class="title">{{'CODE_FORM.TITLE'  | translate}}</div>

                        <div class="subtitle">
                            <p>
                                {{'CODE_FORM.SUBTITLE1' | translate}} <a class="link">{{currentEmail}}.</a> {{'CODE_FORM.SUBTITLE2' | translate}}
                            </p>
                        </div>

                        <form name="codeForm" [formGroup]="codeForm" (submit)="OnSubmit()" novalidate>

                            <mat-form-field appearance="outline">
                                <mat-label>{{'CODE_FORM.INPUT_CODE' | translate}}</mat-label>
                                <input (keyup)="updateInput($event)" maxlength="6" matInput [class.spinner]="loading" formControlName="codigo_verificacion" required />
                                <!-- <mat-icon matSuffix class="secondary-text">payment</mat-icon> -->
                                <mat-error *ngIf="codeForm.get('codigo_verificacion').hasError('required')">{{'CODE_FORM.WRONG_CODE' | translate}}</mat-error>
                            </mat-form-field>

                            <!-- <button mat-raised-button color="accent" class="submit-button" [class.spinner]="loading" aria-label="CREATE AN ACCOUNT"
                                    [disabled]="codeForm.invalid || loading">
                                {{'CODE_FORM.BUTTON_ACCEPT' | translate}}
                            </button> -->

                        </form>

                        <div class="code" fxLayout="column" fxLayoutAlign="left">
                            <a (click)="resendEmail($event)" >{{'CODE_FORM.RESEND' | translate}}</a>
                        </div>

                <!-- </div> -->
            </div>

        </div>
        <div class="footer-side"  #footerContainer fxLayout="column"></div>
    </div>

</div>
