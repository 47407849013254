<div fxLayout="column" class="cont_general" style="width: 100%">

    <!-- <div fxHide.gt-sm class="boton-flotante-container">
        <button mat-raised-button color="accent" class="boton-flotante" 
        (click)="toggleSidebar('contacts-main-sidebar')">
        <mat-icon class="mr-8">filter_list</mat-icon>
        <span>Filtros</span>
    </button>
    </div> -->

    <!-- ANV BAR -->
    <div class="cont_nav--mobiles" fxFlex="100" *ngIf="navMobile === true">
        <div class="cont_itmes" fxFlex="70" fxLayout="column">
            <span class="close" (click)="validMenu()">X</span>
            <div class="items_group">
                <button class="btn btn_blue" [ngStyle]="{'background': getColor()}" [routerLink]="'..'">{{ minisitio.propiedades[0].inicio_texto | uppercase }}</button>
                <button class="btn btn_yellow" [routerLink]="'./'">{{ minisitio.propiedades[0].empleo_text | uppercase }}</button>
            </div>
            <div class="items_group">
                <button class="btn btn_orange" [routerLink]="'/login'">INICIAR SESION</button>
                <button class="btn btn_grey" [routerLink]="'/registro'">REGISTRARME</button>
            </div>

            <div class="items_group" fxLayout="row" fxLayoutAlign="center center">
                <a *ngIf="minisitio.empresa.facebook" href="{{minisitio.empresa.facebook}}" target="_blank"><i  class="fab fa-facebook mr-32" [ngStyle]="{'color': getColor()}"></i></a>
                <a *ngIf="minisitio.empresa.instagram" href="{{minisitio.empresa.instagram}}" target="_blank"><i  class="fab fa-instagram mr-32" [ngStyle]="{'color': getColor()}"></i></a>
                <a *ngIf="minisitio.empresa.linkedIn" href="{{minisitio.empresa.linkedIn}}" target="_blank"><i  class="fab fa-linkedin mr-32" [ngStyle]="{'color': getColor()}"></i></a>
                <a *ngIf="minisitio.propiedades[0].youtube" href="{{minisitio.propiedades[0].youtube}}" target="_blank"><i class="fab fa-youtube mr-32" [ngStyle]="{'color': getColor()}"></i></a>
                <a *ngIf="minisitio.empresa.twitter" href="{{minisitio.empresa.twitter}}" target="_blank"><i  class="fab fa-twitter mr-32" [ngStyle]="{'color': getColor()}"></i></a>
                <a *ngIf="minisitio.propiedades[0].pinterest" href="{{minisitio.propiedades[0].pinterest}}" target="_blank"><i  class="fab fa-pinterest mr-32" [ngStyle]="{'color': getColor()}"></i></a>
                <a *ngIf="minisitio.propiedades[0].whatsapp" href="{{minisitio.propiedades[0].whatsapp}}" target="_blank"><i  class="fab fa-whatsapp mr-32" [ngStyle]="{'color': getColor()}"></i></a>
          </div>
        </div>
    </div>

    <div fxFlex="100" class="cont_nav--comp">
        <nav fxFlex="100" class="navbar nav_desktop">
            <div class="cont_nav" fxLayoutAlign="space-between center">
                <div fxFlex="35" fxFlex.gt-sm="50" class="item_nav item_nav--left" fxLayout="row" fxLayoutAlign="start center">
                    <img class="ok_log" [routerLink]="'..'" src="{{ minisitio.empresa ? minisitio.empresa.url_logo : null }}" alt="" style="cursor:pointer;">
                    <button class="btn_nav btn-navy" [ngStyle]="{'background': getColor()}" [routerLink]="'..'">{{ minisitio.propiedades[0].inicio_texto | uppercase }}</button>
                    <button class="btn_nav btn-clean" [routerLink]="'./'">{{ minisitio.propiedades[0].empleo_text | uppercase }}</button>
                </div>
                <div fxFlex="50" fxFlex.gt-sm="50" class="item_nav" fxLayout="row" fxLayoutAlign="end center">
                    <!-- <a class="btn_nav btn-blog btn-blog--right" href="https://okempleos.com/contactenos/">CONTACTO</a> -->
                    <button class="btn_nav btn-orange" [routerLink]="'/login'">INICIAR SESION</button>
                    <ul class="ul_second" fxLayout="row" fxLayoutAlign="center center">
                        <li class="li_second">
                            <button class="btn_nav btn-wiht--two" [routerLink]="'/registro'">REGISTRARME</button>
                            <!-- <ul class="items_otions">
                                <li><a [routerLink]="'/registro'" [queryParams]="{registro: 'empresa'}">Empresa</a></li>
                                <li><a [routerLink]="'/registro'" [queryParams]="{registro: 'candidato'}">Candidato</a>
                                </li>
                            </ul> -->
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <nav class="navbar nav_movile">
            <div class="cont_nav" fxLayoutAlign="space-between center">
                <div fxFlex="100" class="item_nav" fxLayoutAlign="space-between center">
                    <img class="ok_log item_nav--left" src="{{ minisitio.empresa ? minisitio.empresa.url_logo : null }}" alt=""
                        (click)="back()">
                    <img class="hamburger" (click)="validMenu()" src="../../../assets/images/logos/hamburger_icon.png"
                        alt="">
                </div>
            </div>
        </nav>
    </div>
    <!-- END NAV BAR --> 
        <!-- APP TITLE -->
        <!-- <div fxLayout="row" fxLayoutAlign="start center">

            <div fxHide fxShow.gt-xs class="logo" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon mr-16"
                          [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">list
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Vacantes disponibles
                </span>
            </div>

        </div> -->
        <!-- / APP TITLE -->





    <!-- / HEADER -->

    <!-- SELECTED BAR -->
    <!-- / SELECTED BAR -->

    <!-- CONTENT -->
    <main class="cont_general" style="padding-left: 10%; padding-right: 10%; padding-top: 10%;">
                <!-- SEARCH -->
                <!-- <div class="ml-sm-32" fxLayout="row" fxLayoutAlign="center center" fxFlex>

                    <div class="search-wrapper">
        
                        <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                            <button mat-icon-button (click)="onEnterSearch()">
                                <mat-icon >search</mat-icon>
                            </button>
                            <input [formControl]="searchInput" (keyup.enter)="onEnterSearch()" placeholder="Buscador vacantes">
                        </div>
        
                    </div>
        
                </div> -->
                <!-- / SEARCH -->

        <!-- SIDEBAR -->
        <!-- <fuse-sidebar class="sidebar" name="contacts-main-sidebar" position="left" lockedOpen="gt-sm"> -->

            <!-- SIDEBAR CONTENT -->
            <!-- <div class="content" fusePerfectScrollbar>
                <lista-vacantes-side-bar [@animate]="{value:'*'}"></lista-vacantes-side-bar>
            </div> -->
            <!-- / SIDEBAR CONTENT -->

        <!-- </fuse-sidebar> -->
        <!-- / SIDEBAR -->

        <!-- CENTER -->
        <div class="center" fusePerfectScrollbar #listScrollVacantes>

            <!-- CONTENT -->
            <div class="main_cont">

                <h1 fxLayoutAlign="center center" class="title" style="color:#4B4B4B;">Ofertas laborales {{minisitio.empresa.nombre}}</h1>

                <vacantes-lista (scrollToTop)="scrollToTop()" class="lista"></vacantes-lista>
                <!-- BLOCK SOCIAL MEDIA -->
                <div fxLayout="column" fxLayoutAlign="center center" class="section section__follow">
                    <img class="ok_log" src="{{ minisitio.empresa ? minisitio.empresa.url_logo : null }}"  style="max-width: 30%;" alt="logo">
                    <h1 class="title follow_title"></h1>
                    <div fxLayout="row wrap" fxLayoutAlign="center center" class="follow_items" style="margin-top: 54px;">

                    <a *ngIf="minisitio.empresa.facebook" href="{{minisitio.empresa.facebook}}" target="_blank"><i  class="fab fa-facebook mr-32" [ngStyle]="{'color': getColor()}"></i></a>
                    <a *ngIf="minisitio.empresa.instagram" href="{{minisitio.empresa.instagram}}" target="_blank"><i  class="fab fa-instagram mr-32" [ngStyle]="{'color': getColor()}"></i></a>
                    <a *ngIf="minisitio.empresa.linkedIn" href="{{minisitio.empresa.linkedIn}}" target="_blank"><i  class="fab fa-linkedin mr-32" [ngStyle]="{'color': getColor()}"></i></a>
                    <a *ngIf="minisitio.propiedades[0].youtube" href="{{minisitio.propiedades[0].youtube}}" target="_blank"><i class="fab fa-youtube mr-32" [ngStyle]="{'color': getColor()}"></i></a>
                    <a *ngIf="minisitio.empresa.twitter" href="{{minisitio.empresa.twitter}}" target="_blank"><i  class="fab fa-twitter mr-32" [ngStyle]="{'color': getColor()}"></i></a>
                    <a *ngIf="minisitio.propiedades[0].pinterest" href="{{minisitio.propiedades[0].pinterest}}" target="_blank"><i  class="fab fa-pinterest mr-32" [ngStyle]="{'color': getColor()}"></i></a>
                    <a *ngIf="minisitio.propiedades[0].whatsapp" href="{{minisitio.propiedades[0].whatsapp}}" target="_blank"><i  class="fab fa-whatsapp mr-32" [ngStyle]="{'color': getColor()}"></i></a>

                        

                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="center center" class="follow_items" style="margin-top: 54px;">
                        <a [routerLink]="'..'"[ngStyle]="{'color': getColor()}">{{ minisitio.propiedades[0].inicio_texto }}</a>
                        <a [routerLink]="'./'" [ngStyle]="{'color': getColor()}">{{ minisitio.propiedades[0].empleo_text }}</a>
                        <a [routerLink]="'/registro'" [ngStyle]="{'color': getColor()}">Crear cuenta</a>
                        <a *ngIf="minisitio.empresa.web"  href="https://{{minisitio.empresa.web}}" [ngStyle]="{'color': getColor()}" target="_blank">Sitio web oficial</a>
                    </div>    
                </div>
                <!-- BLOCK OK -->
                <div fxLayout="column" fxLayoutAlign="center center" class="section section__follow_us">
                    <img class="ok_log" src="{{ logoPath }}" alt="logo">
                    <div fxLayout="row wrap" fxLayoutAlign="center center" class="follow_items" style="margin-top: 54px; text-align: center;">
                        <a href="https://okempleos.com/politicas-de-privacidad/" style="color: #4B4B4B;">Políticas de privacidad</a>
                        <a href="https://okempleos.com/politicas-de-privacidad/" style="color: #4B4B4B;">Tratamiento de datos personales</a>
                        <a href="https://app.okempleos.com/terminos" style="color: #4B4B4B;">Términos y condiciones</a>
                    </div>    
                </div>
            </div>
            <!-- / CONTENT -->


        </div>
        <!-- / CENTER -->


    </main>
    <!-- / CONTENT-->

    
    <div class="cont_footer" #contFoo>  
        <footer fxLayout="row wrap" fxLayoutAlign="center center">
            <div class="col-md-4" style="text-align-last: center;">
                <a mat-button href="https://okempleos.com/wp-content/uploads/2022/05/RESOLUCiON-0094-2.pdf" target="_blank" class="text-small text-truncate">
                    <img src="/assets/images/home/SPE.png" style="width: 5%;"  alt="Servicio Público de Empleo">
                    <img src="/assets/images/home/MinTrabajo.png" style="width: 10%; margin: 10px; vertical-align: middle;" alt="Ministerio del Trabajo">
                    Vinculado a la red de prestadores del Servicio Público de Empleo.
                    Autorizado por la 
                    Unidad Administrativa Especial del Servicio Público de Empleo según 
                    Resolución No. 0094 del 8 de Marzo del 2021.
                </a>
            </div>
        </footer>
    </div>

</div>

<!-- ADD CONTACT BUTTON -->
<!-- <button mat-fab class="accent" id="add-contact-button" (click)="newContact()" aria-label="add contact"
        [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}">
    <mat-icon>person_add</mat-icon>
</button> -->
<!-- / ADD CONTACT BUTTON -->
