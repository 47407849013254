<!-- <meta property="og:title" content="">
<meta property="og:url" content=""> -->
<meta property="og:title" content="{{vacante.cargo}} en {{vacante.empresa.nombre}} - {{vacante.ciudad_value}}">
<meta property="og:description" content={{vacante.descripcion}}>
<meta property="og:locale" content="es_CO">
<meta property="og:image" content="https://app.okempleos.com/assets/images/banners/redes.jpg">
<link rel="image_src" href="https://app.okempleos.com/assets/images/banners/redes.jpg"/> 
<meta property="og:type" content="website">
<meta name="twitter:card" content="summary">
<meta name="twitter:description" content={{vacante.descripcion}}>
<meta name="twitter:title" content="{{vacante.cargo}} en {{vacante.empresa.nombre}} - {{vacante.ciudad_value}}">
<!-- <meta property="twitter:site" content="">
<meta property="twitter:title" content=""> -->
<div id="detalle-vacante" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" (click)="verListadoVacantes()">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <!-- <div class="product-image mr-8 mr-sm-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    <img [src]="vacante.empresa.avatar_empresa" alt="logo">
                </div> -->

                <div fxLayout="column" fxLayoutAlign="start start"
                     [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2">
                        <h1>{{vacante.cargo}}</h1>
                    </div>
                    <div class="subtitle secondary-text">
                        <span>Detalle de vacante</span>  / <span> {{vacante.historial_estados[0].estado}}</span> 
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->
            <button mat-raised-button 
                    class="save-product-button"
                    (click)="aplicarVacante()"
                    size="large">
                    <mat-icon>assignment_turned_in</mat-icon><span class="text-bold"> Aplicar</span>
            </button>

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                    <mat-tab-group fxLayout="column" fxFlex>

                        <mat-tab label="Vacante">

                            <div fxFlex="100">
                                <div class="tab-content p-24" fusePerfectScrollbar>
                                    <div class="about-content-vacante" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="60">

                                        <div class="profile-box info-box descripcion mat-card mat-elevation-z4 p-12" fxLayout="column">
                                
                                            <header fxLayout="row" fxLayoutAlign="start center">
                                                <span class="mr-8"><i class="fas fa-user icono"></i></span>
                                                <div class="title">
                                                    <span class="title_descripcion">Descripción</span>
                                                </div>
                                            </header>
                                
                                            <div  class="content">
                                                
                                                <div class="info-line">
                                                    <div class="info" innerHTML="{{vacante.descripcion}}"></div>
                                                </div>
                                
                                            
                                            </div>
                                
                                        </div>
                                
                                        <div class="profile-box info-box informacion mat-card mat-elevation-z4 p-12" fxLayout="column">
                                
                                            <header fxLayout="row" fxLayoutAlign="start center">
                                                <span class="mr-8"><i class="fas fa-info-circle icono"></i></span>
                                                <div class="title">
                                                    <span class="title_informacion">Información</span>
                                                </div>
                                            </header>
                                
                                            <div  class="content">
                                                <div class="info-line">
                                                    <div class="title">Cargo</div>
                                                    <div class="info">{{vacante.cargo}}</div>
                                                </div>
                                
                                                <div class="info-line">
                                                    <div class="title">Tipo de contrato</div>
                                                    <div class="info">{{vacante.tipo_contrato_value}}</div>
                                                </div>
                                
                                                <div class="info-line">
                                                    <div class="title">Sector económico</div>
                                                    <div class="info">{{vacante.sector_economico_value}}</div>
                                                </div>
                                
                                                <div class="info-line">
                                                    <div class="title">Tipo de jornada</div>
                                                    <div class="info">{{vacante.tipo_jornada_value}}</div>
                                                </div>
                                
                                                <div class="info-line">
                                                    <div class="title">Salario</div>
                                                    <div class="info">{{vacante.salario_value}}</div>
                                                </div>
                                
                                                <div class="info-line">
                                                    <div class="title">Área de la vacante</div>
                                                    <div class="info">{{vacante.area_vacante_value}}</div>
                                                </div>
                                
                                                <div class="info-line">
                                                    <div class="title">Número de vacantes</div>
                                                    <div class="info">{{vacante.numero_vacantes}}</div>
                                                </div>
                                
                                            </div>
                                
                                        </div>
                                
                                        <div class="profile-box info-box localizacion mat-card mat-elevation-z4 p-12" fxLayout="column">
                                
                                            <header fxLayout="row" fxLayoutAlign="start center">
                                                <span  class="mr-8"><i class="fas fa-map-marker-alt icono"></i></span>
                                
                                                <div class="title">
                                                    <span class="title_localizacion">Localización</span>
                                                </div>
                                            </header>
                                
                                            <div class="content">
                                                
                                                <div class="info-line">
                                                    <div class="title">Ciudad - País</div>
                                                    <div class="info location" fxLayout="row" fxLayoutAlign="start center">
                                                        <span *ngIf="vacante.localidad_value">{{vacante.localidad_value}} -  </span>
                                                        <span>{{vacante.ciudad_value}} - {{vacante.pais_value}}</span>
                                                        <mat-icon class="s-16 ml-4">location_on</mat-icon>
                                                    </div>
                                                </div>
                                
                                                <div class="info-line">
                                                    <div class="title">Dirección</div>
                                                    <div class="info">{{vacante.direccion}}</div>
                                                </div>
                                
                                                <div fxHide class="info-line" *ngIf="vacante?.latitud"  fxLayout="column" fxLayoutAlign="start">
                                                    <div class="title">Ubicación</div>
                                                    <div fxLayout="column" fxLayoutAlign="start"
                                                    fxFlex="1 0 auto" >
                                                        <div fxLayout="row" fxLayoutAlign="start"  fxFlex="1 0 auto">
                                                            <div fxFlex="100">
                                                                <agm-map [scrollwheel]="scroll" [latitude]="lat" [longitude]="lng" [zoom]="zoom" [mapTypeId]="mapTypeId">
                                                                    <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="false">
                                                                        <agm-info-window>
                                                                            Vacante
                                                                        </agm-info-window>
                                                                    </agm-marker>
                                                                </agm-map>
                                                            </div>        
                                                        </div>
                                                        <div fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">  
                                                            <form fxLayout="row" fxLayoutAlign="start center"  fxFlex="1 0 auto">
                                                                <p class="mr-4">Tipo de mapa:</p>
                                                                <mat-radio-group  [(ngModel)]="mapTypeId" name="mapTypeId">
                                                                    <mat-radio-button  class="pl-4" value="roadmap">Normal</mat-radio-button>
                                                                    <mat-radio-button  class="pl-4" value="satellite">Satelite</mat-radio-button>
                                                                    <mat-radio-button  class="pl-4" value="hybrid">Hibrido</mat-radio-button>
                                                                    <mat-radio-button  class="pl-4" value="terrain">Terreno</mat-radio-button>
                                                                </mat-radio-group>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div class="about-sidebar-vacante" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="40">
                                
                                        <div class="profile-box info-box requisitos mat-card mat-elevation-z4 p-12 mat-card mat-elevation-z4 p-12" fxLayout="column">
                                
                                            <header fxLayout="row" fxLayoutAlign="start center">
                                                <span  class="mr-8"><i class="fas fa-check-square icono"></i></span>
                                                    <div class="title">
                                                        <div class="title_requisitos">Requisitos</div>
                                                    </div>
                                            </header>
                                
                                            <div class="content">
                                
                                                <div class="info-line">
                                                    <div class="title">Nivel educativo</div>
                                                    <div class="info">{{vacante.nivel_educativo_value}}</div>
                                                </div>
                                
                                                <div class="info-line" *ngIf="vacante.profesion_value">
                                                    <div class="title">Profesión</div>
                                                    <div class="info">{{vacante.profesion_value}}</div>
                                                </div>
                                
                                                <div class="info-line">
                                                    <div class="title">Experiencia</div>
                                                    <div class="info">{{vacante.experiencia_value}}</div>
                                                </div>
                                                <div class="info-line">
                                                    <div class="title">Genero</div>
                                                    <div class="info">{{vacante.genero_value}}</div>
                                                </div>
                                
                                                <div class="info-line">
                                                    <div class="title">Fecha límite</div>
                                                    <div class="info">{{vacante.fecha_limite | date:'dd/MM/yyyy' }}</div>
                                                </div>

                                                <div class="info-line">
                                                    <div class="title">Fecha inicio</div>
                                                    <div class="info">{{vacante.fecha_inicio | date:'dd/MM/yyyy' }}</div>
                                                </div>
                                
                                                <div class="info-line">
                                                    <div class="title">Disponibilidad de viajar</div>
                                                    <div class="info">{{vacante.disponibilidad_viaje_value}}</div>
                                                </div>
                                
                                                <div class="info-line">
                                                    <div class="title">Disponibilidad de cambio de residencia</div>
                                                    <div class="info">{{vacante.disponibilidad_cambio_vivienda_value}}</div>
                                                </div>
                                                
                                            </div>
                                
                                        </div>
                                
                                    </div>
                                </div>

                            </div>

                        </mat-tab>
                    

                    </mat-tab-group>

               

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>
