
<div mat-dialog-content fxLayout="column" fxLayoutAlign="center center">

    <div class="logo">
        <img src="assets/images/logos/logo.svg" alt="logo">
    </div>

    <div fxLayout="row" fxLayoutAlign="center center">
        <span class="texto h2">Ya completaste el número máximo de vacantes, para publicar más, te invitamos a adquirir alguno de nuestros planes.</span>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="pt-24">
        <button mat-raised-button color="primary" (click)="onCerrarClick()">Cerrar</button>
      </div>
    
</div>

