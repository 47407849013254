import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';

import { FuseSharedModule } from '@fuse/shared.module';
import {MatSelectModule} from '@angular/material/select';

import { RegistroCandidatosComponent } from './registro-candidatos.component';

import { TranslateModule } from '@ngx-translate/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';

import { ModalRegistroComponent } from './modal/registro-exitoso.component';

import { InformacionPersonalService } from 'app/main/candidato/informacion-personal/informacion-personal.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatMomentDateModule, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { ModalVacantsComponent } from './modal-vacants/modal-vacants.component';
import { ModalMassiveLoadsComponent } from './modal-massive-loads/modal-massive-loads.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

export const MY_FORMATS = {
    parse: {
      dateInput: 'DD/MM/YYYY',
    },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMM YYYY',
    },
  };
const routes = [
    {
        path     : 'registro-candidatos',
        component: RegistroCandidatosComponent
    }
];

@NgModule({
    declarations: [
        RegistroCandidatosComponent,
        ModalRegistroComponent,
        ModalVacantsComponent,
        ModalMassiveLoadsComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatDialogModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        MatSelectModule,
        MatMomentDateModule,
        FuseSharedModule,
        TranslateModule,

        MatRadioModule,
        MatSnackBarModule,
        MatDatepickerModule,
        CommonModule,
        MatTooltipModule,
        MatProgressBarModule,
        MatAutocompleteModule
        
    ],
    providers : [
        InformacionPersonalService,
        RegistroCandidatosComponent,
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
          },
      
          {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
    ]
})
export class RegistroCandidatosModule
{
}
