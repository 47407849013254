import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { MatSnackBar } from '@angular/material/snack-bar';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalConstants } from 'app/common/global-constants';

import { Router } from '@angular/router';
import { InterceptorSkipHeader } from 'app/pixcore/shared/auth.interceptor';
import { SEOService } from 'app/seo-service.service';
import { LogoService } from 'app/logo.service';

@Component({
    selector     : 'forgot-password',
    templateUrl  : './forgot-password.component.html',
    styleUrls    : ['./forgot-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ForgotPasswordComponent implements OnInit
{
    forgotPasswordForm: FormGroup;
    loading = false;
    logoPath: String;


    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _snackBar: MatSnackBar,
        private httpClient: HttpClient,
        private router: Router,
        private seoService: SEOService,
        private logoService: LogoService,
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this.logoPath = this.logoService.getLogoPath();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        /* SEoService */
        this.seoService.setPageTitle('Recuperar contraseña | Ok empleos');
        this.seoService.setDescription('Restablece tu contraseña de forma fácil y rápida.');
        this.seoService.createLinkForCanonicalURL();
        /* SEoService */
        this.forgotPasswordForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }

    OnSubmit(): void {
        this.loading = true;
        const formData = this.forgotPasswordForm.getRawValue();
        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
        this.httpClient.post(`${GlobalConstants.apiURL}reset_password_without_token`, formData, {headers:headers}).subscribe(
            (result: any) => {
                if (!result.errors) {
                    
        
                this.openSnackBar("¡Enlace de restablecimiento de contraseña enviado a tu correo!", 'snack-success');
                this.sleep(1500).then(() => {
                    this.loading = false; 
                    this.router.navigate(['/login']);
                });
                }else {
                    this.openSnackBar(result.message, 'snack-error');
                    this.loading = false;
                }
                
            }
        );
    }

    private sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

    /**
     * Mostrar la respuesta al usuario
     * @param mensaje
     */
    private openSnackBar(mensaje: string, clase: string): void {
        this._snackBar.open(mensaje, 'x', {
         duration: 4000,
         horizontalPosition: 'end',
         verticalPosition: 'top',
         panelClass: clase,
       });
      }
}
