
<div mat-dialog-content fxLayout="column" fxLayoutAlign="center center">

    <div class="logo">
        <img src="assets/images/logos/logo.svg" alt="logo">
    </div>

    <div fxLayout="row" fxLayoutAlign="center center">
        <span *ngIf="informacion.planEmpresa === 1" class="texto h2">Tu plan de prueba ya esta vencido, te invitamos a adquirir una subscripción.</span>
        <span *ngIf="informacion.planEmpresa !== 1"class="texto h2">Tu plan ya esta vencido, te invitamos a renovar la subscripción.</span>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center" class="py-24">
        <span class="fecha-vencimiento h3">Vencimiento: {{informacion.fechaVencimiento | date:'dd/MM/yyyy'}}</span>
        <span class="plan h3">Plan: {{nombrePlan}}</span>
    </div>


    <div fxLayout="row" fxLayoutAlign="center center">
        <button class="mr-4" mat-raised-button color="accent" (click)="onCerrarClick(true)">Ver planes</button>
        <button class="ml-4" mat-raised-button color="primary" (click)="onCerrarClick(false)">Cerrar</button>
      </div>
    
</div>

