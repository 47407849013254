import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { ListaEmpresasService } from './lista-empresas.service';
import { DateAdapter } from '@angular/material/core';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';

@Component({
    selector     : 'lista-empresas',
    templateUrl  : './lista-empresas.component.html',
    styleUrls    : ['./lista-empresas.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ListaEmpresasComponent implements OnInit, OnDestroy
{

    @ViewChild('listScroll', { read : FusePerfectScrollbarDirective, static: true })
    listScroll: FusePerfectScrollbarDirective;

    dialogRef: any;
    hasSelectedVacantes: boolean;
    searchInput: FormControl;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ListaEmpresasService} _listaEmpresasService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _listaEmpresasService: ListaEmpresasService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseConfigService: FuseConfigService,
        private _matDialog: MatDialog,
        private dateAdapter: DateAdapter<any>,
    )
    {
        // Set the defaults
        this.searchInput = new FormControl('');

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        
        this.dateAdapter.setLocale('es');
        
        this.searchInput.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(200),
                distinctUntilChanged()
            )
            .subscribe(searchText => {
                this._listaEmpresasService.onSearchTextEmpresasPublicasChanged.next(searchText);
            });

        this._listaEmpresasService.onFilterEmpresasPublicasChanged
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(filter => {

            if (filter === 'all' || filter === '') {
                this.searchInput.setValue(null);
            }
        });
    }


    scrollToTop(): void {
        this.listScroll.scrollToTop();
    }

    onEnterSearch(): void {
       
        this._listaEmpresasService.onSearchTextEmpresasPublicasEnter.next(true);
        
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Reset the search
        this._listaEmpresasService.onSearchTextEmpresasPublicasChanged.next('');

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    // /**
    //  * New contact
    //  */
    // newContact(): void
    // {
    //     this.dialogRef = this._matDialog.open(ContactsContactFormDialogComponent, {
    //         panelClass: 'contact-form-dialog',
    //         data      : {
    //             action: 'new'
    //         }
    //     });

    //     this.dialogRef.afterClosed()
    //         .subscribe((response: FormGroup) => {
    //             if ( !response )
    //             {
    //                 return;
    //             }

    //             this._empresaAplicacionesService.updateContact(response.getRawValue());
    //         });
    // }

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void
    {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }
}
