<div fxLayout="column" fxLayoutAlign="center center" class="cont">
    <h1>Selección Vacante </h1>
    <p>Por favor selecione la vacante para la cual su candidato </p>
    <input class="search" type="text" [(ngModel)]="searchTerm" name="searchTerm" placeholder="Filtro de búsqueda">
    <div class="vacant_list" fxLayoutAlign="center center">        
        <table>
            <thead>
                <tr>
                    <th width="60%">Cargo</th>
                    <th width="20%">Salario</th>
                    <th width="10%">Aplicaciones</th>
                    <th width="15%">experiencia</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let vacant of vacantes|filter:searchTerm;let index = i" (click)="idNewVacant = vacant.id"
                    class="btn-select-{{idNewVacant  ===  vacant.id ? 'yes' : 'no' }} ">
                    <td width="60%">{{vacant.cargo}}</td>
                    <td width="20%">{{vacant.salario_value}}</td>
                    <td width="10%">{{vacant.cantidad_aplicaciones}}</td>
                    <td width="15%">{{vacant.experiencia_value}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="btns" fxLayoutAlign="center center">
        <button mat-raised-button [disabled]="idNewVacant === 0" (click)="seleccionar(idNewVacant)" class="btn btn_blue"
            fxLayoutAlign="center center">
            Seleccionar
        </button>
        <button mat-raised-button class="btn btn_orange" (click)="onCerrarClick()">Cancelar</button>
    </div>
</div>

<!-- <div class="cont" *ngIf="view === 2" fxLayout="column" fxLayoutAlign="center center">
    <h1>¿Estás seguro de realizar el cambio <br> del candidato de vacante?</h1>
    <p>Recuerde que al momento de cambiar la vacante el candidato se aplicara a la nueva vacante escogida</p>

    <div class="btns" fxLayoutAlign="center center">
        <button mat-raised-button [disabled]="idNewVacant === 0"
            (click)="setNewVacant(data.aplicacion.id , idNewVacant);view = 3" class="btn btn_blue"
            fxLayoutAlign="center center">
            Cambiar
        </button>
        <button mat-raised-button class="btn btn_orange" (click)="view = 1">Cancelar</button>
    </div>
</div> -->
