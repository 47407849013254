import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-float',
  templateUrl: './footer-float.component.html',
  styleUrls: ['./footer-float.component.scss']
})
export class FooterFloatComponent implements OnInit {
  whatsapp = false;
  circle = false;
  constructor() { }

  ngOnInit(): void {
  }

  openWhats() {
    if (this.whatsapp === false) {
      this.whatsapp = true;
      this.circle = false;
    } else {
      this.whatsapp = false;
      this.circle = false;
    }
  }

  openCircle() {
    if (this.circle === true) {
      this.circle = false;
      this.whatsapp = false;
    } else {
      this.circle = true;
      this.whatsapp = false;
    }
  }
}
