import { environment } from "environments/environment";
export class GlobalConstants {

    //------------------------------
    // Entorno Local
    //------------------------------

    /*
    
    // URLs (Local)
    public static apiURL: string = 'http://127.0.0.1:8000/api/';
    public static oauthURL: string = 'http://127.0.0.1:8000/oauth/';
    public static localStorageURL: string = ''; //Es una ruta absoluta

    // Proyecto
    public static siteTitle: string = 'Ok Empleos Portal de Empleo';

    // API Key Services (Local)
    public static apiKeyGoogleMaps: string = 'AIzaSyB3EHOtSWj9KFXxe1q5cTtA5_ilBWLKwXc';

    // Client API (Local)
    public static client1: string = 'wz2GCK9gikufwPnaJNqkSZBcAFAZPC87X9HvH0UV';
    public static client2: string = '7tpG9qIGBdcHHnkMP7a7dzMuoHZvRdSnXeMSWIiY';

   */
    
    //------------------------------
    // Entorno Producción
    //------------------------------
     
    // URLs (Producción)
    // public static URL: string = 'https://app.okempleos.com';
    // public static apiURL: string = 'https://admin.okempleos.com/api/';
    // public static oauthURL: string = 'https://admin.okempleos.com/oauth/';
    // public static iaURL: string = 'http://admin.okempleos.com:8080/';
    // public static mode: string = 'production';

    // URLs (Local)
    // public static URL: string = 'http://localhost:4200';
    // public static apiURL: string = 'http://localhost/api/';
    // public static oauthURL: string = 'http://localhost/oauth/';
    // public static iaURL: string = 'http://admin.okempleos.com:8080/';
    // public static mode: string = 'test';

    // URLs (QA)    
    // public static URL: string = 'https://qaapp.okempleos.com';
    // public static apiURL: string = 'https://qaadmin.okempleos.com/api/';
    // public static oauthURL: string = 'https://qaadmin.okempleos.com/oauth/';
    // public static iaURL: string = 'http://admin.okempleos.com:8080/';
    // public static mode: string = 'test';

    //------------------------------
    // Evironments
    //------------------------------

    public static URL: string = environment.URL;
    public static apiURL: string = environment.apiURL;
    public static oauthURL: string = environment.oauthURL;
    public static iaURL: string = environment.iaURL;
    public static mode: string = environment.mode;

    public static localStorageURL: string = ''; //Es una ruta absoluta

    // Proyecto
    public static siteTitle: string = 'Ok Empleos Portal de Empleo';

    // API Key Services (Producción)
    public static apiKeyGoogleMaps: string = 'AIzaSyB3EHOtSWj9KFXxe1q5cTtA5_ilBWLKwXc';

    // Client API (Producción)
    public static client1: string = 'qEXBx69QQDDVhq8TzOHF0GLqt8ecqHZDhyWvF0om';
    public static client2: string = '94hNDlxyhFSUUFXtFDOaPgmW081ENGoTv4rU2TFC';
    

    //------------------------------
    // General
    //------------------------------

    public static clientId: number = 2;
    public static clientKey: string  = GlobalConstants.client2;

    // Datos de contacto generales
    public static okempleos_telefono_asesor: string  = "3103864456";

    // Api Facebook
    public static facebook_api_id: string  = "888361738657710";


    // -------------------------
    // Restricciones por plan
    // -------------------------

    // Limite creacion de vacantes por plan
    public static limiteVacantesPlan1 = 1;
    public static limiteVacantesPlan2 = 20;
    public static limiteVacantesPlan4 = 1;
    public static limiteVacantesPlan5 = 2;
    public static limiteVacantesPlan6 = 4;

    public static limiteUsuariosPlan1 = 1;
    public static limiteUsuariosPlan2 = 3;
    public static limiteUsuariosPlan3 = 10;
    public static limiteUsuariosPlan4 = 1;
    public static limiteUsuariosPlan5 = 1;
    public static limiteUsuariosPlan6 = 1;
    

    public static plans = [
        {
          num_plan: '1',
          validity: '3 meses',
          price: '$70.000',
          text: '(30 Días Activa)',
          indice: 1,
          plan_id: 4,
          valor_plan: 70000,
          periodo: 'Trimestral'
        },
        {
          num_plan: '2',
          validity: '6 meses',
          price: '$100.000',
          text: '(30 Días Activa/Cada Una)',
          indice: 2,
          plan_id: 5,
          valor_plan: 100000,
          periodo: 'Semestral'
  
        },
        {
          num_plan: '4',
          validity: '1 año',
          price: '$150.000',
          text: '(30 Días Activa/Cada Una)',
          indice: 3,
          plan_id: 6,
          valor_plan: 150000,
          periodo: 'Anual'
  
        }
      ]
    
    public static slidetime: number = environment.slidetime;
}
