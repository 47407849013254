export const locale = {
    lang: 'en',
    data: {
        'RESET_FORM': {
            'TITLE': 'RESET YOUR PASSWORD',
            'EMAIL': 'Email',
            'PASSWORD': 'Password',
            'PASSWORD_CONFIRMATION': 'Password (Confirm)',
            'BUTTON_ACCEPT': 'RESET MY PASSWORD',
            'GO_TO_LOGIN': 'Go back to login',            
        },
        'RESET_FORM_ERROR': {
            'EMAIL_REQUIRED': 'Email is required',
            'EMAIL_INVALID': 'Please enter a valid email address',
            'PASSWORD_REQUIRED': 'Password is required',
            'PASSWORD_CONFIRMATION_REQUIRED': 'Password confirmation is required',
            'PASSWORD_CONFIRMATION_MATCH': 'Passwords must match',            
        }
    }
};
