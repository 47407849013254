import { Pipe, PipeTransform } from '@angular/core';
import { FuseUtils } from '@fuse/utils';

@Pipe({name: 'filterByEvent'})
export class FilterByEventPipe implements PipeTransform
{
    /**
     * Transform
     *
     * @param {any[]} mainArr
     * @param {string} searchText
     * @param {string} orden
     * @returns {any}
     */
    transform(mainArr: any[], searchText: string, orden: string): any
    {
        return FuseUtils.filterArrayByStringJo(mainArr, searchText, orden);
    }
}
