
<div class="sidebar-content side-bar-1">

    <div class="card">
        <!-- SIDEBAR CONTENT -->
        <div class="content p-16" fusePerfectScrollbar>
           
            <div class="nav material2">

                <form  [formGroup]="formFiltro">
               
                    <div fxLayout="column" fxLayoutAlign="start start" class="max-width-100">

                        <mat-form-field appearance="outline" class="max-width-100" fxHide>
                            <mat-label>Búsqueda por palabras</mat-label>
                            <textarea matInput placeholder="Ingresa el texto."
                            name="texto_busqueda"
                            formControlName="texto_busqueda"
                            rows="3">
                            </textarea>
                            <mat-error *ngIf="formFiltro.get('texto_busqueda').hasError('minlength')">Mínimo 3 caracteres</mat-error>
                            <mat-error *ngIf="formFiltro.get('texto_busqueda').hasError('maxlength')">Máximo 100 caracteres</mat-error>
                        </mat-form-field>

                        <div fxLayout="column" fxLayoutAlign="start start" class="max-width-100">
                        
                            <ng-select appearance="outline"
                                [items]="sectoresEconomicos"
                                bindValue="id"
                                bindLabel="nombre"
                                placeholder="Sector económico"
                                formControlName="sectorEcomonico">
                            </ng-select>
    
                        </div>

                        

                        <mat-form-field appearance="outline" floatLabel="always" class="max-width-100">
                            <mat-label>Tipo de empresa</mat-label>
                            <mat-select formControlName="tipoEmpresa">
                                <mat-option  [value]="null" >
                                    ---
                                </mat-option>
                                <mat-option *ngFor="let item of tiposEmpresas" [value]="item.id" >
                                    {{ item.nombre }}
                                </mat-option>
                            </mat-select>
                            <mat-icon matSuffix class="secondary-text">menu</mat-icon>
                        </mat-form-field>

                   

                        <div fxLayout="column" fxLayoutAlign="start start" class="max-width-100">
                        
                            <ng-select appearance="outline"
                                [items]="ciudades"
                                bindValue="id"
                                bindLabel="nombre"
                                placeholder="Ciudad"
                                formControlName="ciudad">
                            </ng-select>
    
                        </div>
    
                        <div *ngIf="formFiltro.get('ciudad').value === 524" fxLayout="column" fxLayoutAlign="start start" class="max-width-100">
                            
                            <ng-select appearance="outline"
                                [items]="localidades"
                                bindValue="id"
                                bindLabel="nombre"
                                placeholder="Localidad"
                                formControlName="localidad_id">
                            </ng-select>
    
                        </div>

                    </div>


                    <div aria-label="frequently contacted" class="pt-16" >

                        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="1 0 auto">

                            <button type="button" *ngIf="!loadingFiltro" mat-raised-button class="mat-accent" (click)="filtroAll()">
                                <mat-icon >list</mat-icon>
                                <span>Todos</span>
                            </button>
    
            
    
                            <button type="button" *ngIf="!loadingFiltro" mat-raised-button class="mat-accent" [disabled]="formFiltro.invalid" (click)="filtrar(formFiltro.value)">
                                <mat-icon>search</mat-icon>
                                <span>Filtrar</span>
                            </button>
    
    
                            <div *ngIf="loadingFiltro" fxLayout="row" fxLayoutAlign="center center" class="py-8 m-auto">
                                <mat-spinner strokeWidth="4" color="primary" diameter="30"> </mat-spinner>
                            </div>

                        </div>
                        
                    </div>
                    

                </form>

        
            </div>


        </div>

    </div>

</div>
