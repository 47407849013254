import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalConstants } from 'app/common/global-constants';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseMatchMediaService } from '@fuse/services/match-media.service';
import { AuthStateService } from 'app/pixcore/shared/auth-state.service';
import { TokenService } from 'app/pixcore/shared/token.service';
import { Router } from '@angular/router';
import { NgItemLabelDirective } from '@ng-select/ng-select/lib/ng-templates.directive';
import { All } from 'app/main/empresa/informacion-general/model/all.model';
import { MatStepper } from '@angular/material/stepper';


export interface DialogData {
    texto: string;
}

@Component({
    selector: 'modal-form-empresa',
    templateUrl: './modal-form-empresa.component.html',
    styleUrls: ['./modal-form-empresa.component.scss']
})
export class ModalFormEmpresaComponent implements OnInit, OnDestroy {

    loading = false;
    urlLocal = GlobalConstants.apiURL;
    informationForm: FormGroup;
    contactForm: FormGroup;
    profileForm: FormGroup;

    // formd de informacion
    sectoresEconomicos: any[];
    tiposEmpresas: any[];
    tipoContratacion: any[];

    // formd de contacto
    hideLabelStep = false;
    ciudades: any[];
    localidades: any[];
    private _unsubscribeAll: Subject<any>;

    tipos: any[];

    disablefields = false;

    //Archivos
    urlLocalStorage = GlobalConstants.localStorageURL;

    //Rut
    formRut: FormGroup;
    errorRut = '';
    validatorRut = true;
    verErrorRut = false;
    fileChargedRut = false;
    nameArchivoRut = null;
    sizeArchivoRut = null;
    typeArchivoRut = null;
    loadingRut = false;
    urlRut = '';
    rutSend = false;

    //CC
    formCamaraComercio: FormGroup;
    errorCamaraComercio = '';
    validatorCamaraComercio = true;
    verErrorCamaraComercio = false;
    fileChargedCamaraComercio = false;
    nameArchivoCamaraComercio = null;
    sizeArchivoCamaraComercio = null;
    typeArchivoCamaraComercio = null;
    loadingCamaraComercio = false;
    urlCamaraComercio = '';
    CamaraComercioSend = false;

    //RL
    formRepresentante: FormGroup;
    errorRepresentante = '';
    validatorRepresentante = true;
    verErrorRepresentante = false;
    fileChargedRepresentante = false;
    nameArchivoRepresentante = null;
    sizeArchivoRepresentante = null;
    typeArchivoRepresentante = null;
    loadingRepresentante = false;
    urlRepresentante = '';
    RepresentanteSend = false;


    @ViewChild('stepper') private myStepper: MatStepper;
    empresa_id = null;




 
    constructor(
        public dialogRef: MatDialogRef<ModalFormEmpresaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder,
        private _httpClient: HttpClient,
        private dateAdapter: DateAdapter<any>,
        private _snackBar: MatSnackBar,
        private _fuseMatchMediaService: FuseMatchMediaService,
        private authState: AuthStateService,
        private router: Router,
        private token: TokenService,
        public fb: FormBuilder,
    ) {
        // this.getSectoresEconomicos();
        // this.getTiposEmpresas();
        // this.getCiudades();
        // this.getLocalidades();
        this.getAllSelects();
        this._unsubscribeAll = new Subject();


        this.tipos = [
            { id: 1, value: "Persona Jurídica" },
            { id: 2, value: "Persona Natural" },
        ]

        this.formRut = this.fb.group({
            archivoRut: [null, [Validators.required]]
        });

        this.formCamaraComercio = this.fb.group({
            archivoCamaraComercio: [null, [Validators.required]]
        });

        this.formRepresentante = this.fb.group({
            archivoRepresentante: [null, [Validators.required]]
        });

    }

    ngOnInit(): void {

        this.dateAdapter.setLocale('es');

        this.informationForm = this.createInformationFom();
        this.contactForm = this.createContactFom();
        this.profileForm = this.createProfileForm();

        this._fuseMatchMediaService.onMediaChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res) => {
                switch (res) {
                    case 'xs': {
                        this.hideLabelStep = false;
                        break;
                    }
                    case 'sm': {
                        this.hideLabelStep = false;
                        break;
                    }
                    default: {
                        this.hideLabelStep = true;
                        break;
                    }
                }
            });

        let nit = String(this.informationForm.get('nit').value);
        this.getNit(nit.substring(0,nit.indexOf("-"))).then((response) => {

           

        if (typeof response.rows[0].razon_social !== 'undefined'){
                this.informationForm.controls['nombre'].setValue(response.rows[0].razon_social);
                this.disablefields = true;
        }

        });

    }

    getAllSelects(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${this.urlLocal}selects_empresa`)
                .subscribe((response: any) => {
                    this.tipoContratacion = response.tipoContratacion;
                    this.sectoresEconomicos = response.sectoresEconomicos;
                    this.localidades = response.localidades;
                    this.ciudades = response.ciudades;
                    this.tiposEmpresas = response.tiposEmpresas;
                    resolve(response);
                }, reject);
        });
    }


    private openSnackBar(mensaje: string, clase: string): void {
        this._snackBar.open(mensaje, 'x', {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'top',
            panelClass: clase,
        });
    }

    createInformationFom(): FormGroup {

        return this._formBuilder.group({

            user_id: [
                {
                    value: this.data.user_id,
                    disabled: false
                }
            ],
            nombre: [
                {
                    value: null,
                    disabled: false
                }, [Validators.required, Validators.minLength(5), Validators.maxLength(100)]
            ],
            nit: [
                {
                    value: this.authState.getUserSession().numero_identificacion,
                    disabled: false
                }, [Validators.required, Validators.minLength(5), Validators.maxLength(100), Validators.pattern('[0-9]+\-[0-9]{1}$')]
            ],
            tipo_persona: [
                {
                    value: this.authState.getUserSession().tipo_persona,
                    disabled: false
                }, Validators.required
            ],
            tipo_perfil: [{
                value: null,
                disabled: false
            }, Validators.required
            ],
            sector_economico_id: [{
                value: null,
                disabled: false
            }, Validators.required
            ],
            tipo_empresa_id: [{
                value: null,
                disabled: false
            }, Validators.required
            ],
            tipo_contratacion_id: [{
                value: null,
                disabled: false
            }, Validators.required
            ],
            tamano: [{
                value: null,
                disabled: false
            }, [Validators.required, Validators.min(0)]
            ],

        });
    }

    createContactFom(): FormGroup {

        return this._formBuilder.group({

            correo: [
                {
                    value: this.authState.getUserSession().email,
                    disabled: false
                }, [Validators.required, Validators.minLength(5), Validators.maxLength(100)]
            ],
            pais_id: [
                {
                    value: 47,
                    disabled: false
                }
            ],
            ciudad_id: [{
                value: null,
                disabled: false
            }, Validators.required
            ],
            localidad_id: [{
                value: null,
                disabled: false
            }
            ],
            telefono: [
                {
                    value: this.authState.getUserSession().telefono,
                    disabled: false
                }, [Validators.minLength(5), Validators.maxLength(20), Validators.pattern('[0-9]*'), Validators.required]
            ],
            direccion: [{
                value: null,
                disabled: false
            }, [Validators.required, Validators.minLength(5), Validators.maxLength(100)]
            ],

        });
    }



    createProfileForm(): FormGroup {

        return this._formBuilder.group({

            perfil: [
                {
                    value: null,
                    disabled: false
                }, [Validators.required, Validators.minLength(10), Validators.maxLength(2000)]
            ],

        });
    }

    onCerrarClick(logout?: boolean): void {
        this.dialogRef.close(logout);
    }

    getTiposEmpresas(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${this.urlLocal}config/registros/240`)
                .subscribe((response: any) => {
                    this.tiposEmpresas = response;
                    resolve(response);
                }, reject);
        });
    }


    getSectoresEconomicos(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${this.urlLocal}config/registros/210/true`)
                .subscribe((response: any) => {
                    this.sectoresEconomicos = response;
                    resolve(response);
                }, reject);
        });

    }

    getCiudades(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${this.urlLocal}division/ciudades_pais/47`)
                .subscribe((response: any) => {
                    this.ciudades = response;
                    resolve(response);
                }, reject);
        });

    }

    getLocalidades(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${this.urlLocal}division/localidades/524`)
                .subscribe((response: any) => {
                    this.localidades = response;
                    resolve(response);
                }, reject);
        });

    }

    getNit(nit): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${this.urlLocal}datos_nit/${nit}`)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });

    }


    onSubmit(informationForm, contactForm, profileForm): void {
        this.loading = true;


        if (contactForm.ciudad_id !== 524) {
            contactForm.localidad_id = null;
        }

        const formData = Object.assign(informationForm, contactForm, profileForm);

        this.postEmpresa(formData).then((response) => {
            if (!response.errors) {
                this.openSnackBar(response.message, 'snack-success');
                //   this.authState.removeUserSession();
                //   this.authState.validateSession();
                // setTimeout(() => {
                //     this.onCerrarClick();
                // }, 1500);
                this.empresa_id = response.id;
                this.myStepper.next();

            } else {
                if (response.errors.correo) {

                    if (response.errors.correo[0] === 'validation.unique') {
                        this.openSnackBar('El correo ya existe, prueba con otro', 'snack-error');
                    } else {
                        this.openSnackBar(response.message, 'snack-error');
                    }


                } else if (response.errors.nit) {

                    if (response.errors.nit[0] === 'validation.unique') {
                        this.openSnackBar('El Nit ya existe, prueba con otro', 'snack-error');
                    } else {
                        this.openSnackBar(response.message, 'snack-error');
                    }

                } else {
                    this.openSnackBar(response.message, 'snack-error');
                }

            }

            this.loading = false;
        });
    }


    postEmpresa(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${this.urlLocal}empresa`, data)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });

    }

    ngOnDestroy(): void {

    }




    logout(): void {
        this.onCerrarClick(false);
        this.authState.setAuthState(false);
        this.token.removeToken();
        this.router.navigate(['login']);
    }

    //Archivos

    //RUT

    onFileRutSelect(event): any {


        const file = (event.target as HTMLInputElement).files[0];
        
    
        if (file !== undefined) {
            this.validatorRut = false;
            this.fileChargedRut = true;
    
            const nameFile = file.name;
            const sizeFile = file.size;
            const typeFile = file.type;
        
            this.nameArchivoRut = nameFile;
            this.sizeArchivoRut = sizeFile / 1048576;
            this.typeArchivoRut = typeFile;
        
            let formatoOk;
    
            switch (typeFile) {
                case 'application/msword': {
                    formatoOk = true;
                    break;
                }
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
                    formatoOk = true;
                    break;
                }
                case 'application/pdf': {
                    formatoOk = true;
                    break;
                }
                default: {
                    formatoOk = false;
                    break;
                }
            }
        
            if (!formatoOk) {
            
                this.errorRut = 'Solo doc, docx o pdf';
                this.verErrorRut = true;
                this.validatorRut = true;
                
                return;
        
                }else if (sizeFile > 5242880) {
        
                this.errorRut = 'Tamaño máximo 5MB';
                this.verErrorRut = true;
                this.validatorRut = true;
                
                return;
        
                }else {
        
                this.validatorRut = false;
                this.verErrorRut = false;
                this.formRut.patchValue({
                    archivoRut: file
                });
    
                this.formRut.get('archivoRut').updateValueAndValidity();
    
                }
            } else {
                
                this.nameArchivoRut = null;
                this.sizeArchivoRut = null;
                this.typeArchivoRut = null;
                this.fileChargedRut = false;
                this.verErrorRut = false;
                this.validatorRut = true;
                this.formRut.reset();
            
            }
            
    }

    onFileCamaraComercioSelect(event): any {


        const file = (event.target as HTMLInputElement).files[0];
        
    
        if (file !== undefined) {
            this.validatorCamaraComercio = false;
            this.fileChargedCamaraComercio = true;
    
            const nameFile = file.name;
            const sizeFile = file.size;
            const typeFile = file.type;
        
            this.nameArchivoCamaraComercio = nameFile;
            this.sizeArchivoCamaraComercio = sizeFile / 1048576;
            this.typeArchivoCamaraComercio = typeFile;
        
            let formatoOk;
    
            switch (typeFile) {
                case 'application/msword': {
                    formatoOk = true;
                    break;
                }
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
                    formatoOk = true;
                    break;
                }
                case 'application/pdf': {
                    formatoOk = true;
                    break;
                }
                default: {
                    formatoOk = false;
                    break;
                }
            }
        
            if (!formatoOk) {
            
                this.errorCamaraComercio = 'Solo doc, docx o pdf';
                this.verErrorCamaraComercio = true;
                this.validatorCamaraComercio = true;
                
                return;
        
                }else if (sizeFile > 5242880) {
        
                this.errorCamaraComercio = 'Tamaño máximo 5MB';
                this.verErrorCamaraComercio = true;
                this.validatorCamaraComercio = true;
                
                return;
        
                }else {
        
                this.validatorCamaraComercio = false;
                this.verErrorCamaraComercio = false;
                this.formCamaraComercio.patchValue({
                    archivoCamaraComercio: file
                });
    
                this.formCamaraComercio.get('archivoCamaraComercio').updateValueAndValidity();
    
                }
            } else {
                
                this.nameArchivoCamaraComercio = null;
                this.sizeArchivoCamaraComercio = null;
                this.typeArchivoCamaraComercio = null;
                this.fileChargedCamaraComercio = false;
                this.verErrorCamaraComercio = false;
                this.validatorCamaraComercio = true;
                this.formCamaraComercio.reset();
            
            }
            
    }
                        
    onFileRepresentanteSelect(event): any {


        const file = (event.target as HTMLInputElement).files[0];
        
    
        if (file !== undefined) {
            this.validatorRepresentante = false;
            this.fileChargedRepresentante = true;
    
            const nameFile = file.name;
            const sizeFile = file.size;
            const typeFile = file.type;
        
            this.nameArchivoRepresentante = nameFile;
            this.sizeArchivoRepresentante = sizeFile / 1048576;
            this.typeArchivoRepresentante = typeFile;
        
            let formatoOk;
    
            switch (typeFile) {
                case 'application/msword': {
                    formatoOk = true;
                    break;
                }
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
                    formatoOk = true;
                    break;
                }
                case 'application/pdf': {
                    formatoOk = true;
                    break;
                }
                default: {
                    formatoOk = false;
                    break;
                }
            }
        
            if (!formatoOk) {
            
                this.errorRepresentante = 'Solo doc, docx o pdf';
                this.verErrorRepresentante = true;
                this.validatorRepresentante = true;
                
                return;
        
                }else if (sizeFile > 5242880) {
        
                this.errorRepresentante = 'Tamaño máximo 5MB';
                this.verErrorRepresentante = true;
                this.validatorRepresentante = true;
                
                return;
        
                }else {
        
                this.validatorRepresentante = false;
                this.verErrorRepresentante = false;
                this.formRepresentante.patchValue({
                    archivoRepresentante: file
                });
    
                this.formRepresentante.get('archivoRepresentante').updateValueAndValidity();
    
                }
            } else {
                
                this.nameArchivoRepresentante = null;
                this.sizeArchivoRepresentante = null;
                this.typeArchivoRepresentante = null;
                this.fileChargedRepresentante = false;
                this.verErrorRepresentante = false;
                this.validatorRepresentante = true;
                this.formRepresentante.reset();
            
            }
            
    }

    enviarRut(form): any{
        if (this.fileChargedRut) {

            const formData: any = new FormData();
            formData.append('rut', form.archivoRut);
            formData.append('id', this.empresa_id);
            this.loadingRut = true;

            this.subirRut(formData).then((res) => {
                if (!res.errors) { 
                    this.rutSend = true;
                }            

                this.loadingRut = false
                this.fileChargedRut = false;
                this.verErrorRut = false;
                this.validatorRut = true;
            });
        }

        this.fileChargedRut = false;
        this.validatorRut = true;
        this.verErrorRut = false;
        
    }

    enviarCamaraComercio(form): any{
        if (this.fileChargedCamaraComercio) {

            const formData: any = new FormData();
            formData.append('camaracomercio', form.archivoCamaraComercio);
            formData.append('id', this.empresa_id);
            this.loadingCamaraComercio = true

            this.subirCamaraComercio(formData).then((res) => {

                if (!res.errors) { 
                    this.CamaraComercioSend = true;
                }             

                this.loadingCamaraComercio = false
                this.fileChargedCamaraComercio = false;
                this.verErrorCamaraComercio = false;
                this.validatorCamaraComercio = true;
            });
        }

        this.fileChargedCamaraComercio = false;
        this.validatorCamaraComercio = true;
        this.verErrorCamaraComercio = false;
        
    }

    enviarRepresentante(form): any{
        if (this.fileChargedRepresentante) {

            const formData: any = new FormData();
            formData.append('representante', form.archivoRepresentante);
            formData.append('id', this.empresa_id);
            this.loadingRepresentante = true

            this.subirRepresentante(formData).then((res) => {
                if (!res.errors) { 
                    this.RepresentanteSend = true;
                }

                this.loadingRepresentante = false
                this.fileChargedRepresentante = false;
                this.verErrorRepresentante = false;
                this.validatorRepresentante = true;
            });
        }

        this.fileChargedRepresentante = false;
        this.validatorRepresentante = true;
        this.verErrorRepresentante = false;
        
    }

    subirRut(data): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${this.urlLocal}empresa_rut`, data)
                .subscribe((response: any) => {
                    resolve(response);
                    if (!response.errors){
                        this.openSnackBar(response.message, 'snack-success');                        
                    } else {
                        this.openSnackBar(response.message, 'snack-error');
                    }
                    
                }, reject);
        });
        
    }

    subirCamaraComercio(data): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${this.urlLocal}empresa_camaracomercio`, data)
                .subscribe((response: any) => {
                    resolve(response);
                    if (!response.errors){
                        this.openSnackBar(response.message, 'snack-success');                        
                    } else {
                        this.openSnackBar(response.message, 'snack-error');
                    }
                    
                }, reject);
        });
        
    }

    subirRepresentante(data): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${this.urlLocal}empresa_representantelegal`, data)
                .subscribe((response: any) => {
                    resolve(response);
                    if (!response.errors){
                        this.openSnackBar(response.message, 'snack-success');                        
                    } else {
                        this.openSnackBar(response.message, 'snack-error');
                    }
                    
                }, reject);
        });
        
    }



}
