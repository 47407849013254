import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as spanish } from './i18n/es';
import { locale as english } from './i18n/en';

import { MatSnackBar } from '@angular/material/snack-bar';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalConstants } from 'app/common/global-constants';

import { ActivatedRoute, Router } from '@angular/router';
import { InterceptorSkipHeader } from 'app/pixcore/shared/auth.interceptor';

@Component({
    selector     : 'reset-password',
    templateUrl  : './reset-password.component.html',
    styleUrls    : ['./reset-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ResetPasswordComponent implements OnInit, OnDestroy
{
    resetPasswordForm: FormGroup;

    token: string;
    email: string;
    loading = false;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _snackBar: MatSnackBar,
        private httpClient: HttpClient,
        private router: Router,
        private actRoute: ActivatedRoute
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this._fuseTranslationLoaderService.loadTranslations(spanish, english);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */    

    ngOnInit(): void
    {
        //GET Params
        this.token = this.actRoute.snapshot.queryParamMap.get("token");
        this.email = this.actRoute.snapshot.queryParamMap.get("email");

        console.log(this.token + " | " + this.email);

        this.resetPasswordForm = this._formBuilder.group({
            //name           : ['', Validators.required],
            token          : [this.token,Validators.required],
            email          : [{
                value   : this.email,
                disabled: true
            }, [Validators.required, Validators.email, Validators.minLength(5), Validators.maxLength(100)]],
            password       : ['', [Validators.required, Validators.minLength(5), Validators.maxLength(100)]],
            password_confirmation: ['', [Validators.required, confirmPasswordValidator]]
        });

        // Update the validity of the 'password_confirmation' field
        // when the 'password' field changes
        this.resetPasswordForm.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.resetPasswordForm.get('password_confirmation').updateValueAndValidity();
            });      
          
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    OnSubmit(): void {
        this.loading = true;
        const formData = this.resetPasswordForm.getRawValue();
        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
        this.httpClient.post(`${GlobalConstants.apiURL}reset_password_with_token`, formData, {headers:headers}).subscribe(
            (result: any) => {
                if (!result.errors) {
                    this.openSnackBar("¡Contraseña cambiada correctamente!", 'snack-success');
                    this.sleep(1000).then(() => {
                        this.loading = false;
                        this.router.navigate(['/login']);
                    });
                }else {
                    this.openSnackBar(result.message, 'snack-error');
                    this.loading = false;
                }
            }  
        );
    }

    private sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

    /**
     * Mostrar la respuesta al usuario
     * @param mensaje
     */
    private openSnackBar(mensaje: string, clase: string): void {
        this._snackBar.open(mensaje, 'x', {
         duration: 4000,
         horizontalPosition: 'end',
         verticalPosition: 'top',
         panelClass: clase,
       });
      }

}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if ( !control.parent || !control )
    {
        return null;
    }

    const password = control.parent.get('password');
    const password_confirmation = control.parent.get('password_confirmation');

    if ( !password || !password_confirmation )
    {
        return null;
    }

    if ( password_confirmation.value === '' )
    {
        return null;
    }

    if ( password.value === password_confirmation.value )
    {
        return null;
    }

    return {passwordsNotMatching: true};
};
