import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'home',
        title: 'Inicio',
        isItemBigTitle: true,
        //translate: 'NAV.HOME',
        type: 'item',
        svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Candidato_01_Inicio.svg',
        url: '/apps/dashboards/analytics',
        tutorial: 'En una sola pantalla revisa tus aplicaciones y el estado de tu postulación, vacantes de tu interés, capacitaciones y más.'
    }, 
    {
        id: 'informacion',
        title: 'Mi Información',
        //translate: 'NAV.MY_INFORMATION',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'perfil',
                title: 'Perfil',
                translate: 'NAV.MY_DATA',
                type: 'collapsable',
                isItemBigTitle: true,
                svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Candidato_02_Perfil.svg',
                children: [
                    {
                        id: 'datos_personales',
                        title: 'Datos personales',
                        type: 'item',
                        isItemTitle: true,
                        translate: 'NAV.PERSONAL_DATA',
                        svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Candidato_03_Datos.svg',
                        url: '/candidato/informacionpersonal',
                        tutorial: ''
                    },
                    {
                        id: 'educacion',
                        title: 'Información académica',
                        type: 'item',
                        isItemTitle: true,
                        translate: 'NAV.ACADEMIC_DATA',
                        svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Candidato_04_Academica.svg',
                        url: '/candidato/informacionacademica/educacion',
                        tutorial: ''
                    },
                    {
                        id: 'experiencia',
                        title: 'Experiencia',
                        isItemTitle: true,
                        type: 'item',
                        translate: 'NAV.EXPERIENCE',
                        svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Candidato_05_Expericiencia.svg',
                        url: '/candidato/informacionacademica/experiencia',
                        tutorial: ''
                    },
                    {
                        id: 'habilidad',
                        title: 'Habilidad',
                        type: 'item',
                        isItemTitle: true,
                        translate: 'NAV.SKILLS',
                        svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Candidato_06_Habilidades.svg',
                        url: '/candidato/informacionacademica/habilidad',
                        tutorial: ''
                    },
                    {
                        id: 'honores',
                        title: 'Honores y premios',
                        type: 'item',
                        isItemTitle: true,
                        translate: 'NAV.AWARDS',
                        svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Candidato_07_Honores.svg',
                        url: '/candidato/informacionacademica/premios',
                        tutorial: ''
                    },
                    {
                        id: 'idiomas',
                        title: 'Idiomas',
                        type: 'item',
                        isItemTitle: true,
                        translate: 'NAV.LANGUAGES',
                        svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Candidato_08_Idiomas.svg',
                        url: '/candidato/informacionacademica/idioma',
                        tutorial: ''
                    }
                ],
                tutorial: 'Aquí puedes completar toda la información de tu vida personal y profesional. Entre más completa esté tu información, más posibilidades tendrás de encontrar tu trabajo ideal.'
            },
            {
                id: 'perfil_publico',
                title: 'Perfil público',
                isItemBigTitle: true,
                //translate: 'NAV.MY_APPLICATIONS',
                type: 'item',
                svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Candidato_09_PerfilPublico.svg',
                url: '/candidato/profile',
                tutorial: 'Esta es la vista que las empresas tendrán a la hora de revisar tu perfil. Asegurate de tener tu información completa.'
            },
             {
                id: 'evaluacion_competencias',
                title: 'Mis competencias',
                isItemBigTitle: true,
                //translate: 'NAV.MY_APPLICATIONS',
                type: 'item',
                svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Candidato_10_Competencias.svg',
                url: '/candidato/evaluacioncompetencias',
                tutorial: 'Esta prueba, además de mostrarte tu top 3 de competencias, permite a los reclutadores conocer más sobre ti y tu perfil.'
            }, 
        ],
        tutorial: ''
    },
    {
        id: 'empresa',
        title: 'Mi gestión',
        //translate: 'NAV.MY_INFORMATION',
        type: 'group',
        icon: 'domain',
        children: [
                        
            {
                id: 'lista_empresas',
                title: 'Empresas',
                isItemBigTitle: true,
                //translate: 'NAV.MY_APPLICATIONS',
                type: 'item',
                svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Candidato_11_Empresas.svg',
                url: '/candidato/lista-empresas',
                tutorial: 'Busca y filtra las empresas que están buscando talentos como tú.'
            },
            {
                id: 'listado_vacantes',
                title: 'Vacantes',
                isItemBigTitle: true,
                type: 'item',
                //translate: 'NAV.PERSONAL_DATA',
                svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Candidato_12_Vacantes.svg',
                url: '/vacante/candidato-vacantes/lista',
                tutorial: 'De manera ágil busca y comparte las vacantes de interés. Revisa constantemente. Siempre encontrarás nuevas ofertas de empleo.'
            },
            {
                id: 'listado_vacantes_favoritas',
                title: 'Vacantes Favoritas',
                isItemBigTitle: true,
                type: 'item',
                //translate: 'NAV.PERSONAL_DATA',
                svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Candidato_14_Favoritos.svg',
                url: '/vacante/candidato-vacantes-favoritas/lista',
                tutorial: 'En esta sección podrás revisar todas las vacantes que has marcado como favoritas. Una sabia decisión si deseas prepararte antes de aplicar.'
            },
            {
                id: 'listado_candidato_aplicaciones',
                title: 'Aplicaciones',
                isItemBigTitle: true,
                type: 'item',
                //translate: 'NAV.PERSONAL_DATA',
                svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Candidato_13_MisAplicaciones.svg',
                url: '/vacante/candidato-aplicaciones/lista',
                tutorial: 'Revisa todas tus aplicaciones y el estado actual de cada una de ellas.'
            },
        ],
        tutorial: ''
    },
    {
        id: 'interes',
        title: 'De mi interés',
        //translate: 'NAV.MY_INFORMATION',
        type: 'group',
        icon: 'class',
        children: [
            {
                id: 'centro_ayuda',
                title: 'Ayuda',
                isItemBigTitle: true,
                type: 'item',
                //translate: 'NAV.PERSONAL_DATA',
                svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Icono_07_Ayuda.svg',
                url: '/tutoriales/lista',
                tutorial: ''//'¿Necesitas ayuda? Aquí encontrarás nuestro Centro de Ayuda con videos para que conozcas la plataforma.'
            },
            {
                id: 'calculadora',
                title: 'Calculadora',
                isItemBigTitle: true,
                type: 'item',
                //translate: 'NAV.PERSONAL_DATA',
                svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Icono_08_Calculadora.svg',
                externalUrl: true,
                openInNewTab: true,
                url: 'https://okempleos.com/calculadora-salarial/',
                tutorial: ''//'Usa nuestra Calculadora Salarial para conocer el estimado de tu salario mensual según tu salario base y descuentos por prestaciones sociales, además de agregar otros descuentos.'
            },
            {
                id: 'blog',
                title: 'Blog',
                type: 'item',
                isItemBigTitle: true,
                //translate: 'NAV.PERSONAL_DATA',
                svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Icono_10_Blog.svg',              
                externalUrl: true,
                openInNewTab: true,
                url: 'https://news.okempleos.com/',
                tutorial: ''//'En el Blog podrás encontrar artículos de tu interés.'
            },
            {
                id: 'contacto',
                title: 'Contacto',
                type: 'item',                
                externalUrl: true,
                isItemBigTitle: true,
                openInNewTab: true,
                //translate: 'NAV.PERSONAL_DATA',
                svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Icono_09_Contacto.svg',
                url: 'https://okempleos.com/contactenos/',
                tutorial: ''//'Por último, si tienes alguna duda o pregunta, no dudes en contactarnos. En Ok Empleos estamos atentos a tus inquietudes.'
            },
        ],
        tutorial: ''
    }
    
];