<div class="cont_okupa">
    <app-navbar></app-navbar>
    <header>
        <h1 class="header_title"><span>OKupa</span> tus posiciones con OK Empleos</h1>
        <p>Reúne tu equipo de trabajo para tu negocio o emprendimiento de manera rápida, económica y sin esfuerzo.</p>
        <img class="img_header" src="assets/images/serverimg/chica_ok_mora.png" alt="">
    </header>
    <main>
        <section fxLayout="row wrap" class="section section--cards" fxLayoutAlign="center center">
            <h1 fxFlex="100" class="title_cards">Publica tus ofertas en nuestro portal</h1>
            <p fxFlex="100" fxFlex.gt-xs="75">¡Ok Empleos tiene una nueva sección! Si eres tendero, emprendedor o
                microempresario, te
                ofrecemos un servicio único pensado especialmente para ti y tus necesidades a un bajo costo.</p>
            <div fxLayout="row wrap" fxFlex="100" class="cards_items" fxLayoutAlign="center center">
                <div *ngFor="let pre of prices; let index = i" fxFlex="100" fxFlex.gt-xs="48" fxFlex.gt-sm="30"
                    (click)="opcion = pre.indice" class="card_cont card-{{opcion === pre.indice ? 'yes' : 'no' }}">
                    <div class="header_card" fxLayoutAlign="space-between center">
                        <h1>Plan {{ pre.num_plan }} Publicación:</h1>
                        <img *ngIf="opcion === pre.indice" class="start_log"
                            src="../../../../assets/images/logos/star_card.png" alt="">
                        <img class="start_log" *ngIf="pre.num_plan === '4' && opcion !== pre.indice" class="start_log"
                            src="../../../../assets/images/logos/ia_pre_select.png" alt="">
                    </div>
                    <h2>{{ pre.price }}</h2>
                    <p>Vigencia por <span>{{ pre.validity }}</span></p>
                    <hr>
                    <div class="items_row" fxLayout="row" fxLayoutAlign="start center">
                        <img class="icon_check" *ngIf="opcion != pre.indice"
                            src="../../../../assets/images/logos/check_purple.png" alt="">
                        <img class="icon_check" *ngIf="opcion === pre.indice"
                            src="../../../../assets/images/logos/check_blod.png" alt="">
                        <p>{{ pre.num_plan }} Publicación {{ pre.text }}</p>
                    </div>
                    <div class="items_row" fxLayout="row" fxLayoutAlign="start center">
                        <img class="icon_check" *ngIf="opcion != pre.indice"
                            src="../../../../assets/images/logos/check_purple.png" alt="">
                        <img class="icon_check" *ngIf="opcion === pre.indice"
                            src="../../../../assets/images/logos/check_blod.png" alt="">
                        <p>Acceso a todas las HV postuladas</p>
                    </div>
                    <div class="items_row" fxLayout="row" fxLayoutAlign="start center">
                        <img class="icon_check" *ngIf="opcion != pre.indice"
                            src="../../../../assets/images/logos/check_purple.png" alt="">
                        <img class="icon_check" *ngIf="opcion === pre.indice"
                            src="../../../../assets/images/logos/check_blod.png" alt="">
                        <p>Sugerencia inmediata de candidatos. (Postulados y No Postulados)</p>
                    </div>
                    <div class="items_row" fxLayout="row" fxLayoutAlign="start center">
                        <img class="icon_check" *ngIf="opcion != pre.indice"
                            src="../../../../assets/images/logos/check_purple.png" alt="">
                        <img class="icon_check" *ngIf="opcion === pre.indice"
                            src="../../../../assets/images/logos/check_blod.png" alt="">
                        <p>Soporte técnico y apoyo.</p>
                    </div>
                    <button class="btn btn-purple"
                        (click)="onClickPlan(pre.plan_id, pre.valor_plan, pre.periodo)">Cómpralo Ya</button>
                </div>
            </div>
            <p fxFlex="100" fxFlex.gt-xs="75">Si eres una empresa dedicada al reclutamiento de personal
                (temporal/outsourcing) estos planes NO aplican para ti.
                Contáctanos <a
                    href="https://api.whatsapp.com/send?phone=573144041975&text=Hola Ok Empleos 👋 soy una empresa dedicada al reclutamiento de personal (temporal/outsourcing), quiero una asesoría.😀"
                    target="_blank">aquí</a> y adquiere un plan a tu medida.</p>
        </section>
        <section class="section section--preselc">
            <div class="cont_preselec" fxLayout="row wrap" fxLayoutAlign="center center">
                <img class="img_prese" fxFlex="70" fxFlex.gt-xs="50" fxFlex.gt-sm="15"
                    src="assets/images/serverimg/okupaDos.png" alt="">
                <p class="text_prese" fxFlex="90" fxFlex.gt-sm="75">*<span>Ok Empleos pre selecciona y resalta</span>
                    los candidatos que tienen más afinidad con tu vacante.
                </p>
            </div>
        </section>
        <section class="section section--ia" fxLayout="row wrap" fxLayoutAlign="center center">
            <p class="text_ia" fxFlex="90" fxFlex.gt-sm="45">
                Inicia el proceso de reclutamiento de tu equipo de trabajo con los candidatos más idóneos para ti y tus
                vacantes, gracias a la preselección automática e inmediata de nuestra IA “Inteligencia Artificial”.
            </p>
            <img class="img_prese" fxFlex="70" fxFlex.gt-xs="50" fxFlex.gt-sm="45"
                src="assets/images/serverimg/IAOkupa.png" alt="">
        </section>

        <section class="section section--pay">
            <h1 class="title_pay">Medios de Pago</h1>
            <div fxLayout="row wrap" class="cont_pays" fxLayoutAlign="center center">
                <img class="img_pay" fxFlex="30" fxFlex.gt-xs="30" fxFlex.gt-sm="15"
                    src="assets/images/serverimg/visa.png" alt="">
                <img class="img_pay" fxFlex="30" fxFlex.gt-xs="30" fxFlex.gt-sm="15"
                    src="assets/images/serverimg/mastercard.png" alt="">
                <img class="img_pay" fxFlex="30" fxFlex.gt-xs="30" fxFlex.gt-sm="15"
                    src="assets/images/serverimg/americaexp.png" alt="">
                <img class="img_pay" fxFlex="30" fxFlex.gt-xs="30" fxFlex.gt-sm="15"
                    src="assets/images/serverimg/pse.png" alt="">
                <img class="img_pay" fxFlex="30" fxFlex.gt-xs="30" fxFlex.gt-sm="15"
                    src="assets/images/serverimg/nequi.png" alt="">
            </div>
        </section>
        <section fxLayout="column" fxLayoutAlign="center center" class="section section--follow">
            <h1 class="title follow_title">Síguenos en nuestras redes sociales</h1>
            <div fxLayout="row wrap" fxLayoutAlign="center center" class="follow_items">
                <a *ngFor="let url of followLog" href="{{url.url}}" target="_blank">
                    <img class="follow_icon" src="{{url.img}}" alt="">
                </a>
            </div>
        </section>
    </main>

    <app-footer></app-footer>
    <app-footer-float></app-footer-float>

</div>