
<div mat-dialog-content fxLayout="column" fxLayoutAlign="center center">



    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <div fxFlex="100" fxFlex.gt-sm="30">
            <img src="assets/images/logos/packages.svg" class="logo-lateral"  alt="logo">
        </div>

        <div fxFlex="100" fxFlex.gt-sm="60" fxLayout="column">

            <div fxLayoutAlign="start center">
                <span class="tittle">Estudios de confiabilidad</span>
            </div>
        
            <div fxLayoutAlign="center center" class="py-24">
                <span class="subtittle">Centralizamos y recolectamos toda la información necesaria para generar tranquilidad y seguridad. Verificamos antecedentes y terceros en menos de 1 un minuto.</span>
            </div>
        
            <div fxLayoutAlign="start center" style=" border-bottom: 1px solid #C4C4C4; margin-bottom: 18px; margin-top: 15px;" >
            </div> 

                
                <mat-radio-group class="radio-group" (change)="changeComboo($event)" [(ngModel)]="favoritePackage" style="display: contents;">
                    <mat-radio-button class="radio-button" *ngFor="let package of packages" [value]="package.valor">
                    {{package.nombre}} {{package.valor_i}}
                    </mat-radio-button>
                </mat-radio-group>
        
            <div fxLayoutAlign="start center" style=" border-bottom: 1px solid #C4C4C4; margin-bottom: 18px; margin-top: 15px;" >
            </div> 

            <div fxLayout="row" fxLayoutAlign="end end">
                <!-- <button style="border-radius: 6px !important; border: 1px solid #1D3B4F !important;" class="mr-4" mat-raised-button color="secundary" (click)="chargePackage(favoritePackage)">GENERAR FACTURA</button> -->
                <button style="border-radius: 6px !important" [disabled]="!selected" mat-raised-button color="primary" (click)="startPayment(favoritePackage)">COMPRAR</button>
            </div>

        </div>
        

    </div>

   



</div>

