import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
  @ViewChild('contFoo') public conte: ElementRef;

  constructor(
    private _router: Router,
  ) {

  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (this.conte !== undefined) {
      this.conte.nativeElement.focus();
    }

    const cont = this.conte.nativeElement;
    // console.log('contttt',cont);
    // console.log('rutassss', this._router.url);

    if (this._router.url === '/inicio') {
      cont.style.backgroundColor = '#EEEDF2';
    }

    if (this._router.url === '/okupa') {
      cont.style.backgroundColor = '#fff';
    }
  }

}
