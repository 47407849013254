<button class="p-8" mat-button (click)="logout()">
    <mat-icon>exit_to_app</mat-icon>
    <span> Salir </span>
</button>

<mat-dialog-content fxLayout="column" fxLayoutAlign="center center">

    <mat-horizontal-stepper linear #stepper labelPosition="bottom" class="width-100 min-height-100">

        <mat-step [stepControl]="informationForm">
            <ng-template *ngIf="hideLabelStep" matStepLabel>Información general</ng-template>
            <form name="informationForm" id="informationForm" fxLayout="column" fxFlex="1 0 auto"
                [formGroup]="informationForm">

                <div *ngIf="!hideLabelStep" class="texto label-mobile p-8 h3">Información general</div>

                <div fxLayout="column" fxLayoutAlign.gt-sm="start center" fxFlex="1 0 auto" fxLayout.gt-sm="row">

                    <mat-form-field appearance="outline" fxFlex="100" class="pr-sm-4" fxFlex.gt-sm="50">
                        <mat-label>Nombre de la empresa</mat-label>
                        <input matInput formControlName="nombre" required [readonly]="disablefields">
                        <mat-icon matSuffix class="secondary-text">business</mat-icon>
                        <mat-error *ngIf="informationForm.get('nombre').hasError('required')">El nombre de la empresa es
                            requerido!</mat-error>
                        <mat-error *ngIf="informationForm.get('nombre').hasError('minlength')">Mínimo 5 caracteres
                        </mat-error>
                        <mat-error *ngIf="informationForm.get('nombre').hasError('maxlength')">Máximo 100 caracteres
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="100" class="pl-sm-4" fxFlex.gt-sm="50">
                        <mat-label>NIT</mat-label>
                        <input readonly matInput formControlName="nit" required [readonly]="disablefields">
                        <mat-icon matSuffix class="secondary-text">business</mat-icon>
                        <mat-error *ngIf="informationForm.get('nit').hasError('required')">El Nit es requerido!
                        </mat-error>
                        <mat-error *ngIf="informationForm.get('nit').hasError('minlength')">Mínimo 5 caracteres
                        </mat-error>
                        <mat-error *ngIf="informationForm.get('nit').hasError('maxlength')">Máximo 100 caracteres
                        </mat-error>
                        <mat-error *ngIf="informationForm.get('nit').hasError('pattern')">Ejemplo: 2546456-6</mat-error>
                    </mat-form-field>

                </div>

                <div fxLayout="column" fxLayoutAlign.gt-sm="start center" fxFlex="1 0 auto" fxLayout.gt-sm="row">
                    <mat-form-field appearance="outline" fxFlex="100" class="pr-sm-4" fxFlex.gt-sm="50">
                        <mat-label>Tamaño del equipo</mat-label>
                        <input type="number" matInput formControlName="tamano" required>
                        <mat-icon matSuffix class="secondary-text">supervised_user_circle</mat-icon>
                        <mat-error *ngIf="informationForm.get('tamano').hasError('required')">El Tamaño del Equipo es
                            requerido!</mat-error>
                        <mat-error *ngIf="informationForm.get('tamano').hasError('min')">Mínimo 0</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="100" class="pl-sm-4 width-100" fxFlex.gt-sm="50">
                        <mat-label>Tipo de empresa</mat-label>
                        <mat-select formControlName="tipo_empresa_id" required>
                            <mat-option *ngFor="let item of tiposEmpresas" [value]="item.id">
                                {{ item.nombre }}
                            </mat-option>
                        </mat-select>
                        <mat-icon matSuffix class="secondary-text">list</mat-icon>
                        <mat-error>El Tipo de Empresa es requerido!</mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="column" fxLayoutAlign.gt-sm="start center" fxFlex="1 0 auto" fxLayout.gt-sm="row">
                    <mat-form-field appearance="outline" fxFlex="100" class="width-100">
                        <mat-label>Tipo de contratación</mat-label>
                        <mat-select formControlName="tipo_contratacion_id" required>
                            <mat-option *ngFor="let item of tipoContratacion" [value]="item.id">
                                {{ item.nombre }}
                            </mat-option>
                        </mat-select>
                        <mat-icon matSuffix class="secondary-text">list</mat-icon>
                        <mat-error>El Tipo de contratación es requerido!</mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="column" fxLayoutAlign.gt-sm="start center" fxFlex="1 0 auto" fxLayout.gt-sm="row">
                    <div fxFlex="100" class="mb-8 ng-select-container-custom width-100">
                        <ng-select appearance="outline" [items]="sectoresEconomicos" bindValue="id" bindLabel="nombre"
                            placeholder="Sector económico" formControlName="sector_economico_id">
                        </ng-select>
                        <div class="error-container">
                            <mat-error *ngIf="informationForm.get('sector_economico_id').hasError('required')">El Sector
                                económico es requerido!</mat-error>
                        </div>
                    </div>
                </div>

                <mat-form-field appearance="outline">
                    <mat-label>Tipo Empresa</mat-label>
                    <mat-select formControlName="tipo_persona" required>
                        <mat-option *ngFor="let tipo of tipos" [value]="tipo.id">
                            {{tipo.value}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text">business</mat-icon>
                    <mat-error>El tipo de Empresa es requerido</mat-error>
                </mat-form-field>

                <div fxLayout="column" fxLayoutAlign.gt-sm="start center" fxFlex="1 0 auto" fxLayout.gt-sm="row"
                    class="my-12">
                    <div fxFlex="100">
                        <mat-label class="mr-8">¿Perfil empresarial visible al público?*:</mat-label>
                        <mat-radio-group formControlName="tipo_perfil" aria-label="Tipo de Perfil" fxFlex="50" required>
                            <mat-radio-button class="pl-4" [value]="1">Sí</mat-radio-button>
                            <mat-radio-button class="pl-4" [value]="2">No</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="end center" fxFlex="1 0 auto">
                    <button mat-raised-button color="primary" [disabled]="informationForm.invalid"
                        matStepperNext>Siguiente</button>
                </div>
            </form>

        </mat-step>


        <mat-step [stepControl]="contactForm">
            <ng-template *ngIf="hideLabelStep" matStepLabel>Contacto</ng-template>
            <form name="contactForm" id="contactForm" fxLayout="column" fxFlex="1 0 auto" [formGroup]="contactForm">
                <div *ngIf="!hideLabelStep" class="texto label-mobile p-8 h3">Contacto</div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                    <mat-form-field appearance="outline" fxFlex="100" class="pr-sm-4">
                        <mat-label>Teléfono</mat-label>
                        <input matInput formControlName="telefono" type="tel" required>
                        <mat-icon matSuffix class="secondary-text">settings_phone</mat-icon>
                        <mat-error *ngIf="contactForm.get('telefono').hasError('pattern')">Solo escribe números
                        </mat-error>
                        <mat-error *ngIf="contactForm.get('telefono').hasError('required')">El teléfono es requerido!
                        </mat-error>
                        <mat-error
                            *ngIf="contactForm.get('telefono').hasError('minlength') && !contactForm.get('telefono').hasError('pattern')">
                            Mínimo 5 caracteres</mat-error>
                        <mat-error
                            *ngIf="contactForm.get('telefono').hasError('maxlength') && !contactForm.get('telefono').hasError('pattern')">
                            Máximo 20 caracteres</mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Dirección</mat-label>
                        <input matInput formControlName="direccion" required>
                        <mat-icon matSuffix class="secondary-text">pin_drop</mat-icon>
                        <mat-error *ngIf="contactForm.get('direccion').hasError('required')">La Dirección es requerida!
                        </mat-error>
                        <mat-error *ngIf="contactForm.get('direccion').hasError('minlength')">Mínimo 5 caracteres
                        </mat-error>
                        <mat-error *ngIf="contactForm.get('direccion').hasError('maxlength')">Máximo 100 caracteres
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mb-8">

                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Correo de contacto</mat-label>
                        <input matInput formControlName="correo" type="email" email required>
                        <mat-icon matSuffix class="secondary-text">alternate_email</mat-icon>
                        <mat-error *ngIf="contactForm.get('correo').hasError('required')">El Correo Electrónico es
                            requerido!</mat-error>
                        <mat-error *ngIf="contactForm.get('correo').hasError('email')">El correo no es válido
                        </mat-error>
                        <mat-error *ngIf="contactForm.get('correo').hasError('minlength')">Mínimo 5 caracteres
                        </mat-error>
                        <mat-error *ngIf="contactForm.get('correo').hasError('maxlength')">Máximo 100 caracteres
                        </mat-error>
                    </mat-form-field>



                </div>

                <div fxLayout="column" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mb-12"
                    fxLayout.gt-sm="row">


                    <div fxFlex="100" fxFlex.gt-sm="50" class="pr-sm-4 mb-8 ng-select-container-custom width-100">

                        <ng-select appearance="outline" [items]="ciudades" bindValue="id" bindLabel="nombre"
                            placeholder="Ciudad" formControlName="ciudad_id">
                        </ng-select>

                        <div class="error-container">
                            <mat-error *ngIf="contactForm.get('ciudad_id').hasError('required')">La Ciudad es requerida!
                            </mat-error>
                        </div>

                    </div>

                    <div *ngIf="contactForm.get('ciudad_id').value === 524" fxFlex="100" fxFlex.gt-sm="50"
                        class="pl-sm-4 mb-8 ng-select-container-custom width-100">

                        <ng-select appearance="outline" [items]="localidades" bindValue="id" bindLabel="nombre"
                            placeholder="Localidad" formControlName="localidad_id">
                        </ng-select>

                    </div>

                </div>

                <div fxLayout="row" fxLayoutAlign="end center" fxFlex="1 0 auto">
                    <button class="mr-4" mat-raised-button color="secundary" matStepperPrevious>Atrás</button>
                    <button mat-raised-button color="primary" [disabled]="contactForm.invalid"
                        matStepperNext>Siguiente</button>
                </div>
            </form>

        </mat-step>

        <mat-step [stepControl]="profileForm">
            <ng-template *ngIf="hideLabelStep" matStepLabel>Perfil</ng-template>
            <form name="profileForm" id="profileForm" fxLayout="column" fxFlex="1 0 auto" [formGroup]="profileForm">

                <div *ngIf="!hideLabelStep" class="texto label-mobile p-8 h3">Perfil</div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" floatLabel="always" class="w-100-p" fxFlex="100">
                        <mat-label>Perfil Empresarial*</mat-label>
                        <textarea matInput
                            placeholder="Ingresa el perfil completo con información relevante de la empresa."
                            name="perfil" formControlName="perfil" rows="6">
                        </textarea>
                        <mat-error *ngIf="profileForm.get('perfil').hasError('minlength')">Mínimo 10 caracteres
                        </mat-error>
                        <mat-error *ngIf="profileForm.get('perfil').hasError('maxlength')">Máximo 2000 caracteres
                        </mat-error>
                        <mat-error *ngIf="profileForm.get('perfil').hasError('required')">El perfil es requerido!
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="end center" class="pt-12" fxFlex="1 0 auto">
                    <button *ngIf="!loading" class="mr-4" mat-raised-button color="secundary"
                        matStepperPrevious>Atrás</button>
                    <button mat-raised-button color="primary" [disabled]="profileForm.invalid || loading" class="ml-4"
                        (click)="onSubmit(informationForm.value, contactForm.value, profileForm.value)">

                        <span *ngIf="!loading">Siguiente</span>
                        <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center center" class="py-8">
                            <mat-spinner strokeWidth="4" color="primary" diameter="30"> </mat-spinner>
                        </div>
                    </button>
                </div>
            </form>

        </mat-step>

        <mat-step  [stepControl]="profileForm" [editable]="false">
            <ng-template *ngIf="hideLabelStep" matStepLabel>Documentos</ng-template>
            <!-- start RUT-->
            <div class="pl-16 pr-8 py-16" fxLayout="column" fxLayoutAlign="start" class="cont_item">

                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="h3">RUT</div>
                </div>

                <div *ngIf="loadingRut" fxLayout="row" fxLayoutAlign="center center" class="mt-24">
                    <mat-spinner strokeWidth="8" color="primary" diameter="50"> </mat-spinner>
                    <span class="ml-12">Cargando...</span>
                </div>

                <form *ngIf="!loadingRut" [formGroup]="formRut" id="formRut" fxLayout="column"
                    fxLayoutAlign="center center" class="pt-24 m-auto">

                    <div class="file-uploader">
                        <input hidden type="file" accept=".doc,.docx,.pdf" #fileInputRut
                            (change)="onFileRutSelect($event)">
                        <button mat-fab [matTooltip]="textoToolTipRut" matTooltipPosition="below" color="primary"
                            class="add-file-button" (click)="fileInputRut.click()" aria-label="Add file">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>

                    <mat-error class="pt-24 m-auto" *ngIf="verErrorRut">
                        {{errorRut}}
                    </mat-error>

                    <div fxLayout="column" *ngIf="fileChargedRut" fxLayoutAlign="center center" class="pt-24 m-auto">

                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>Nombre</mat-label>
                            <input matInput disabled [value]="nameArchivoRut">
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>Tamaño en MB</mat-label>
                            <input matInput disabled [value]="sizeArchivoRut | number:'1.0-0'">
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>Tipo</mat-label>
                            <input matInput disabled [value]="typeArchivoRut">
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="end center" fxFlex="1 0 auto" class="mt-12">
                        <button mat-raised-button color="primary" [disabled]="validatorRut" type="submit"
                            (click)="enviarRut(formRut.value)">Subir RUT</button>
                    </div>
                </form>
            </div>
            <!-- end RUT-->
            <!-- start CC-->
            <div class="pl-16 pr-8 py-16" fxLayout="column" fxLayoutAlign="start" class="cont_item">

                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="h3">Camára de comercio</div>
                </div>

                <div *ngIf="loadingCamaraComercio" fxLayout="row" fxLayoutAlign="center center" class="mt-24">
                    <mat-spinner strokeWidth="8" color="primary" diameter="50"> </mat-spinner>
                    <span class="ml-12">Cargando...</span>
                </div>



                <form *ngIf="!loadingCamaraComercio" [formGroup]="formCamaraComercio" id="formCamaraComercio"
                    fxLayout="column" fxLayoutAlign="center center" class="pt-24 m-auto">

                    <div class="file-uploader">
                        <input hidden type="file" accept=".doc,.docx,.pdf" #fileInputCamaraComercio
                            (change)="onFileCamaraComercioSelect($event)">
                        <button mat-fab [matTooltip]="textoToolTipCamaraComercio" matTooltipPosition="below"
                            color="primary" class="add-file-button" (click)="fileInputCamaraComercio.click()"
                            aria-label="Add file">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>

                    <mat-error class="pt-24 m-auto" *ngIf="verErrorCamaraComercio">
                        {{errorCamaraComercio}}
                    </mat-error>

                    <div fxLayout="column" *ngIf="fileChargedCamaraComercio" fxLayoutAlign="center center"
                        class="pt-24 m-auto">

                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>Nombre</mat-label>
                            <input matInput disabled [value]="nameArchivoCamaraComercio">
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>Tamaño en MB</mat-label>
                            <input matInput disabled [value]="sizeArchivoCamaraComercio | number:'1.0-0'">
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>Tipo</mat-label>
                            <input matInput disabled [value]="typeArchivoCamaraComercio">
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayoutAlign="end center" fxFlex="1 0 auto" class="mt-12">
                        <button mat-raised-button color="primary" [disabled]="validatorCamaraComercio" type="submit"
                            (click)="enviarCamaraComercio(formCamaraComercio.value)">Subir
                            Cámara de Comercio</button>
                    </div>
                </form>
            </div>
            <!-- end CC-->
            <!-- start RL-->
            <div class="pl-16 pr-8 py-16" fxLayout="column" fxLayoutAlign="start" class="cont_item">

                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="h3">CC Representante legal</div>
                </div>

                <div *ngIf="loadingRepresentante" fxLayout="row" fxLayoutAlign="center center" class="mt-24">
                    <mat-spinner strokeWidth="8" color="primary" diameter="50"> </mat-spinner>
                    <span class="ml-12">Cargando...</span>
                </div>

                <form *ngIf="!loadingRepresentante" [formGroup]="formRepresentante" id="formRepresentante"
                    fxLayout="column" fxLayoutAlign="center center" class="pt-24 m-auto">

                    <div class="file-uploader">
                        <input hidden type="file" accept=".doc,.docx,.pdf" #fileInputRepresentante
                            (change)="onFileRepresentanteSelect($event)">
                        <button mat-fab [matTooltip]="textoToolTipRepresentante" matTooltipPosition="below"
                            color="primary" class="add-file-button" (click)="fileInputRepresentante.click()"
                            aria-label="Add file">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>

                    <mat-error class="pt-24 m-auto" *ngIf="verErrorRepresentante">
                        {{errorRepresentante}}
                    </mat-error>

                    <div fxLayout="column" *ngIf="fileChargedRepresentante" fxLayoutAlign="center center"
                        class="pt-24 m-auto">

                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>Nombre</mat-label>
                            <input matInput disabled [value]="nameArchivoRepresentante">
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>Tamaño en MB</mat-label>
                            <input matInput disabled [value]="sizeArchivoRepresentante | number:'1.0-0'">
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>Tipo</mat-label>
                            <input matInput disabled [value]="typeArchivoRepresentante">
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayoutAlign="end center" fxFlex="1 0 auto" class="mt-12">
                        <button mat-raised-button color="primary" [disabled]="validatorRepresentante" type="submit"
                            (click)="enviarRepresentante(formRepresentante.value)">Subir
                            Representante Legal</button>
                    </div>
                </form>
            </div>
            <!-- end RL-->
            <div fxLayout="row" fxLayoutAlign="end center" class="pt-12" fxFlex="1 0 auto">
                <button mat-raised-button color="primary"       class="ml-4"
                    (click)="onCerrarClick()">Finalizar</button>
            </div>
        </mat-step>

    </mat-horizontal-stepper>


</mat-dialog-content>