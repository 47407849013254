import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { GlobalConstants } from 'app/common/global-constants'

import { PoliticasService } from 'app/public/politicas/politicas.service';

import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

import { FuseUtils } from '@fuse/utils';
import { SEOService } from 'app/seo-service.service';
import { LogoService } from 'app/logo.service';

@Component({
    selector     : 'politicas',
    templateUrl  : './politicas.component.html',
    styleUrls    : ['./politicas.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class PoliticasComponent implements OnInit
{
    faqs: any;
    faqsFiltered: any;
    step: number;
    searchInput: any;
    logoPath: String;


    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {PoliticasService} _politicasService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private http: HttpClient,
        private router: Router,
        private _politicasService: PoliticasService,
        private seoService: SEOService,
        private logoService: LogoService,
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the defaults
        this.searchInput = new FormControl('');
        this.step = 0;
        this.logoPath = this.logoService.getLogoPath();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

     // Private
     private _unsubscribeAll: Subject<any>;

    /**
     * On init
     */
    ngOnInit(): void
    {
        /* SEoService */
        this.seoService.setPageTitle('Políticas | Ok empleos');
        this.seoService.createLinkForCanonicalURL();
        this.seoService.setDescription('Nuestra sección de políticas de privacidad.');
        /* SEoService */
        var data = [
            {
                'question': 'Política de Privacidad',
                'answer': 'GESTIÓN ESTRATÉGICA DE TALENTO HUMANO S.A.S. comunica a sus grupos de interés la Política de Privacidad y Protección de datos personales que ha sido adoptada en cumplimiento del régimen legal vigente contenido en las Ley Estatutaria 1581 de 2012.GESTIÓN ESTRATÉGICA DE TALENTO HUMANO S.A.S. trata datos personales de sus grupos de interés en ejercicio de su objeto social.'
                + 'Para el cumplimiento de las obligaciones derivadas de los contratos comerciales y demás negocios jurídicos que celebra GESTIÓN ESTRATÉGICA DE TALENTO HUMANO S.A.S. en desarrollo de su objeto social.Para el cumplimiento de las obligaciones y/o compromisos derivados de las relaciones, contractuales o no, existentes con sus grupos de interés.Para el cumplimiento de las obligaciones legales que involucren datos personales de sus grupos de interés.Para la gestión comercial y relacionamiento con sus grupos de interés.Para comunicar a sus grupos de interés información sobre sus bienes, servicios, publicaciones, eventos de capacitación y actividades comerciales y publicitarias asociadas a su actividad empresarial, sea que ésta se realice o no de manera directa por GESTIÓN ESTRATÉGICA DE TALENTO HUMANO S.A.S.Para desplegar hacia sus grupos de interés actividades de responsabilidad social empresarial.'
                + 'En cada proceso empresarial, de acuerdo con los datos personales recolectados y el tratamiento a realizar, se informarán de manera previa en el respectivo aviso de privacidad las finalidades particulares, la razón social de GESTIÓN ESTRATÉGICA DE TALENTO HUMANO S.A.S. y sus datos de contacto. Si el tratamiento se efectuare por un encargado, este estará bajo las directrices generales de GESTIÓN ESTRATÉGICA DE TALENTO HUMANO S.A.S. y deberá dar cumplimiento a los derechos que le asisten al Titular y poner en marcha los mecanismos dispuestos para dar a conocer las Políticas de Privacidad y Protección de datos personales respectivas.Cualquier persona que haga parte de uno de los grupos de interés de GESTIÓN ESTRATÉGICA DE TALENTO HUMANO S.A.S. en su condición de Titular o legítimamente autorizado y en relación con el tratamiento de sus datos personales, tiene derecho a:'
                + 'Ejercer el derecho de Habeas Data, el cual consiste en conocer, actualizar y rectificar su información de carácter personal, así como cancelar los mismos en aquellos casos en que proceda, de acuerdo con las normas vigentes. Para ejercer este derecho el Titular de los datos debería seguir los canales dispuestos GESTIÓN ESTRATÉGICA DE TALENTO HUMANO S.A.S. los cuales se encuentran detallados en la presente Política.Solicitar evidencia de la existencia del consentimiento otorgado, salvo cuando exista una autorización legal para el tratamiento o el mismo se realice en el marco de una relación contractual.Ejercer las acciones que la Ley reconoce en materia de protección de datos personales y Habeas Data.'
                + 'El ejercicio del derecho de Habeas Data, así como el acceso por el Titular o quien demuestre un legítimo interés a sus datos personales, deberá realizarse a través de los canales dispuestos por GESTIÓN ESTRATÉGICA DE TALENTO HUMANO S.A.S. enviando para ello una comunicación escrita a través de alguno de los siguientes medios:Página Web: www.gesth.netCorreo electrónico: gerencia@gesth.netDirección: Carrera 20 b # 77 – 05, Oficina 204Para la radicación y atención de sus solicitudes el Titular o quien demuestre un legítimo interés a sus datos personales debe suministrar a lo menos la siguiente informaciónNombre completo y apellidosDatos de contacto (Dirección física y/o electrónica y teléfonos de contacto)Medios para recibir respuestaMotivo(s)/hecho(s) que dan lugar al reclamo con una breve descripción del derecho que desea ejercer (conocer, actualizar, rectificar, solicitar prueba de la autorización otorgada, revocarla, suprimir, acceder a la información)Firma (si aplica) y número de identificación.'
                + 'El término máximo previsto por la ley para resolver estas reclamaciones es de quince (15) días hábiles, contado a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, GESTIÓN ESTRATÉGICA DE TALENTO HUMANO S.A.S informará al interesado los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.Una vez cumplidos los términos señalados por la Ley 1581 de 2012 y las demás normas que la reglamenten o complementen, el Titular al que se deniegue, total o parcialmente, el ejercicio de los derechos de acceso, actualización, rectificación, supresión y revocación, podrá poner su caso en conocimiento de la Superintendencia de Industria y Comercio –Delegatura para la Protección de Datos Personales-.Esta Política ha sido aprobada por GESTIÓN ESTRATÉGICA DE TALENTO HUMANO S.A.S. y entrará en vigencia a partir del 1 de septiembre del año 2019.Los datos personales proporcionados se conservarán mientras se mantenga la relación contractual con el Titular de la información.Los datos personales proporcionados se conservarán mientras no se solicite su supresión por el interesado y siempre que no exista un deber legal de conservarlos.Los datos personales proporcionados se conservarán durante un plazo de dos (2) años a partir de la última confirmación de interés de parte del Titular.'
                + 'Te invitamos a revisar nuestras vacantes vigentes en -> https://okempleos.com/ y a visitar nuestra fan page en Facebook -> https://www.facebook.com/GesTalentoHumano/Fecha de actualización: Enero 29 de 2020.'
                
            }
        ];

        this.faqs = data; //this._politicasService.faqs;
        this.faqsFiltered = this.faqs;

        this.searchInput.valueChanges
            .subscribe(searchText => {
                this.faqsFiltered = FuseUtils.filterArrayByString(this.faqs, searchText);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set step
     *
     * @param {number} index
     */
    setStep(index: number): void
    {
        this.step = index;
    }

    /**
     * Next step
     */
    nextStep(): void
    {
        this.step++;
    }

    /**
     * Previous step
     */
    prevStep(): void
    {
        this.step--;
    }
    
}
