import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'modal-limite-vacantes',
  templateUrl: './modal-limite-vacantes.component.html',
  styleUrls: ['./modal-limite-vacantes.component.scss']
})
export class ModalLimiteVacantesComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalLimiteVacantesComponent>,
  
    ) {

    }

  ngOnInit(): void {
   

  }

  onCerrarClick(): void {
    this.dialogRef.close();
  }

}
