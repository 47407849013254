import { Component, OnInit, Inject, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthStateService } from 'app/pixcore/shared/auth-state.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { RegistroCandidatosComponent } from '../registro-candidatos.component';
import { GlobalConstants } from 'app/common/global-constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-modal-massive-loads',
  templateUrl: './modal-massive-loads.component.html',
  styleUrls: ['./modal-massive-loads.component.scss']

})
export class ModalMassiveLoadsComponent implements OnInit, OnChanges {

  vacantes = []
  idNewVacant = 0;
  filterBy: string;
  aplicacionesTotales: number;
  aplicaciontesAplicadas: number
  aplicacionesPreseleccionados: number;
  aplicacionesFinalistas: number;
  aplicacionesContratados: number;
  aplicacionesDescartados: number;
  view = 1;
  searchTerm:string="";
  file_selected=null;
  sending = false;
  finished = false;
  total = 0;
  complete = 0;
  errors = 0;
  success = 0;
  current = 1;
  urlLocal = GlobalConstants.apiURL;
  URL = GlobalConstants.URL;
  results :any =[];

  constructor(
    public dialogRef: MatDialogRef<ModalMassiveLoadsComponent>,
    private authState: AuthStateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _matSnackBar: MatSnackBar,
    private router: Router,
    private _registroCandidato: RegistroCandidatosComponent,
    private httpClient: HttpClient,
  ) {
  }
  
  ngOnChanges(): void {
    
  }
  ngOnInit(): void {
   

  }

  onCerrarClick(): void {
    this.dialogRef.close();
  }


  onFileSelected(value): void {
    this.file_selected = value;    
  }

  sendData(): void {
    this.complete = 0;
    this.sending = true;
    this.total = 0;
    this.errors = 0;
    this.success = 0;

    const reader = new FileReader();

    reader.onload = () => {
    
      const csv=require('csvtojson');
      const csvData = reader.result as string;

      csv({
          noheader:false,
          output: "csv"
      })
      .fromString(csvData)
      .then((csv)=>{ 
          this.total = csv.length;
          csv.forEach(csvrow => {

            this.sendCandidate(csvrow).then( res => {
             
            });

          });
          
      })
    
    }
    reader.readAsText(this.file_selected[0]);
  }

  async sendCandidate(candidate) : Promise<any>{
    const headers = new HttpHeaders();
    const formData = {
      "email": candidate[0],
      "user_type": "1",
      "accept_terms": candidate[12]==="Si" ? true : false,
      "password": this.limpianumeros(candidate[6]),
      "password_confirmation": this.limpianumeros(candidate[6]),
      "tipo_documento_id": candidate[5],
      "numero_identificacion": this.limpianumeros(candidate[6]),
      "primer_apellido": this.limpiatexto(candidate[1]),
      "segundo_apellido":  this.limpiatexto(candidate[2]),
      "primer_nombre":  this.limpiatexto(candidate[3]),
      "segundo_nombre" :  this.limpiatexto(candidate[4]),
      "fecha_nacimiento": candidate[11],
      "genero_id": candidate[8],
      "ciudad_id": candidate[10],
      "tipo_persona": null,
      "telefono": this.limpianumeros([9]),
      "telefono_2": null,
      "fecha_expedicion": candidate[14],
      "nivel_educativo_id": candidate[7],
      "vacante_id": candidate[13]? candidate[13] : null
  };

    this.httpClient.post(`${this.urlLocal}register_user_wc`, formData, { headers: headers }).subscribe(
      (result: any) => {
          if (!result.errors) {

            this.success++;
            formData['status'] = 'OK';
            this.results.push(formData);
          } else if (result.errors.email) {
            this.errors++;
            formData['status'] = result.errors['email'];
            this.results.push(formData);
          } else if (result.errors['numero_identificacion']) {
            this.errors++;
            formData['status'] = result.errors['numero_identificacion'];
            this.results.push(formData);
          } else {
            this.errors++;
            formData['status'] = 'Error no identificado';
            this.results.push(formData);
          }

          this.current++;
          this.complete  = (  this.current /  this.total) * 100;
          if( this.complete == 100){
            this.finished = true;
            this.sending = false;
          }

      });

  }

  downloadFile(filename='data_result') {
    let final_name= filename.substring(0,filename.length -4) +'_resultados';
    let csvData = this.ConvertToCSV(this.results, ['email',	'primer_apellido',	'segundo_apellido',	'primer_nombre',	'segundo_nombre',	'tipo_documento_id',	'numero_identificacion',	'nivel_educativo_id',	'genero_id',	'telefono',	'ciudad_id',	'fecha_nacimiento',	'accept_terms',	'vacante_id',	'fecha_expedicion', 'status']);
    // console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", final_name + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
}

ConvertToCSV(objArray, headerList) {
  let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  let str = '';
  let row = 'No,';
  for (let index in headerList) {
   row += headerList[index] + ',';
  }
  row = row.slice(0, -1);
  str += row + '\r\n';
  for (let i = 0; i < array.length; i++) {
   let line = (i+1)+'';
   for (let index in headerList) {
    let head = headerList[index];
    line += ',' + array[i][head];
   }
   str += line + '\r\n';
  }
  return str;
 }

 limpiatexto(texto){
  return texto.replace(/[\p{Emoji}\p{Pd}]/gu, "");
 }

 limpianumeros(texto){
  return String(texto).match(/\d+/g).join("");
 }



}
