import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';


import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { TokenService } from 'app/pixcore/shared/token.service';
import { AuthStateService } from 'app/pixcore/shared/auth-state.service';
import { GlobalConstants } from 'app/common/global-constants';
import { GlobalTexts } from 'app/common/global-texts';

import { SocialAuthService } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";

@Component({
    selector     : 'toolbar',
    templateUrl  : './toolbar.component.html',
    styleUrls    : ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy
{
    loggedIn: boolean;
    urlLocal = GlobalConstants.apiURL;
    isCandidato: boolean;
    candidatoId: number;
    porcentaje: number;
    itemsChecklist = [];
    isChecklist = false;
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    isAdminMode = false;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    userData: any;
    urlLocalStorage = GlobalConstants.localStorageURL;
    planEmpresa: string;
    role = '';

    user: SocialUser;
    loggedInS: boolean;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private router: Router,
        private token: TokenService,
        private authState: AuthStateService,
        private _httpClient: HttpClient,
        private _socialAuthService: SocialAuthService
    )
    {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon : 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon : 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon : 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon : 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon : 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id   : 'es',
                title: 'Spanish',
                flag : 'es'
            },
            {
                id   : 'en',
                title: 'English',
                flag : 'us'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this._socialAuthService.authState.subscribe((user) => {
            this.user = user;
            this.loggedInS = (user != null);
          });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.authState.onDataChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((value) => {


            if (value === 1) { 
                this.isCandidato = true
                this.candidatoId = this.authState.getCandidatoId();

                if (this.candidatoId) {
                    this.isChecklist = true
                    this.getLista();
                }

            } else { 
                this.isCandidato = false; 
            }



            if (value !== null) {

                if(localStorage.getItem('admin_mode')) {
                    this.isAdminMode = true;
                }else {
                    this.isAdminMode = false;
                }

                this.userData = this.authState.getUserSession();
                this.planEmpresa = 'Bienvenida';

                if (this.userData && this.userData.empresa)
                {                    
                    if (this.userData.empresa && this.userData.empresa.planes[0])
                    {
                        this.planEmpresa = this.userData.empresa.planes[0] ? this.userData.empresa.planes[0].planes.nombre : 'Bienvenida';
                        
                    }

                    this.role = this.nombreRol(this.userData.role_id);
                    
                }
            }           
        });
     
        this.authState.onLoginChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((value) => {
            this.loggedIn = value;

        })
        
        //@TODO: Ejecutar cuando se lleve a cabo el Servicio
       

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {id: this._translateService.currentLang});
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    getLista(): any
    {     
        if (this.candidatoId !== null){
            this._httpClient.get(`${this.urlLocal}candidato_progress/${this.candidatoId}`)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((items: any) => { 
                    this.porcentaje = this.obtenerPorcentaje(items);
                    this.itemsChecklist = this.asignarLink(items);
            });
        }
        
    }

    obtenerPorcentaje(lista): number {
        let cuenta = 0;

        for (const item of lista) {
            if (item.checked) {
                cuenta = cuenta + item.porcentaje;
            }
        }
        return cuenta;
    }

    goToLinkItemChecklist(link): void {
        this.router.navigate([`${link}`]);
    }

    asignarLink(lista): any[] {

        const listaLink = lista;
        listaLink.forEach(x => {

            switch(x.name) { 
                case 'Datos personales': { 
                   x.link = `/candidato/informacionpersonal`;
                   x.icon = `datos.gif`;
                   break; 
                }

                case 'Hoja de vida': { 
                    x.link = `/candidato/informacionpersonal`;
                    x.icon = `hojavida.gif`;
                    break; 
                }

                case 'Perfil': { 
                    x.link = `/candidato/informacionpersonal`;
                    x.icon = `perfil.gif`;
                    break; 
                }

                case 'Video presentación': { 
                    x.link = `/candidato/informacionpersonal`;
                    x.icon = `video.gif`;
                    break; 
                }

                case 'Experiencia': { 
                    x.link = `/candidato/informacionacademica/experiencia`;
                    x.icon = `laboral.gif`;
                   break; 
                }
                case 'Informacion academica': { 
                    x.link = `/candidato/informacionacademica/educacion`;
                    x.icon = `academico.gif`;
                   break; 
                }
                case 'Foto subida': { 
                    x.link = `/usuario`;
                    x.icon = `foto.gif`;
                   break; 
                }
                case 'Evaluación de competencias': { 
                    x.link = `/candidato/evaluacioncompetencias`;
                    x.icon = `evaluacion.gif`;
                   break; 
                }
                default: { 
                    x.link = `/`
                    break; 
                 }
             } 
        });
        return listaLink;
    }


    nombreRol(roleId: number): string {

        let nombreRol;
        switch (roleId) {
            case 1: {
                nombreRol = 'Administrador';
                break;
            }
            case 2: {
                nombreRol = 'Moderador';
                break;
            }
            case 3: {
                nombreRol = 'Reclutador';
                break;
            }
            case 4: {
                nombreRol = 'Contabilidad';
                break;
            }

        }

        return nombreRol;
    }
    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void
    {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void
    {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    /**
     * Logout
     *
     */
    logout(): void
    {

        if (this.loggedInS){
            this._socialAuthService.signOut().then(() => {
                this.authState.setAuthState(false);
                this.token.removeToken();
                localStorage.removeItem('admin_mode');
                localStorage.removeItem('aplicacionesIds')
                this.router.navigate(['login']);
            });
        } else {
            this.authState.setAuthState(false);
            this.token.removeToken();
            localStorage.removeItem('admin_mode');
            localStorage.removeItem('aplicacionesIds')
            this.router.navigate(['login']);
        }
    }

    
    verHojaDeVida(): void
    {
        this.router.navigate([`/candidato/hoja-de-vida/${this.candidatoId}`]);
    }

    verPerfil(): void
    {
        this.router.navigate(['/usuario']);
    }

    toggleNotifications(activated: boolean) {
        // call api set notifications
            return new Promise((resolve, reject) => {
                this._httpClient.post(`${this.urlLocal}candidato_notificacion`, {'activated': activated})
                .subscribe((response: any) => {
                    // set local data
                    this.userData.candidato.send_notifications = activated;
                    resolve(response);
                }, reject);
        });
    }

}
