<button class="p-8" mat-button (click)="logout()">
    <mat-icon>exit_to_app</mat-icon>
    <span> Salir </span>
</button>

<mat-dialog-content fxLayout="column" fxLayoutAlign="center center">

    <mat-horizontal-stepper linear #stepper labelPosition="bottom" class="width-100 min-height-100">

        <mat-step [stepControl]="personalDataForm">
            <ng-template *ngIf="hideLabelStep" matStepLabel>Datos personales</ng-template>
            <form name="personalDataForm" id="personalDataForm" fxLayout="column" fxFlex="1 0 auto"
                [formGroup]="personalDataForm">

                <div *ngIf="!hideLabelStep" class="texto label-mobile p-8 h3">Datos personales</div>

                <div fxLayout="column" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayout.gt-sm="row">

                    <mat-form-field appearance="outline" fxFlex="100" class="pr-sm-4 width-100" fxFlex.gt-sm="50">
                        <mat-label>Primer apellido*</mat-label>
                        <input matInput formControlName="primer_apellido" />
                        <mat-icon fxHide fxShow.gt-sm matSuffix class="secondary-text">account_circle</mat-icon>
                        <mat-error *ngIf="personalDataForm.get('primer_apellido').hasError('required')">El Primer
                            apellido es requerido!</mat-error>
                        <mat-error *ngIf="personalDataForm.get('primer_apellido').hasError('minlength')">Mínimo 3
                            caracteres</mat-error>
                        <mat-error *ngIf="personalDataForm.get('primer_apellido').hasError('maxlength')">Máximo 100
                            caracteres</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="100" class="pl-sm-4 width-100" fxFlex.gt-sm="50">
                        <mat-label>Primer nombre*</mat-label>
                        <input matInput formControlName="primer_nombre" />
                        <mat-icon fxHide fxShow.gt-sm matSuffix class="secondary-text">account_circle</mat-icon>
                        <mat-error *ngIf="personalDataForm.get('primer_nombre').hasError('required')">El Primer nombre
                            es requerido!</mat-error>
                        <mat-error *ngIf="personalDataForm.get('primer_nombre').hasError('minlength')">Mínimo 3
                            caracteres</mat-error>
                        <mat-error *ngIf="personalDataForm.get('primer_nombre').hasError('maxlength')">Máximo 100
                            caracteres</mat-error>
                    </mat-form-field>


                </div>

                <div fxLayout="column" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayout.gt-sm="row">
                    <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="50" class="pr-sm-4 width-100">
                        <mat-label>Tipo de documento</mat-label>
                        <mat-select formControlName="tipo_documento_id" required>
                            <mat-option *ngFor="let item of tiposDocumento" [value]="item.id">
                                {{ item.nombre }}
                            </mat-option>
                        </mat-select>
                        <mat-icon fxHide fxShow.gt-sm matSuffix class="secondary-text">menu</mat-icon>
                        <mat-error>El Tipo de documento es requerido!</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="50" class="pl-sm-4 width-100">
                        <mat-label>Número de documento*</mat-label>
                        <input [readonly]="DocReadOnly" matInput formControlName="numero_documento" />
                        <mat-icon fxHide fxShow.gt-sm matSuffix class="secondary-text">payment</mat-icon>
                        <mat-error *ngIf="personalDataForm.get('numero_documento').hasError('required')">El Número de
                            documento es requerido!</mat-error>
                        <mat-error
                            *ngIf="personalDataForm.get('numero_documento').hasError('minlength') && !personalDataForm.get('numero_documento').hasError('pattern')">
                            Mínimo 5 caracteres</mat-error>
                        <mat-error
                            *ngIf="personalDataForm.get('numero_documento').hasError('maxlength') && !personalDataForm.get('numero_documento').hasError('pattern')">
                            Máximo 100 caracteres</mat-error>
                        <mat-error *ngIf="personalDataForm.get('numero_documento').hasError('pattern')">Solo números
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="column" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayout.gt-sm="row">
                    <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="30" class="pr-sm-4 width-100">
                        <mat-label>Fecha de nacimiento*</mat-label>
                        <input formControlName="fecha_nacimiento" matInput [matDatepicker]="picker" [min]="minDate"
                            [max]="maxDate" (dateChange)="cambioFechaNacimiento($event)" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error>La Fecha de nacimiento es requerida!</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="35" class="pl-sm-4 width-100">
                        <mat-label>Sexo</mat-label>
                        <mat-select formControlName="genero_id" required>
                            <div *ngFor="let item of generos">
                                <mat-option *ngIf="item.nombre != 'Indiferente'" [value]="item.id">
                                    {{ item.nombre }}
                                </mat-option>
                            </div>
                        </mat-select>
                        <mat-icon fxHide fxShow.gt-sm matSuffix class="secondary-text">wc</mat-icon>
                        <mat-error>El Sexo es requerido!</mat-error>
                    </mat-form-field>

                    <div appearance="outline" fxFlex="100" fxFlex.gt-sm="35" class="pl-sm-4 width-100">

                        <ng-select class="select pl-sm-4" appearance="outline" placeholder="Orientación Sexual"
                            addTagText="Agregar orientacion" [addTag]="true" formControlName="orientacion_id">
                            <div *ngFor="let oreint of orientaciones">
                                <ng-option *ngIf="oreint.nombre !== 'Otra orientacion'" [value]="oreint.id">
                                    {{oreint.nombre}}
                                </ng-option>
                                <ng-option *ngIf="oreint.nombre === 'Otra orientacion'" [value]="oreint.id"
                                    formControlName="orientacion_detail">
                                    {{oreint.nombre}}
                                </ng-option>
                            </div>
                        </ng-select>

                    </div>

                </div>


                <div fxLayout="row" fxLayoutAlign="end center" fxFlex="1 0 auto" class="mt-16">
                    <button mat-raised-button color="primary" [disabled]="personalDataForm.invalid"
                        matStepperNext>Siguiente</button>
                </div>
            </form>

        </mat-step>


        <mat-step [stepControl]="contactForm">
            <ng-template *ngIf="hideLabelStep" matStepLabel>Contacto</ng-template>
            <form name="contactForm" id="contactForm" fxLayout="column" fxFlex="1 0 auto" [formGroup]="contactForm">

                <div *ngIf="!hideLabelStep" class="texto label-mobile p-8 h3">Contacto</div>

                <div fxLayout="column" fxLayoutAlign="start center" fxLayout.gt-sm="row" fxFlex="1 0 auto">

                    <mat-form-field appearance="outline" fxFlex="100" class="pr-sm-4 width-100" fxFlex.gt-sm="50">
                        <mat-label>Teléfono*</mat-label>
                        <input matInput formControlName="telefono" type="tel" />
                        <mat-icon fxHide fxShow.gt-sm matSuffix class="secondary-text">settings_phone</mat-icon>
                        <mat-error *ngIf="contactForm.get('telefono').hasError('required')">El Teléfono es requerido!
                        </mat-error>
                        <mat-error *ngIf="contactForm.get('telefono').hasError('pattern')">Solo escribe números
                        </mat-error>
                        <mat-error
                            *ngIf="!contactForm.get('telefono').hasError('pattern') && contactForm.get('telefono').hasError('minlength')">
                            Mínimo 5 caracteres</mat-error>
                        <mat-error
                            *ngIf="!contactForm.get('telefono').hasError('pattern') && contactForm.get('telefono').hasError('maxlength')">
                            Máximo 20 caracteres</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="100" class="pl-sm-4 width-100" fxFlex.gt-sm="50">
                        <mat-label>Correo electrónico*</mat-label>
                        <input matInput formControlName="correo" type="email" email />
                        <mat-icon fxHide fxShow.gt-sm matSuffix class="secondary-text">alternate_email</mat-icon>
                        <mat-error *ngIf="contactForm.get('correo').hasError('required')">El Correo Electrónico es
                            requerido!</mat-error>
                        <mat-error *ngIf="contactForm.get('correo').hasError('email')">El correo no es válido
                        </mat-error>
                        <mat-error *ngIf="contactForm.get('correo').hasError('minlength')">Mínimo 5 caracteres
                        </mat-error>
                        <mat-error *ngIf="contactForm.get('correo').hasError('maxlength')">Máximo 100 caracteres
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="column" fxLayoutAlign="start center" fxLayout.gt-sm="row" fxFlex="1 0 auto">


                    <div fxFlex="100" fxFlex.gt-sm="50" class="pr-sm-4 mb-8 ng-select-container-custom width-100">

                        <ng-select appearance="outline" [items]="ciudades" bindValue="id" bindLabel="nombre"
                            placeholder="Ciudad*" formControlName="ciudad_id">
                        </ng-select>

                        <div class="error-container">
                            <mat-error *ngIf="contactForm.get('ciudad_id').hasError('required')">La Ciudad es requerida!
                            </mat-error>
                        </div>

                    </div>

                    <div *ngIf="contactForm.get('ciudad_id').value === 524" fxFlex="100" fxFlex.gt-sm="50"
                        class="pl-sm-4 mb-8 ng-select-container-custom width-100">

                        <ng-select appearance="outline" [items]="localidades" bindValue="id" bindLabel="nombre"
                            placeholder="Localidad" formControlName="localidad_id">
                        </ng-select>

                    </div>
                </div>

                <div fxLayout="column" fxLayoutAlign="start center" fxLayout.gt-sm="row" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class="pr-sm-4 width-100">
                        <mat-label>Dirección*</mat-label>
                        <input matInput formControlName="direccion" />
                        <mat-icon fxHide fxShow.gt-sm matSuffix class="secondary-text">pin_drop</mat-icon>
                        <mat-error *ngIf="contactForm.get('direccion').hasError('required')">La Dirección es requerida!
                        </mat-error>
                        <mat-error *ngIf="contactForm.get('direccion').hasError('minlength')">Mínimo 5 caracteres
                        </mat-error>
                        <mat-error *ngIf="contactForm.get('direccion').hasError('maxlength')">Máximo 100 caracteres
                        </mat-error>

                    </mat-form-field>
                </div>

                <div fxLayout="column" fxLayoutAlign="start center" fxLayout.gt-sm="row" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class="pl-sm-4 width-100">
                        <mat-label>Barrio*</mat-label>
                        <input matInput formControlName="barrio" />
                        <mat-icon fxHide fxShow.gt-sm matSuffix class="secondary-text">room</mat-icon>
                        <mat-error *ngIf="contactForm.get('barrio').hasError('required')">El Barrio es requerido!
                        </mat-error>
                        <mat-error *ngIf="contactForm.get('barrio').hasError('minlength')">Mínimo 4 caracteres
                        </mat-error>
                        <mat-error *ngIf="contactForm.get('barrio').hasError('maxlength')">Máximo 100 caracteres
                        </mat-error>

                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="end center" fxFlex="1 0 auto" class="mt-16">
                    <button class="mr-4" mat-raised-button color="secundary" matStepperPrevious>Anterior</button>
                    <button mat-raised-button color="primary" [disabled]="contactForm.invalid"
                        matStepperNext>Siguiente</button>
                </div>
            </form>

        </mat-step>


        <mat-step [stepControl]="informationForm">
            <ng-template *ngIf="hideLabelStep" matStepLabel>Información extra</ng-template>

            <form name="informationForm" id="informationForm" fxLayout="column" fxFlex="1 0 auto"
                [formGroup]="informationForm">

                <div *ngIf="!hideLabelStep" class="texto label-mobile p-8 h3">Información extra</div>

                <div fxLayout="column" fxLayoutAlign="start center" fxLayout.gt-sm="row" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class="pr-sm-4 width-100" fxFlex.gt-sm="50">
                        <mat-label>Grupo sanguíneo</mat-label>
                        <mat-select formControlName="grupo_sanguineo_id" required>
                            <mat-option *ngFor="let item of gruposSanguineos" [value]="item.id">
                                {{ item.nombre }}
                            </mat-option>
                        </mat-select>
                        <mat-icon fxHide fxShow.gt-sm matSuffix class="secondary-text">menu</mat-icon>
                        <mat-error>El Grupo sanguíneo es requerido!</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="100" class="pl-sm-4 width-100" fxFlex.gt-sm="50">
                        <mat-label>Rh</mat-label>
                        <mat-select formControlName="rh_id" required>
                            <mat-option *ngFor="let item of rh" [value]="item.id">
                                {{ item.nombre }}
                            </mat-option>
                        </mat-select>
                        <mat-icon fxHide fxShow.gt-sm matSuffix class="secondary-text">menu</mat-icon>
                        <mat-error>El Rh es requerido!</mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="column" fxLayoutAlign="start center" fxLayout.gt-sm="row" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class="pr-sm-4 width-100" fxFlex.gt-sm="50">
                        <mat-label>Nivel educativo</mat-label>
                        <mat-select formControlName="nivel_educativo_id" required>
                            <mat-option *ngFor="let item of nivelEducativo" [value]="item.id">
                                {{ item.nombre }}
                            </mat-option>
                        </mat-select>
                        <mat-icon fxHide fxShow.gt-sm matSuffix class="secondary-text">school</mat-icon>
                        <mat-error>El Nivel educativo requerido!</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="100" class="pl-sm-4 width-100" fxFlex.gt-sm="50">
                        <mat-label>Experiencia</mat-label>
                        <mat-select formControlName="experiencia_id" required>
                            <mat-option *ngFor="let item of experiencia" [value]="item.id">
                                {{ item.nombre }}
                            </mat-option>
                        </mat-select>
                        <mat-icon fxHide fxShow.gt-sm matSuffix class="secondary-text">timeline</mat-icon>
                        <mat-error>La Experiencia es requerida!</mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="column" fxLayoutAlign="start center" fxLayout.gt-sm="row" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class="pr-sm-4 width-100" fxFlex.gt-sm="50">
                        <mat-label>Estado civíl</mat-label>
                        <mat-select formControlName="estado_civil_id" required>
                            <mat-option *ngFor="let item of estadoCivil" [value]="item.id">
                                {{ item.nombre }}
                            </mat-option>
                        </mat-select>
                        <mat-icon fxHide fxShow.gt-sm matSuffix class="secondary-text">school</mat-icon>
                        <mat-error>El Estado Civil es requerido!</mat-error>
                    </mat-form-field>


                    <mat-form-field appearance="outline" fxFlex="100" class="pl-sm-4 width-100" fxFlex.gt-sm="50">
                        <mat-label>Aspiración salarial</mat-label>
                        <mat-select formControlName="salario_id" required>
                            <mat-option *ngFor="let item of salarios" [value]="item.id">
                                {{ item.nombre }}
                            </mat-option>
                        </mat-select>
                        <mat-icon fxHide fxShow.gt-sm matSuffix class="secondary-text">room</mat-icon>
                        <mat-error>La aspiración salarial es requerida!</mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row" fxFlex.gt-sm="1 0 auto"
                    class="mb-12">
                    <div class="mr-sm-8 mb-8 mb-sm-0" fxFlex="100" fxFlex.gt-sm="50">
                        <mat-label>Disponibilidad de viajar*:</mat-label>
                        <br />
                        <mat-radio-group formControlName="disponibilidad_viaje" aria-label="Disponibilidad de viajar">
                            <mat-radio-button [value]="1">Si</mat-radio-button>
                            <mat-radio-button class="pl-8" [value]="2">No</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="ml-sm-8" fxFlex="100" fxFlex.gt-sm="50">
                        <mat-label>Disponibilidad de cambio de residencia*:</mat-label>
                        <br />
                        <mat-radio-group formControlName="disponibilidad_cambio_vivienda"
                            aria-label="Disponibilidad de cambio de residencia">
                            <mat-radio-button [value]="1">Si</mat-radio-button>
                            <mat-radio-button class="pl-8" [value]="2">No</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="ml-sm-8" fxFlex="100" fxFlex.gt-sm="50">
                        <mat-label>Se reconoce como parte de una población focalizada?*:</mat-label>
                        <br />
                        <mat-radio-group formControlName="poblacion_focalizada"
                            aria-label="Se reconoce como parte de una población focalizada?">
                            <mat-radio-button [value]="1">Si</mat-radio-button>
                            <mat-radio-button class="pl-8" [value]="0" [checked]='true'>No</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>

                <div *ngIf="informationForm.get('poblacion_focalizada').value === 1" fxLayout="column"
                    fxLayoutAlign="start center" fxLayout.gt-sm="row" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class="pr-sm-4 width-100" fxFlex.gt-sm="50">
                        <mat-label>Grupo étnico</mat-label>
                        <mat-select formControlName="grupo_etnico_id">
                            <mat-option *ngFor="let item of gruposEtnicos" [value]="item.id">
                                {{ item.nombre }}
                            </mat-option>
                        </mat-select>
                        <mat-error>El Grupo étnico es requerido!</mat-error>
                    </mat-form-field>


                    <mat-form-field appearance="outline" fxFlex="100" class="pl-sm-4 width-100" fxFlex.gt-sm="50">
                        <mat-label>Tipo discapacidad</mat-label>
                        <mat-select formControlName="discapacidad_id">
                            <mat-option *ngFor="let item of condicionDiscapacidad" [value]="item.id">
                                {{ item.nombre }}
                            </mat-option>
                        </mat-select>
                        <mat-error>La aspiración salarial es requerida!</mat-error>
                    </mat-form-field>
                </div>

                <div *ngIf="informationForm.get('poblacion_focalizada').value === 1" fxLayout="column"
                    fxLayoutAlign="start center" fxLayout.gt-sm="row" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class="pr-sm-4 width-100" fxFlex.gt-sm="50">
                        <mat-label>Tipo de población</mat-label>
                        <mat-select formControlName="tipo_poblacion_id">
                            <mat-option *ngFor="let item of tipoPoblacion" [value]="item.id">
                                {{ item.nombre }}
                            </mat-option>
                        </mat-select>
                        <mat-error>El tipo de población es requerido!</mat-error>
                    </mat-form-field>


                    <mat-form-field appearance="outline" fxFlex="100" class="pl-sm-4 width-100" fxFlex.gt-sm="50">
                        <mat-label>Condición en salud mental</mat-label>
                        <mat-select formControlName="salud_mental_id">
                            <mat-option *ngFor="let item of condicionSaludMental" [value]="item.id">
                                {{ item.nombre }}
                            </mat-option>
                        </mat-select>
                        <mat-error>La condición de salud mental es requerida!</mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="end center" fxFlex="1 0 auto">
                    <button class="mr-4" mat-raised-button color="secundary" matStepperPrevious>Anterior</button>
                    <button mat-raised-button color="primary" [disabled]="informationForm.invalid"
                        matStepperNext>Siguiente</button>
                </div>
            </form>

        </mat-step>

        <mat-step [stepControl]="profileForm">
            <ng-template *ngIf="hideLabelStep" matStepLabel>Perfil</ng-template>
            <form name="profileForm" id="profileForm" fxLayout="column" fxFlex="1 0 auto" [formGroup]="profileForm">

                <div *ngIf="!hideLabelStep" class="texto label-mobile p-8 h3">Perfil</div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" floatLabel="always" class="w-100-p" fxFlex="100">
                        <mat-label>Perfil*</mat-label>
                        <textarea matInput
                            placeholder="Ingresa tu perfil completo con información relevante sobre ti, tu preparación, experiencia y competencias."
                            name="perfil" formControlName="perfil" rows="6">
                        </textarea>
                        <mat-error *ngIf="profileForm.get('perfil').hasError('minlength')">Mínimo 10 caracteres
                        </mat-error>
                        <mat-error *ngIf="profileForm.get('perfil').hasError('maxlength')">Máximo 2000 caracteres
                        </mat-error>
                        <mat-error *ngIf="profileForm.get('perfil').hasError('required')">El perfil es requerido!
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="end center" fxFlex="1 0 auto" class="mt-16">
                    <button class="mr-4" mat-raised-button color="secundary" matStepperPrevious>Anterior</button>
                    <button mat-raised-button color="primary" [disabled]="profileForm.invalid"
                        matStepperNext>Siguiente</button>
                </div>

            </form>

        </mat-step>


        <mat-step [stepControl]="educacionForm">
            <ng-template *ngIf="hideLabelStep" matStepLabel>Educación</ng-template>
            <form name="educacionForm" id="educacionForm" fxLayout="column" fxFlex="1 0 auto"
                [formGroup]="educacionForm">

                <div *ngIf="!hideLabelStep" class="texto label-mobile p-8 h3">Educación</div>

                <div fxLayout="column" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayout.gt-sm="row"
                    class="mb-32 mb-sm-8">

                    <mat-form-field appearance="outline" fxFlex="100" class="width-100 mb-8 mb-sm-4">
                        <mat-label>Último Título Obtenido</mat-label>
                        <input matInput formControlName="titulo_obtenido" required>
                        <mat-icon fxHide fxShow.gt-sm matSuffix class="secondary-text">account_circle</mat-icon>
                        <mat-hint>
                            <mat-icon>error</mat-icon>Recuerda que podrás agregar más Estudios desde tu Perfil
                        </mat-hint>
                        <mat-error *ngIf="educacionForm.get('titulo_obtenido').hasError('required')">El Título es
                            requerido!</mat-error>
                        <mat-error *ngIf="educacionForm.get('titulo_obtenido').hasError('minlength')">Mínimo 5
                            caracteres</mat-error>
                        <mat-error *ngIf="educacionForm.get('titulo_obtenido').hasError('maxlength')">Máximo 100
                            caracteres</mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="column" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayout.gt-sm="row">
                    <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="50"
                        class="pr-sm-4 width-100 mb-8 mb-sm-0">
                        <mat-label>Nivel académico</mat-label>
                        <mat-select formControlName="nivel_academico_id" required>
                            <mat-option *ngFor="let item of nivelEducativo" [value]="item.id">
                                {{ item.nombre }}
                            </mat-option>
                        </mat-select>
                        <mat-icon fxHide fxShow.gt-sm matSuffix class="secondary-text">school</mat-icon>
                        <mat-error>El Nivel Académico es requerido!</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="50"
                        class="pl-sm-4 width-100 mb-8 mb-sm-0">
                        <mat-label>Estado de los estudios</mat-label>
                        <mat-select formControlName="estado_estudios_id" (selectionChange)="changeSelect($event)"
                            required>
                            <mat-option *ngFor="let item of estadosEstudios" [value]="item.id">
                                {{ item.nombre }}
                            </mat-option>
                        </mat-select>
                        <mat-icon fxHide fxShow.gt-sm matSuffix class="secondary-text">school</mat-icon>
                        <mat-error>El Estado de los estudios es requerido!</mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="column" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayout.gt-sm="row">
                    <mat-form-field *ngIf="educacionForm.get('estado_estudios_id').value != 1550" appearance="outline" fxFlex="100" fxFlex.gt-sm="50" class="pr-sm-4 width-100"
                        [matTooltip]="'Sólo puedes seleccionar fechas entre tu día de nacimiento (' + minDateInicioText + ') y la fecha actual'"
                        matTooltipPosition="above">
                        <mat-label>Inicio</mat-label>

                        <input formControlName="fecha_inicio" required matInput
                            [matDatepicker]="picker_inicio_educacion" [max]="maxDateInicio" [min]="minDateInicio"
                            (dateChange)="cambioFechaInicialEducacion($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker_inicio_educacion"></mat-datepicker-toggle>
                        <mat-datepicker #picker_inicio_educacion></mat-datepicker>
                        <mat-error>La Fecha inicio es requerida!</mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="educacionForm.get('estado_estudios_id').value === 1550"
                        [matTooltip]="'Sólo puedes seleccionar fechas entre tu día de nacimiento (' + minDateInicioText + ') y la fecha actual'"
                        appearance="outline" fxFlex="100" fxFlex.gt-sm="50" class="pl-sm-4 width-100">
                        <mat-label>Finalización</mat-label>
                        <input formControlName="fecha_fin" matInput [matDatepicker]="picker_fin_educacion"
                            [max]="maxDateInicio" [min]="minDateInicio">
                        <mat-datepicker-toggle matSuffix [for]="picker_fin_educacion"></mat-datepicker-toggle>
                        <mat-error *ngIf="educacionForm.get('fecha_fin').hasError('required')">La Fecha Fin es
                            requerida!</mat-error>
                        <mat-datepicker #picker_fin_educacion></mat-datepicker>
                    </mat-form-field>
                </div>


                <div fxLayout="row" fxLayoutAlign="end center" fxFlex="1 0 auto" class="mt-16">
                    <button class="mr-4" mat-raised-button color="secundary" matStepperPrevious>Anterior</button>
                    <button mat-raised-button color="primary" [disabled]="educacionForm.invalid"
                        matStepperNext>Siguiente</button>
                </div>
            </form>

        </mat-step>

        <mat-step [stepControl]="experienciaForm" [optional]="!hasExperiencia">
            <ng-template *ngIf="hideLabelStep" matStepLabel>Experiencia</ng-template>

            <div fxLayout="column">

                <div *ngIf="!hideLabelStep" class="texto label-mobile p-8 h3">Experiencia</div>

                <div fxLayout="row" fxLayoutAlign="start start" class="mb-12">
                    <div class="mr-sm-8 mb-8 mb-sm-0" fxFlex="100">
                        <mat-label>¿Tienes experiencia laboral?</mat-label>
                        <br />
                        <mat-radio-group (change)="hasExperienciaChange($event)">
                            <mat-radio-button [value]="true">Si</mat-radio-button>
                            <mat-radio-button class="pl-8" [value]="false">No</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>

                <form *ngIf="hasExperiencia" name="experienciaForm" id="experienciaForm" fxLayout="column"
                    fxFlex="1 0 auto" [formGroup]="experienciaForm">

                    <div fxLayout="column" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayout.gt-sm="row"
                        class="mb-8">
                        <mat-form-field appearance="outline" fxFlex="100" class="width-100">
                            <mat-label>Nombre de la empresa</mat-label>
                            <input matInput formControlName="empresa" required>
                            <mat-icon fxHide fxShow.gt-sm matSuffix class="secondary-text">business</mat-icon>
                            <mat-error *ngIf="experienciaForm.get('empresa').hasError('required')">La empresa es requerida!</mat-error>
                            <mat-error *ngIf="experienciaForm.get('empresa').hasError('minlength')">Mínimo 5 caracteres</mat-error>
                            <mat-error *ngIf="experienciaForm.get('empresa').hasError('maxlength')">Máximo 100 caracteres</mat-error>
                        </mat-form-field>

                        <!-- <div fxFlex="100" class="width-100 ng-select-container-custom">

                            <ng-select appearance="outline" placeholder="Agrega la empresa aquí *"
                                addTagText="Haga clic para agregar la empresa: " [addTag]="true"
                                (change)="changeSelectEmpresa($event)" (keyup)="keyupSelectEmpresa($event)"
                                (blur)="blurSelectEmpresa($event)"
                                (focusout)="focusOutSelectEmpresa($event, experienciaForm.controls['empresa'].value)"
                                formControlName="empresa">
                                <ng-option *ngFor="let empresa of listaEmpresas" [value]="empresa.id">{{empresa.nombre}}
                                </ng-option>
                            </ng-select>

                            <div class="error-container">
                                <mat-error
                                    *ngIf="experienciaForm.get('empresa').hasError('required') && !experienciaForm.pristine">
                                    La empresa es requerida!</mat-error>
                            </div>

                        </div> -->
                    </div>

                    <div fxLayout="column" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayout.gt-sm="row"
                        class="mb-32 mb-sm-8">

                        <mat-form-field appearance="outline" fxFlex="100" class="width-100">
                            <mat-label>Último cargo desempeñado</mat-label>
                            <input matInput formControlName="cargo" required>
                            <mat-icon fxHide fxShow.gt-sm matSuffix class="secondary-text"><svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.7 9.35L16.7 10.35L14.65 8.3L15.65 7.3C15.7528 7.19923 15.891 7.14278 16.035 7.14278C16.179 7.14278 16.3172 7.19923 16.42 7.3L17.7 8.58C17.91 8.79 17.91 9.14 17.7 9.35ZM8 14.94L14.06 8.88L16.11 10.93L10.06 17H8V14.94ZM8 10C3.58 10 0 11.79 0 14V16H6V14.11L10 10.11C9.34 10.03 8.67 10 8 10ZM8 0C6.93913 0 5.92172 0.421427 5.17157 1.17157C4.42143 1.92172 4 2.93913 4 4C4 5.06087 4.42143 6.07828 5.17157 6.82843C5.92172 7.57857 6.93913 8 8 8C9.06087 8 10.0783 7.57857 10.8284 6.82843C11.5786 6.07828 12 5.06087 12 4C12 2.93913 11.5786 1.92172 10.8284 1.17157C10.0783 0.421427 9.06087 0 8 0Z" fill="#929292"/>
                                </svg></mat-icon>
                            <mat-hint>
                                <mat-icon>error</mat-icon>Recuerda que podrás agregar más Experiencia Laboral desde tu
                                Perfil
                            </mat-hint>
                            <mat-error *ngIf="experienciaForm.get('cargo').hasError('required')">El Cargo es requerido!
                            </mat-error>
                            <mat-error *ngIf="experienciaForm.get('cargo').hasError('minlength')">Mínimo 5 caracteres
                            </mat-error>
                            <mat-error *ngIf="experienciaForm.get('cargo').hasError('maxlength')">Máximo 100 caracteres
                            </mat-error>
                        </mat-form-field>



                    </div>



                    <div fxLayout="column" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayout.gt-sm="row">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="50" class="pr-sm-4  width-100">
                            <mat-label>Teléfono de contacto*</mat-label>
                            <input matInput formControlName="empresa_telefono" type="tel">
                            <mat-icon fxHide fxShow.gt-sm matSuffix class="secondary-text"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.2291 15.2617L16.6891 14.9717C16.0791 14.9017 15.4791 15.1117 15.0491 15.5417L13.2091 17.3817C10.3791 15.9417 8.05906 13.6317 6.61906 10.7917L8.46906 8.94172C8.89906 8.51172 9.10906 7.91172 9.03906 7.30172L8.74906 4.78172C8.62906 3.77172 7.77906 3.01172 6.75906 3.01172H5.02906C3.89906 3.01172 2.95906 3.95172 3.02906 5.08172C3.55906 13.6217 10.3891 20.4417 18.9191 20.9717C20.0491 21.0417 20.9891 20.1017 20.9891 18.9717V17.2417C20.9991 16.2317 20.2391 15.3817 19.2291 15.2617Z" fill="#929292"/>
                                </svg></mat-icon>
                            <mat-error *ngIf="experienciaForm.get('empresa_telefono').hasError('required')">El Teléfono
                                es requerido!</mat-error>
                            <mat-error *ngIf="experienciaForm.get('empresa_telefono').hasError('pattern')">Solo escribe
                                números</mat-error>
                            <mat-error
                                *ngIf="experienciaForm.get('empresa_telefono').hasError('minlength') && !experienciaForm.get('empresa_telefono').hasError('pattern')">
                                Mínimo 5 caracteres</mat-error>
                            <mat-error
                                *ngIf="experienciaForm.get('empresa_telefono').hasError('maxlength') && !experienciaForm.get('empresa_telefono').hasError('pattern')">
                                Máximo 20 caracteres</mat-error>
                        </mat-form-field>
                        <div class="ml-sm-4 pb-12" fxFlex="100" fxFlex.gt-sm="50">
                            <mat-label>¿Trabaja actualmente?*:</mat-label>
                            <br />
                            <mat-radio-group (change)="changeRadio($event)" formControlName="trabaja_actualmente"
                                aria-label="trabaja_actualmente" required>
                                <mat-radio-button [value]="1">Si</mat-radio-button>
                                <mat-radio-button class="pl-8" [value]="0">No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>

                    <div fxLayout="column" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayout.gt-sm="row">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="50" class="pr-sm-4 width-100"
                            [matTooltip]="'Sólo puedes seleccionar fechas entre tu día de nacimiento (' + minDateInicioText + ') y la fecha actual'"
                            matTooltipPosition="above">
                            <mat-label>Desde</mat-label>

                            <input formControlName="fecha_inicio" required matInput
                                [matDatepicker]="picker_inicio_experiencia" [max]="maxDateInicio" [min]="minDateInicio"
                                (dateChange)="cambioFechaInicialExperiencia($event)">
                            <mat-datepicker-toggle matSuffix [for]="picker_inicio_experiencia"></mat-datepicker-toggle>
                            <mat-datepicker #picker_inicio_experiencia></mat-datepicker>
                            <mat-error>La Fecha inicio es requerida!</mat-error>
                        </mat-form-field>

                        <mat-form-field *ngIf="experienciaForm.get('trabaja_actualmente').value === 0"
                            [matTooltip]="'Sólo puedes seleccionar fechas entre la fecha de inicio seleccionada (' + minDateFinTextExperiencia + ') y la fecha actual'"
                            appearance="outline" fxFlex="100" fxFlex.gt-sm="50" class="pl-sm-4 width-100">
                            <mat-label>Hasta</mat-label>
                            <input formControlName="fecha_fin" matInput [matDatepicker]="picker_fin_experiencia"
                                [max]="maxDateInicio" [min]="minDateFinExperiencia">
                            <mat-datepicker-toggle matSuffix [for]="picker_fin_experiencia"></mat-datepicker-toggle>
                            <mat-error *ngIf="experienciaForm.get('fecha_fin').hasError('required')">La Fecha Fin es
                                requerida!</mat-error>
                            <mat-datepicker #picker_fin_experiencia></mat-datepicker>
                        </mat-form-field>
                    </div>


                </form>

                <div fxLayout="row" fxLayoutAlign="end center" class="pt-12" fxFlex="1 0 auto">
                    <button *ngIf="!loading" class="mr-4" mat-raised-button color="secundary"
                        matStepperPrevious>Anterior</button>
                    <button mat-raised-button color="primary" [disabled]="experienciaForm.invalid || loading"
                        class="ml-4"
                        (click)="onSubmit(personalDataForm.value, contactForm.value, informationForm.value, profileForm.value, educacionForm.value, experienciaForm.value)">
                        <span *ngIf="!loading">Enviar</span>

                        <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center center" class="py-8">
                            <mat-spinner strokeWidth="4" color="primary" diameter="30"> </mat-spinner>
                        </div>

                    </button>

                </div>


            </div>


        </mat-step>


    </mat-horizontal-stepper>


</mat-dialog-content>