<div id="forgot-password" fxLayout="column">

    <div id="forgot-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="forgot-password-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="{{ logoPath }}" alt="logo" style="height: 100px; width:auto; ">
            </div>

            <div class="title">RECUPERA TU CONTRASEÑA</div>

            <form name="forgotPasswordForm" [formGroup]="forgotPasswordForm" (submit)="OnSubmit()" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>Correo electrónico</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
                        Email es requerido
                    </mat-error>
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">
                        Por favor, ingresa un correo válido
                    </mat-error>
                </mat-form-field>

                <button mat-raised-button class="submit-button" [class.spinner]="loading" color="accent"
                        aria-label="SEND RESET LINK" [disabled]="forgotPasswordForm.invalid || loading">
                    ENVIAR ENLACE
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/login'">Regresar al login</a>
            </div>

        </div>

    </div>

</div>
