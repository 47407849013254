<app-navbar-planes></app-navbar-planes>
<div id="price-tables" class="page-layout simple fullwidth" #target >
    <!-- HEADER -->
    <div *ngIf="show_plan == 0" class="header-planes" fxLayout="row wrap" fxLayoutAlign="center start">
        <div fxLayout="column">
            <img src="/assets/images/banners/planes_img_izquierda.png" alt="planes">
        </div>
        <div fxHide fxShow.gt-sm="true" fxLayout="column" fxLayoutAlign="center center" style="align-self: center;">
            <h1>Planes</h1>
            <h2>Ok empleos</h2>
        </div>

        <div fxLayout="column"  fxHide fxShow.gt-lg="true">
            <img src="/assets/images/banners/planes_img_derecha.png" alt="planes">
        </div>
    </div>

    <div *ngIf="show_plan == 1" class="header-precios-1" fxLayout="column" fxLayoutAlign="center center" >
        <h1>Reclutamiento</h1>
        <!-- <h2>Ok empleos</h2> -->
    </div>
    <div *ngIf="show_plan == 2" class="header-precios-2" fxLayout="column" fxLayoutAlign="center center">
        <h1>Selección y Marca</h1>
        <!-- <h2>¡Todo en uno!</h2> -->
    </div>
    <div *ngIf="show_plan == 3" class="header-precios-3" fxLayout="column" fxLayoutAlign="center center">
        <h1>Retanqueo</h1>
        <!-- <h2>y Aceleradores</h2> -->
    </div>


    
    
    <!-- CONTENT -->
    <div class="content" fxLayout="column">

        
        <div *ngIf="show_plan == 1" class="plain-tables" fxLayout="row wrap" fxLayoutAlign="center start">
            <div *ngIf="planActual" class="plain-table  mat-elevation-z4" fxLayout="column">
                <table>
                    <tr>
                      <th style="width: 40%;" class="title-3">Publica tus vacantes en <br>
                        cualquier plan de manera<br><span class="title-2">Ilimitada</span></th>
                      <th class="title-2">{{planActual.nombre | uppercase}} <br><span style="font-size: 20px;" [innerHTML]=planActual.descripcion></span> </th>
                      <th *ngFor="let x of planesFiltered | slice:1" fxHide fxShow.gt-sm="true" class="title-2">{{x.nombre | uppercase}} <br><span style="font-size: 20px;" [innerHTML]=x.descripcion></span> </th>
                    </tr>
                    <tr *ngFor="let fila of planActual.detalles; let i = index">
                      <td *ngIf="i != 0" class="plain-cell plain-blue-1">{{fila.plan_item_type.descripcion}}</td>
                      <td *ngIf="i != 0" class="plain-cell plain-blue-2 plain-cell-rigth" [ngClass]="fila.texto_mostrar=='X' ? 'plain-cell-color-2' : ''" >{{ fila.texto == 1 ? fila.texto_mostrar : fila.cantidad}}</td>
                      <ng-container *ngIf="i != 0">
                      <td *ngFor="let x of planesFiltered | slice:1" fxHide fxShow.gt-sm="true"  class="plain-cell plain-blue-2 plain-cell-rigth" [ngClass]="x.detalles[i].texto_mostrar=='X' ? 'plain-cell-color-2' : ''" >{{ x.detalles[i].texto == 1 ? x.detalles[i].texto_mostrar : x.detalles[i].cantidad}}</td>
                      </ng-container>  
                    </tr>
                    <tr>
                        <td class="plain-cell plain-blue-1" style="background: #E5E2FF; text-align: center;"><strong>Mes</strong></td>
                        <td class="plain-cell plain-blue-2 plain-cell-rigth" style="background: #F5EFFF;">${{(planActual.precio - 0).toLocaleString()}}</td>
                        <td *ngFor="let x of planesFiltered | slice:1" fxHide fxShow.gt-sm="true" class="plain-cell plain-blue-2 plain-cell-rigth" style="background: #F5EFFF;">${{(x.precio - 0).toLocaleString()}}</td>
                    </tr>
                    <tr>
                        <td class="plain-cell plain-blue-1" style="background: #E5E2FF; text-align: center;"><strong>Semestral {{planes[1].descuento_semestral - 0}}% dcto</strong></td>
                        <td class="plain-cell plain-blue-2 plain-cell-rigth" style="background: #F5EFFF;">${{((planActual.precio - planActual.precio*planActual.descuento_semestral/100)*6).toLocaleString()}}</td>
                        <td *ngFor="let x of planesFiltered | slice:1" fxHide fxShow.gt-sm="true" class="plain-cell plain-blue-2 plain-cell-rigth" style="background: #F5EFFF;">${{((x.precio - x.precio*x.descuento_semestral/100)*6).toLocaleString()}}</td>
                    </tr>
                    <tr>
                        <td class="plain-cell plain-blue-1" style="background: #E5E2FF; text-align: center;"><strong>Anual {{planes[1].descuento_anual - 0}}% dcto</strong></td>
                        <td class="plain-cell plain-blue-2 plain-cell-rigth" style="background: #F5EFFF;">${{((planActual.precio -planActual.precio*planActual.descuento_anual/100)*12).toLocaleString()}}</td>
                        <td *ngFor="let x of planesFiltered | slice:1" fxHide fxShow.gt-sm="true" class="plain-cell plain-blue-2 plain-cell-rigth" style="background: #F5EFFF;">${{((x.precio -x.precio*x.descuento_anual/100)*12).toLocaleString()}}</td>
                    </tr>
                    <tr fxHide fxShow.gt-sm="true">
                        <td></td>
                        <td></td>
                        <td *ngFor="let x of planesFiltered | slice:1" style="padding-top: 10px;"><button class="btn btn_blue_1" (click)="registro()"><span style="font-weight: 100;">¡Adquierelo ya!</span></button></td>
                    </tr>
                    
                </table>
                <div class="price-tables" fxLayout="row wrap" fxLayoutAlign="space-evenly" fxShow fxHide.gt-sm="true" style="padding-top: 10px;">
                    <div fxLayout="column">
                        <button class="btn btn_blue_1" (click)="registro()"><span>¡Adquiere tu plan ahora!</span></button>
                    </div>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="center center">
                <p  style="max-width: 90%;">Todas tus vacantes en <strong>Ok empleos</strong> son expuestas en Metabuscadores como Google Jobs, Facebook Jobs, Jooble entre otros, alcanzando con esto un número importante de candidatos</p>
            </div>

            <div class="price-tables" fxLayout="row wrap" fxLayoutAlign="space-evenly" fxShow fxHide.gt-sm="true">
                <div  fxLayout="column">
                    <button class="btn btn_link" [disabled]="indiceActual == 0" (click)="mostrarAnteriorPlan()" >< Anterior plan</button>
                </div>
                <div fxLayout="column">
                    <!-- <button class="btn btn_blue_1" (click)="registro()"><span>¡Adquiere tu plan ahora!</span></button> -->
                </div>
                <div fxLayout="column">
                    <button class="btn btn_link" [disabled]="indiceActual >= planesFiltered.length -1" (click)="mostrarSiguientePlan()">Siguiente plan ></button>
                </div>
            </div>      
        
        </div>

        <div *ngIf="show_plan == 2" class="plain-tables" fxLayout="row wrap" fxLayoutAlign="center start">
            <div  fxHide fxShow.gt-sm="true" style="overflow-x:scroll; padding: 10px">
            <table fxHide fxShow.gt-sm="true" style="width: 53%;height: fit-content; vertical-align: top; color: #065EB6;">
                <th style="width: 30%;">
                    <div *ngIf="planActual" class="plain-table  mat-elevation-z4" fxLayout="column" style="height: 100%; background: #F9F9F9; border-radius: 30px;  align-items: center; width:max-content">
                        <table>
                            <tr>
                                <th class="title-3">
                                    <img style="max-width : 112px; padding: 5%;" src="assets/images/planes/selection_value.svg" alt="plan">
                                </th>
                            <tr *ngFor="let fila of planActual.detalles; let i = index">
                            <td class="plain-cell plain-blue-3" [innerHTML]=fila.plan_item_type.descripcion style="font-weight: normal;border: 2px solid #065EB6;"></td>
                            </tr>                    
                        </table>
                    </div>
                </th>
                <th *ngFor="let x of planesFiltered">
                    <div *ngIf="planActual" class="plain-table  mat-elevation-z4" fxLayout="column" style="height: 100%; border-radius: 30px; align-items: center;">
                        <table>
                            
                                <th class="title-3">
                                    <img style="max-width : 80px; padding: 8%;" src="assets/images/planes/selection_key.svg" alt="seleccion">
                                </th>
                            
                            <tr>
                                <td  class="plain-cell plain-blue-3 plain-cell-rigth-2" style="background: #E3F1FF; border: 2px solid #065EB6;">${{(x.precio -0).toLocaleString()}}</td>
                            </tr>
                            <tr *ngFor="let fila of x.detalles; let i = index">    
                                <td class="plain-cell plain-blue-3 plain-cell-rigth-2" style="background: #E3F1FF; border: 2px solid #065EB6; height: 45px;" [ngClass]="i==0 ? 'plain-text-bold' :''">{{ fila.texto == 1 ? fila.texto_mostrar : fila.cantidad}}</td>
                            </tr>                    
                        </table>
                        <button class="btn btn_blue_2" (click)="registro()" style="margin-top: 20px; width: max-content;"><span>¡Adquirir ya!</span></button>
                    </div>
                    
                </th>
            </table>
            </div>

            <div fxShow fxHide.gt-sm="true" style="overflow-x:scroll; padding: 10px">
                <table fxShow fxHide.gt-sm="true" style="width: 53%;height: fit-content; vertical-align: top; color: #065EB6; overflow-x:scroll;">
                    <th style="width: 50%;">
                        <div *ngIf="planActual" class="plain-table  mat-elevation-z4" fxLayout="column" style="height: 100%; background: #F9F9F9; border-radius: 30px;  align-items: center;">
                            <table>
                                <tr>
                                    <th class="title-3">
                                        <img style="max-width : 37%; padding: 5%;" src="assets/images/planes/selection_value.svg" alt="seleccion">
                                    </th>
                                <tr *ngFor="let fila of planActual.detalles; let i = index">
                                <td class="plain-cell plain-blue-3" [innerHTML]=fila.plan_item_type.descripcion style="font-weight: normal;border: 2px solid #065EB6;"></td>
                                </tr>                    
                            </table>
                        </div>
                    </th>
                    <th  style="width: 50%;">
                        <div *ngIf="planActual" class="plain-table  mat-elevation-z4" fxLayout="column" style="height: 100%; border-radius: 30px; align-items: center;">
                            <table>
                                
                                    <th class="title-3">
                                        <img style="max-width : 37%; padding: 8%;" src="assets/images/planes/selection_key.svg" alt="clave">
                                    </th>
                                
                                <tr>
                                    <td  class="plain-cell plain-blue-3 plain-cell-rigth-2" style="background: #E3F1FF; border: 2px solid #065EB6;">${{(planActual.precio -0).toLocaleString()}}</td>
                                </tr>
                                <tr *ngFor="let fila of planActual.detalles; let i = index">    
                                    <td class="plain-cell plain-blue-3 plain-cell-rigth-2" style="background: #E3F1FF; border: 2px solid #065EB6; height: 40px;" [ngClass]="i==0 ? 'plain-text-bold' :''">{{ fila.texto == 1 ? fila.texto_mostrar : fila.cantidad}}</td>
                                </tr>                    
                            </table>
                        </div>
                    </th>
                </table>
            </div>

            <div class="price-tables" fxLayout="row wrap" fxLayoutAlign="space-evenly" fxShow fxHide.gt-sm="true" style="padding-top: 10px;">
                <div fxLayout="column">
                    <button class="btn btn_blue_2" (click)="registro()"><span>¡Adquiere tu plan ahora!</span></button>
                </div>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="center center" style="color: #065EB6;">
                <p  style="max-width: 90%;">Todas tus vacantes en <strong>Ok empleos</strong> son expuestas en Metabuscadores como Google Jobs, Facebook Jobs, Jooble entre otros, alcanzando con esto un número importante de candidatos</p>
            </div>

            <div class="price-tables" fxLayout="row wrap" fxLayoutAlign="space-evenly" fxShow fxHide.gt-sm="true">
                <div  fxLayout="column">
                    <button class="btn btn_link_2" [disabled]="indiceActual == 0" (click)="mostrarAnteriorPlan()">< Anterior plan</button>
                </div>
                <div fxLayout="column">
                    <!-- <button class="btn btn_blue_2" (click)="registro()"><span>¡Adquiere tu plan ahora!</span></button> -->
                </div>
                <div fxLayout="column">
                    <button class="btn btn_link_2" [disabled]="indiceActual >= planesFiltered.length -1" (click)="mostrarSiguientePlan()">Siguiente plan ></button>
                </div>
            </div>      
        
        </div>

        <div *ngIf="show_plan == 3" class="plain-tables" fxLayout="row wrap" fxLayoutAlign="center start">
            <div  fxLayout="row wrap">
                <div fxLayout="column">
                    <div style="width: 507px; height: 1787px; position: relative">
                        <div style="width: 507px; height: 1787px; left: 0px; top: 0px; position: absolute; background: #F9F9F9; box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.10); border-radius: 30px"></div>
                        <div style="left: 76px; top: 296px; position: absolute; text-align: right; color: #212950; font-size: 24px; font-family: Montserrat; font-weight: 700; line-height: 39.50px; word-wrap: break-word">Destacar publicación</div>
                        <div style="left: 76px; top: 633px; position: absolute; text-align: center; color: #212950; font-size: 24px; font-family: Montserrat; font-weight: 700; line-height: 39.50px; word-wrap: break-word">Destacar publicación<br/>y acceder a 10 HV</div>
                        <div style="left: 24px; top: 932px; position: absolute; text-align: center; color: #212950; font-size: 24px; font-family: Montserrat; font-weight: 700; line-height: 39.50px; word-wrap: break-word">Participación Feria de<br/>Empleo presencial o virtual</div>
                        <div style="left: 81px; top: 1064px; position: absolute; text-align: center; color: #212950; font-size: 24px; font-family: Montserrat; font-weight: 700; line-height: 39.50px; word-wrap: break-word">TikTok @Okempleos</div>
                        <div style="left: 125px; top: 1284px; position: absolute; text-align: center; color: #212950; font-size: 24px; font-family: Montserrat; font-weight: 700; line-height: 39.50px; word-wrap: break-word">Reclutamiento<br/>personalizado<br/>y especializado</div>
                        <div style="left: 181px; top: 1643px; position: absolute; text-align: center; color: #212950; font-size: 24px; font-family: Montserrat; font-weight: 700; line-height: 39.50px; word-wrap: break-word">Minisitio</div>
                        <div style="width: 384px; height: 0px; left: 53px; top: 437.50px; position: absolute; border: 0.50px #3D348B solid"></div>
                        <div style="width: 384px; height: 0px; left: 53px; top: 202px; position: absolute; border: 0.50px #3D348B solid"></div>
                        <div style="width: 384px; height: 0px; left: 53px; top: 910px; position: absolute; border: 0.50px #3D348B solid"></div>
                        <div style="width: 384px; height: 0px; left: 62px; top: 1027px; position: absolute; border: 0.50px #3D348B solid"></div>
                        <div style="width: 384px; height: 0px; left: 62px; top: 1144px; position: absolute; border: 0.50px #3D348B solid"></div>
                        <div style="width: 384px; height: 0px; left: 62px; top: 1550px; position: absolute; border: 0.50px #3D348B solid"></div>
                        <div style="width: 155.34px; height: 103.96px; left: 167.46px; top: 61px; position: absolute">
                            <img src="assets/images/planes/left_logo_refill.svg" alt="cart">
                        </div>
                      </div>
                </div>
                <div fxLayout="column">
                    <div style="width: 517px; height: 1787px; position: relative">
                        <div style="width: 508px; height: 1787px; left: 0px; top: 0px; position: absolute; background: white; box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.10); border-radius: 30px"></div>
                        <div style="left: 110px; top: 88px; position: absolute; text-align: center; color: #212950; font-size: 44px; font-family: Montserrat; font-weight: 700; line-height: 48.50px; word-wrap: break-word">VALORES</div>
                        <div style="left: 61px; top: 715px; position: absolute; text-align: center; color: white; font-size: 27px; font-family: Montserrat; font-weight: 700; line-height: 39px; word-wrap: break-word">¡Adquiere tu plan ahora!</div>
                        
                        <div *ngFor="let fila of planesFiltered[0].detalles" class="refill-purchase-btn" style="width: 406px; height: 98px; padding-left: 29px; padding-right: 29px; padding-top: 26px; padding-bottom: 26px; left: 56px; top: 209px; position: relative; background: #F1F3FE; border-radius: 12px; border-left: 1px #212950 solid; border-top: 1px #212950 solid; border-right: 1px #212950 solid; border-bottom: 1px #212950 solid; justify-content: flex-start; align-items: flex-start; display: inline-flex; margin-bottom: 20px;">
                          <div style="width: 312px; height: 46px; text-align: center; color: #212950; font-size: 30px; font-family: Montserrat; font-weight: 400; line-height: 42px; word-wrap: break-word">${{(fila.cantidad).toLocaleString()}}</div>
                          <div style="width: 35.90px; height: 35px; position: relative">
                            <div style="width: 35.90px; height: 25.44px; left: 0px; top: 0px; position: absolute;"> <img src="assets/images/planes/cart.svg" alt="cart"></div>
                          </div>  
                        </div>

                        <div *ngFor="let fila of planesFiltered[1].detalles" class="refill-purchase-btn" style="width: 406px; height: 98px; padding-left: 29px; padding-right: 29px; padding-top: 26px; padding-bottom: 26px; left: 56px; top: 210px; position: relative; background: #DEE3FF; border-radius: 12px; border-left: 1px #212950 solid; border-top: 1px #212950 solid; border-right: 1px #212950 solid; border-bottom: 1px #212950 solid; justify-content: flex-start; align-items: flex-start; display: inline-flex; margin-bottom: 20px;">
                          <div style="width: 312px; height: 46px; text-align: center"><span style="color: #212950; font-size: 26px; font-family: Montserrat; font-weight: 400; line-height: 50px; word-wrap: break-word" [innerHTML]="fila.texto_mostrar"></span></div>
                          <div style="width: 35.90px; height: 35px; position: relative">
                            <div style="width: 35.90px; height: 25.44px; left: 0px; top: 0px; position: absolute;"> <img src="assets/images/planes/cart.svg" alt="cart"></div>
                          </div>  
                        </div>

                        <div class="refill-purchase-btn" style="width: 406px; height: 98px; padding-left: 29px; padding-right: 29px; padding-top: 26px; padding-bottom: 26px; left: 56px; top: 917px; position: absolute; background: #C7D0FF; border-radius: 12px; border-left: 1px #212950 solid; border-top: 1px #212950 solid; border-right: 1px #212950 solid; border-bottom: 1px #212950 solid; justify-content: flex-start; align-items: flex-start; display: inline-flex">
                          <div style="width: 312px; height: 46px; text-align: center; color: #212950; font-size: 30px; font-family: Montserrat; font-weight: 400; line-height: 42px; word-wrap: break-word">${{(planesFiltered[2].detalles[0].cantidad).toLocaleString()}}</div>
                          <div style="width: 35.90px; height: 35px; position: relative">
                            <div style="width: 35.90px; height: 25.44px; left: 0px; top: 0px; position: absolute;"> <img src="assets/images/planes/cart.svg" alt="cart"</div>
                          </div>
                        </div>

                        <div class="refill-purchase-btn" style="width: 406px; height: 98px; padding-left: 29px; padding-right: 29px; padding-top: 26px; padding-bottom: 26px; left: 56px; top: 1033px; position: absolute; background: #C7D0FF; border-radius: 12px; border-left: 1px #212950 solid; border-top: 1px #212950 solid; border-right: 1px #212950 solid; border-bottom: 1px #212950 solid; justify-content: flex-start; align-items: flex-start; display: inline-flex">
                          <div style="width: 312px; height: 46px; text-align: center; color: #212950; font-size: 30px; font-family: Montserrat; font-weight: 400; line-height: 42px; word-wrap: break-word">${{(planesFiltered[3].detalles[0].cantidad).toLocaleString()}}</div>
                          <div style="width: 35.90px; height: 35px; position: relative">
                            <div style="width: 35.90px; height: 25.44px; left: 0px; top: 0px; position: absolute;"> <img src="assets/images/planes/cart.svg" alt="cart"></div>
                          </div>
                        </div>

                        <div *ngFor="let fila of planesFiltered[4].detalles" class="refill-purchase-btn" style="width: 406px; height: 132px; padding-left: 29px; padding-right: 29px; padding-top: 26px; padding-bottom: 26px; left: 56px; top: 440px; position: relative; background: #A8B6FF; border-radius: 12px; border-left: 1px #212950 solid; border-top: 1px #212950 solid; border-right: 1px #212950 solid; border-bottom: 1px #212950 solid; justify-content: flex-start; align-items: flex-start; display: inline-flex; margin-bottom: 10px;">
                          <div style="width: 312px; height: 89px; text-align: center"><span style="color: #212950; font-size: 30px; font-family: Montserrat; font-weight: 400; line-height: 44px; word-wrap: break-word" [innerHTML]="fila.texto_mostrar"></span></div>
                          <div style="width: 35.90px; height: 35px; position: relative">
                            <div style="width: 35.90px; height: 25.44px; left: 0px; top: 0px; position: absolute;"> <img src="assets/images/planes/cart.svg" alt="cart"></div>
                          </div>
                        </div>

                        <div class="refill-purchase-btn" style="width: 406px; height: 101px; padding-left: 29px; padding-right: 29px; padding-top: 26px; padding-bottom: 26px; left: 56px; top: 1597px; position: absolute; background: #8498FF; border-radius: 12px; border-left: 1px #212950 solid; border-top: 1px #212950 solid; border-right: 1px #212950 solid; border-bottom: 1px #212950 solid; justify-content: flex-start; align-items: flex-start; display: inline-flex">
                          <div style="width: 312px; height: 82px; text-align: center; color: #212950; font-size: 30px; font-family: Montserrat; font-weight: 400; line-height: 42px; word-wrap: break-word">${{(planesFiltered[5].detalles[0].cantidad).toLocaleString()}}</div>
                          <div style="width: 35.90px; height: 35px; position: relative">
                            <div style="width: 35.90px; height: 25.44px; left: 0px; top: 0px; position: absolute;"> <img src="assets/images/planes/cart.svg" alt="cart"></div>
                          </div>
                        </div>
                      </div>
                    
                </div>
                <div fxLayout="column">
                    <div style="width: 507px; height: 1787px; position: relative">
                        <div style="width: 507px; height: 1787px; left: 0px; top: 0px; position: absolute; background: white; box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.10); border-radius: 30px"></div>
                        <div style="width: 378px; height: 208px; left: 65px; top: 213px; position: absolute"><span style="color: #212950; font-size: 33px; font-family: Montserrat; font-weight: 700; line-height: 39.50px; word-wrap: break-word">Destacar publicación<br/></span><span style="color: #212950; font-size: 18px; font-family: Montserrat; font-weight: 400; line-height: 39.50px; word-wrap: break-word">Estas dos opciones las puedes </span><span style="color: #212950; font-size: 18px; font-family: Montserrat; font-weight: 400; line-height: 22px; word-wrap: break-word">adquirir en cualquier momento. Sin embargo, se muestran especialmente apenas la empresa publica sus vacantes. Después de inscribirlos, enviar un mail programado para quienes publiquen y no generen ninguna acción.</span></div>
                        <div style="width: 378px; height: 87px; left: 65px; top: 925px; position: absolute"><span style="color: #212950; font-size: 18px; font-family: Montserrat; font-weight: 400; line-height: 22px; word-wrap: break-word">Separa un espacio en las ferias<br/></span><span style="color: #212950; font-size: 18px; font-family: Montserrat; font-weight: 700; line-height: 22px; word-wrap: break-word">Ok empleos</span><span style="color: #212950; font-size: 18px; font-family: Montserrat; font-weight: 400; line-height: 22px; word-wrap: break-word"> y recluta en sitio. Conoce la programación. Incluidas en plan </span><span style="color: #212950; font-size: 18px; font-family: Montserrat; font-weight: 700; line-height: 22px; word-wrap: break-word">Estándar y Enterprise</span></div>
                        <div style="width: 378px; height: 66px; left: 65px; top: 1053px; position: absolute; color: #212950; font-size: 18px; font-family: Montserrat; font-weight: 400; line-height: 22px; word-wrap: break-word">Úsalo para tus convocatorias presenciales o para llevar más candidatos a tus publicaciones</div>
                        <div style="width: 378px; height: 242px; left: 56px; top: 1267px; position: absolute"><span style="color: #212950; font-size: 18px; font-family: Montserrat; font-weight: 400; line-height: 22px; word-wrap: break-word">Atracción de candidatos solicitados a través de diferentes canales y estrategias<br/></span><span style="color: #212950; font-size: 18px; font-family: Montserrat; font-weight: 400; line-height: 22px; word-wrap: break-word"><br/></span><span style="color: #212950; font-size: 18px; font-family: Montserrat; font-weight: 400; line-height: 22px; word-wrap: break-word">Análisis y Preselección de aptos a través de entrevista<br/></span><span style="color: #212950; font-size: 18px; font-family: Montserrat; font-weight: 400; line-height: 22px; word-wrap: break-word"><br/></span><span style="color: #212950; font-size: 18px; font-family: Montserrat; font-weight: 400; line-height: 22px; word-wrap: break-word">Ofrecimiento de la vacante, interés del candidato en la misma<br/></span><span style="color: #212950; font-size: 18px; font-family: Montserrat; font-weight: 400; line-height: 22px; word-wrap: break-word"><br/></span><span style="color: #212950; font-size: 18px; font-family: Montserrat; font-weight: 400; line-height: 22px; word-wrap: break-word">Envío de HV</span></div>
                        <div style="width: 381px; height: 106px; left: 65px; top: 1606px; position: absolute; color: #212950; font-size: 18px; font-family: Montserrat; font-weight: 400; line-height: 22px; word-wrap: break-word">Página / Landing Page personalizada con tus vacantes, marca e información institucional incluido en plan Enterprise anual y plan de selección 50 y 100. Valor por un año.</div>
                        <div style="width: 395px; height: 70px; left: 65px; top: 1185px; position: absolute; color: #212950; font-size: 30px; font-family: Montserrat; font-weight: 700; line-height: 30px; word-wrap: break-word">Nuestro reclutamiento personalizado incluye:</div>
                        <div style="width: 378px; height: 76px; left: 65px; top: 628px; position: absolute; text-align: center; color: #212950; font-size: 33px; font-family: Montserrat; font-weight: 700; line-height: 39.50px; word-wrap: break-word">Créditos vigentes<br/>por 6 meses</div>
                        <div style="width: 384px; height: 0px; left: 62px; top: 437.50px; position: absolute; border: 0.50px #3D348B solid"></div>
                        <div style="width: 384px; height: 0px; left: 62px; top: 202px; position: absolute; border: 0.50px #3D348B solid"></div>
                        <div style="width: 384px; height: 0px; left: 62px; top: 910px; position: absolute; border: 0.50px #3D348B solid"></div>
                        <div style="width: 384px; height: 0px; left: 62px; top: 1027px; position: absolute; border: 0.50px #3D348B solid"></div>
                        <div style="width: 384px; height: 0px; left: 62px; top: 1144px; position: absolute; border: 0.50px #3D348B solid"></div>
                        <div style="width: 384px; height: 0px; left: 62px; top: 1550px; position: absolute; border: 0.50px #3D348B solid"></div>
                        <div style="width: 96px; height: 129.19px; left: 206px; top: 39.07px; position: absolute">
                            <img src="assets/images/planes/rigth_logo_refill.svg" alt="planes">
                        </div>
                      </div>
                </div>
            </div>
        </div>

        <!-- PRICE TABLES -->
        <h1 *ngIf="show_plan != 0">Planes a tu medida</h1>
        <div class="price-tables" fxLayout="row wrap" fxLayoutAlign="center start">
            
            <div *ngIf="show_plan != 1" class="price-table style-3 mat-elevation-z0" fxLayout="column">

                <div class="package-type blue_1" fxLayout="column">
                    <!-- <div class="title blue_1">Reclutamiento</div> -->
                    <div class="subtitle blue_1">Reclutamiento</div>
                    <br>
                </div>

                <div class="plan-footer h2 pagar">

                    <button class="btn btn_blue_1" (click)="showPlan(1, target)" ><span>Ver más información</span></button>
                </div>

                <br>

                <!-- <div class="divisor-footer-1 mb-12" fxLayout="column" fxLayoutAlign="start start"></div>                -->


                <div class="terms" fxLayout="column" fxLayoutAlign="">
                   
                    <div class="term-2">Todas tus vacantes en <span class="text-bold">Ok empleos</span> son expuestas en Metabuscadores como Google Jobs, Facebook Jobs, Jooble entre otros, alcanzando con esto un número importante de candidatos</div>
                            
                </div>




            </div>

            <div *ngIf="show_plan != 2" class="price-table style-3 mat-elevation-z0" fxLayout="column">

                <div class="package-type blue_2" fxLayout="column">
                    <!-- <div class="title blue_2">Planes</div> -->
                    <div class="subtitle blue_2">Selección<br> y Marca</div>
                </div>

                <div class="plan-footer h2 pagar">
                    <button class="btn btn_blue_2" (click)="showPlan(2, target)" ><span>Ver más información</span></button>
                </div>

                <br>

                <!-- <div class="divisor-footer-2 mb-12" fxLayout="column" fxLayoutAlign="start start"></div> -->


                <div class="terms" fxLayout="column" fxLayoutAlign="">
                   
                    <div class="term-2">Disminuye el tiempo de selección con nuestras entrevistas asíncronas. Evalúa tu personal a contratar objetivamente con pruebas de valores, competencias, habilidades comerciales y de liderazgo, elige las pruebas según  el perfil y conoce sus antecedentes, evitando riesgos al contratar</div>
                            
                </div>




            </div>

            <div *ngIf="show_plan != 3" class="price-table style-3 mat-elevation-z0" fxLayout="column">

                <div class="package-type blue_3" fxLayout="column">
                    <!-- <div class="title blue_3">Planes</div> -->
                    <div class="subtitle blue_3">Retanqueo <br>y Aceleradores</div>
                </div>

                <div class="plan-footer h2 pagar">
                    <button class="btn btn_blue_3" (click)="showPlan(3, target)" ><span>Ver más información</span></button>
                </div>

                <br>

                <!-- <div class="divisor-footer-3 mb-12" fxLayout="column" fxLayoutAlign="start start"></div> -->


                <div class="terms" fxLayout="column" fxLayoutAlign="">
                   
                    <div class="term-2">Disminuye el tiempo de selección con nuestras entrevistas asíncronas. Evalúa tu personal a contratar objetivamente con pruebas de valores, competencias, habilidades comerciales y de liderazgo, elige las pruebas según  el perfil y conoce sus antecedentes, evitando riesgos al contratar</div>
                            
                </div>



            </div>

        </div>
        
    </div>

    <!-- Botón flotante -->
    <button class="floating-button" (click)="goToPlanTutorial()" fxHide fxShow.gt-xs="true"><span>¿Cómo pagar tu plan?</span></button>

    <app-footer></app-footer>

</div>

