import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FuseMatchMediaService } from '@fuse/services/match-media.service';
import { cityList } from '@fuse/types/citys';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { ModalVisualizeComponent } from 'app/modals/modal-visualize/modal-visualize.component';
import { Location } from '@angular/common';
import { environment } from "environments/environment";
import Reveal from 'reveal.js';
import Markdown from 'reveal.js/plugin/markdown/markdown.esm.js';
import { GlobalConstants } from 'app/common/global-constants';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MinisitioVacantesService } from '../minisitio-vacantes/minisitio-vacantes.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SchoolFirstComponent } from '../first/school-first/school-first.component';
import { LogoService } from 'app/logo.service';
@Component({
    selector: 'minisitio',
    templateUrl: './minisitio.component.html',
    styleUrls: ['./minisitio.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MinisitioComponent implements OnInit {
    @ViewChild('minisitioDiv') minisitioDiv: ElementRef;
    @ViewChild('secondDiv') secondDiv: ElementRef;
    @ViewChild('searchDiv') searchDiv: ElementRef;
    searchInput: FormControl;
    private url = 'assets/images/serverimg/';
    private urlLogs = '../../../assets/images/logos/';
    videosCandidatoSlider = [];
    optionsDisplaySlider: any;
    valueFond = 1;
    followLog = [];
    isMenuOpen = false;
    movil_left = false;
    menuList = 1;
    citys = [];
    palabra = '';
    localizacion = 0;
    widthModal = '60vw';
    currentStateEmpresa = '0';
    currentStateCandidato = "1";
    imgCollection: Array<object> = [
        // {
        //     image: this.url + 'adeco.png',
        //     thumbImage: this.url + 'adeco.png',
        // }, 
        {
            image: this.url + 'ambrosia.png',
            thumbImage: this.url + 'ambrosia.png',
            alt: 'Image 2'
        },
        {
            image: this.url + 'applus.png',
            thumbImage: this.url + 'applus.png',
        }, {
            image: this.url + 'ascrodus.png',
            thumbImage: this.url + 'ascrodus.png',
            alt: 'Image 2'
        },
        {
            image: this.url + 'awake.png',
            thumbImage: this.url + 'awake.png',
        }, {
            image: this.url + 'bombolandia.png',
            thumbImage: this.url + 'bombolandia.png',
            alt: 'Image 2'
        },
        {
            image: this.url + 'geopark.png',
            thumbImage: this.url + 'geopark.png',
        }, {
            image: this.url + 'gesth.png',
            thumbImage: this.url + 'gesth.png',
            alt: 'Image 2'
        },
        {
            image: this.url + 'pixeling.png',
            thumbImage: this.url + 'pixeling.png',
        }, {
            image: this.url + 'politecnico.png',
            thumbImage: this.url + 'politecnico.png',
            alt: 'Image 2'
        },
        {
            image: this.url + 'prosegur.png',
            thumbImage: this.url + 'prosegur.png',
        }, {
            image: this.url + 'refinancia.png',
            thumbImage: this.url + 'refinancia.png',
            alt: 'Image 2'
        }, {
            image: this.url + 'tejando.png',
            thumbImage: this.url + 'tejando.png',
            alt: 'Image 2'
        },
    ];
    urlLocal = GlobalConstants.apiURL;
    banners : any;
    minisitio: any;
    navMobile = false;
    slug : any;
    safeSrc: SafeResourceUrl;
    vacantes: any;
    logoPath: String;

    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseMatchMediaService: FuseMatchMediaService,
        private cdRef: ChangeDetectorRef,
        private _fuseConfigService: FuseConfigService,
        private _router: Router,
        public dialog: MatDialog,
        public dialogvid: MatDialog,
        private _activaRoute: ActivatedRoute,
        private httpClient: HttpClient,
        private location: Location,
        private sanitizer: DomSanitizer,
        private _minisitioVacantesService: MinisitioVacantesService,
        private logoService: LogoService

    ) {
        this.searchInput = new FormControl('');
        this.citys = cityList;

        // console.log('ciudades:', this.citys);
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this.followLog = [
            {
                img: this.urlLogs + '/log_tiktok.png',
                url: 'https://www.tiktok.com/@en.modo.ok?'
            },
            {
                img: this.urlLogs + '/log_instagram.png',
                url: 'https://www.instagram.com/ok.empleos/?hl=es'
            },
            {
                img: this.urlLogs + '/log_linkedin.png',
                url: 'https://www.linkedin.com/company/ok-empleos/'
            },
            {
                img: this.urlLogs + '/log_face.png',
                url: 'https://www.facebook.com/OkEmpleosColombia'
            },
            {
                img: this.urlLogs + '/log_youtube.png',
                url: 'https://www.youtube.com/channel/UCE56i6YHPlYBrpATAilz3XQ'
            },
            {
                img: this.urlLogs + '/log_twiter.png',
                url: 'https://twitter.com/ok_empleos'
            },
        ]

        this.videosCandidatoSlider = [
            {
                video: 'https://www.youtube.com/watch?v=K1yx4E2JNIE',
                alt: 'OkEmpleos',
                videoId: 'K1yx4E2JNIE'
            },
            {
                video: 'https://www.youtube.com/watch?v=zPDB-nQcd3w',
                alt: 'OkEmpleos',
                videoId: 'zPDB-nQcd3w'
            },
            {
                video: 'https://www.youtube.com/watch?v=IWw0EleFzzc',
                alt: 'OkEmpleos',
                videoId: 'IWw0EleFzzc'
            },
            {
                video: 'https://www.youtube.com/watch?v=9gbK2jB6jTA',
                alt: 'OkEmpleos',
                videoId: '9gbK2jB6jTA'
            },
            {
                video: 'https://www.youtube.com/watch?v=zB7Ds9f_fU0',
                alt: 'OkEmpleos',
                videoId: 'zB7Ds9f_fU0'
            }
        ]

        this._unsubscribeAll = new Subject();
        // this.openVideo();

        this.logoPath = this.logoService.getLogoPath();
        
    }

    ngOnInit(): void {

        // this._activaRoute.queryParams
        this._activaRoute.params
        .subscribe(params => {

            if (params.empresa) {
                this.slug = params.empresa;
                this.httpClient.get(`${this.urlLocal}minisitio/${params.empresa}`).subscribe(
                    (result: any) => {
                        this.minisitio = result[0];
                        this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(this.minisitio.propiedades[0].block2_video);
                        this.videosCandidatoSlider = this.minisitio.propiedades[0].block5_videos;
                        this.getBanners();
                        if (result.length == 0){
                            // this._router.navigate(['/inicio']); 
                        }
                  

                });

                this._minisitioVacantesService.getVacantes( params.empresa).then(vacantes => {


                    this.vacantes = vacantes;
                    // console.log(this.vacantes);
                });
        

               
            } else {
                // this._router.navigate(['/inicio']); 
            }

        });





    }

    openVideo() {
        const vid = document.getElementsByClassName('play');
        // console.log('video', vid);

        const video = document.querySelector("video");

        // console.log(video);

        if(!video) return;
        
        const promise = video.play();
        if(promise !== undefined){
            promise.then(() => {
                // Autoplay started
            }).catch(error => {
                // Autoplay was prevented.
                video.muted = true;
                video.play();
            });
        }
    }


    ngAfterViewInit(): void {


        // const headers = new HttpHeaders().set(InterceptorSkipHeader, '');

        // const formData = this.registerForm.getRawValue();

        // formData.user_type = 1;
        // formData.email = email;
        // formData.name = name;
        // formData.minisitio_name = minisitio_name;
        // formData.last_name = last_name;
        // formData.photo_url = photoUrl;
        // formData.password = password;
        // formData.provider = provider;
        // formData.provider_id = providerId;


        this.httpClient.get(`${this.urlLocal}banner/${this.slug}`).subscribe(
            (result: any) => {

                this.cdRef.markForCheck();
                let deck = new Reveal({
                    plugins: [ Markdown ]
                 })
                 deck.initialize({
                    autoSlide: GlobalConstants.slidetime * 1000,
                    loop: true,
                    width: "100%",
	                height: "100%",
                    margin: 0,
                 });
            });




        // Reveal.initialize({
        //     parallaxBackgroundImage: '',
        //     parallaxBackgroundSize: '',
        //     parallaxBackgroundHorizontal: 200,
        //     parallaxBackgroundVertical: 50
        //   });
        // also try this.cdRef.detectChanges(); instead of the above
    }

    goToScroll() {
        const route = document.getElementById("school");
        route.scrollIntoView({ behavior: "smooth" });
    }

    openModalVisual(): void {
        let dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.maxWidth = '80vw';
        dialogConfig.maxHeight = '95vh';
        this.dialog.open(ModalVisualizeComponent, dialogConfig);
    }

    searchVacante(palabra) {
        this._router.navigate(['/lista-vacantes'],
            { queryParams: { palabra: palabra } });
    }

    onEnterSearch(isEmpresa?): void {

        const text = this.searchInput.value

        if (text.length >= 3) {

            if (isEmpresa) {
                this._router.navigateByUrl(`/vacante/empresa-vacantes/lista?texto=${text}`)
            } else {
                this._router.navigateByUrl(`/vacante/candidato-vacantes/lista?texto=${text}`)
            }
        }
    }

    changeMenuList() {
        if (this.menuList === 1) {
            this.menuList = 2;
        } else {
            this.menuList = 1;
        }
    }

    chageFond() {
        setInterval(() => {
            if (this.valueFond === 1) {
                this.valueFond = 2;
            } else {
                this.valueFond = 1;
            }
        }, 3000);
    }

    toggleMenu(): void {
        this.isMenuOpen = !this.isMenuOpen;
    }

    validMenu(){
        if(this.navMobile === true){
          this.navMobile = false;
        }else{
          this.navMobile = true; 
        }
      }
    
    back(): void {
        this.location.back()
    }

    getColor(){
        return this.minisitio.propiedades[0].font_color;
    }

    getHeaderheight(){
        let height = "84hv";
        return this.minisitio.propiedades[0].header_height ? this.minisitio.propiedades[0].header_height: height;
    }

    isDynamicHeader(){
        let dynamic = false;
        return this.minisitio.propiedades[0].dynamic_header ? this.minisitio.propiedades[0].dynamic_header: dynamic;
    }

    getBanners(){
        this.httpClient.get(`${this.urlLocal}banner/${this.slug}`).subscribe(
            (result: any) => {
                this.banners = result
            });
    }

    detalleVacante(id: any, empresaId: number): void {
        const url = `public/detalle-vacante/${id}`;
        this._router.navigateByUrl(url);
    }

    imageClick(event) {
        const video = this.videosCandidatoSlider[event];
        this.openModalVideo(video.videoId);
    }

    openModalVideo(videoId): void {
        let dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.data = videoId;
        dialogConfig.width = 'auto';
        dialogConfig.maxWidth = '80vw';
        dialogConfig.minHeight = '40vh';
        this.dialogvid.open(SchoolFirstComponent, dialogConfig);
    }

}