<div fxLayout="column" class="cont_general" style="background:#eeedf2">
    <!-- <app-navbar></app-navbar> -->
    <!-- ANV BAR -->
    <div class="cont_nav--mobiles" fxFlex="100" *ngIf="navMobile === true">
        <div class="cont_itmes" fxFlex="70" fxLayout="column">
            <span class="close" (click)="validMenu()">X</span>
            <div class="items_group">
                <button class="btn btn_blue" [routerLink]="'./'">{{ minisitio.propiedades[0].inicio_texto | uppercase }}</button>
                <button class="btn btn_yellow" [routerLink]="'./vacantes'">{{ minisitio.propiedades[0].empleo_text | uppercase }}</button>
            </div>
            <div class="items_group">
                <button class="btn btn_orange" [routerLink]="'/login'">INICIAR SESION</button>
                <button class="btn btn_grey" [routerLink]="'/registro'">REGISTRARME</button>
            </div>

            <div class="items_group" fxLayout="row" fxLayoutAlign="center center">
                <a *ngIf="minisitio.empresa.facebook" href="{{minisitio.empresa.facebook}}" target="_blank"><i  class="fab fa-facebook mr-32" [ngStyle]="{'color': getColor()}"></i></a>
                <a *ngIf="minisitio.empresa.instagram" href="{{minisitio.empresa.instagram}}" target="_blank"><i  class="fab fa-instagram mr-32" [ngStyle]="{'color': getColor()}"></i></a>
                <a *ngIf="minisitio.empresa.linkedIn" href="{{minisitio.empresa.linkedIn}}" target="_blank"><i  class="fab fa-linkedin mr-32" [ngStyle]="{'color': getColor()}"></i></a>
                <a *ngIf="minisitio.propiedades[0].youtube" href="{{minisitio.propiedades[0].youtube}}" target="_blank"><i class="fab fa-youtube mr-32" [ngStyle]="{'color': getColor()}"></i></a>
                <a *ngIf="minisitio.empresa.twitter" href="{{minisitio.empresa.twitter}}" target="_blank"><i  class="fab fa-twitter mr-32" [ngStyle]="{'color': getColor()}"></i></a>
                <a *ngIf="minisitio.propiedades[0].pinterest" href="{{minisitio.propiedades[0].pinterest}}" target="_blank"><i  class="fab fa-pinterest mr-32" [ngStyle]="{'color': getColor()}"></i></a>
                <a *ngIf="minisitio.propiedades[0].whatsapp" href="{{minisitio.propiedades[0].whatsapp}}" target="_blank"><i  class="fab fa-whatsapp mr-32" [ngStyle]="{'color': getColor()}"></i></a>
          </div>
        </div>
    </div>

    <div fxFlex="100" class="cont_nav--comp">
        <nav fxFlex="100" class="navbar nav_desktop">
            <div class="cont_nav" fxLayoutAlign="space-between center">
                <div fxFlex="35" fxFlex.gt-sm="50" class="item_nav item_nav--left" fxLayout="row" fxLayoutAlign="start center">
                    <img class="ok_log" [routerLink]="'./'" src="{{ minisitio.empresa ? minisitio.empresa.url_logo : null }}" alt="">
                    <button class="btn_nav btn-navy" [ngStyle]="{'background': getColor()}" [routerLink]="'./'">{{ minisitio.propiedades[0].inicio_texto | uppercase }}</button>
                    <button class="btn_nav btn-clean" [routerLink]="'./vacantes'">{{ minisitio.propiedades[0].empleo_text | uppercase }}</button>
                </div>
                <div fxFlex="50" fxFlex.gt-sm="50" class="item_nav" fxLayout="row" fxLayoutAlign="end center">
                    <!-- <a class="btn_nav btn-blog btn-blog--right" href="https://okempleos.com/contactenos/">CONTACTO</a> -->
                    <button class="btn_nav btn-orange" [routerLink]="'/login'">INICIAR SESION</button>
                    <ul class="ul_second" fxLayout="row" fxLayoutAlign="center center">
                        <li class="li_second">
                            <button class="btn_nav btn-wiht--two" [routerLink]="'/registro'">REGISTRARME</button>
                            <!-- <ul class="items_otions">
                                <li><a [routerLink]="'/registro'" [queryParams]="{registro: 'empresa'}">Empresa</a></li>
                                <li><a [routerLink]="'/registro'" [queryParams]="{registro: 'candidato'}">Candidato</a>
                                </li>
                            </ul> -->
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <nav class="navbar nav_movile">
            <div class="cont_nav" fxLayoutAlign="space-between center">
                <div fxFlex="100" class="item_nav" fxLayoutAlign="space-between center">
                    <img class="ok_log item_nav--left" src="{{ minisitio.empresa ? minisitio.empresa.url_logo : null }}" alt=""
                        (click)="back()">
                    <img class="hamburger" (click)="validMenu()" src="../../../assets/images/logos/hamburger_icon.png"
                        alt="">
                </div>
            </div>
        </nav>
    </div>
    <!-- END NAV BAR -->
    <header fxLayout="column" fxLayoutAlign="start center" style="margin-top: 3%;" [ngStyle]="{'height' : getHeaderheight()}">
        <div *ngIf="!isDynamicHeader()" class="reveal">
            <div class="slides">
                <section *ngFor="let banner of banners" class="slide-background-content" style="background-image: url('{{banner.details[0].fondo_src}}');">               
                        <div style="text-align: left; margin-top: 10%; margin-left: 10%;">
                            <h2 style="color: #FFFFFF; font-family: 'Montserrat'; font-style: normal; font-weight: 700; font-size: 92px; line-height: 76px;"></h2>
                            <p style="color: #FFFFFF; font-family: 'Montserrat'; font-style: normal; font-weight: 500; font-size: 25px; line-height: 48px;"></p>
                        </div>
                </section>
            </div>
        </div>
        <div *ngIf="isDynamicHeader()" class="reveal">
            <div class="slides">
                <section *ngFor="let banner of banners" class="slide-background-content" style="background-image: url('{{banner.details[0].fondo_src}}');">
                        <div [innerHtml]="banner.details[0].titulo  | safeHtml"></div>
                </section>
            </div>
        </div>
    </header>
    <div class="main_cont">

        <!-- BLOCK 3  Who-->
        <section *ngIf="minisitio.propiedades[0].block3_activo" class="section section__find">
            <h1 class="title" [ngStyle]="{'color': getColor()}">{{ minisitio.propiedades[0].block3_titulo }}</h1>
            <div fxLayout="row wrap" class="cont_find">
                <div fxLayout="row" fxFlex="100" fxFlex.gt-sm="100" fxLayoutAlign="center center">
                    <p class="parr--visible" style=" font-size: 20px; line-height: normal; margin-left: 0%; margin-right: 0%; align-items: center; text-align: justify;">{{ minisitio.propiedades[0].block3_texto }}</p>
                </div>
                <div fxLayout="row" fxFlex="100" fxFlex.gt-sm="100" fxLayoutAlign="center center">    
                    <button [ngStyle]="{'background-color': getColor()}" class="button-custom btn--find" mat-raised-button color="accent"  [routerLink]="'./vacantes'">{{minisitio.propiedades[0].block3_boton_texto}}</button>
                </div>
            </div>
        </section>
        <!-- BLOCK 4  Ours-->
        <section *ngIf="minisitio.propiedades[0].block4_activo" class="section section__visible">
            <h1 class="title title--visible"></h1>
            <h2 class="title--h2" [ngStyle]="{'color': getColor()}">{{ minisitio.propiedades[0].block4_titulo }}</h2>
            <!-- <p class="parr--visible">{{ minisitio.propiedades[0].block4_texto }}</p> -->
            <div class="parr--visible" [innerHtml]=" minisitio.propiedades[0].block4_texto  | safeHtml"></div>
            <p class="visible_subtext"></p>
            <button [ngStyle]="{'background-color': getColor()}" class="button-custom btn--visible" mat-raised-button color="accent"  [routerLink]="'./vacantes'">{{ minisitio.propiedades[0].block4_boton_texto }}</button>
            <img class="visible_img" fxFlex="80" fxFlex.gt-xs="45" fxFlex.gt-sm="70"
                src="{{ minisitio.propiedades[0].block4_imagen }}" alt="">
        </section>
        <!-- BLOCK 1 Marquee -->
        <section *ngIf="minisitio.propiedades[0].block1_activo" class="section section__find" style="margin-top:68px;">
                    <h1 class="title" [ngStyle]="{'color': getColor()}">{{ minisitio.propiedades[0].block1_texto }}</h1>
                    <div fxLayout="row wrap" class="cont_find" style="padding-top: 3%;">
                        <div fxLayout="row" fxFlex="100" fxFlex.gt-sm="100" fxLayoutAlign="center center">
                            <!-- <div class="companies_slide  companies_slide-desktop">
                                <ng-image-slider [images]="imgCollection" #nav [infinite]="true" [autoSlide]="2" [showArrow]="false"
                                    [imagePopup]="false" [imageSize]="{width: '15%',space: 12}"></ng-image-slider>
                            </div>
                            <div class="companies_slide companies_slide-mobile">
                                <ng-image-slider [images]="imgCollection" #nav [infinite]="true" [autoSlide]="2" [showArrow]="false"
                                    [imagePopup]="false" [imageSize]="{width: '35%',space: 12}"></ng-image-slider>
                            </div> -->
                            <marquee [routerLink]="'./vacantes'"
                            direction="left"
                            style="color: #929292;
                            height: 58px;
                            font-family: 'Montserrat';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 32px;
                            line-height: 52px;
                            text-align: center;
                            text-transform: uppercase;
                            border-radius: 60px;"
                            bgcolor="#F5F5F5"
                            scrolldelay="20"
                            behavior="loop"
                            >
                            <mat-icon class="mr-4">monetization_on</mat-icon>
                            <label *ngFor="let vacante of vacantes" [routerLink]="'./vacantes'">      {{vacante.cargo}}      <mat-icon class="mr-4">monetization_on</mat-icon>      </label>  
                            </marquee>
                        </div>
                    </div>
        </section>
        <!-- BLOCK 2 video -->
        <section *ngIf="minisitio.propiedades[0].block2_activo" class="section section__find">
                    <h1 class="title" [ngStyle]="{'color': getColor()}">{{ minisitio.propiedades[0].block2_titulo }}</h1>
                    <div fxLayout="row wrap" class="cont_find">
                        <div fxLayout="row" fxFlex="100" fxFlex.gt-sm="100" fxLayoutAlign="center center">
                            
                            <iframe width="60%" height="480" [src]="safeSrc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
        </section>        
        <!-- BLOCK 5 -->
        <section *ngIf="minisitio.propiedades[0].block5_activo" fxLayout="column" id="school" class="section section__school">
            <h1 class="title" [ngStyle]="{'color': getColor()}">{{ minisitio.propiedades[0].block5_titulo }}</h1>
            <div fxFlex="100">
                <!-- slidert movile -->
                <ng-image-slider class="slider-school--mobile" #nav (imageClick)="imageClick($event)"
                    [imagePopup]="false" [animationSpeed]="5" [infinite]="true" [images]="videosCandidatoSlider"
                    [autoSlide]="1" [slideImage]="1" [imageSize]="{width:'100%', height:'auto',space: 12}">
                </ng-image-slider>

                <!-- slidert Desktop -->
                <ng-image-slider class="slider-school--desktop" #nav (imageClick)="imageClick($event)"
                    [imagePopup]="false" [animationSpeed]="5" [infinite]="true" [images]="videosCandidatoSlider"
                    [autoSlide]="1" [slideImage]="1" [imageSize]="{width:'35%', height:'auto',space: 12}">
                </ng-image-slider>
            </div>
        </section>
        <!-- BLOCK SOCIAL MEDIA -->
        <section fxLayout="column" fxLayoutAlign="center center" class="section section__follow">
            <img class="ok_log" src="{{ minisitio.empresa ? minisitio.empresa.url_logo : null }}" style="max-width: 30%;" alt="logo">
            <h1 class="title follow_title"></h1>
            <div fxLayout="row wrap" fxLayoutAlign="center center" class="follow_items" style="margin-top: 54px;">

                <a *ngIf="minisitio.empresa.facebook" href="{{minisitio.empresa.facebook}}"  target="_blank"><i  class="fab fa-facebook mr-32" [ngStyle]="{'color': getColor()}"></i></a>
                <a *ngIf="minisitio.empresa.instagram" href="{{minisitio.empresa.instagram}}" target="_blank"><i  class="fab fa-instagram mr-32" [ngStyle]="{'color': getColor()}"></i></a>
                <a *ngIf="minisitio.empresa.linkedIn" href="{{minisitio.empresa.linkedIn}}" target="_blank"><i  class="fab fa-linkedin mr-32" [ngStyle]="{'color': getColor()}"></i></a>
                <a *ngIf="minisitio.propiedades[0].youtube" href="{{minisitio.propiedades[0].youtube}}" target="_blank"><i class="fab fa-youtube mr-32" [ngStyle]="{'color': getColor()}"></i></a>
                <a *ngIf="minisitio.empresa.twitter" href="{{minisitio.empresa.twitter}}" target="_blank"><i  class="fab fa-twitter mr-32" [ngStyle]="{'color': getColor()}"></i></a>
                <a *ngIf="minisitio.propiedades[0].pinterest" href="{{minisitio.propiedades[0].pinterest}}" target="_blank"><i  class="fab fa-pinterest mr-32" [ngStyle]="{'color': getColor()}"></i></a>
                <a *ngIf="minisitio.propiedades[0].whatsapp" href="{{minisitio.propiedades[0].whatsapp}}" target="_blank"><i  class="fab fa-whatsapp mr-32" [ngStyle]="{'color': getColor()}"></i></a>
                

            </div>
            <div fxLayout="row wrap" fxLayoutAlign="center center" class="follow_items" style="margin-top: 54px;">
                <a [routerLink]="'./'"[ngStyle]="{'color': getColor()}">{{ minisitio.propiedades[0].inicio_texto }}</a>
                <a [routerLink]="'./vacantes'" [ngStyle]="{'color': getColor()}">{{ minisitio.propiedades[0].empleo_text }}</a>
                <a [routerLink]="'/registro'" [ngStyle]="{'color': getColor()}">Crear cuenta</a>
                <a *ngIf="minisitio.empresa.web"  href="https://{{minisitio.empresa.web}}" [ngStyle]="{'color': getColor()}" target="_blank">Sitio web oficial</a>
            </div>
            <img class="ok_log" src="{{ logoPath }}"  style="margin-top: 54px;" alt="logo">
            <div fxLayout="row wrap" fxLayoutAlign="center center" class="follow_items" style="margin-top: 54px; margin-bottom: 100px; text-align: center;">
                <a href="https://okempleos.com/politicas-de-privacidad/" style="color: #4B4B4B; ">Políticas de privacidad</a>
                <a href="https://okempleos.com/politicas-de-privacidad/" style="color: #4B4B4B;">Tratamiento de datos</a>
                <a href="https://app.okempleos.com/terminos" style="color: #4B4B4B;">Términos y condiciones</a>
            </div>       
        </section>
         <!-- BLOCK OK -->
        <!-- <section fxLayout="column" fxLayoutAlign="center center" class="section section__follow_us">
           
        </section> -->
        <div class="cont_footer" #contFoo style="margin-top: 65px;">  

        </div>
        <footer fxLayout="row wrap" fxLayoutAlign="center center">
            <div class="col-md-4" style="text-align-last: center;">
                <a mat-button href="https://okempleos.com/wp-content/uploads/2022/05/RESOLUCiON-0094-2.pdf" target="_blank" class="text-small text-truncate" style="color:#FFF !important;">
                    <img src="/assets/images/home/SPE.png" style="width: 5%;"  alt="Servicio Público de Empleo">
                    <img src="/assets/images/home/MinTrabajo.png" style="width: 10%; margin: 10px; vertical-align: middle;" alt="Ministerio del Trabajo">
                    Vinculado a la red de prestadores del Servicio Público de Empleo.
                    Autorizado por la 
                    Unidad Administrativa Especial del Servicio Público de Empleo según 
                    Resolución No. 0094 del 8 de Marzo del 2021.
                </a>
            </div>
        </footer>

    </div>


</div>