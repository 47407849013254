import { Renderer2, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { navigation } from 'app/navigation/navigation';
import { navigation_empresa } from 'app/navigation/navigation_empresa';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationSpanish } from 'app/navigation/i18n/es';

import { GlobalConstants } from './common/global-constants';
import { AuthStateService } from 'app/pixcore/shared/auth-state.service';
import { Router, NavigationEnd  } from '@angular/router';
import { ModalFormCandidatoComponent } from './modals/modal-form-candidato/modal-form-candidato.component';
import { ModalFormEmpresaComponent } from './modals/modal-form-empresa/modal-form-empresa.component';
import { ModalPostCandidatoComponent } from './modals/modal-post-candidato/modal-post-candidato.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JoyrideService } from 'ngx-joyride';
import { environment } from "environments/environment";
import { SEOService } from './seo-service.service';
import { LogoService } from './logo.service';

import { filter } from 'rxjs/operators';
// import { getMessaging, getToken, onMessage } from "firebase/messaging";

@Component({
    selector   : 'app',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy
{
    title = GlobalConstants.siteTitle;
    urlLocal = GlobalConstants.apiURL;
    fuseConfig: any;
    navigation: any;
    tipoNavigation: any;
    currentRoute: string;

    tipoUsuario: number;
    tipoRol: number;
    empresaPlan: number;
    usuarioId: number
    candidatoCreado: boolean;
    empresaCreada: boolean;
   

    isUserDatos = false;
    isAPublicRoute = false;
    userDatos: any;

    // Private
    private _unsubscribeAll: Subject<any>;
    private config: {version: string}; 

    isPreLaunch = environment.preLaunch; 
    public logoPath: string;
    // tittle = 'af-notification';
    // message:any = null;
    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private authState: AuthStateService,
        private router: Router,
        private http: HttpClient,
        public _dialog: MatDialog,
        private readonly joyrideService: JoyrideService,
        private seoService: SEOService,
        private _renderer2: Renderer2,
        @Inject(DOCUMENT) private _document: Document,
        private logoService: LogoService,
        
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        // Register the navigation to the service

        this._fuseNavigationService.register('navigation', navigation);
        this._fuseNavigationService.register('navigation_empresa', navigation_empresa);
        
        router.events.pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(event => 
         {
            this.currentRoute = event['url'];          
            // console.log('ROUTE'+this.currentRoute);
            if (this.currentRoute.includes('login') 
            || this.currentRoute.includes('registro') 
            || this.currentRoute === '/terminos' 
            || this.currentRoute === '/politicas' 
            || this.currentRoute === '/forgot-password' 
            || this.currentRoute.includes('reset-password') 
            || this.currentRoute.includes('/lista-vacantes')
            || this.currentRoute.includes('/detalle-vacante')
            || this.currentRoute === '/lista-empresas' 
            || this.currentRoute === '/planes'
            || this.currentRoute === '/okupa'
            || this.currentRoute.includes('aliados')
            || this.currentRoute.includes('verificacion')
            || this.currentRoute === '/'
            || this.currentRoute.includes('apps/dashboards/analytics')
            || this.currentRoute.includes('okselect')
            || this.currentRoute.includes('pixcore')) {
                this.isUserDatos = false;
                this.isAPublicRoute = true;
            }
                
            else {               
                this.authState.validateSession();
                this.isAPublicRoute = false;
            }
               
            // console.log('this.isUserDatos ' + this.isUserDatos + ' | this.isAPublicRoute ' + this.isAPublicRoute);
            
                   
        });

        this.authState.onDataChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((value) => {
            if (value === 1) { 
                this.tipoNavigation = 'navigation';
                this._fuseNavigationService.setCurrentNavigation(this.tipoNavigation);
                if(this.isPreLaunch){
                this.actualizarNavegacionCandidatoExterior();
                }
            }else {
                this.tipoNavigation = 'navigation_empresa'; 
                this._fuseNavigationService.setCurrentNavigation(this.tipoNavigation); 
            }

            if (value === 3 || value === 2) {
                if (this.authState.getUserSession()) {

                    this.tipoRol = this.authState.getUserSession().role_id;
                    this.actualizarNavegacionPorRol(this.tipoRol);

                    if (this.authState.getUserSession().empresa) {
                        this.empresaPlan = this.authState.getUserSession().empresa.plan_id;
                        this.actualizarNavegacionPorPlanEmpresa(this.empresaPlan);
                    }
                    
                }
            }

            
            
            if (this.isUserDatos === false && this.isAPublicRoute === false) {
               
                if (this.authState.getUserSession()) {
                    this.userDatos = this.authState.getUserSession();
                    this.tipoUsuario = this.userDatos.user_type;
                    this.tipoRol = this.userDatos.role_id;
                    this.usuarioId = this.userDatos.id;
        
                    if (this.tipoUsuario === 3) { 
                        

                        if (!this.userDatos.first_name || !this.userDatos.last_name) {
                            this.router.navigateByUrl(`/usuario`);
                        }
                        
                    }else {

                        if (this.tipoUsuario === 1 && (!this.userDatos.candidato || (this.userDatos.candidato && !this.userDatos.candidato.direccion))) {

                            this.getPrimerLogin().then((res) => {
                    
                                if (res.direccion) {
    
                                    // this.router.navigateByUrl(`/`); 
    
                                }else {
                                    this.abrirModalForm('candidato');
                                }
                            });

                        }else if (this.tipoUsuario === 2 && !this.userDatos.empresa) {
                            this.getPrimerLogin().then((res) => {
                    
                                if (res.id) {
    
                                    // this.router.navigateByUrl(`/`); 
    
                                }else {
    
                                    this.abrirModalForm('empresa');
                                    
                                }
                            });
                        }
                    }
            
                    this.isUserDatos = true;
                }   
            }
        }); 

        
        

        // Add languages
        this._translateService.addLangs(['es', 'en']);

        // Set the default language
        this._translateService.setDefaultLang('es');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationSpanish, navigationEnglish);

        // Use a language
        this._translateService.use('es');

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         */

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.

        setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('es');
        });

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if ( this._platform.ANDROID || this._platform.IOS )
        {
            this.document.body.classList.add('is-mobile');
        }

        this.logoPath = this.logoService.getLogoPath();
        
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        /* SEoService */
        this.seoService.setPageTitle(`Ok Empleos ${environment.country} | Portal de Empleo | El trabajo que mereces | El talento que buscas`);
        this.seoService.createLinkForCanonicalURL();
        this.seoService.setPageKeyword();
        /* SEoService */
        /* Schema org */
        this.createSchema();

        /* Schema org */
        this.config = require('./../assets/config.json'); 
        console.log(this.config.version);

        const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache'); 
      
        this.http
        .get<{ version: string }>("/assets/config.json", {headers})
        .subscribe(config => {
        if (config.version !== this.config.version) {
            location.reload(); 
            }
        });


        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.fuseConfig = config;

                // Boxed
                if ( this.fuseConfig.layout.width === 'boxed' )
                {
                    this.document.body.classList.add('boxed');
                }
                else
                {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for ( let i = 0; i < this.document.body.classList.length; i++ )
                {
                    const className = this.document.body.classList[i];

                    if ( className.startsWith('theme-') )
                    {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
        
        
        //if (this.isPreLaunch) {
            //this.actualizarNavegacionCandidatoExterior();
       // }
        // this.requestPermission();
        // this.listen();
        
    }
    createSchema() {
        const json =  {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "url": "https://app.okempleos.com",
            "potentialAction": {
                "@type": "SearchAction",
                "target": "https://app.okempleos.com/lista-vacantes?palabra={search_term_string}",
                "query-input": "required name=search_term_string"
            }
        };
        let script = this._renderer2.createElement('script');
        script.type = `application/ld+json`;
        script.text = `${JSON.stringify(json)}`;
        
        this._renderer2.appendChild(this._document.head, script);
    }

    actualizarNavegacionPorRol(rol: number): void {

        // los items que se le bloquean a cada usuario
        const roleModerador = ['admin_usuarios', 'planes', 'contacto_asesor', 'resumen_plan'];
        const roleReclutador = ['admin_usuarios', 'planes', 'datos_empresa', 'contacto_asesor', 'resumen_plan'];
        const roleContabilidad = ['datos_empresa', 'usuarios', 'roles', 'vacantes', 'lista-candidatos', 'resumen_plan'];

        roleModerador.forEach(x => {
            this._fuseNavigationService.updateNavigationItem(x , {
                hidden: false
            });
        });

        roleReclutador.forEach(x => {
            this._fuseNavigationService.updateNavigationItem(x , {
                hidden: false
            });
        });

        roleContabilidad.forEach(x => {
            this._fuseNavigationService.updateNavigationItem(x , {
                hidden: false
            });
        });

        switch (rol) {
            case 2: {
                roleModerador.forEach(x => {
                    this._fuseNavigationService.updateNavigationItem(x , {
                        hidden: true
                    });
                });
                break;
            }
            case 3: {
                roleReclutador.forEach(x => {
                    this._fuseNavigationService.updateNavigationItem(x , {
                        hidden: true
                    });
                });
                break;
            }

            case 4: {
                roleContabilidad.forEach(x => {
                    this._fuseNavigationService.updateNavigationItem(x , {
                        hidden: true
                    });
                });
                break;
            }

        }
    }

    actualizarNavegacionPorPlanEmpresa(planEmpresa: number): void {

       
        const plan1Badge = ['lista-candidatos', 'lista-preseleccionados', 'lista-colaboradores', 'procesos-seleccion'];
        plan1Badge.forEach(x => {
            this._fuseNavigationService.updateNavigationItem(x , {
                badge: null
            });
        });

        

        switch (planEmpresa) {
            case 1: {
                const badge = {
                    title: 'Pro',
                }
                plan1Badge.forEach(x => {
                    this._fuseNavigationService.updateNavigationItem(x , {
                        badge: badge
                    });
                });

                break;
            }
            case 4: {
                const badge = {
                    title: 'Pro',
                }
                plan1Badge.forEach(x => {
                    this._fuseNavigationService.updateNavigationItem(x , {
                        badge: badge
                    });
                });

                break;
            }
            case 5: {
                const badge = {
                    title: 'Pro',
                }
                plan1Badge.forEach(x => {
                    this._fuseNavigationService.updateNavigationItem(x , {
                        badge: badge
                    });
                });

                break;
            }
            case 6: {
                const badge = {
                    title: 'Pro',
                }
                plan1Badge.forEach(x => {
                    this._fuseNavigationService.updateNavigationItem(x , {
                        badge: badge
                    });
                });

                break;
            }
            
        }
    }


    actualizarNavegacionCandidatoExterior(): void {
        
          const exterior = ['lista_empresas', 'listado_vacantes', 'listado_vacantes_favoritas', 'listado_candidato_aplicaciones'];
          const badge = {
            title: 'Pro',
            bg: '#1D3A4E',
            fg: '#FFFFFF'
          }
          exterior.forEach(x => {
            this._fuseNavigationService.updateNavigationItem(x , {
              badge: badge,
              url: '#'
            });
          });
        
      }    


    
    getPrimerLogin(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.http.get(`${this.urlLocal}primer_login/${this.usuarioId}`)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
        
    }

    abrirModalForm(tipo: string): void {
        let texto: string;
        texto = tipo;
        
        const data = {
            texto: texto,
            user_id: this.usuarioId
        };

        if (tipo === 'candidato') {
            const modal = this._dialog.open(ModalFormCandidatoComponent, { 
                data: data,
                disableClose: true,
                maxWidth: '95vw'
            });

            modal.afterClosed().subscribe((result) => {
                if (result && !this.isPreLaunch) {
                    this.authState.removeUserSession();
                    this.authState.validateSession();
                    const modal2 = this._dialog.open(ModalPostCandidatoComponent);

                    modal2.afterClosed().subscribe((res) => {

                        if (res) {
                            this.router.navigateByUrl('/vacante/candidato-vacantes/lista').then((res) => {
                                // console.log('llego a la ruta y sigue el remove usersession')
                                
                            })
                        }else {
                            this.router.navigateByUrl('/usuario').then((res) => {
                                // this.authState.removeUserSession();
                                // this.authState.validateSession();
                            });
                        }
                    });

                    
                }
               
            });
        }

        if (tipo === 'empresa') {
           
            const modal = this._dialog.open(ModalFormEmpresaComponent, { 
                data: data,
                disableClose: true,
                maxWidth: '95vw'
            });

            modal.afterClosed().subscribe(() => {
                this.router.navigateByUrl('/usuario').then((res) => {
                    this.authState.removeUserSession();
                    this.authState.validateSession();
                });
            });
        }

        

        
        
    }


    // requestPermission() {
    //     const messaging = getMessaging();
    //     getToken(messaging, 
    //      { vapidKey: environment.firebase.vapidKey}).then(
    //        (currentToken) => {
    //          if (currentToken) {
    //            console.log("Hurraaa!!! we got the token.....");
    //            console.log(currentToken);
    //          } else {
    //            console.log('No registration token available. Request permission to generate one.');
    //          }
    //      }).catch((err) => {
    //         console.log('An error occurred while retrieving token. ', err);
    //     });
    //   }
    //   listen() {
    //     const messaging = getMessaging();
    //     onMessage(messaging, (payload) => {
    //       console.log('Message received. ', payload);
    //       this.message=payload;
    //     });
    //   }

    

    

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
}
