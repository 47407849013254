export const locale = {
    lang: 'es',
    data: {
        'WELCOME': {
            'TITLE': '¡Hola! Bienvenido a ___ :)',
            'SUBTITLE': '___'
        },
        'CODE_FORM': {
            'TITLE': 'Consulta tu correo electrónico',
            'SUBTITLE1': 'Te enviamos un correo electrónico con un código de verificación a ',
            'SUBTITLE2': 'Escríbelo a continuación para confirmar tu dirección de correo electrónico.',
            'RESEND': 'Solicitar otro código',
            'INPUT_CODE': 'Introduce tu código de 6 dígitos',
            'WRONG_CODE': 'El código ingresado es incorrecto...',
          
        }
    }
};
