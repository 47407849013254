export const locale = {
    lang: 'es',
    data: {
        'NAV': {
            'HOME': 'Inicio',
            'EMPRESA': 'Empresa',
            'APPLICATIONS': 'Aplicaciones',
            'DASHBOARDS'  : 'Dashboards',
            'CALENDAR'    : 'Calendario',
            'ECOMMERCE'   : 'E-Commerce',
            'ACADEMY'     : 'Academia',
            'MAIL'        : {
                'TITLE': 'Correo',
                'BADGE': '25'
            },
            'MAIL_NGRX'        : {
                'TITLE': 'Correo Ngrx',
                'BADGE': '13'
            },
            'CHAT'        : 'Chat',
            'FILE_MANAGER': 'Administrador de archivos',
            'CONTACTS'    : 'Contactos',
            'TODO'        : 'To-Do',
            'SCRUMBOARD'  : 'Scrumboard',
            'MY_INFORMATION': 'Mi información',
            'MY_DATA': 'Mis datos',
            'PERSONAL_DATA': 'Datos personales',
            'ACADEMIC_DATA': 'Información académica',
            'EXPERIENCE': 'Experiencia',
            'SKILLS': 'Habilidad',
            'AWARDS': 'Honores y premios',
            'LANGUAGES': 'Idiomas',
            'MY_APPLICATIONS': 'Mis aplicaciones',
            'VIDEO_PRESENTATION': 'Video presentación',
            'LABORAL_OFERTS': 'Ofertas laborales',
            'COMPENTENCE_TESTS': 'Pruebas de competencia',
            'AUTH': 'Autenticación',
            'LOGIN': 'Ingreso',
            'REGISTER': 'Registro',
            'FORGOT_PASSWORD': 'Olvidé contraseña',
            'RESET_PASSWORD': 'Restablecer contraseña',
        },
    }
};
