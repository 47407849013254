import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as spanish } from './i18n/es';
import { locale as english } from './i18n/en';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Params, Router, ActivatedRoute } from '@angular/router';
import { GlobalConstants } from 'app/common/global-constants';
import { AuthService } from 'app/pixcore/shared/auth.service';
import { TokenService } from 'app/pixcore/shared/token.service';
import { AuthStateService } from 'app/pixcore/shared/auth-state.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { InterceptorSkipHeader } from 'app/pixcore/shared/auth.interceptor';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { SEOService } from 'app/seo-service.service';
import { LogoService } from 'app/logo.service';

@Component({
    selector     : 'login',
    templateUrl  : './login.component.html',
    styleUrls    : ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class LoginComponent implements OnInit
{
    loginForm: FormGroup;
    hide = true;
    loadingLogin = false;
    urlLocal = GlobalConstants.apiURL;
    urlLocalOauth = GlobalConstants.oauthURL;
    clientId = GlobalConstants.clientId;
    clientKey = GlobalConstants.clientKey;
    URL = GlobalConstants.URL;

    isRedirectVacante = false;
    vacanteId: number;

    routeParams: Params;
    currentStateCandidato = "1";
    currentStateEmpresa = "0";

    user: SocialUser;
    loggedIn: boolean;

    linkedInCredentials = {
        clientId: "77wet208pi7uo6",
        redirectUrl: `${this.URL}/login-linkedin`,
        scope: "r_liteprofile%20r_emailaddress" // To read basic user profile data and email
    };

    logoPath: string;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private http: HttpClient,
        private router: Router,
        public authService: AuthService,
        private token: TokenService,
        private authState: AuthStateService,
        private _snackBar: MatSnackBar,
        private _route: ActivatedRoute,
        private _socialAuthService: SocialAuthService,
        private seoService: SEOService,
        private logoService: LogoService,
     
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        this._fuseTranslationLoaderService.loadTranslations(spanish, english);

        if (this.authState.getUserSession()) {
            this.router.navigate(['/home']);
        } 

        this.logoPath = this.logoService.getLogoPath();
            
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        /* SEoService */
        this.seoService.setPageTitle('Ingreso | Ok empleos');
        this.seoService.createLinkForCanonicalURL();
        this.seoService.setDescription('Bienvenido de nuevo, ingresa a tu cuenta y consigue el empleo que mereces y el talento que buscas.');
        /* SEoService */
        this.loginForm = this._formBuilder.group({
            email   : ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });

        this._route.queryParams
        .subscribe(params => {
           
            if (params.login) {
                if (params.login === 'candidato') {
                    this.currentStateEmpresa = "0";
                    this.currentStateCandidato = "1"
                }else if (params.login === 'empresa') {
                    this.currentStateEmpresa = "1";
                    this.currentStateCandidato = "0"; 
                }else {
                    this.currentStateEmpresa = "0";
                    this.currentStateCandidato = "1"
                }
            }

        });

        this._socialAuthService.authState.subscribe((user) => {
            this.user = user;
            this.loggedIn = (user != null);
            if (this.loggedIn) {
                        
               this.auth_register(this.user.email, this.user.name, this.user.firstName, this.user.lastName, this.user.photoUrl, this.user.id, this.user.provider, this.user.id);
           }
        });
    }

    auth_register(email: string, name: string, first_name: string, last_name: string, photoUrl: string, password: string , provider: string, provider_id: string) {

        this.loadingLogin = true;

        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');

        const formData = this.loginForm.getRawValue();

        formData.user_type = 1;
        formData.email = email;
        formData.name = name;
        formData.first_name = first_name;
        formData.last_name = last_name;
        formData.photo_url = photoUrl;
        formData.password = password;
        formData.provider = provider;
        formData.provider_id = provider_id;

        if (email === "" || email == null) {
            this.loadingLogin = false;
            this.openSnackBar("Es necesario un email asociado a la cuenta", 'snack-error');
            return;
        }

     
        this.http.post(`${this.urlLocal}auth_user_social_media`, formData, {headers: headers}).subscribe(
            (result: any) => {
                if (!result.errors) {

                    localStorage.removeItem('admin_mode');
                    this.openSnackBar("Ingreso satisfactorio", 'snack-success');
                    this.responseHandler(result.sessionData);
                    this.authState.removeUserSession();
                    this.authState.setAuthState(true);
                    this.router.navigate(['/home']);
                    this.loadingLogin = false;
                }else {
                    this.loadingLogin = false;
                    this.openSnackBar(result.message, 'snack-error');
                }

            });
    }

    OnSubmit(): void
    {
        this.loadingLogin = true;
        const formData = this.loginForm.getRawValue();
        const data = {
            email: formData.email,
            password: formData.password,        
            grant_type: 'password',
            client_id: this.clientId,
            client_secret: this.clientKey,
            scope: '*'
        }

        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');

        this.http.post(`${this.urlLocal}new_login`, data, {headers:headers})
        .subscribe(
            (result: any) => {
                if (result.status === undefined) {
                    localStorage.removeItem('admin_mode');
                    this.openSnackBar("Ingreso satisfactorio", 'snack-success');
                    this.responseHandler(result);
                    this.authState.removeUserSession();
                    this.authState.setAuthState(true);
                    this.router.navigate(['/home']);
                    this.loadingLogin = false;
                } else {
                    this.openSnackBar("Aún falta verificar el correo", 'snack-error');
                    this.loadingLogin = false;
                    this.router.navigate(['/verificacion'], { queryParams: { email: result.data.email } });
                }
            },
            error => {
                if (error.status === 0) {
                    this.openSnackBar('Sin conexión al servidor', 'snack-error');
                }else {
                    this.openSnackBar('Los datos ingresados son incorrectos.', 'snack-error');
                }
                
                this.loadingLogin = false;
                //this.loginForm.reset();
                //@TODO: Mostrar notificación con error
            }
            
            
        )
    
    }


    signInWithGoogle(): void {
        this._socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
    }
    
    signInWithFB(): void {
        const fbLoginOptions = {
            scope: 'email',
            return_scopes: true,
            enable_profile_selector: true
          };
        this._socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID,fbLoginOptions);
    }

    signInWithLinkedIn(): void {
        window.location.href = `https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=${
        this.linkedInCredentials.clientId
        }&redirect_uri=${this.linkedInCredentials.redirectUrl}&scope=${this.linkedInCredentials.scope}`;
    }

    signOut(): void {
        this._socialAuthService.signOut();
    }  
    

    // Handle response
    responseHandler(data){
        this.token.handleData(data.access_token);
    }

    /**
     * Mostrar la respuesta al usuario
     * @param mensaje
     */
    private openSnackBar(mensaje: string, clase: string): void {
        this._snackBar.open(mensaje, 'x', {
         duration: 4000,
         horizontalPosition: 'end',
         verticalPosition: 'top',
         panelClass: clase,
       });
      }
}
