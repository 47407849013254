export const locale = {
    lang: 'es',
    data: {
        'WELCOME': {
            'TITLE': '¡Hola! Bienvenido a ___ :)',
            'SUBTITLE': '___'
        },
        'LOGIN_FORM': {
            'TITLE': 'INGRESA A LA CUENTA',
            'EMAIL': 'Correo administrador',
            'EMAIL_USER': 'Correo usuario',
            'PASSWORD': 'Contraseña',
            'REMEMBER_ME': 'Recuérdame',
            'FORGOT_PASSWORD': '¿Olvidaste tu contraseña?',
            'OR': 'O',
            'LOG_GOOGLE': 'Ingresar con Google',
            'LOG_FACEBOOK': 'Ingresar con Facebook',
            'LOG_LINKEDIN': 'Ingresar con LinkedIn',
            'DONT_ACCOUNT': '¿No tienes una cuenta?',
            'REGISTER_CANDIDATE': 'Candidato',
            'REGISTER_COMPANY': 'Empresa',
            'CREATE_ACCOUNT': 'Crear cuenta',
            'TERMS': 'términos y condiciones',
            'BUTTON_LOGIN': 'INGRESAR'
        },
        'LOGIN_FORM_ERROR': {
            'EMAIL_USER_REQUIRED': 'El correo del usuario requerido',
            'EMAIL_REQUIRED': 'El correo del administrador es requerido',
            'EMAIL_INVALID': 'Por favor, ingresa un correo válido',
            'PASSWORD_REQUIRED': 'La contraseña es requerida',
        }
    }
};
