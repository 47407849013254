import { Component, OnInit } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { AuthStateService } from 'app/pixcore/shared/auth-state.service';
import { formatDate } from "@angular/common";
import { Router, NavigationEnd  } from '@angular/router';
import { GlobalConstants } from 'app/common/global-constants';
import { Location } from '@angular/common'

declare function OpenWompiModal(amount: number, periodo: string, reference: string, currency: string, empresa_id: number, plan_id: number, mode: string, backend_url: string, frontend_url: string): any;
@Component({
  selector: 'app-okupa',
  templateUrl: './okupa.component.html',
  styleUrls: ['./okupa.component.scss']
})
export class OkupaComponent implements OnInit {
  prices = []
  opcion = 0;
  followLog = [];
  private urlLogs = '../../../../assets/images/logos/';
  dateNow: any;
  mode = GlobalConstants.mode;
  frontend_url = GlobalConstants.URL;
  backend_url = GlobalConstants.apiURL;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private authState: AuthStateService,
    private router: Router,
    private location: Location
  ) {
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    }

    this.prices = GlobalConstants.plans;

    this.followLog = [
      {
          img: this.urlLogs + '/log_tiktok.png',
          url: 'https://www.tiktok.com/@en.modo.ok?'
      },
      {
          img: this.urlLogs + '/log_instagram.png',
          url: 'https://www.instagram.com/ok.empleos/?hl=es'
      },
      {
          img: this.urlLogs + '/log_linkedin.png',
          url: 'https://www.linkedin.com/company/ok-empleos/'
      },
      {
          img: this.urlLogs + '/log_face.png',
          url: 'https://www.facebook.com/OkEmpleosColombia'
      },
      {
          img: this.urlLogs + '/log_youtube.png',
          url: 'https://www.youtube.com/channel/UCE56i6YHPlYBrpATAilz3XQ'
      },
      {
          img: this.urlLogs + '/log_twiter.png',
          url: 'https://twitter.com/ok_empleos'
      },
  ]
  }


  ngOnInit(): void {
  }

  GenerateTransactionReference(plan_id): string{
    this.dateNow = new Date();
    let reference = "";
    let sufix = "OK";
    let empresa_id = this.authState.getUserSession().empresa.id;
    let empresa_id_format =  this.pad(empresa_id, 4, "0"); // "0005"
    const format = 'yyyyMMddhhmmss';
    const locale = 'es-CO';
    const formattedDate = formatDate(this.dateNow, format, locale);


    reference = sufix + "_" + plan_id + "_" + empresa_id_format + "_" + formattedDate;
    console.log(reference);
    return reference;
}

pad(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

  onClickPlan(num_plan: any, valor_plan: any, periodo: any){
    // console.log(num_plan);
    // console.log(this.authState.isLoggedIn() && this.authState.isEmpresa());
    if(this.authState.isLoggedIn() && this.authState.isEmpresa()){
      OpenWompiModal(valor_plan, periodo, this.GenerateTransactionReference(num_plan), 'COP', this.authState.getUserSession().empresa.id, num_plan,this.mode,this.backend_url, this.frontend_url);
    } else {
      this.router.navigateByUrl(`/registro?registro=empresa`)
    }
  }

  back(): void {
    this.location.back()
  }

}
