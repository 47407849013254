import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Filtro } from 'app/public/lista-empresas/model/filtro.model';
import { ListaEmpresasService } from 'app/public/lista-empresas/lista-empresas.service';


@Component({
    selector: 'side-bar-empresas',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss']
})
export class SideBarListaEmpresasComponent implements OnInit, OnDestroy {


    loadingFiltro = false;


    formFiltro: FormGroup;
    filterBy: string;
    empresas: any[];

    sectoresEconomicos: any[];
    tiposEmpresas: any[];
    ciudades: any[];
    localidades: any[];

    changed = false;
    filtro: Filtro;
    searchText = ''

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ListaEmpresasService} _listaEmpresasService
     */
    constructor(
        private _listaEmpresasService: ListaEmpresasService,
        private _formBuilder: FormBuilder,
    
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.formFiltro = this.createFormFiltro();

        this.sectoresEconomicos = this._listaEmpresasService.sectoresEconomicos;
        this.tiposEmpresas = this._listaEmpresasService.tiposEmpresas;
        this.ciudades = this._listaEmpresasService.ciudades;
        this.localidades = this._listaEmpresasService.localidades;
        this.empresas = this._listaEmpresasService.empresasPublicas;
       
        this._listaEmpresasService.onSearchTextEmpresasPublicasChanged
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(searchText => {
            this.searchText = searchText;
            this.formFiltro.get('texto_busqueda').setValue(this.searchText);
        });

        this._listaEmpresasService.onSearchTextEmpresasPublicasEnter
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(value => {
        
            if (this.searchText === null || this.searchText === '' || this.searchText.length >= 3) {
                this.filtrar(this.formFiltro.value);
            }
        });
    
        this.filterBy = this._listaEmpresasService.filterBy || 'all';

        this._listaEmpresasService.onListadoChangedEmpresasPublicas
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(changed => {
            this.changed = changed;

            if (this.changed) {
                this.filtroAll();
            }
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this._listaEmpresasService.onFilterEmpresasPublicasChanged.next('all');
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    createFormFiltro(): FormGroup
    {
        return this._formBuilder.group({
            sectorEcomonico: [{
                value: null,
                disabled: false
            }],
            tipoEmpresa: [{
                value: null,
                disabled: false
            }],
            ciudad: [{
                value: null,
                disabled: false
            }],
            localidad_id: [{
                value: null,
                disabled: false
            }],
            texto_busqueda: [{
                value: null,
                disabled: false
            }, [Validators.minLength(3), Validators.maxLength(100)]]  
        });
    }

    filtroAll(): void {

        if (this.filterBy === 'all') {
               
            return;
        }

        this.loadingFiltro = true
        this.filterBy = 'all';
      
        this.createFormFiltro();
        this.formFiltro.reset();
       
        this._listaEmpresasService.onFilterEmpresasPublicasChanged.next(this.filterBy);
        
        setTimeout(() => {
            this.loadingFiltro = false
        }, 1500);
        
    }

    filtrar(form): void {


        if (!form.sectorEcomonico && !form.tipoEmpresa && !form.ciudad && !form.localidad_id && !form.texto_busqueda) {

            if (this.filterBy !== 'all') {
               
                this.filtroAll();
            } 
            
        }else {
            this.loadingFiltro = true
            this.filterBy = 'filtroApi';

            const filtros = {
                sector: [form.sectorEcomonico],
                empresa: [form.tipoEmpresa],
                ciudad: [form.ciudad],
                localidad_id: [form.localidad_id] || null,
                texto_busqueda: form.texto_busqueda || null
            };

            if (form.sectorEcomonico === null) {
                    filtros.sector = [];
            }

            if (form.tipoEmpresa === null) {
                filtros.empresa = [];
            }

            if (form.ciudad === null) {
                filtros.ciudad = [];
            }

            if (form.ciudad !== 524) {
                filtros.localidad_id = [];
            }

            if (form.localidad_id === null) {
                filtros.localidad_id = [];
            }
                  
            
            this.filtro = new Filtro(filtros);
            this._listaEmpresasService.filtrar(this.filtro).then(() => {
                this.loadingFiltro = false;

            });;
        }
            
            
            

    }

}
