export const locale = {
    lang: 'es',
    data: {
        'RESET_FORM': {
            'TITLE': 'RESTABLECE TU CONTRASEÑA',
            'EMAIL': 'Correo',
            'PASSWORD': 'Contraseña',
            'PASSWORD_CONFIRMATION': 'Contraseña (Confirmación)',
            'BUTTON_ACCEPT': 'RESTABLECER CONTRASEÑA',
            'GO_TO_LOGIN': 'Regresar al login',            
        },
        'RESET_FORM_ERROR': {
            'EMAIL_REQUIRED': 'Correo es requerido',
            'EMAIL_INVALID': 'Por favor, ingresa un correo válido',
            'PASSWORD_REQUIRED': 'Contraseña requerida',
            'PASSWORD_CONFIRMATION_REQUIRED': 'La confirmación de la contraseña es requerida',
            'PASSWORD_CONFIRMATION_MATCH': 'Las contraseñas deben coincidir',            
        }
    }
};
