import { Component, OnInit } from '@angular/core';
import { ProcessService } from './process.service';
import { Process } from './process.model';
import { AuthStateService } from 'app/pixcore/shared/auth-state.service';

@Component({
  selector: 'app-chat-manager',
  templateUrl: './chat-manager.component.html',
  styleUrls: ['./chat-manager.component.scss']
})
export class ChatManagerComponent implements OnInit {
  processes: Process[] = [];
  currentUser = this._authService.getUserSession(); // Aquí puedes obtener el usuario actual, por ejemplo, de un servicio de autenticación
  

  constructor(private processService: ProcessService, private _authService: AuthStateService) {}

  ngOnInit() {
    console.log(this.currentUser);
    if (this.currentUser != null){
      this.processes = this.processService.getProcessesForUser(this.currentUser.id);
      console.log(this.processes);
    }
  }
}