import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { MinisitioVacantesService } from './minisitio-vacantes.service';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { Router } from '@angular/router';
import { VerticalLayout1Component } from 'app/layout/vertical/layout-1/layout-1.component';
import { Location } from '@angular/common';
import { GlobalConstants } from 'app/common/global-constants';
import { HttpClient } from '@angular/common/http';
import { LogoService } from 'app/logo.service';

@Component({
    selector: 'minisitio-vacantes',
    templateUrl: './minisitio-vacantes.component.html',
    styleUrls: ['./minisitio-vacantes.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class MinisitioVacantesComponent implements OnInit, OnDestroy {

    @ViewChild('listScrollVacantes', { read: FusePerfectScrollbarDirective, static: true })
    listScroll: FusePerfectScrollbarDirective;

    dialogRef: any;
    searchInput: FormControl;

    // Private
    private _unsubscribeAll: Subject<any>;
    vacantesLenght: number;
    filterBy: string;
    navMobile = false;
    minisitio: any;
    urlLocal = GlobalConstants.apiURL;
    logoPath: String;
    /**
     * Constructor
     *
     * @param {CandidatoVacantesService} _listaVacantesService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _listaVacantesService: MinisitioVacantesService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseConfigService: FuseConfigService,
        private dateAdapter: DateAdapter<any>,
        private route: ActivatedRoute,
        private _router: Router,
        private _verticalLayout1Component: VerticalLayout1Component,
        private location: Location,
        private httpClient: HttpClient,
        private logoService: LogoService,
    ) {
        // Set the defaults
        this.searchInput = new FormControl('');

        // Set the private defaults
        this._unsubscribeAll = new Subject();


        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        this.logoPath = this.logoService.getLogoPath();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

            this.route.params.subscribe(params => {

                if (params.empresa) {

                    if (this._router) {

                        this.httpClient.get(`${this.urlLocal}minisitio/${params.empresa}`).subscribe(
                            (result: any) => {
                                this.minisitio = result[0];

                                if (result.length == 0){
                                    // this._router.navigate(['/inicio']); 
                                }
                        

                        });
                

                    
                    } else {
                        this._router.navigate(['/inicio']); 

                    }
                }
            });

    }

    detalleVacante(id: number): void {
        const url = `public/detalle-vacante/${id}`;
        this._router.navigateByUrl(url);

        // window.open(`https://app.okempleos.com/vacante/candidato-vacantes/lista/${id}/${empresaId}`);
        // window.open(`http://localhost:4200/vacante/candidato-vacantes/lista/${id}/${empresaId}`);

    }

    scrollToTop(): void {
        this._verticalLayout1Component.scrollToTop()
        this.listScroll.scrollToTop();
    }

    onEnterSearch(): void {

        this._listaVacantesService.onSearchTextVacantesPublicasEnter.next(true);

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Reset the search
        this._listaVacantesService.onSearchTextVacantesPublicasChanged.next('');

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    // /**
    //  * New contact
    //  */
    // newContact(): void
    // {
    //     this.dialogRef = this._matDialog.open(ContactsContactFormDialogComponent, {
    //         panelClass: 'contact-form-dialog',
    //         data      : {
    //             action: 'new'
    //         }
    //     });

    //     this.dialogRef.afterClosed()
    //         .subscribe((response: FormGroup) => {
    //             if ( !response )
    //             {
    //                 return;
    //             }

    //             this._empresaAplicacionesService.updateContact(response.getRawValue());
    //         });
    // }

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void {
        this._verticalLayout1Component.scrollToTop()
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }

    validMenu(){
        if(this.navMobile === true){
          this.navMobile = false;
        }else{
          this.navMobile = true; 
        }
      }
    
    back(): void {
        this.location.back()
    }

    getColor(){
        return this.minisitio.propiedades[0].font_color;
    }
}
