import { Component, OnInit, AfterViewInit, ViewEncapsulation, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FuseMatchMediaService } from '@fuse/services/match-media.service';
import { cityList } from '@fuse/types/citys';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SchoolFirstComponent } from './school-first/school-first.component';
import { ModalVisualizeComponent } from 'app/modals/modal-visualize/modal-visualize.component';
import { ModalCompletTallerComponent } from './modal-complet-taller/modal-complet-taller.component';
import { environment } from "environments/environment";
import Reveal from 'reveal.js';
import Markdown from 'reveal.js/plugin/markdown/markdown.esm.js';
import { GlobalConstants } from 'app/common/global-constants';
import { HttpClient } from '@angular/common/http';
import { reject } from 'lodash';
import { SEOService } from 'app/seo-service.service';
import { ViewportScroller } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
    selector: 'first',
    templateUrl: './first.component.html',
    styleUrls: ['./first.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FirstComponent implements OnInit, AfterViewInit{
    @ViewChild('firstDiv') firstDiv: ElementRef;
    @ViewChild('secondDiv') secondDiv: ElementRef;
    @ViewChild('searchDiv') searchDiv: ElementRef;
    @ViewChild('taller') anchortaller: ElementRef; 
    searchInput: FormControl;
    private url = 'assets/images/home/aliados/';
    private urlLogs = '../../../assets/images/logos/';
    videosCandidatoSlider = [];
    optionsDisplaySlider: any;
    valueFond = 1;
    followLog = [];
    isMenuOpen = false;
    movil_left = false;
    menuList = 1;
    citys = [];
    palabra = '';
    localizacion = 0;
    widthModal = '60vw';
    currentStateEmpresa = '0';
    currentStateCandidato = "1";
    imgCollection: Array<object> = [
        {            
            image: this.url + 'ambrosia.png',
            thumbImage: this.url + 'ambrosia.png',
            alt: 'Ambrosia',
            w: 'auto',
            h: '60px'
        },
        {
            image: this.url + 'applus.png',
            thumbImage: this.url + 'applus.png',
            alt: 'APPLUS',
            w: 'auto',
            h: '60px'
        }, {
            image: this.url + 'ascrodus.png',
            thumbImage: this.url + 'ascrodus.png',
            alt: 'ASCRODUS',
            w: 'auto',
            h: '60px'
        },
        {
            image: this.url + 'awake.png',
            thumbImage: this.url + 'awake.png',
            alt: 'AWAKE',
            w: 'auto',
            h: '60px'
        }, 
        {
            image: this.url + 'bombolandia.png',
            thumbImage: this.url + 'bombolandia.png',
            alt: 'BOMBOLADIA',
            w: 'auto',
            h: '60px'
        },
        {
            image: this.url + 'gesth.png',
            thumbImage: this.url + 'gesth.png',
            alt: 'GESTH',
            w: 'auto',
            h: '60px'
        },
        {
            image: this.url + 'pixeling.png',
            thumbImage: this.url + 'pixeling.png',
            alt: 'PIXELING',
            w: 'auto',
            h: '60px'
        }, {
            image: this.url + 'politecnico.png',
            thumbImage: this.url + 'politecnico.png',
            alt: 'POLITECNICO',
            w: 'auto',
            h: '60px'
        },
        {
            image: this.url + 'prosegur.png',
            thumbImage: this.url + 'prosegur.png',
            alt: 'PROSEGUR',
            w: 'auto',
            h: '60px'
        }, 
        {
            image: this.url + 'refinancia.png',
            thumbImage: this.url + 'refinancia.png',
            alt: 'REFINANCIA',
            w: 'auto',
            h: '60px'
        }, 
        {
            image: this.url + 'tejando.png',
            thumbImage: this.url + 'tejando.png',
            alt: 'TEJANDO',
            w: 'auto',
            h: '60px'
        },
    ];
    urlLocal = GlobalConstants.apiURL;
    banners : any = [{"id":1,"nombre":"Prueba","tiempo_ms":10000,"animacion":2,"busqueda_pos":"-13%","activo":1,"created_by":1,"created_at":null,"updated_at":"2023-05-12T16:26:03.000000Z","empresa_id":null,"details":[{"id":1,"banner_id":1,"fondo_src":"http:\/\/localhost:4200\/assets\/images\/examples\/academia_banner.png","titulo":"ACADEMIA <br>DE EMPLEO","subtitulo":"\u00a1No Pierdas M\u00e1s Oportunidades De Empleo! <br>Capac\u00edtate En Empleabilidad Y Convi\u00e9rtete <br>En El Candidato Ideal.","img_src":"http:\/\/localhost:4200\/assets\/images\/examples\/academia_banner.png","img_pos":"left","img_animacion":"none","btn1_nombre":"BTN1","btn1_link":"\/btn1","btn2_nombre":"BTN2","btn2_link":"\/btn2","created_by":1,"created_at":null,"updated_at":"2023-04-28T17:14:50.000000Z"}]}];
    env = environment
    deferredPrompt: any;
    showButton = false;

    constructor(
        private _fuseMatchMediaService: FuseMatchMediaService,
        private cdRef: ChangeDetectorRef,
        private _fuseConfigService: FuseConfigService,
        private _router: Router,
        public dialog: MatDialog,
        public dialogvid: MatDialog,
        private _activaRoute: ActivatedRoute,
        private httpClient: HttpClient,
        private seoService: SEOService,
        private viewportScroller: ViewportScroller,
        private _deviceDetectorService: DeviceDetectorService


    ) {
        this.searchInput = new FormControl('');
        this.citys = cityList;

        // console.log('ciudades:', this.citys);
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this.followLog = [
            {
                img: this.urlLogs + '/log_tiktok.png',
                url: 'https://www.tiktok.com/@en.modo.ok?'
            },
            {
                img: this.urlLogs + '/log_instagram.png',
                url: 'https://www.instagram.com/ok.empleos/?hl=es'
            },
            {
                img: this.urlLogs + '/log_linkedin.png',
                url: 'https://www.linkedin.com/company/ok-empleos/'
            },
            {
                img: this.urlLogs + '/log_face.png',
                url: 'https://www.facebook.com/OkEmpleosColombia'
            },
            {
                img: this.urlLogs + '/log_youtube.png',
                url: 'https://www.youtube.com/channel/UCE56i6YHPlYBrpATAilz3XQ'
            },
            {
                img: this.urlLogs + '/log_twiter.png',
                url: 'https://twitter.com/ok_empleos'
            },
        ]

        this.videosCandidatoSlider = [
            {
                video: 'https://www.youtube.com/watch?v=K1yx4E2JNIE',
                alt: 'OkEmpleos',
                videoId: 'K1yx4E2JNIE'
            },
            {
                video: 'https://www.youtube.com/watch?v=zPDB-nQcd3w',
                alt: 'OkEmpleos',
                videoId: 'zPDB-nQcd3w'
            },
            {
                video: 'https://www.youtube.com/watch?v=IWw0EleFzzc',
                alt: 'OkEmpleos',
                videoId: 'IWw0EleFzzc'
            },
            {
                video: 'https://www.youtube.com/watch?v=9gbK2jB6jTA',
                alt: 'OkEmpleos',
                videoId: '9gbK2jB6jTA'
            },
            {
                video: 'https://www.youtube.com/watch?v=zB7Ds9f_fU0',
                alt: 'OkEmpleos',
                videoId: 'zB7Ds9f_fU0'
            }
        ]

        // this.openVideo();
        this.getBanner();
        
        
    }

    ngOnInit(): void {
        /* SEoService */
        this.seoService.setPageTitle(`Ok Empleos ${environment.country} | Ofertas de Empleo | El trabajo que mereces | El talento que buscas`);
        this.seoService.createLinkForCanonicalURL();
        /* SEoService */
        

        this.chageFond();

        this._activaRoute.queryParams
            .subscribe(params => {

                if (params.login) {
                    if (params.login === 'candidato') {
                        this.currentStateEmpresa = "0";
                        this.currentStateCandidato = "1"
                    } else if (params.login === 'empresa') {
                        this.currentStateEmpresa = "1";
                        this.currentStateCandidato = "0";
                    } else {
                        this.currentStateEmpresa = "0";
                        this.currentStateCandidato = "1"
                    }
                }

            });
        
        // this.openVideo();

        window.addEventListener('beforeinstallprompt', (event) => {
            // Prevent the mini-infobar from appearing on mobile
            event.preventDefault();
            // Stash the event so it can be triggered later.
            this.deferredPrompt = event;
            // Show the install prompt to the user
            if(this._deviceDetectorService.isMobile()){
                this.showButton = true;
            } else {
                this.showButton = false;
            }
          });
    }

    openVideo() {
        const vid = document.getElementsByClassName('play');
        // console.log('video', vid);

        const video = document.querySelector("video");

        // console.log(video);

        if(!video) return;
        
        const promise = video.play();
        if(promise !== undefined){
            promise.then(() => {
                // Autoplay started
            }).catch(error => {
                // Autoplay was prevented.
                video.muted = true;
                video.play();
            });
        }
    }


    getBanner() {
        
    //    return new Promise((resolve, reject) => {
        this.httpClient.get(`${this.urlLocal}banners`).subscribe(
            (result: any) => {
                this.banners = result;

                // // console.log(this.banners);
                // this.cdRef.markForCheck();
                // let deck = new Reveal({
                //     plugins: [ Markdown ]
                //  })
                //  deck.initialize({
                //     autoSlide: GlobalConstants.slidetime * 1000,
                //     loop: true,
                //     width: "100%",
	            //     height: "100%",
                //     margin: 0,
                //  });
                //  this._activaRoute.fragment.subscribe(fragment => {  
                //     if(fragment != null){
                //         document.querySelector('#' + fragment).scrollIntoView();
                //     }
                // });
                
                //  resolve('')
            });
    //    }) 
    }

    ngAfterViewInit(): void {
     

        // const headers = new HttpHeaders().set(InterceptorSkipHeader, '');

        // const formData = this.registerForm.getRawValue();

        // formData.user_type = 1;
        // formData.email = email;
        // formData.name = name;
        // formData.first_name = first_name;
        // formData.last_name = last_name;
        // formData.photo_url = photoUrl;
        // formData.password = password;
        // formData.provider = provider;
        // formData.provider_id = providerId;


        // this.httpClient.get(`${this.urlLocal}banners`).subscribe(
        //     (result: any) => {
        //         this.banners = result;

        //         this.cdRef.markForCheck();
        //         let deck = new Reveal({
        //             plugins: [ Markdown ]
        //          })
        //          deck.initialize({
        //             autoSlide: 10000,
        //             loop: true
        //          });
        //     });

            // console.log('bannes',environment.banners );
            // if (environment.banners){
            //     console.log('entro');
            //     this.firstDiv.nativeElement.style.display = 'none';
            //     this.secondDiv.nativeElement.style.display = 'block';
            //     // this.searchDiv.nativeElement.style.marginTop = '-13%';
            // } else {
            //     console.log('entro 2');
            //     this.firstDiv.nativeElement.style.display = 'block';
            //     this.secondDiv.nativeElement.style.display = 'none';
            // }




        // Reveal.initialize({
        //     parallaxBackgroundImage: '',
        //     parallaxBackgroundSize: '',
        //     parallaxBackgroundHorizontal: 200,
        //     parallaxBackgroundVertical: 50
        //   });
        // also try this.cdRef.detectChanges(); instead of the above

        this.httpClient.get(`${this.urlLocal}banners`).subscribe(
            (result: any) => {
                this.banners = result;

                this.cdRef.markForCheck();
                let deck = new Reveal({
                    plugins: [ Markdown ]
                 })
                 deck.initialize({
                    autoSlide: GlobalConstants.slidetime * 1000,
                    loop: true,
                    width: "100%",
	                height: "100%",
                    margin: 0,
                 });
            });
    }

    goToScroll() {
        const route = document.getElementById("school");
        route.scrollIntoView({ behavior: "smooth" });
    }

    imageClick(event) {
        const video = this.videosCandidatoSlider[event];
        this.openModalVideo(video.videoId);
    }

    openModalVideo(videoId): void {
        let dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.data = videoId;
        dialogConfig.width = 'auto';
        dialogConfig.maxWidth = '80vw';
        dialogConfig.minHeight = '40vh';
        this.dialogvid.open(SchoolFirstComponent, dialogConfig);
    }

    openClosehigher(videoId): void {
        let dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.data = videoId;
        dialogConfig.width = this.widthModal;
        dialogConfig.maxWidth = '80vw';
        dialogConfig.maxHeight = '40vw';
        this.dialog.open(ModalCompletTallerComponent, dialogConfig);
    }

    openModalVisual(): void {
        let dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.maxWidth = '80vw';
        dialogConfig.maxHeight = '95vh';
        this.dialog.open(ModalVisualizeComponent, dialogConfig);
    }

    searchVacante(palabra) {
        this._router.navigate(['/lista-vacantes'],
            { queryParams: { palabra: palabra } });
    }

    onEnterSearch(isEmpresa?): void {

        const text = this.searchInput.value

        if (text.length >= 3) {

            if (isEmpresa) {
                this._router.navigateByUrl(`/vacante/empresa-vacantes/lista?texto=${text}`)
            } else {
                this._router.navigateByUrl(`/vacante/candidato-vacantes/lista?texto=${text}`)
            }
        }
    }

    changeMenuList() {
        if (this.menuList === 1) {
            this.menuList = 2;
        } else {
            this.menuList = 1;
        }
    }

    chageFond() {
        setInterval(() => {
            if (this.valueFond === 1) {
                this.valueFond = 2;
            } else {
                this.valueFond = 1;
            }
        }, 3000);
    }

    toggleMenu(): void {
        this.isMenuOpen = !this.isMenuOpen;
    }

    goToURL(url){
        this._router.navigateByUrl(url);
    }

    goToURLExternal(url){
        window.open(url, '_blank');
    }

    showInstallPrompt() {
    if (this.deferredPrompt) {
            const installPrompt = confirm('¿Deseas instalar la aplicación?');
            if (installPrompt) {
                this.deferredPrompt.prompt();
                this.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                this.deferredPrompt = null;
                });
            }
            
        }
    }

    installPlayStore() {
        if (this.deferredPrompt) {
            const installPrompt = confirm('¿Deseas instalar la aplicación?');
            if (installPrompt) {
                this._router.navigateByUrl('https://play.google.com/store/apps/details?id=com.okempleos.app.twa');
            }
            this.deferredPrompt = null;
        }
    }

}