<mat-toolbar>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>

        <div fxLayout="row" fxLayoutAlign="center center" fxHide fxShow.gt-xs>
            <!--<a mat-button target="_blank" routerLink="/terminos">Términos y condiciones</a> 
            <span>&bull;</span>
            <a mat-button target="_blank" routerLink="/politicas">Política de Privacidad</a>
            <span>&bull;</span>
            <a mat-button routerLink="#">GESTH</a>
            <span>&bull;</span>-->
            <a mat-button routerLink="#">V1.107.98 (Jun 24, 2024)</a>
            <div class="col-md-4">
                <a mat-button href="https://okempleos.com/wp-content/uploads/2022/05/RESOLUCiON-0094-2.pdf" target="_blank" class="text-small text-truncate">
                    <img src="/assets/images/home/SPE.png" style="width: 5%;"  alt="Servicio Público de Empleo">
                    <img src="/assets/images/home/MinTrabajo.png" style="width: 10%; margin: 10px; vertical-align: middle;" alt="Ministerio del Trabajo">
                    Vinculado a la red de prestadores del Servicio Público de Empleo.
                    Autorizado por la 
                    Unidad Administrativa Especial del Servicio Público de Empleo según 
                    Resolución No. 0094 del 8 de Marzo del 2021.
                </a>
            </div>

        </div>

    </div>

</mat-toolbar>
