import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { GlobalConstants } from 'app/common/global-constants'

import { TokenService } from 'app/pixcore/shared/token.service';
import { AuthStateService } from 'app/pixcore/shared/auth-state.service';
import { Console } from 'console';

@Component({
  selector: 'app-secure',
  templateUrl: './secure.component.html',
  styleUrls: ['./secure.component.scss']
})
export class SecureComponent implements OnInit {

  urlLocal = GlobalConstants.apiURL;
  user;
   /**
    * Constructor
    *
    * @param {HttpClient} http
    */
  constructor(
    private http: HttpClient,
    private auth: AuthStateService,
    public router: Router,
    public token: TokenService,
  ) { 
    this.auth.validateSession();
  }

  ngOnInit(): void {

    this.auth.userAuthState.subscribe(val => {
      console.log("------------------------");
      console.log(val);
    });
    
  }

}
