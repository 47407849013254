<div id="register" >

    <div id="background-empresa" [@fadeInOut]=currentStateEmpresa></div>
    <div id="background-candidato" [@fadeInOut]=currentStateCandidato></div>

    <div id="register-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
        
        <div id="register-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}" style="height: auto !important;">

            <!-- <div fxLayout="row"  fxLayoutAlign="end center">
                <button mat-raised-button  class="mb-6" (click)="openModalMassiveLoads()" matTooltip="Carga masiva">
                    <mat-icon>cloud_upload</mat-icon>
                </button>
            </div> -->
            <div class="logo">
                <a href="{{URL}}" target="_blank">
                    <img src="{{ logoPath }}" alt="logo" style="height:100px; width:auto;">
                </a>
            </div>            

            <div class="title">REGISTRA UN NUEVO CANDIDATO</div>
            
            <div fxLayout="row">
                <form name="registerForm" [formGroup]="registerForm" (submit)="OnSubmit()" novalidate fxLayout="column" fxFlex="1 0 84%" fxLayoutAlign="start">

                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="50" class="pr-sm-4">
                            <mat-label>Primer apellido*</mat-label>
                            <input matInput formControlName="primer_apellido">
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                            <mat-error *ngIf="registerForm.get('primer_apellido').hasError('required')">El Primer apellido es requerido!
                            </mat-error>
                            <mat-error *ngIf="registerForm.get('primer_apellido').hasError('minlength')">Mínimo 3 caracteres</mat-error>
                            <mat-error *ngIf="registerForm.get('primer_apellido').hasError('maxlength')">Máximo 100 caracteres</mat-error>
                        </mat-form-field>
<!--             
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="50" class="pl-sm-4">
                            <mat-label>Segundo apellido</mat-label>
                            <input matInput formControlName="segundo_apellido">
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                            <mat-error *ngIf="registerForm.get('segundo_apellido').hasError('minlength')">Mínimo 3 caracteres</mat-error>
                            <mat-error *ngIf="registerForm.get('segundo_apellido').hasError('maxlength')">Máximo 100 caracteres</mat-error>
                        </mat-form-field> -->


                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="50" class="pr-sm-4">
                            <mat-label>Primer nombre*</mat-label>
                            <input matInput formControlName="primer_nombre">
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                            <mat-error *ngIf="registerForm.get('primer_nombre').hasError('required')">El Primer nombre es requerido!
                            </mat-error>
                            <mat-error *ngIf="registerForm.get('primer_nombre').hasError('minlength')">Mínimo 3 caracteres</mat-error>
                            <mat-error *ngIf="registerForm.get('primer_nombre').hasError('maxlength')">Máximo 100 caracteres</mat-error>
                        </mat-form-field>
            
                        <!-- <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="50" class="pl-sm-4">
                            <mat-label>Segundo nombre</mat-label>
                            <input matInput formControlName="segundo_nombre">
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                            <mat-error *ngIf="form.get('segundo_nombre').hasError('minlength')">Mínimo 3 caracteres</mat-error>
                            <mat-error *ngIf="form.get('segundo_nombre').hasError('maxlength')">Máximo 100 caracteres</mat-error>
                        </mat-form-field> -->

                    </div>

                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field *ngIf="currentStateEmpresa" appearance="outline">
                            <mat-label>Tipo Empresa</mat-label>
                            <mat-select formControlName="tipo_persona">
                                <mat-option *ngFor="let tipo of tipos" [value]="tipo.id">{{tipo.value}}</mat-option>
                            </mat-select>
                            <mat-icon matSuffix class="secondary-text">business</mat-icon>
                            <mat-error >El tipo de Empresa es requerido</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="30" class="pr-sm-4">
                            <mat-label>Tipo de documento</mat-label>
                            <mat-select formControlName="tipo_documento_id" required>
                                <mat-option *ngFor="let item of tiposDocumento" [value]="item.id">
                                    {{ item.nombre }}
                                </mat-option>
                            </mat-select>
                            <mat-icon matSuffix class="secondary-text">menu</mat-icon>
                            <mat-error>El Tipo de documento es requerido!</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="30" class="pr-sm-4">
                            <mat-label *ngIf="currentStateCandidato">Número de documento</mat-label>
                            <mat-label *ngIf="currentStateEmpresa">NIT</mat-label>
                            <input matInput formControlName="numero_identificacion" required />
                            <mat-icon matSuffix class="secondary-text">search</mat-icon>
                            <mat-error
                                *ngIf="registerForm.get('numero_identificacion').hasError('required') && currentStateCandidato">
                                El documento es requerido!</mat-error>
                            <mat-error
                                *ngIf="registerForm.get('numero_identificacion').hasError('required') && currentStateEmpresa">El
                                Nit es requerido!</mat-error>
                            <mat-error
                                *ngIf="registerForm.get('numero_identificacion').hasError('minlength') && !registerForm.get('numero_identificacion').hasError('pattern')">
                                Mínimo 5 caracteres</mat-error>
                            <mat-error
                                *ngIf="registerForm.get('numero_identificacion').hasError('maxlength') && !registerForm.get('numero_identificacion').hasError('pattern')">
                                Máximo 100 caracteres</mat-error>
                            <mat-error
                                *ngIf="registerForm.get('numero_identificacion').hasError('pattern') && currentStateCandidato">
                                Solo números</mat-error>
                            <mat-error
                                *ngIf="registerForm.get('numero_identificacion').hasError('pattern') && currentStateEmpresa">
                                Ejemplo: 2546456-6</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="40" class="pr-sm-4">
                            <mat-label>Fecha expedición documento</mat-label>
                            <input formControlName="fecha_expedicion" matInput [matDatepicker]="picker2">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>

                        <ng-container *ngIf="currentStateEmpresa">
                            <div fxLayout="row" fxLayoutAlign="start center" class="mb-8 accent alert"
                                *ngIf="!isEmailComporativo">
                                <mat-icon class="mr-4">warning</mat-icon>
                                <span>Por favor, ingresa un correo corporativo, o de otro modo, tu Perfil no será público hasta
                                    ser validado</span>
                            </div>
                            <span class="text">Regístrate con tu correo corporativo</span>
                        </ng-container>

                    </div>

                    
                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="35" class="pr-sm-4">
                            <mat-label>Fecha de nacimiento*</mat-label>
                            <input formControlName="fecha_nacimiento" matInput [matDatepicker]="picker" [min]="minDate"
                                [max]="maxDate">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error>La Fecha de nacimiento es requerida!</mat-error>
                        </mat-form-field>
            
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="30" class="pl-sm-4">
                            <mat-label>Sexo</mat-label>
                            <mat-select formControlName="genero_id">
                                <div *ngFor="let item of generos">
                                    <mat-option *ngIf="item.nombre != 'Indiferente'" [value]="item.id">
                                        {{ item.nombre }}
                                    </mat-option>
                                </div>
                            </mat-select>
                            <mat-icon matSuffix class="secondary-text">wc</mat-icon>
                            <mat-error>El Sexo es requerido!</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="35" class="pl-sm-4">
                            <mat-label>Nivel educativo</mat-label>
                            <mat-select formControlName="nivel_educativo_id" required>
                                <mat-option *ngFor="let item of nivelEducativo" [value]="item.id" >
                                    {{ item.nombre }}
                                </mat-option>
                            </mat-select>
                            <mat-icon matSuffix class="secondary-text">school</mat-icon>
                            <mat-error>El Nivel educativo requerido!</mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="30" class="pr-sm-4">
                            <mat-label>{{'REGISTER_FORM.EMAIL' | translate}}</mat-label>
                            <input (change)="inputEmail($event)" matInput formControlName="email" type="email">
                            <mat-icon matSuffix class="secondary-text">mail</mat-icon>

                            <mat-error *ngIf="registerForm.get('email').hasError('required')">
                                Correo electrónico requerido
                            </mat-error>
                            <mat-error *ngIf="registerForm.get('email').hasError('email')">
                                Correo electrónico invalido
                            </mat-error>
                            <mat-error
                                *ngIf="registerForm.get('email').hasError('minlength') && !registerForm.get('email').hasError('email')">
                                Mínimo 5 caracteres
                            </mat-error>
                            <mat-error
                                *ngIf="registerForm.get('email').hasError('maxlength') && !registerForm.get('email').hasError('email')">
                                Máximo 100 caracteres
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="20" class="pr-sm-4">
                            <mat-label>Teléfono</mat-label>
                            <input matInput formControlName="telefono" type="tel" [maxlength]="12">
                            <mat-icon matSuffix class="secondary-text">settings_phone</mat-icon>
                            <mat-error *ngIf="registerForm.get('telefono').hasError('required')">El Teléfono es requerido!</mat-error> 
                            <mat-error *ngIf="registerForm.get('telefono').hasError('pattern')">Solo escribe números</mat-error>
                            <mat-error *ngIf="registerForm.get('telefono').hasError('minlength') && !registerForm.get('telefono').hasError('pattern')">Mínimo 5 caracteres</mat-error>
                            <mat-error *ngIf="registerForm.get('telefono').hasError('maxlength') && !registerForm.get('telefono').hasError('pattern')">Máximo 20 caracteres</mat-error> 
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="20" class="pr-sm-4">
                            <mat-label>Teléfono (Opcional)</mat-label>
                            <input matInput formControlName="telefono_2" type="tel" [maxlength]="12">
                            <mat-icon matSuffix class="secondary-text">settings_phone</mat-icon>
                            <mat-error *ngIf="registerForm.get('telefono').hasError('required')">El Teléfono es requerido!</mat-error> 
                            <mat-error *ngIf="registerForm.get('telefono').hasError('pattern')">Solo escribe números</mat-error>
                            <mat-error *ngIf="registerForm.get('telefono').hasError('minlength') && !registerForm.get('telefono').hasError('pattern')">Mínimo 5 caracteres</mat-error>
                            <mat-error *ngIf="registerForm.get('telefono').hasError('maxlength') && !registerForm.get('telefono').hasError('pattern')">Máximo 20 caracteres</mat-error> 
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="30" class="pl-sm-4">
                            <mat-label>Ciudad</mat-label>
                                <mat-select formControlName="ciudad_id" required>
                                    <input (keyup)="onKey($event.target.value)" class="mat-option mat-focus-indicator ng-tns-c77-23 ng-star-inserted">
                                    <mat-option *ngFor="let item of selectedciudades" [value]="item.id" >
                                        {{ item.nombre }}
                                    </mat-option>
                                </mat-select>
                                <mat-error>La ciudad es requerida!</mat-error>
                        </mat-form-field>

                    </div>

                    <div  fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="100" class="pr-sm-4">
                            <mat-label>Perfil</mat-label>
                            <input matInput formControlName="perfil">
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start start" class="mb-12">
                        <div class="mr-sm-8 mb-8 mb-sm-0" fxFlex="100">
                            <mat-label>¿Tiene experiencia laboral?</mat-label>
                            <br />
                            <mat-radio-group (change)="hasExperienciaChange($event)">
                                <mat-radio-button [value]="true">Si</mat-radio-button>
                                <mat-radio-button class="pl-8" [value]="false" checked>No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>

                    <div *ngIf="hasExperiencia" fxLayout="column" fxFlex="1 0 auto">

                        <div fxLayout="column" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayout.gt-sm="row"
                            class="mb-8">

                            <div fxFlex="100" class="width-100 ng-select-container-custom">
                                <mat-form-field appearance="outline">
                                    <mat-label>Agrega la empresa aquí *</mat-label>
                                    <input type="text" matInput [matAutocomplete]="auto" formControlName="empresa" (blur)="blurSelectEmpresa($event)">
                                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="changeSelectEmpresa($event.option.value, listaEmpresas)" 
                                    (focusout)="focusOutSelectEmpresa($event, experienciaForm.controls['empresa'].value)">
                                        <mat-option *ngFor="let empresa of filteredEmpresas | async" [value]="empresa.nombre">{{empresa.nombre}}</mat-option>
                                        <!-- <mat-option [value]="empresaText">
                                            Agregar nueva empresa: {{ empresaText }}
                                        </mat-option> -->
                                    </mat-autocomplete>
                                </mat-form-field>

                                <div class="error-container">
                                    <!-- <mat-error>La empresa es requerida!</mat-error> -->
                                </div>

                            </div>
                        </div>

                        <div fxLayout="column" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayout.gt-sm="row"
                            class="mb-32 mb-sm-8">

                            <mat-form-field appearance="outline" fxFlex="100" class="width-100">
                                <mat-label>Último cargo desempeñado</mat-label>
                                <input matInput formControlName="cargo" required>
                                <mat-icon fxHide fxShow.gt-sm matSuffix class="secondary-text">account_circle</mat-icon>
                                <!-- <mat-hint>
                                    <mat-icon>error</mat-icon>Recuerda que podrás agregar más Experiencia Laboral desde tu
                                    Perfil
                                </mat-hint> -->
                                <!-- <mat-error >El Cargo es requerido!</mat-error> -->
                            </mat-form-field>
                        </div>

                        <div fxLayout="column" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayout.gt-sm="row">

                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="50" class="pr-sm-4  width-100">
                                <mat-label>Teléfono de contacto*</mat-label>
                                <input matInput formControlName="empresa_telefono" type="tel">
                                <mat-icon fxHide fxShow.gt-sm matSuffix class="secondary-text">settings_phone</mat-icon>
                                <!-- <mat-error>El Teléfono es requerido!</mat-error> -->
                            </mat-form-field>
                            <div class="ml-sm-4 pb-12" fxFlex="100" fxFlex.gt-sm="50">
                                <mat-label>¿Trabaja actualmente?*:</mat-label>
                                <br />
                                <mat-radio-group (change)="changeRadio($event)" formControlName="trabaja_actualmente"
                                    aria-label="trabaja_actualmente" required>
                                    <mat-radio-button [value]="1">Si</mat-radio-button>
                                    <mat-radio-button class="pl-8" [value]="0">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>

                        <div fxLayout="column" fxLayoutAlign="start center" fxFlex="1 0 auto" fxLayout.gt-sm="row">
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="50" class="pr-sm-4 width-100"
                                [matTooltip]="'Sólo puedes seleccionar fechas entre tu día de nacimiento (' + minDateInicioText + ') y la fecha actual'"
                                matTooltipPosition="above">
                                <mat-label>Desde</mat-label>

                                <input formControlName="fecha_inicio" required matInput
                                    [matDatepicker]="picker_inicio_experiencia" [max]="maxDateInicio" [min]="minDateInicio"
                                    (dateChange)="cambioFechaInicialExperiencia($event)">
                                <mat-datepicker-toggle matSuffix [for]="picker_inicio_experiencia"></mat-datepicker-toggle>
                                <mat-datepicker #picker_inicio_experiencia></mat-datepicker>
                                <!-- <mat-error>La Fecha inicio es requerida!</mat-error> -->
                            </mat-form-field>

                            <mat-form-field *ngIf="registerForm.get('trabaja_actualmente').value === 0" [matTooltip]="'Sólo puedes seleccionar fechas entre la fecha de inicio seleccionada (' + minDateFinTextExperiencia + ') y la fecha actual'"
                                appearance="outline" fxFlex="100" fxFlex.gt-sm="50" class="pl-sm-4 width-100">
                                <mat-label>Hasta</mat-label>
                                <input formControlName="fecha_fin" matInput [matDatepicker]="picker_fin_experiencia"
                                    [max]="maxDateInicio" [min]="minDateFinExperiencia">
                                <mat-datepicker-toggle matSuffix [for]="picker_fin_experiencia"></mat-datepicker-toggle>
                                <!-- <mat-error>La Fecha Fin es requerida!</mat-error> -->
                                <mat-datepicker #picker_fin_experiencia></mat-datepicker>
                            </mat-form-field>
                        </div>

                    </div>


                    <mat-label *ngIf="isOnlyRegister">{{'REGISTER_FORM.USER_TYPE' | translate}}</mat-label>
                    <mat-radio-group *ngIf="isOnlyRegister" fxLayout="column" fxLayoutAlign="start start"
                        formControlName="user_type" required>
                        <mat-radio-button class="mb-6" value="1" (change)="OnCandidatoSelected($event)"> Candidato
                        </mat-radio-button>
                        <mat-radio-button class="mb-6" value="2" (change)="OnEmpresaSelected($event)"> Empresa
                        </mat-radio-button>
                    </mat-radio-group>


                    <!-- <div class="terms">
                        <mat-checkbox name="terms" aria-label="I read and accept" formControlName="accept_terms" required>
                            <span>{{'REGISTER_FORM.ACCEPT' | translate}}</span>
                        </mat-checkbox>
                        <a class="link" target="_blank" [routerLink]="'/terminos'">{{'REGISTER_FORM.TERMS' | translate}}</a>
                        y las
                        <a class="link" target="_blank" [routerLink]="'/politicas'">Políticas de privacidad</a>
                    </div> -->


                    <div  fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto" >
                        <!-- <a fxLayout="column" fxFlex="50" fxFlex.gt-sm="50" mat-stroked-button
                            class="pr-sm-4" fxLayoutAlign="center center" (click)="openModalVacants(1 , 15);">
                            <img class="btn_img" src="assets/images/serverimg/usermodd.png" alt="">
                            <img class="btn_img-white" src="assets/images/serverimg/usermodd_white.png"
                                alt="">
                            <p>Aplicar a vacante </p>
                        </a> -->

                        <button fxLayout="column" fxFlex="100" fxFlex.gt-sm="100" class="file-upload-button" mat-stroked-button >
                            <input type="file"
                            accept=".pdf,.doc,.docx"
                            #fileInput
                            (change)="onFileSelected(fileInput)" style="height: 50px;"
                            />
                            <mat-icon>attach_file</mat-icon>
                            <div>Hoja de vida</div>
                            <span class="file-info">{{ fileInfo }}</span>
                        </button>
                        
                        

                    </div>




                    

                    


                    <div style="text-align:center; align-self: center; margin-top:10px">

                        <re-captcha (resolved)="resolved($event)" formControlName="recaptcha"
                            siteKey="6LfRB18aAAAAAICC6Va4sV6BwtJBdcDxYCgn5nrH">
                        </re-captcha>

                        <div class="terms">
                            <mat-checkbox name="terms" aria-label="I read and accept" formControlName="accept_terms" required>
                                <span>{{'REGISTER_FORM.ACCEPT' | translate}}</span>
                            </mat-checkbox>
                            <a class="link" target="_blank" [routerLink]="'/terminos'">{{'REGISTER_FORM.TERMS' | translate}}</a>
                            y las
                            <a class="link" target="_blank" [routerLink]="'/politicas'">Políticas de privacidad</a>
                        </div>

                        <button mat-raised-button color="accent" class="submit-button" [class.spinner]="loading"
                            aria-label="CREATE AN ACCOUNT" [disabled]="registerForm.invalid || loading">
                            {{'REGISTER_FORM.BUTTON_ACCEPT' | translate}}
                        </button>

                    </div>






                </form>

            </div>

            <!-- <span style="font-weight: 600;">o</span> -->

            <!-- <ng-container *ngIf="currentStateCandidato">

                <button mat-raised-button class="facebook" (click)="signInWithFB()">
                    <div style="margin-left: 0 10px; display: inline;">
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M21.7732 12.9477C21.7732 8.05224 17.829 4.08386 12.9643 4.08386C8.09747 4.08496 4.15332 8.05224 4.15332 12.9488C4.15332 17.372 7.37515 21.0387 11.5858 21.7037V15.51H9.35053V12.9488H11.588V10.9944C11.588 8.77346 12.9038 7.54683 14.9155 7.54683C15.8801 7.54683 16.8876 7.71971 16.8876 7.71971V9.89989H15.7766C14.6832 9.89989 14.3418 10.5837 14.3418 11.2851V12.9477H16.7841L16.3943 15.5089H14.3407V21.7026C18.5513 21.0375 21.7732 17.3709 21.7732 12.9477Z"
                                fill="white" />
                        </svg>
                    </div>
                    {{'REGISTER_FORM.BUTTON_ACCEPT_FB' | translate}}
                </button>

                <button mat-raised-button class="google" (click)="signInWithGoogle()">
                    <div style="margin-left: 0 10px; display: inline; ">
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="24" height="24" transform="translate(0.962891 0.893799)" fill="white" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M21.5479 13.097C21.5479 12.4629 21.491 11.8532 21.3853 11.2678H12.9629V14.727H17.7757C17.5684 15.8449 16.9383 16.792 15.9912 17.4261V19.6699H18.8814C20.5724 18.1131 21.5479 15.8205 21.5479 13.097Z"
                                fill="#4285F4" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M12.9632 21.8364C15.3778 21.8364 17.4021 21.0356 18.8817 19.6698L15.9916 17.426C15.1908 17.9626 14.1665 18.2796 12.9632 18.2796C10.6341 18.2796 8.6626 16.7065 7.95937 14.5928H4.97168V16.9098C6.44317 19.8324 9.46744 21.8364 12.9632 21.8364Z"
                                fill="#34A853" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M7.95938 14.593C7.78053 14.0565 7.6789 13.4833 7.6789 12.8939C7.6789 12.3045 7.78053 11.7314 7.95938 11.1948V8.87781H4.97169C4.36602 10.0851 4.02051 11.4509 4.02051 12.8939C4.02051 14.3369 4.36602 15.7028 4.97169 16.91L7.95938 14.593Z"
                                fill="#FBBC05" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M12.9632 7.50782C14.2762 7.50782 15.455 7.95903 16.3818 8.84517L18.9468 6.28023C17.398 4.83719 15.3737 3.95105 12.9632 3.95105C9.46744 3.95105 6.44317 5.95504 4.97168 8.87769L7.95937 11.1947C8.6626 9.08094 10.6341 7.50782 12.9632 7.50782Z"
                                fill="#EA4335" />
                        </svg>
                    </div>
                    {{'REGISTER_FORM.BUTTON_ACCEPT_GOOGLE' | translate}}
                </button>

                <button mat-raised-button class="google" (click)="signInWithLinkedIn()">
                    <div style="margin-left: 0 10px; display: inline; ">
                        <img src="assets/images/social/linkedin.svg" class="img" alt="linkedin">
                    </div>
                    {{'REGISTER_FORM.BUTTON_ACCEPT_LINKEDIN' | translate}}
                </button>

            </ng-container> -->

            <!-- <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">{{'REGISTER_FORM.ALREADY_ACCOUNT' | translate}}</span>
                <a class="link" [routerLink]="'/login'">{{'REGISTER_FORM.LOGIN' | translate}}</a>
            </div> -->

        </div>

    </div>
    

</div>