export class Filtro {

    opciones?: number[];
    fecha_inicial?: string;
    fecha_final?: string;

    tipo_contrato?: number[];
    tipo_jornada?: number[];
    salario?: number[];
    sector_economico?: number[];
    area_vacante?: number[];

    experiencia?: number[];
    genero?: number[];
    disponibilidadViaje?: number[];
    disponibilidadCambioResidencia?: number[];

    nivelEducacion?: number[];
    ciudad_id?: number[];
    localidad_id?: number[];


    texto_busqueda?: string;


    constructor(filtro?) {
        this.opciones = filtro.opciones || [];
        this.fecha_inicial = filtro.fecha_inicial || null;
        this.fecha_final = filtro.fecha_final || null;
        this.tipo_contrato = filtro.tipo_contrato || null;
        this.sector_economico = filtro.sector_economico || null;
        this.tipo_jornada = filtro.tipo_jornada || null;
        this.salario = filtro.salario || null;
        this.area_vacante = filtro.area_vacante || null;
        this.nivelEducacion = filtro.nivelEducacion || null;
        this.experiencia = filtro.experiencia || null;
        this.genero = filtro.genero || null;
        this.disponibilidadViaje = filtro.disponibilidadViaje || null;
        this.disponibilidadCambioResidencia = filtro.disponibilidadCambioResidencia || null;
        this.ciudad_id = filtro.ciudad_id || null;
        this.localidad_id = filtro.localidad_id || null;
        this.texto_busqueda = filtro.texto_busqueda || null

    }
}
