import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,  } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthStateService } from '../shared/auth-state.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ModalTipoPlanComponent } from '../modals/modal-tipo-plan/modal-tipo-plan.component'

@Injectable({
  providedIn: 'root'
})
export class PlanTypeGuard implements CanActivate  {

  tipoPlanEmpresa: number;

  constructor(private _authService: AuthStateService, private router: Router, public _matDialog: MatDialog){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    return this.checkTipoPlan(next);
  
  }


  checkTipoPlan(route: ActivatedRouteSnapshot): boolean {
   
    if (!this._authService.getUserSession().candidato) {
      this.tipoPlanEmpresa = this._authService.getUserSession().empresa.plan_id
      const empresaPlan = this._authService.getUserSession().empresa.plan_id;
      if (route.data.planes.includes(empresaPlan)) {
        return true;
      }else {

        this.modalTipoPlan(this.tipoPlanEmpresa)
        this.router.navigate(['/home']);
        return false;
      }

    }else {
      return true;
    }

  }

  modalTipoPlan(tipoPlanEmpresa): void {
      const modal = this._matDialog.open(ModalTipoPlanComponent, { 
        data: tipoPlanEmpresa
    });
  }
  
}
