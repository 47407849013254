import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { PublicComponent} from './public.component';
import { LoginModule } from './login/login.module';
import { LoginAdminModule } from './login-admin/login-admin.module';
import { FirstModule } from './first/first.module';
import { RegisterModule } from './register/register.module';
import { CodeModule } from './code/code.module';
import { ResetPasswordModule } from './reset-password/reset-password.module';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { ListaEmpresasModule } from './lista-empresas/lista-empresas.module';
import { ListaVacantesModule } from './lista-vacantes/lista-vacantes.module';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FaqModule } from './faq/faq.module';
import { TerminosModule } from './terminos/terminos.module';
import { PoliticasModule } from './politicas/politicas.module';
import { PlanesPublicoModule } from './planes/planes-publico.module';
import { DetalleVacanteModule } from './detalle-vacante/detalle-vacante.module';
import { ModalCompartirComponent } from '../modals/modal-compartir/modal-compartir.component';
import { ModalEmpresaCompartirVacanteComponent } from '../modals/modal-empresa-compartir-vacante/modal-empresa-compartir-vacante.component';
import { ModalProgressCandidatoComponent } from 'app/modals/modal-progress-candidato/modal-progress-candidato.component';
import { ModalPostCandidatoComponent } from 'app/modals/modal-post-candidato/modal-post-candidato.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { LoginLinkedinModule } from './login-linkedin/login-linkedin.module';
import {MatIconModule} from '@angular/material/icon';
import { OkupaModule } from './first/okupa/ocupa.module';
import { NavbarModule } from './navbar/navbar.module';
import { MinisitioModule } from './minisitio/minisitio.module';
import { MinisitioVacantesModule } from './minisitio-vacantes/minisitio-vacantes.module';
import { RegistroCandidatosModule } from './registro-candidatos/registro-candidatos.module';

const routes = [

  {
    path        : 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path        : 'login-admin',
    loadChildren: () => import('./login-admin/login-admin.module').then(m => m.LoginAdminModule)
  },
 
];

@NgModule({
  declarations: [
    PublicComponent,
    ModalCompartirComponent,
    ModalProgressCandidatoComponent,
    ModalPostCandidatoComponent,
    ModalEmpresaCompartirVacanteComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    LoginModule,
    FirstModule,
    LoginAdminModule,
    RegisterModule,
    CodeModule,
    ResetPasswordModule,
    ForgotPasswordModule,
    FaqModule,
    TerminosModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    FuseSharedModule,
    PoliticasModule,
    ListaEmpresasModule,
    ListaVacantesModule,
    PlanesPublicoModule,
    DetalleVacanteModule,
    LoginLinkedinModule,    
    OkupaModule,
    NavbarModule,
    MinisitioModule,
    MinisitioVacantesModule,       
    RegistroCandidatosModule
  ],
  exports: [
    ModalCompartirComponent,
    ModalProgressCandidatoComponent,
    ModalPostCandidatoComponent,
    ModalEmpresaCompartirVacanteComponent
  ]
})
export class PublicModule { }
