import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import { LoginLinkedinComponent } from 'app/public/login-linkedin/login-linkedin.component';

import { TranslateModule } from '@ngx-translate/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';


const routes = [
    {
        path     : 'login-linkedin',
        component: LoginLinkedinComponent
    }
];

@NgModule({
    declarations: [
        LoginLinkedinComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatSnackBarModule,
    ]
})
export class LoginLinkedinModule
{
}
