import { Injectable, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable({
   providedIn: 'root'
})
export class SEOService { 
   constructor(private title: Title, @Inject(DOCUMENT) private doc: any,  private metaService: Meta) {
   }
   setPageTitle(title: string) {
      this.title.setTitle(title);
   }   
   getPageTitle() {
      return this.title.getTitle();
   }
   setPageKeyword(){
     this.metaService.addTag({ name: 'description', content: 'Ok Empleos es un novedoso portal de empleo con ofertas a nivel nacional. Registra GRATIS tu hoja de vida y postúlate a nuestras vacantes vigentes.' });
     this.metaService.addTag({ name: 'keywords', content: 'empleos, candidatos, empresas, portal de empleo, hoja de vida, ofertas de empleo, búsqueda de empleo' });

   }
   createLinkForCanonicalURL() {
      let link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(link);
      link.setAttribute('href', this.doc.URL);
   }
   setDescription(description: string) {
      this.metaService.updateTag({ name: 'description', content:  description });
   }
   setKeyword(keywords: string) {
      this.metaService.updateTag({ name: 'keywords', content: keywords });
   }
} 