import { Component, OnDestroy, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as spanish } from './i18n/es';
import { locale as english } from './i18n/en';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Params, Router, ActivatedRoute } from '@angular/router';
import { GlobalConstants } from 'app/common/global-constants';
import { AuthService } from 'app/pixcore/shared/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalRegistroComponent } from './modal/registro-exitoso.component';
import { AuthStateService } from 'app/pixcore/shared/auth-state.service';
import { InterceptorSkipHeader } from 'app/pixcore/shared/auth.interceptor';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { TokenService } from 'app/pixcore/shared/token.service';
import { InformacionPersonalService } from 'app/main/candidato/informacion-personal/informacion-personal.service';
import { ModalVacantsComponent } from './modal-vacants/modal-vacants.component';
import { ModalMassiveLoadsComponent } from './modal-massive-loads/modal-massive-loads.component';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { LogoService } from 'app/logo.service';


import * as moment from 'moment';

@Component({
    selector: 'register',
    templateUrl: './registro-candidatos.component.html',
    styleUrls: ['./registro-candidatos.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class RegistroCandidatosComponent implements OnInit, OnDestroy {

    registerForm: FormGroup;
    hide1 = true;
    hide2 = true;
    loading = false;
    generos: any[];
    minDate: Date;
    maxDate: Date;

    // Private
    private _unsubscribeAll: Subject<any>;

    urlLocal = GlobalConstants.apiURL;
    URL = GlobalConstants.URL;
    tipos: any[];
    isOnlyRegister = true;
    isEmailComporativo = true;
    currentStateCandidato = true;
    currentStateEmpresa = false;

    user: SocialUser;
    loggedIn: boolean;

    linkedInCredentials = {
        clientId: "77wet208pi7uo6",
        redirectUrl: `${this.URL}/login-linkedin`,
        scope: "r_liteprofile%20r_emailaddress" // To read basic user profile data and email
    };

    tiposDocumento: any[];
    nivelEducativo: any[];
    ciudades: any[];
    selectedciudades: any[];

    vacantes: any[];
    empresa_id: number;
    user_id: number;
    pageIndexFiltro: number = 1;
    vacante_id: number = null;

    fileInfo: string;
    hojavidalink: string;

    hasExperiencia = false;

    listaEmpresas: any [];

    empresaText = '';
    empresa_id_ex = null;

    filteredEmpresas : Observable<any[]>;
    logoPath: String;

    

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private httpClient: HttpClient,
        private router: Router,
        public authService: AuthService,
        private authState: AuthStateService,
        private _snackBar: MatSnackBar,
        public _dialog: MatDialog,
        private _route: ActivatedRoute,
        private _socialAuthService: SocialAuthService,
        private token: TokenService,
        private _informacionPersonalService: InformacionPersonalService,
        private logoService: LogoService,
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: false
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this.tipos = [
            { id: 1, value: "Persona Jurídica" },
            { id: 2, value: "Persona Natural" },
        ]

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this._fuseTranslationLoaderService.loadTranslations(spanish, english);

        const currentYear = new Date().getFullYear();
        const currentMont = new Date().getMonth();
        const currentDay = new Date().getDate();
        this.maxDate = new Date(currentYear - 15, currentMont, currentDay);
        this.minDate = new Date(currentYear - 90, currentMont, currentDay);
        this.logoPath = this.logoService.getLogoPath();

        
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this._informacionPersonalService.getAllSelectsPublic().then((res) =>{
            this.tiposDocumento = this._informacionPersonalService.tiposDocumento;
            this.generos = this._informacionPersonalService.generos;
            this.nivelEducativo = this._informacionPersonalService.nivelEducativo;
            this.ciudades = this._informacionPersonalService.ciudades;
            this.selectedciudades = this.ciudades;
            this.listaEmpresas = res.listaEmpresas;
        });
        this.registerForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email, Validators.minLength(5), Validators.maxLength(100)]],
            user_type: ['', Validators.required],
            accept_terms: [false, Validators.required],
            password: ['', null],
            password_confirmation: ['', null],
            tipo_documento_id: [{ 
                value: 1,
                disabled: false
            }, Validators.required
            ],
            numero_identificacion: [null],
            primer_apellido :  ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
            primer_nombre: [{
                value: null,
                disabled: false
            }, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]
            ],
            fecha_nacimiento: [{
                value: null,
                disabled: false
            }, Validators.required
            ],
            genero_id: [{
                value: null,
                disabled: false
            }, Validators.required
            ],
            ciudad_id: [{
                value: null,
                disabled: false
            }, Validators.required
            ],
            tipo_persona: [null],
            telefono: [null],
            telefono_2: [null],
            perfil: [null],
            fecha_expedicion:  ['', null],
            nivel_educativo_id : [{
                value   : null,
                disabled: false
            }, Validators.required],
            empresa: [{
                value: null,
                disabled: false,
            }],
            empresa_id: [null],
            cargo: [null],
            empresa_telefono: [null],
            trabaja_actualmente: [null],
            fecha_inicio: [null],
            fecha_fin: [null],
            recaptcha: [null]
        });

        this._socialAuthService.authState.subscribe((user) => {
            this.user = user;
            this.loggedIn = (user != null);
            if (this.loggedIn) {

                this.auth_register(this.user.email, this.user.name, this.user.firstName, this.user.lastName, this.user.photoUrl, this.user.id, this.user.provider, this.user.id);
            }
        });

        // Update the validity of the 'password_confirmation' field
        // when the 'password' field changes
        this.registerForm.get('numero_identificacion').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.registerForm.get('password_confirmation').updateValueAndValidity();
            });

        //default view 
        this.isOnlyRegister = false;

        this.registerForm.controls['numero_identificacion'].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(100), Validators.pattern('[0-9]+$')]);
        this.registerForm.controls['telefono'].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(12), Validators.pattern('[0-9]+$')]);
        this.registerForm.controls['telefono_2'].setValidators([Validators.minLength(5), Validators.maxLength(12), Validators.pattern('[0-9]+$')]);
        this.registerForm.get('numero_identificacion').updateValueAndValidity();
        this.registerForm.get('user_type').setValue('1');
        this.currentStateEmpresa = false;

        this._route.queryParams
            .subscribe(params => {

                if (params.registro) {
                    this.isOnlyRegister = false;
                    if (params.registro === 'candidato') {
                        this.registerForm.controls['numero_identificacion'].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(100), Validators.pattern('[0-9]+$')]);
                        this.registerForm.get('numero_identificacion').updateValueAndValidity();
                        this.registerForm.get('user_type').setValue('1');
                        this.currentStateEmpresa = false;
                        this.currentStateCandidato = true;
                    } else if (params.registro === 'empresa') {
                        this.registerForm.controls['numero_identificacion'].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(100), Validators.pattern('[0-9]+\-[0-9]{1}$')]);
                        this.registerForm.get('numero_identificacion').updateValueAndValidity();
                        this.registerForm.get('user_type').setValue('2');
                        this.registerForm.controls['tipo_persona'].setValidators([Validators.required]);
                        this.registerForm.get('tipo_persona').updateValueAndValidity();
                        this.currentStateEmpresa = true;
                        this.currentStateCandidato = false;
                    } else {
                        this.registerForm.controls['numero_identificacion'].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(100), Validators.pattern('[0-9]+$')]);
                        this.registerForm.get('numero_identificacion').updateValueAndValidity();
                        this.registerForm.get('user_type').setValue('1');
                        this.currentStateEmpresa = false;
                        this.currentStateCandidato = true;
                    }
                } else {
                    this.registerForm.controls['numero_identificacion'].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(100), Validators.pattern('[0-9]+$')]);
                    this.registerForm.get('numero_identificacion').updateValueAndValidity();
                    this.registerForm.get('user_type').setValue('1');
                    this.currentStateEmpresa = false;
                    this.currentStateCandidato = true;
                }
            });


        if (!window.location.href.includes('localhost')) {
            this.registerForm.controls['recaptcha'].setValidators([Validators.required]);

        }

        // this.getVacants();

        localStorage.removeItem('vacanteId');

        this.filteredEmpresas = this.registerForm.get('empresa').valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value))
          );
    }
    auth_register(email: string, name: string, first_name: string, last_name: string, photoUrl: string, password: string, provider: string, providerId: string) {

        this.loading = true;

        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');

        const formData = this.registerForm.getRawValue();

        formData.user_type = 1;
        formData.email = email;
        formData.name = name;
        formData.first_name = first_name;
        formData.last_name = last_name;
        formData.photo_url = photoUrl;
        formData.password = password;
        formData.provider = provider;
        formData.provider_id = providerId;


        this.httpClient.post(`${this.urlLocal}auth_user_social_media`, formData, { headers: headers }).subscribe(
            (result: any) => {
                if (!result.errors) {

                    localStorage.removeItem('admin_mode');
                    this.openSnackBar("Ingreso satisfactorio", 'snack-success');
                    this.responseHandler(result.sessionData);
                    this.authState.removeUserSession();
                    this.authState.setAuthState(true);
                    this.router.navigate(['/home']);
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.openSnackBar(result.message, 'snack-error');
                }

            });
    }

    inputEmail(e): void {


        if (
            e.target.value.includes('gmail') ||
            e.target.value.includes('hotmail') ||
            e.target.value.includes('yahoo') ||
            e.target.value.includes('outlook')
        ) {
            this.isEmailComporativo = false;
        } else {
            this.isEmailComporativo = true;
        }


    }

    resolved(captchaResponse: string): void {
        // console.log(`Resolved response token: ${captchaResponse}`);

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    abrirModal(usuario): void {
        const modal = this._dialog.open(ModalRegistroComponent, {
            data: usuario
        });

        modal.afterClosed().subscribe(() => {

            this.router.navigate(['/login']);
        });
    }

    OnSubmit(): void {

        // console.log(localStorage.getItem('vacanteId'));
        if (!this.registerForm.invalid){
            this.loading = true;

            const headers = new HttpHeaders();

                
            const formData = this.registerForm.getRawValue();

            if (formData.user_type !== '1') {
                formData.correo_valido = this.isEmailComporativo;
            }
            formData.password = formData.numero_identificacion;
            formData.password_confirmation = formData.numero_identificacion;
            formData.fecha_nacimiento = moment(formData.fecha_nacimiento, ['YYYY-MM-DD', 'DD-MM-YYYY']).format('YYYY-MM-DD');
            formData.fecha_expedicion = moment(formData.fecha_expedicion, ['YYYY-MM-DD', 'DD-MM-YYYY']).format('YYYY-MM-DD');
            if (formData.fecha_expedicion === "Invalid date"){
                formData.fecha_expedicion = null;
            }
            formData.vacante_id = localStorage.getItem('vacanteId');

            formData.url_hojadevida = this.hojavidalink ? this.hojavidalink : null;

            formData.experiencia_laboral = this.hasExperiencia;

            if (this.empresaText != null){
                formData.empresa_id = null;
            }

            this.httpClient.post(`${this.urlLocal}register_user_wc_public`, formData, { headers: headers }).subscribe(
                (result: any) => {
                    if (!result.errors) {
                        this.loading = false;
                        if (formData.user_type === '1') {

                            //this.abrirModal(formData.name);
                            this.openSnackBar("Registro satisfactorio", 'snack-success');
                            this.router.navigateByUrl('/login');
                        } else {
                            let mensaje = 'Registro satisfactorio';
                            if (!this.isEmailComporativo) {
                                mensaje = 'Registro satisfactorio, sin embargo tu inscripción entrará a validación';
                            }

                            this.openSnackBar(`${mensaje}`, 'snack-success');
                            this.sleep(1000).then(() => {
                                this.router.navigateByUrl('/login');
                            });

                        }
                    } else if (result.errors.email) {
                        this.loading = false;

                        this.openSnackBar(result.errors.email[0], 'snack-error');

                    } else if (result.errors.numero_identificacion) {
                        this.loading = false;
                        this.openSnackBar(result.errors.numero_identificacion[0], 'snack-error');
                    } else {
                        this.loading = false;
                        this.openSnackBar(result.message, 'snack-error');
                    }

                });
        }
    }

    private sleep(ms): Promise<any> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    /**
     * Mostrar la respuesta al usuario
     * @param mensaje
     */
    private openSnackBar(mensaje: string, clase: string): void {
        this._snackBar.open(mensaje, 'x', {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'top',
            panelClass: clase,
        });
    }

    OnCandidatoSelected(e): void {


        this.currentStateEmpresa = false;
        this.currentStateCandidato = true;
        this.registerForm.controls['numero_identificacion'].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(100), Validators.pattern('[0-9]+$')]);
        this.registerForm.get('numero_identificacion').updateValueAndValidity();
    }

    OnEmpresaSelected(e): void {


        this.currentStateEmpresa = true;
        this.currentStateCandidato = false;
        this.registerForm.controls['numero_identificacion'].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(100), Validators.pattern('[0-9]+\-[0-9]{1}$')]);
        this.registerForm.get('numero_identificacion').updateValueAndValidity();
    }


    signInWithGoogle(): void {
        this._socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
    }

    signInWithFB(): void {
        const fbLoginOptions = {
            scope: 'email',
            return_scopes: true,
            enable_profile_selector: true
        };
        this._socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID, fbLoginOptions);
    }

    signInWithLinkedIn(): void {
        window.location.href = `https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=${this.linkedInCredentials.clientId
            }&redirect_uri=${this.linkedInCredentials.redirectUrl}&scope=${this.linkedInCredentials.scope}`;
    }

    signOut(): void {
        this._socialAuthService.signOut();
    }

    // Handle response
    responseHandler(data) {
        this.token.handleData(data.access_token);
    }

    openModalVacants(aplicacion, send): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.data = this.vacantes;
        dialogConfig.width = 'auto';
        dialogConfig.maxWidth = '80vw';
        dialogConfig.maxHeight = '70vw';
        this._dialog.open(ModalVacantsComponent, dialogConfig);
        
    }

    openModalMassiveLoads(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.width = 'auto';
        dialogConfig.maxWidth = '80vw';
        dialogConfig.maxHeight = '70vw';
        this._dialog.open(ModalMassiveLoadsComponent, dialogConfig);
        
    }

    getVacants() {
        this.vacantes = []
        //Set business id before call getVacantes
        let page =1;
        this.empresa_id = this.authState.getEmpresaId();
        this.user_id = this.authState.getUserId();
        this.httpClient.get(`${this.urlLocal}vacantes_publico?per_page=500`)
                .subscribe((response: any) => {
                    this.vacantes = response.data;
                }, (err) => {
                    this.vacantes = [];
                });
      }

    getVacantes(page?: any): Promise<any> {
        this.pageIndexFiltro = page || 1;
        return new Promise((resolve) => {
              
                this.httpClient.get(`${this.urlLocal}vacantes_como_empresa/${this.empresa_id}?page=${page}`)
                .subscribe((response: any) => {
                    this.vacantes = response.data;
                    resolve(this.vacantes);

                }, (err) => {
                    this.vacantes = [];
                    resolve(err);

                });
              
            
            }
        );
    }

    setVacanteId(id){
        localStorage.setItem('vacanteId', id);
    }

    onKey(value) { 
        this.selectedciudades = this.search(value);
    }

    search(value: string) { 
        let filter = value.toLowerCase();
        return this.ciudades.filter(option => option.nombre.toLowerCase().includes(filter));
    }

    onFileSelected(event: any) {
        // console.log(event);
        const file: File = event.files[0];
        const formData: any = new FormData();
        formData.append('hojadevida', file);
        this.subirHojaDeVida(formData).then((res) => {
            this.fileInfo = `${file.name} (${formatBytes(file.size)})`;
            this.hojavidalink = res;
        
        });
        function formatBytes(bytes: number): string {
            const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            const factor = 1024;
            let index = 0;
      
            while (bytes >= factor) {
              bytes /= factor;
              index++;
            }
      
            return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
          }
      }


    subirHojaDeVida(data): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.httpClient.post(`${this.urlLocal}candidato_hojadevida_general_public`, data)
                .subscribe((response: any) => {                    
                    resolve(response);
                    if (!response.errors){                     
                        this.openSnackBar("Hoja de vida cargada correctamente", 'snack-success');                        
                    } else {
                        this.openSnackBar("hoja de vida no aceptada", 'snack-error');
                    }
                    
                }, reject);
        });
        
    }

    hasExperienciaChange(event): void{

        this.hasExperiencia = event.value;

        if (event.value) {
            this.registerForm.controls['trabaja_actualmente'].setValidators([Validators.required]);
            this.registerForm.controls['cargo'].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(100)]);
            this.registerForm.controls['empresa'].setValidators([Validators.required]);
            this.registerForm.controls['empresa_telefono'].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(20), Validators.pattern('[0-9]*')]);
            this.registerForm.controls['fecha_inicio'].setValidators([Validators.required]);
            this.registerForm.controls['fecha_fin'].clearValidators();
            
        }else {

            this.registerForm.controls['trabaja_actualmente'].clearValidators();
            this.registerForm.controls['cargo'].clearValidators();
            this.registerForm.controls['empresa'].clearValidators();
            this.registerForm.controls['empresa_telefono'].clearValidators();
            this.registerForm.controls['fecha_inicio'].clearValidators();
            this.registerForm.controls['fecha_fin'].clearValidators();

        }

    }

    keyupSelectEmpresa(event) {
        console.log(event.target.value);
        // this.empresaText = event.target.value;

    }
    blurSelectEmpresa(event) {
        // console.log(event.target.value);
        // this.empresaText = event.target.value;
    }


    focusOutSelectEmpresa(event, empresa) {
        
        if(empresa == null) {
            this.registerForm.controls['empresa'].setValue(this.empresaText);
        }
    }

    changeSelectEmpresa(event, lista : any[]) {
        if (event != null ) {
            let filtred = lista.filter( x => x.nombre === event);
            if (filtred!=null){
                this.registerForm.controls['empresa_id'].setValue(filtred[0].id);

            } else {

                this.registerForm.controls['empresa_id'].setValue(null);
            }   
            
        }
    }


    private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.listaEmpresas.filter(empresa => this._normalizeValue(empresa.nombre).includes(filterValue));
    }

    private _normalizeValue(value: string): string {
        return value.toLowerCase().replace(/\s/g, '');
    }


}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('numero_identificacion');
    const password_confirmation = control.parent.get('numero_identificacion');

    if (!password || !password_confirmation) {
        return null;
    }

    if (password_confirmation.value === '') {
        return null;
    }

    if (password.value === password_confirmation.value) {
        return null;
    }

    return { passwordsNotMatching: true };
};