import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler } from "@angular/common/http";
import { TokenService } from "../shared/token.service";

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {
    constructor(private tokenService: TokenService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {

        if (req.headers.has(InterceptorSkipHeader)) {
            const headers = req.headers.delete(InterceptorSkipHeader);
            return next.handle(req.clone({ headers }));
        }

        if(this.tokenService.getToken())
        {
            const accessToken = this.tokenService.getToken();
            req = req.clone({
                setHeaders: {
                    Authorization: "Bearer " + accessToken
                }
            });
        }        
        return next.handle(req);
    }
}